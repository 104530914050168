import styled from "styled-components/macro";
import { T_PLAIN_big, T_TITLE_2 } from "../../global.style";
import Switch from "../../components/inputs/switch";
import Clickable from "../../components/clickable";
import { VIEW_PADDING_VALUE } from "../../views/View.styles";

export const StyledVatSubmissionDetail = styled.div`
    padding-bottom: ${VIEW_PADDING_VALUE}px;
`;

export const StyledHeading = styled.h2`
    ${T_TITLE_2};
    display: flex;
    align-items: center;
    margin: 16px 0;
`;

export const StyledParagraph = styled.p`
    ${T_PLAIN_big};
    margin-bottom: 24px;
`;

export const SwitchStyled = styled(Switch)`
    margin-left: 40px;
`;

export const HeaderSubtitle = styled.div`
    ${T_TITLE_2};
`;

export const LinksGrid = styled.div`
    display: grid;
    grid-template-columns: auto auto auto;
    row-gap: 12px;
    column-gap: 25px;
    width: fit-content;
    margin-bottom: 24px;
    ${T_PLAIN_big};
`;

export const ClickableStyled = styled(Clickable)`
    display: inline-flex;
    align-items: center;

    & > svg {
        margin-left: 5px;
    }
`;

export const TableWrapperStyled = styled.div`
    margin-bottom: 42px;
`;

export const CustomFormWrapper = styled.div`
    padding-bottom: 38px; // todo: how much padding??
`;