import BindingContext, { IEntity } from "./BindingContext";
import { areSimpleValuesEqual, forEachKey, isDefined, isNotDefined, isObjectEmpty } from "@utils/general";
import isPlainObject from "is-plain-object";
import { TRecordAny, TValue } from "../global.types";
import { BatchRequest, ICommandArgs } from "./OData";
import { IAdditionalData, Model } from "../model/Model";
import { Property } from "@evala/odata-metadata/src";
import { cloneDeep, isEqual } from "lodash";
import { IChartOfAccountsEntity, IEntityBase } from "./GeneratedEntityTypes";
import { ODATA_API_URL } from "../constants";
import { IAppContext } from "../contexts/appContext/AppContext.types";
import { memoizedWithCacheStrategy } from "@utils/CacheCleaner";
import { CacheStrategy } from "../enums";
import customFetch from "../utils/customFetch";
import { getUtcDate } from "../types/Date";

import { ODataPropertyValue } from "@odata/Data.types";

export const getUniqueContextsSuffix = (bindingContext1: BindingContext, bindingContext2: BindingContext = null): BindingContext[] => {
    const bindingContexts1 = bindingContext1.getFullPathAsArrayOfContexts();
    const bindingContexts2 = bindingContext2 && bindingContext2.getFullPathAsArrayOfContexts();

    // remove the mutual path prefix
    while (
        bindingContexts2?.[0]
        && (bindingContexts1[0].getPath() === bindingContexts2[0].getPath()
            || bindingContexts1[0].getPath() === bindingContexts2[0].getPath(true))) {
        bindingContexts1.shift();
        bindingContexts2.shift();
    }

    return bindingContexts1;
};

export const getUniqueContextsSuffixAsString = (bindingContext1: BindingContext, bindingContext2: BindingContext, separator = "/"): string => {
    const suffix = getUniqueContextsSuffix(bindingContext1, bindingContext2);
    return suffix.map(bc => bc.getPath(true)).join(separator);
};

export const getSharedContextPrefix = (bindingContext1: BindingContext, bindingContext2: BindingContext): BindingContext => {
    let parentBc = bindingContext2.getParent();

    while (parentBc && bindingContext1.getNavigationPath(true) !== parentBc.getNavigationPath(true)) {
        parentBc = parentBc.getParent();
    }

    return parentBc || bindingContext2;
};

interface IGetBoundValue {
    bindingContext: BindingContext;
    data: any | any[];
    dataBindingContext?: BindingContext;
}

export interface ISetBoundValue extends IGetBoundValue {
    newValue: any;
    preventCloning?: boolean;
}

export interface ISaveEntity {
    entity: IEntity;
    bindingContext: BindingContext;
    originalEntity?: IEntity;
    batch: BatchRequest;
    queryParams?: TRecordAny;
    changesOnly?: boolean;
    isNew?: boolean;
    // list of entities (of navigation or collection properties) with independent entity set that will be handled by prepareBatch
    // to create PATCH (for navigation) or CREATE/PATCH/REMOVE (for collection) requests automatically
    updateEnabledFor?: string[];
    customBatchId?: string;
    etag?: string;
}

/**
 * Retrieves bound data from the data structure that can specify its root binding context
 *
 * @param arguments
 * @param {BindingContext} arguments.bindingContext Points to where the value is bound
 * @param {object} arguments.data
 * @param {BindingContext} [arguments.dataBindingContext] Points to the starting point of the data object
 * @returns {any}
 */
export const getBoundValue = ({ bindingContext, data, dataBindingContext }: IGetBoundValue) => {
    let value = data;

    if (!data || isObjectEmpty(data)) {
        return undefined;
    }

    const bindingContexts = getUniqueContextsSuffix(bindingContext, dataBindingContext);

    for (const bindingContext of bindingContexts) {
        const path = bindingContext.getPath(true);

        // key can be '0'
        if (isDefined(bindingContext.getKey())) {
            const keyName = bindingContext.getKeyPropertyName();
            // data object can either contain array of entities or object with the name of the navigation that then contains the array of entities
            // e.g. for Items(1) the data object can be
            // either [{Item1}, {Item2}]
            // or {Items: [{Item1}, {Item2}]
            if (!Array.isArray(value)) {
                value = value && value[path];
            }

            value = value && (value as any[]).find(item => item?.[keyName] === bindingContext.getKey());
        } else {
            value = value && value[path];
        }
    }

    return value;
};

export interface ICachedFieldState {
    isRequired?: boolean;
    isVisible?: boolean;
}

export interface IStorageSmartFieldValuesWithoutError {
    value: TValue;
    origValue?: TValue;
    additionalFieldData?: IAdditionalData;
}

export interface IStorageSmartFieldValues extends IStorageSmartFieldValuesWithoutError, ICachedFieldState {
}

/**
 * Sets value into the data structure to path specified by the bindingContext.
 * Returns updated data structure.
 *
 * @param arguments
 * @param {BindingContext} arguments.bindingContext Points to where the value is bound
 * @param {object|array} arguments.data
 * @param {any} arguments.newValue
 * @param {BindingContext} [arguments.dataBindingContext] Points to the starting point of the data object
 * @param {boolean} [arguments.preventCloning] Whether the data structure should be treated as immutable or not
 * @returns {object|array}
 */

export const setBoundValue = ({
                                  bindingContext,
                                  data,
                                  newValue,
                                  dataBindingContext,
                                  preventCloning
                              }: ISetBoundValue) => {
    const _getValueWithKey = (bindingContext: BindingContext, value: any, path: string) => {
        const keyName = bindingContext.getKeyPropertyName();

        // same approach as in getBoundValue, see explanation there
        if (!Array.isArray(value)) {
            // if the collection object doesn't exist, create it, together with the new entity
            if (!value[path]) {
                value[path] = [{
                    [keyName]: bindingContext.getKey()
                }];
            }
            value = value[path];
        }

        let indexOfItem = (value as any[]).findIndex(item => item[keyName] === bindingContext.getKey());

        // if entity doesn't exist, create it
        if (indexOfItem < 0) {
            value.push({
                [keyName]: bindingContext.getKey()
            });
            indexOfItem = value.length - 1;
        }

        return [value, indexOfItem];
    };

    const _setValue = <T>(obj: T, key: keyof T, value: any) => {
        if ((Array.isArray(obj) && isNotDefined(value))) {
            obj.splice(key as number, 1);
        } else if (value === undefined) {
            delete obj[key];
        } else {
            obj[key] = value;
        }
    };

    let updatedData;

    if (preventCloning) {
        updatedData = data;
    } else {
        updatedData = Array.isArray(data) ? [...data] : { ...data };
    }

    let value = updatedData;

    const bindingContexts = getUniqueContextsSuffix(bindingContext, dataBindingContext);

    for (let i = 0; i < bindingContexts.length; i++) {
        const bindingContext = bindingContexts[i];
        const path = bindingContext.getPath(true);

        if (i === bindingContexts.length - 1) {
            // key can be '0'
            if (isDefined(bindingContext.getKey())) {
                const [val, indexOfItem] = _getValueWithKey(bindingContext, value, path);

                value = val;
                _setValue(value, indexOfItem, newValue);
            } else {
                _setValue(value, path, newValue);
            }
        } else {
            if (isDefined(bindingContext.getKey())) {
                const [val, indexOfItem] = _getValueWithKey(bindingContext, value, path);
                value = val;

                if (!preventCloning) {
                    // copy the object to prevent accessing old object reference
                    value[indexOfItem] = { ...value[indexOfItem] };
                }

                value = value[indexOfItem];

            } else {
                if (isPlainObject(value[path])) {
                    if (!preventCloning) {
                        // copy the object to prevent accessing old object reference
                        value[path] = { ...value[path] };
                    }
                }

                if (!value[path]) {
                    value[path] = {};
                }

                value = value[path];
            }
        }
    }

    return updatedData;
};

export const setNestedValue = (value: TValue | TRecordAny, path: string, data: TRecordAny) => {
    let schema = data;
    const parsedPath = path.split("/");
    const len = parsedPath.length;

    for (let i = 0; i < len - 1; i++) {
        let elem: number | string = parsedPath[i];
        if (!schema[elem]) {
            const nextIdx = Number.parseInt(parsedPath[i + 1]);
            const idx = Number.parseInt(elem);
            if (!isNaN(idx)) {
                elem = idx;
            }
            schema[elem] = isNaN(nextIdx) ? {} : [];
        }

        schema = schema[elem];
    }

    schema[parsedPath[len - 1]] = value;
    return data;
};

export const getNestedValue = (path: string, data: TRecordAny) => {
    let schema = data;
    const parsedPath = path.split("/");
    const len = parsedPath.length;
    for (let i = 0; (i < len - 1) && schema; i++) {
        const elem = parsedPath[i];
        schema = schema[elem];
    }

    return schema ? schema[parsedPath[len - 1]] : undefined;
};

/**
 * Method returns same value as EntityType.getFullName(), but from property metadata. When using with navigation
 * property, entity is the property value itself and propName should be default empty string
 * @param entity
 * @param propName
 */
export const getFullEntityTypeFromProp = (entity: IEntityBase, propName = ""): string => {
    return entity?._metadata?.[propName]?.type?.slice(1);
};

interface IGetSubordinateEntitySetPath {
    bindingContext: BindingContext;
    navigationProperty: string;
    entity: IEntity;
    dataBindingContext?: BindingContext;
}

// try to find first entity type that contains property with the same entity type as navigationProperty and has Subordinate annotation
// this means that the navigationProperty doesn't have its own entity set, but it has to be put together from the parent entity
// e.g. propBindingContext === "InvoicesReceived(2)/Labels/Label" => entity set is "LabelHierarchies(1)/Labels"
// for this to work we always have to load the reference as additionalProperty (in this case LabelHierarchies Id has to be present in "entity" object)
export const getSubordinateEntitySetPath = (args: IGetSubordinateEntitySetPath) => {
    const metadata = args.bindingContext._metadata;
    // getSubordinateEntitySetPath sometimes need wholeEntityTree, but we don't know (and cannot determine) correct dataBindingContext,
    // which should be used to obtain property value from args.entity.
    // Usually this function is called with data object with dataBindingContext same as bindingContext
    const dataBindingContext = args.dataBindingContext ?? args.bindingContext.getRootParent();

    const value = getBoundValue({
        bindingContext: args.bindingContext.navigate(args.navigationProperty),
        dataBindingContext,
        data: args.entity
    });
    const navigationEntityTypeName = getFullEntityTypeFromProp(value) ?? args.bindingContext.navigate(args.navigationProperty).getEntityType().getFullName();
    let subordinateEntitySetPath;

    // special case
    // Account has navigation property Parent which references the same Account entity type
    // differently from e.g. InvoiceReceived/BillingAddress which is dependent on the parent
    // the Account is not, it is still dependent on the ChartOfAccounts and so correct request is
    // {
    //     "Parent": {
    //         "@odata.id": "ChartsOfAccounts(4)/Accounts(927)"
    //     }
    // }
    // this CANNOT be inferred from the data and has, thus this condition is needed
    if (navigationEntityTypeName === args.bindingContext.getEntityType().getFullName()) {
        subordinateEntitySetPath = args.bindingContext.removeKey().toString();
    } else {
        if (isNotDefined(value) || isObjectEmpty(value)) {
            // if value is not set, we don't need to know SubordinateEntitySetPath. In some case, there is none, e.g. if
            // date is out of any FY, there is no chart of account and therefore account is not set either
            return null;
        }
        for (const entitySet of Object.values(metadata.entitySets)) {
            if (subordinateEntitySetPath) {
                break;
            }

            const entityTypeName = entitySet.getType().getFullName();
            const entityType = metadata.entities[entityTypeName];

            for (const property of Object.values(entityType.getProperties())) {
                const propertyEntityTypeName = property.getType().getFullName();

                if (!property.isSubordinate() || entityTypeName === navigationEntityTypeName) {
                    // we are only interested in subordinate properties, those can be used to compose nested entity set path like ChartsOfAccounts(4)/Accounts(927)
                    // and we have to avoid loops caused by properties that refer to the same entity type, like Account/Children, where Children are again Accounts
                    continue;
                }

                if (propertyEntityTypeName === navigationEntityTypeName) {
                    let foundRefProp: Property;
                    let entity: IEntity = null;
                    let currentBc = args.bindingContext;
                    let skip = false;

                    while (!foundRefProp && currentBc) {
                        entity = getBoundValue({
                            bindingContext: currentBc,
                            dataBindingContext,
                            data: args.entity
                        });
                        const [refProp, ...rest] = Object.values(currentBc.getEntityType().getProperties()).filter(p => {
                            const storedEntityTypeName = getFullEntityTypeFromProp(entity[p.getName()]);
                            const type = storedEntityTypeName ?? p.getType().getFullName();
                            const areTypesEqual = type === entityTypeName;

                            // special case now relevant for Documents
                            // there is one shared RegularDocumentItem shared for multiple different document types
                            // => we need to iterate over all of them, to find the one that actually is equal with storedEntityTypeName
                            // without throwing an error
                            if (!areTypesEqual && storedEntityTypeName && entityType.getBaseType().getFullName() === p.getType().getFullName()) {
                                // skip when the BaseType (Document) is same, but the actual type (e.g. InvoiceReceived vs InvoiceIssued) is different
                                skip = true;
                            }

                            return areTypesEqual;
                        });

                        if (rest?.length > 0) {
                            throw new Error(`${currentBc.toString()} contains multiple references to ${entityTypeName}, which is wrong. Ask backend dev to fix this.`);
                        }

                        foundRefProp = refProp;
                        currentBc = currentBc.getParent();
                    }

                    if (skip) {
                        continue;
                    }

                    if (!foundRefProp || isNotDefined(entity?.[foundRefProp.getName()])) {
                        throw new Error(`${args.bindingContext.navigate(args.navigationProperty).toString()} data object is missing reference to ${entityTypeName}. Did you forget to add it as 'additionalProperty'?`);
                    }

                    subordinateEntitySetPath = `${entitySet.getName()}(${entity[foundRefProp.getName()].Id})/${property.getName()}`;
                    break;
                }
            }
        }
    }

    return subordinateEntitySetPath;
};

interface IWholeEntityTree {
    // whole entity from the root binding context
    // needed for getSubordinateEntitySetPath so that we can lookup references
    // that are not present on the entity but instead on some of its parents
    wholeEntityTree?: IEntity;
    dataBindingContext?: BindingContext;
}

/**
 * Prepares body object and creates additional batch calls based on differences between original state of entity and its current state
 *
 * How different properties are handled
 * 1. simple properties - no changes
 *      e.g. InvoicesReceived(1)/NumberTheirs
 * 2. navigation properties
 *      a) with referential constraint - sent as simple property instead of navigation (e.g. as CurrencyCode: "CZK" instead of Currency: {code: "CZK"}
 *          e.g. InvoicesReceived(1)/Currency
 *      b) subordinate navigation - sent as @odata.delta
 *          e.g. InvoicesReceived(1)/DocumentBillingBankAccount
 *      c) with custom entity set - referenced with @odata.id, POST request are sent automatically for new ones, PATCH/DELETE aren't => we would have to keep originalEntity in sync
 *          e.g. InvoicesReceived(1)/BusinessPartner
 * 3. collections
 *      a) subordinate collections - sent as @odata.delta or @odata.removed
 *          e.g. InvoicesReceived(1)/Items
 *      b) with custom entity set - referenced with @odata.id, POST/PATCH/DELETE are all handled automagically
 *          e.g. BankStatements(1)/Transactions
 *
 */

interface IPreparePropertyArgs {
    /** Name of the property */
    prop: string;
    propBindingContext: BindingContext;
    /** Name of the key property of current entity type */
    propKeyName: string;
    /** Name of the navigation set, if exists (for independent navigation property or collection) */
    navigationEntitySet: string;
    navigationEntitySetBindingContext: BindingContext;
}

export interface IPrepareBatchResult {
    values: TRecordAny;
    /** Array of callbacks that will add into the batch requests that are dependent on the parent one */
    afterRequests: addRequestCallback[];
}


export const prepareBatch = (args: ISaveEntity & IWholeEntityTree, shouldKeepKey?: boolean): IPrepareBatchResult => {
    const values: TRecordAny = {};
    const afterRequests: addRequestCallback[] = [];

    args.originalEntity = args.originalEntity ?? {};
    args.wholeEntityTree = args.wholeEntityTree ?? args.entity;

    if (!args.entity) {
        return {
            values: args.entity,
            afterRequests
        };
    }

    if (!shouldKeepKey) {
        // remove new #id or regular id
        args.entity = removeKeyProperty(args.entity, args.bindingContext);
    }

    // check what changed and create oData calls base on those changes
    for (const prop of Object.keys(args.entity)) {
        if (BindingContext.isMetaProperty(prop)) {
            continue;
        }

        const propBindingContext = args.bindingContext.navigate(prop);
        const propKeyName: string = propBindingContext.getKeyPropertyName();

        if (
            (propBindingContext.getProperty().isReadOnly() && propBindingContext.getPath() !== propKeyName) // keep key property if its not already removed
            || (propBindingContext.getProperty().isImmutable() && !args.bindingContext.isNew())
        ) {
            continue;
        }

        // try to retrieve entity set - either independent one (InvoicesReceived), or nested for subordinate entity (ChartOfAccounts(1)/Accounts)
        let navigationEntitySet = null;
        let navigationEntitySetBindingContext = null;

        if (propBindingContext.isNavigation()) {
            try {
                // type of the navigation property can be specified in _metadata.type
                const metadataEntityTypeFullName = getFullEntityTypeFromProp(args.entity[prop]);

                if (metadataEntityTypeFullName) {
                    navigationEntitySet = propBindingContext._metadata.getEntitySetForEntityType(propBindingContext._metadata.entities[metadataEntityTypeFullName])?.getName();
                    if (!navigationEntitySet) {
                        /**
                         * Tries also to get subordinateEntitySet for properties with type in _metadata,
                         * e.g. DocumentItem relation on MinorAssetItem
                         */
                        navigationEntitySet = getSubordinateEntitySetPath({
                            bindingContext: args.bindingContext,
                            dataBindingContext: args.dataBindingContext,
                            navigationProperty: prop,
                            entity: args.wholeEntityTree
                        });
                    }
                } else {
                    navigationEntitySet = propBindingContext.getEntitySet().getName();
                }
            } catch (e) {
                const preparePropEntityTypeName = propBindingContext.getEntityType().getFullName();
                if (!propBindingContext.getProperty().isSubordinate() || preparePropEntityTypeName === args.bindingContext.getEntityType().getFullName()) {
                    navigationEntitySet = getSubordinateEntitySetPath({
                        bindingContext: args.bindingContext,
                        dataBindingContext: args.dataBindingContext,
                        navigationProperty: prop,
                        entity: args.wholeEntityTree
                    });
                }
            }
        }

        if (navigationEntitySet) {
            navigationEntitySetBindingContext = args.bindingContext.createNewBindingContext(navigationEntitySet);
        }

        const prepareArgs: IPreparePropertyArgs = {
            prop, propBindingContext, propKeyName, navigationEntitySet, navigationEntitySetBindingContext
        };
        let result: IPreparePropertyResult;

        if (propBindingContext.isCollection()) {
            result = prepareCollection(args, prepareArgs);
        } else if (propBindingContext.isNavigation()) {
            result = prepareNavigationProperty(args, prepareArgs);
        } else {
            result = prepareSimpleProperty(args, prepareArgs);
        }

        if (result?.prop) {
            values[result.prop] = result.value;
        }

        if (result?.afterRequests) {
            afterRequests.push(...result.afterRequests);
        }
    }

    return {
        values, afterRequests
    };
};

type addRequestCallback = (parentRef: ODataPropertyValue) => void;

interface IPreparePropertyResult {
    prop: string;
    value: TRecordAny;
    afterRequests?: addRequestCallback[];
}

export const findPropertyOfType = (bindingContext: BindingContext, entityTypeName: string): Property => {
    return Object.values(bindingContext.getEntityType().getProperties()).find((property: Property) => property.type.name === entityTypeName);
};

export const isPropertyRequiredOnParent = (property: Property): boolean => {
    return !property || property.isNullable();
};

const prepareSimpleProperty = (args: ISaveEntity, {
    prop,
    propKeyName
}: IPreparePropertyArgs): IPreparePropertyResult => {
    if (args.changesOnly && isEqual(args.entity[prop], args.originalEntity[prop]) && propKeyName !== prop) {
        return null;
    }

    let value = args.entity[prop];

    if (value === "") {
        // backend fails on empty strings if some other check is implemented (e.g. email)
        // sending null instead of ""
        value = null;
    }

    return {
        prop, value
    };
};

const prepareNavigationProperty = (saveArgs: ISaveEntity, {
    prop,
    propBindingContext,
    propKeyName,
    navigationEntitySet,
    navigationEntitySetBindingContext
}: IPreparePropertyArgs): IPreparePropertyResult => {
    const referentialConstraint = propBindingContext.getProperty().getReferentialConstraint();

    let returnPropName = null;
    let returnValue = null;
    const afterRequests: addRequestCallback[] = [];

    if (referentialConstraint || navigationEntitySet) {
        const isUpdateEnabled = !!saveArgs.updateEnabledFor?.includes(propBindingContext.getNavigationPath(true));
        // for saveArgs.changesOnly === false, we only want to know if the navigation points to another object
        const hasReferenceChanged = !isEqual((saveArgs.entity[prop] as IEntity)?.[propKeyName], (saveArgs.originalEntity[prop] as IEntity)?.[propKeyName]);
        // for saveArgs.changesOnly === true, we want to know whether any change has happened to create patch request
        let hasAnythingChanged = false;

        if (saveArgs.changesOnly && isUpdateEnabled) {
            hasAnythingChanged = !isEqual((saveArgs.entity[prop] as IEntity), (saveArgs.originalEntity[prop] as IEntity));
        }

        // check if the parent reference is nullable to establish the direction of the relation dependency
        // to correctly order the requests in batch
        // e.g. when creating new InvoiceReceived - InvoiceReceived requires BusinessPartner to be created => batch order is [BusinessPartner, InvoiceReceived]
        // but when creating new Account - BalanceSheetLayout requires Account => batch order is [Account, BalanceSheetLayout]

        const parentEntityType = saveArgs.bindingContext.getEntityType();
        const parentEntityTypeName = parentEntityType.getName();
        const navigationToParent = findPropertyOfType(navigationEntitySetBindingContext, parentEntityTypeName);

        if (!saveArgs.changesOnly || hasReferenceChanged || hasAnythingChanged) {
            if (referentialConstraint) {
                // for navigation properties with referentialConstraint like Currency
                // use CurrencyCode in the request instead of more complex navigation object
                returnPropName = referentialConstraint.property;
                returnValue = (saveArgs.entity[prop]?.[referentialConstraint.referencedProperty]) ?? null;
            } else if (navigationEntitySet) {
                if (isObjectEmpty(saveArgs.entity[prop]) || Object.values(saveArgs.entity[prop]).every(val => val === null)) {
                    // empty object
                    returnPropName = prop;
                    returnValue = null;
                } else {
                    const referencedValue = (saveArgs.entity[prop] as IEntity)?.[propKeyName];
                    let reference;

                    // id present - just use as navigation to navigationEntitySet
                    if (referencedValue) {
                        // references to other batch id starts with "$" - we use those directly
                        if (referencedValue.toString().startsWith("$")) {
                            reference = referencedValue;
                        } else {
                            // if the entity type is in "updateEnabledFor" create patch requests based on differences with originalEntity
                            if (saveArgs.updateEnabledFor?.includes(propBindingContext.getNavigationPath(true))) {
                                const fnUpdateEntity = () => {
                                    return updateEntity({
                                        entity: saveArgs.entity[prop],
                                        originalEntity: saveArgs.originalEntity[prop] ?? {},
                                        bindingContext: navigationEntitySetBindingContext.addKey(referencedValue),
                                        batch: saveArgs.batch,
                                        changesOnly: true,
                                        etag: saveArgs.entity[prop]?._metadata?.[""]?.etag
                                    });
                                };

                                if (isPropertyRequiredOnParent(navigationToParent)) {
                                    fnUpdateEntity();
                                } else {
                                    afterRequests.push(
                                        (parentReference: ODataPropertyValue) => {
                                            fnUpdateEntity();
                                        }
                                    );
                                }
                            }

                            if (!saveArgs.changesOnly || hasReferenceChanged) {
                                // otherwise, we have to build correct entity path
                                reference = `${navigationEntitySet}(${referencedValue})`;
                            }
                        }
                    } else {
                        // until isNavigationForUpdate behavior is changed, it can return {Id: null} instead of just null
                        // we don't want to create new object when it doesn't have any properties
                        if (!saveArgs.entity[prop].hasOwnProperty(propKeyName) || Object.keys(saveArgs.entity[prop]).length > 1) {
                            // no id - create new object in the navigationEntitySet adding another request in the batch
                            // and use the new id as reference in the current request
                            // e.g. create new BusinessPartner while saving InvoiceReceived

                            const fnCreateEntity = (entity: IEntity) => {
                                let newNavEntitySet = navigationEntitySetBindingContext;
                                if (!navigationEntitySetBindingContext.isNew()) {
                                    newNavEntitySet = navigationEntitySetBindingContext.addKey(1, true);
                                }

                                return createEntity({
                                    entity: entity,
                                    bindingContext: newNavEntitySet,
                                    batch: saveArgs.batch
                                });
                            };

                            // prop is required on parent
                            if (isPropertyRequiredOnParent(navigationToParent)) {
                                const newId = fnCreateEntity(saveArgs.entity[prop]);

                                reference = `$${newId}`;
                            } else { // parent is required on prop
                                afterRequests.push(
                                    (parentReference: ODataPropertyValue) => {
                                        const entity = cloneDeep(saveArgs.entity[prop]);

                                        // insert reference to parent
                                        entity[navigationToParent.getName()] = {
                                            [parentEntityType.getKeys()[0].getName()]: parentReference
                                        };

                                        fnCreateEntity(entity);
                                    }
                                );
                            }
                        }
                    }

                    if (reference) {
                        returnPropName = prop;
                        returnValue = {
                            "@odata.id": reference
                        };
                    }
                }
            }
        }
    } else { // subordinate navigation property
        const navPropName = saveArgs.isNew ? prop : `${prop}@odata.delta`;
        const entity = saveArgs.entity[prop];
        const origEntity = saveArgs.originalEntity[prop] ?? {};

        if (saveArgs.changesOnly && !isObjectEmpty(entity) && isObjectEmpty(compareEntities(entity, origEntity, propBindingContext))) {
            return null;
        }

        const result = prepareBatch({
            ...saveArgs,
            entity: entity,
            originalEntity: origEntity,
            bindingContext: propBindingContext
        });

        returnPropName = navPropName;

        if (!isObjectEmpty(result.values)) {
            returnValue = result.values;
        } else {
            returnValue = null;
        }

        if (result?.afterRequests) {
            afterRequests.push(...result.afterRequests);
        }
    }

    return {
        prop: returnPropName,
        value: returnValue,
        afterRequests: afterRequests
    };
};

export const prepareCollection = (args: ISaveEntity, {
    prop,
    propBindingContext,
    propKeyName,
    navigationEntitySet,
    navigationEntitySetBindingContext
}: IPreparePropertyArgs): IPreparePropertyResult => {
    let items: IEntity[] = [];
    const afterRequests: addRequestCallback[] = [];
    const keyPropertyName = propBindingContext.getKeyPropertyName();
    let key;
    let itemsDeltaNeeded = false;

    // removed items
    if (args.originalEntity[prop]) {
        for (const origItem of args.originalEntity[prop]) {
            if (!(args.entity[prop] as IEntity[])?.find(item => item[propKeyName] === origItem[propKeyName])) {
                key = origItem[keyPropertyName];

                if (navigationEntitySet && args.updateEnabledFor?.includes(propBindingContext.getNavigationPath(true))) {
                    // item from collection with own entity set + updateEnabledFor
                    const wrapper = args.batch.fromPath(navigationEntitySet);

                    wrapper.delete(key);
                } else if (propBindingContext.getProperty().isIndependent()) {
                    // delete just $ref, not the entity itself
                    // sadly, @data.delta doesn't seem to support that, so we need to send each change separately in batch
                    const wrapper = args.batch.fromPath(propBindingContext.getRootParent().getPath(true));

                    wrapper.delete(propBindingContext.getRootParent().getKey(), { customUrlSuffix: `/${propBindingContext.addKey(origItem).getPath()}/$ref` });
                } else {
                    itemsDeltaNeeded = true;
                    // @odata.removed has to be first key of the object it seems (before the id property)
                    items.push({
                        "@odata.removed": {
                            reason: "removed"
                        },
                        [keyPropertyName]: key
                    });
                }
            }
        }
    }

    // new and updated items
    if (args.entity[prop]) {
        for (let i = 0; i < args.entity[prop].length; i++) {
            const item = args.entity[prop][i];
            const origItemIndex = args.originalEntity?.[prop]?.findIndex((origItem: IEntity) => origItem[propKeyName] === item[propKeyName]);
            const origItem = isDefined(origItemIndex) && origItemIndex >= 0 ? args.originalEntity[prop][origItemIndex] : null;
            let preparedItem = item;

            if (origItem) {
                const itemChanges = args.changesOnly ? compareEntities(item, origItem, propBindingContext) : item;

                if (!isObjectEmpty(itemChanges)) {
                    preparedItem = itemChanges;
                } else {
                    preparedItem = null;
                }
            }

            if (preparedItem) {
                if (!navigationEntitySet
                    // includable properties can be included in the first POST request, together with the parent
                    || (args.isNew && propBindingContext.getProperty().isIncludable())
                    // dependent properties has to always be updated via parent, even if they have their own entity set
                    || propBindingContext.getProperty().isDependent()
                ) {
                    const result = prepareBatch({
                        ...args,
                        entity: preparedItem,
                        originalEntity: origItem ?? {},
                        bindingContext: propBindingContext.addKey(preparedItem)
                    }, !!origItem);

                    itemsDeltaNeeded = true;
                    items.push(result.values);
                    afterRequests.push(...result.afterRequests);
                } else { // item from collection with own entity set
                    const referencedValue = item[propKeyName];
                    const itemBc = navigationEntitySetBindingContext?.addKey(preparedItem);

                    if (!referencedValue) {
                        // collections with own entity set always have the reference with foreign key, not the other way around
                        // => don't push anything to 'items' and use afterRequests for the batch requests
                        afterRequests.push(
                            (parentReference: ODataPropertyValue) => {
                                const parentEntityType = args.bindingContext.getEntityType();
                                const navigationToParent = findPropertyOfType(navigationEntitySetBindingContext, parentEntityType.getName());

                                preparedItem[navigationToParent.getName()] = {
                                    [parentEntityType.getKeys()[0].getName()]: parentReference
                                };

                                createEntity({
                                    entity: preparedItem,
                                    bindingContext: itemBc,
                                    batch: args.batch
                                });

                            }
                        );
                    } else {
                        updateEntity({
                            entity: preparedItem,
                            originalEntity: origItem,
                            bindingContext: itemBc,
                            batch: args.batch,
                            changesOnly: true,
                            etag: item?._metadata?.[""]?.etag
                        });

                        items.push({
                            "@odata.id": `${navigationEntitySet}(${referencedValue})`
                        });

                        // since items use own entity set, we only need to send delta update on the parent entity
                        // if the order of the items has changed, because subsequent updates are sent in separate requests
                        if (i !== origItemIndex) {
                            itemsDeltaNeeded = true;
                        }
                    }
                }

            }
        }
    }

    let propName = prop;

    if (!args.isNew) {
        propName += "@odata.delta";
    }

    if (!itemsDeltaNeeded) {
        items = [];
    }

    if (args.changesOnly && items.length === 0) {
        return {
            prop: null,
            value: null,
            afterRequests
        };
    }

    return {
        prop: propName,
        value: items,
        afterRequests
    };
};

const shouldIgnoreProp = (prop: string) => {
    return prop === "TemporaryGuid" || BindingContext.isLocalContextPath(prop);
};

export const areSaveValuesEqual = (val1: any, val2: any): boolean => {
    let areEqual = true;

    if (isNotDefined(val1)) {
        return isNotDefined(val2) || isObjectEmpty(val2);
    }

    if (isNotDefined(val2)) {
        return false;
    }

    if (typeof val1 === "object" && !(val1 instanceof Date)) {
        if (Array.isArray(val1)) {
            if (!val2 || val1.length !== val2.length) {
                areEqual = false;
            } else {
                for (let i = 0; i < val1.length; i++) {
                    areEqual = areEqual && areSaveValuesEqual(val1[i], val2[i]);
                }
            }
        } else if (isObjectEmpty(val1)) {
            return isObjectEmpty(val2);
        } else {
            for (const navProp of Object.keys(val1)) {
                if (shouldIgnoreProp(navProp)) {
                    continue;
                }

                areEqual = areEqual && areSaveValuesEqual(val1[navProp], val2[navProp]);
            }
        }
    } else {
        areEqual = areSimpleValuesEqual(val1, val2);
    }

    return areEqual;
};

const compareEntities = (updatedEntity: IEntity = {}, origEntity: IEntity = {}, bindingContext: BindingContext) => {
    if (!!updatedEntity !== !!origEntity) {
        return updatedEntity;
    }


    const differentValues: IEntity = {};

    for (const navProp of Object.keys(updatedEntity ?? {})) {
        if (shouldIgnoreProp(navProp)) {
            continue;
        }

        if (!areSaveValuesEqual(updatedEntity[navProp], origEntity[navProp])) {
            differentValues[navProp] = updatedEntity[navProp];
        }
    }

    if (!isObjectEmpty(differentValues)) {
        const keyProp = bindingContext.getKeyPropertyName();
        differentValues[keyProp] = updatedEntity[keyProp];
    }

    return differentValues;
};

const removeKeyProperty = (entity: IEntity, bindingContext: BindingContext) => {
    let bindingContextWithKey = bindingContext;

    // key can be '0'
    if (isNotDefined(bindingContext.getKey())) {
        bindingContextWithKey = bindingContext.addKey(entity);
    }

    const keyName = bindingContextWithKey.getKeyPropertyName();
    const { [keyName]: newEntityProp, ...cleanEntity } = entity;

    return cleanEntity;
};

export const mainBatchRequestId = "main";

export const saveEntity = (args: ISaveEntity): BatchRequest => {
    if (args.bindingContext.isNew()) {
        createEntity({
            entity: args.entity,
            bindingContext: args.bindingContext,
            queryParams: args.queryParams,
            batch: args.batch,
            updateEnabledFor: args.updateEnabledFor,
            customBatchId: args.customBatchId ?? mainBatchRequestId
        });
    } else {
        updateEntity({
            changesOnly: args.changesOnly,
            entity: args.entity,
            originalEntity: args.originalEntity,
            bindingContext: args.bindingContext,
            queryParams: args.queryParams,
            batch: args.batch,
            updateEnabledFor: args.updateEnabledFor,
            etag: args.etag,
            customBatchId: args.customBatchId ?? mainBatchRequestId
        });
    }

    return args.batch;
};

export const createEntity = ({
                                 entity,
                                 bindingContext,
                                 batch,
                                 queryParams,
                                 wholeEntityTree,
                                 dataBindingContext,
                                 updateEnabledFor,
                                 customBatchId
                             }: ISaveEntity & IWholeEntityTree) => {
    const queryableEntity = batch.fromPath(bindingContext.removeKey().toString());
    const result = prepareBatch({ entity, bindingContext, isNew: true, wholeEntityTree, dataBindingContext, updateEnabledFor, batch });

    queryableEntity.queryParameters(queryParams);

    if (customBatchId) {
        queryableEntity.batchId(customBatchId);
    }

    const batchId = queryableEntity.create(result.values);

    if (result.afterRequests) {
        for (const addReqCallback of result.afterRequests) {
            addReqCallback(`$${batchId}`);
        }
    }

    return batchId;
};

export const updateEntity = ({
                                 entity,
                                 originalEntity,
                                 bindingContext,
                                 batch,
                                 queryParams,
                                 changesOnly,
                                 updateEnabledFor,
                                 etag,
                                 customBatchId
                             }: ISaveEntity & { customBatchId?: string }) => {
    const queryableEntity = batch.fromPath(bindingContext.toString());
    const result = prepareBatch({
        entity,
        originalEntity,
        bindingContext,
        changesOnly,
        updateEnabledFor,
        batch
    });
    let batchId = null;

    if (!isObjectEmpty(result?.values) || !isObjectEmpty(queryParams)) {
        const args: ICommandArgs = {};

        if (etag) {
            args.headers = {
                "If-Match": etag
            };
        }

        queryableEntity.queryParameters(queryParams);

        if (customBatchId) {
            queryableEntity.batchId(customBatchId);
        }

        batchId = queryableEntity.update(null, result.values, args);
    }

    if (result?.afterRequests) {
        for (const addReqCallback of result.afterRequests) {
            addReqCallback(bindingContext.getKey());
        }
    }

    return batchId;
};

/**
 *  This method converts binding context path with collection (Items(81)/Description) to it's version based on indexed data =>
 *  Items[2]/Description. Such path is required for yup validation
 * @param bindingContext
 * @param data Data for determining indexes
 * @param dataBindingContext
 * @param separator
 */
export const getIndexedPathFromBindingContext = (bindingContext: BindingContext, data: any, dataBindingContext: BindingContext, separator = ".") => {
    const bindingContexts = getUniqueContextsSuffix(bindingContext, dataBindingContext);

    let path = "";
    let nested = data;

    for (let i = 0; i < bindingContexts.length; i++) {
        const bc = bindingContexts[i];
        path = i > 0 ? path + separator : path;
        if (bc.isInCollection()) {
            let index;

            nested = nested[bc.getPath(true)];

            if (nested) {
                const keyName = bc.getKeyPropertyName();
                index = (nested as any[]).findIndex(item => item[keyName] === bc.getKey());
                nested = nested[index];
            } else {
                index = 0;
            }

            path += `${bc.getPath(true)}[${index}]`;
        } else {
            path += bc.getPath();
        }
    }
    return path;
};

/**
 * Create binding context for collection path as Items[2]/Description => Items(88)/Description
 * @param path
 * @param data
 * @param dataBindingContext
 */
export const createBindingContextFromIndexedCollection = (path: string, data: any, dataBindingContext: BindingContext) => {
    const parts = path.split(".");
    let nested = data;
    let bc = dataBindingContext;

    for (const part of parts) {
        if (part.includes("[")) {
            // separate items[8] => item & 8
            const match = part.match(/\[(.*?)\]/);
            const index = match[1];
            const core = part.substring(0, match.index);

            const collBc = bc.navigate(core);
            const keyName = collBc.getKeyPropertyName();

            if (!nested) {
                // todo: not sure what should do
                throw new Error("Collection not existing");
            }
            nested = nested[core][index];

            // new item vs existing item, id can be '0'
            const id = !isNotDefined(nested[BindingContext.NEW_ENTITY_ID_PROP]) ? nested[BindingContext.NEW_ENTITY_ID_PROP] : nested[keyName];

            bc = bc.navigate(core).addKey(id, !isNotDefined(nested[BindingContext.NEW_ENTITY_ID_PROP]));
        } else {
            bc = bc.navigate(part);
            nested = nested?.[part];
        }
    }

    return bc;
};

export const createBindingContextFromValidatorPath = (path: string, dataBindingContext: BindingContext, data: IEntity): BindingContext => {
    const isPathWithCollection = path.includes("[");

    return isPathWithCollection ?
        createBindingContextFromIndexedCollection(path, data, dataBindingContext) :
        dataBindingContext.navigate(path.replace(/\./g, "/"));
};

export const getShortPath = (parent: BindingContext, item: BindingContext) => {
    return item.getFullPath().substring(parent.getFullPath().length + 1);
};

export interface IUpdateDataOnChange extends ISetBoundValue {
    saveToNavigation?: boolean;
    storage: Model<any>;
    currentValue?: TValue;
    ignoreNavigation?: boolean;
}

export const isNavigationForUpdate = (bc: BindingContext, value: any) => {
    // DON'T CHANGE THIS CONDITION !!!!
    // EVERY CASE THIS CONDITION DOES NOT COVER HAS TO BE SOLVED BY SPECIAL WAY
    // THIS CONDITION CAN BE CHANGED ONLY BY FULL TEAM AGREEMENT
    return bc.isNavigation() && isDefined(value) && typeof value !== "object";
    // you are navigation & have defined value & not object -----> INSERT TO ID
    // multivalues are like this:
    // BankAccount: [{id: 5}, {id: 10}]

    // but are solved by storeMultivalue
};

export function setDirtyFlag(storage: Model, bindingContext: BindingContext, dataBindingContext?: BindingContext): void {
    const isDirty = storage.isDirty(bindingContext);
    if (isDirty) {
        // once dirty, we don't need to set it again
        return;
    }
    do {
        storage.setDirty(bindingContext);
        if (bindingContext.getKey()) {
            storage.setDirty(bindingContext.removeKey());
        } else if (!bindingContext.isSame(storage.data.bindingContext, true) && bindingContext.isAnyPartCollection()) {
            const pathWithoutKeys = bindingContext.getPathBy(
                (bc) => bc.getParent() && !bc.getParent().isSame(storage.data.bindingContext, true),
                true
            );
            storage.setDirty(storage.data.bindingContext.navigate(pathWithoutKeys));
        }
        bindingContext = bindingContext.getParent();
    } while (bindingContext);
    storage.setDirty(dataBindingContext ?? storage.data.bindingContext);
}

export const updateDataOnChange = (args: IUpdateDataOnChange, isCreate = false) => {
    const isNavigation = isNavigationForUpdate(args.bindingContext, args.newValue);
    const fieldBc = isNavigation ? args.bindingContext.getNavigationBindingContext() : args.bindingContext;

    const updatedData = setBoundValue({
        bindingContext: fieldBc,
        data: args.data,
        newValue: args.newValue,
        dataBindingContext: args.dataBindingContext ?? args.storage.data.bindingContext,
        preventCloning: args.preventCloning
    });

    if (!isCreate) {
        setDirtyFlag(args.storage, fieldBc, args.dataBindingContext);
    }

    return updatedData;
};

/** Returns highest found new entity id for given items (entities) that use BindingContext.NEW_ENTITY_ID_PROP as id prop */
export const getNewItemsMaxId = (items: IEntity[]) => {
    let newItemsMax = 0;

    for (const item of items || []) {
        if (item.hasOwnProperty(BindingContext.NEW_ENTITY_ID_PROP)) {
            const idValue = item[BindingContext.NEW_ENTITY_ID_PROP];
            if (!isNaN(idValue)) {
                newItemsMax = Math.max(newItemsMax, idValue);
            }
        }
    }

    return newItemsMax;
};

const getChartsOfAccounts = async (context: IAppContext) => {
    const response = await customFetch(`${ODATA_API_URL}/ChartsOfAccounts?$expand=FiscalYear`);

    if (!response.ok) {
        throw new Error(`Failed to load charts of accounts info from ${ODATA_API_URL}/ChartsOfAccounts, error code: ${response.status}`);
    }

    return (await response.json())?.value as IChartOfAccountsEntity[];
};

export const getChartsOfAccountsMemoized = memoizedWithCacheStrategy<IAppContext, IChartOfAccountsEntity[]>(getChartsOfAccounts, (context) => `coa_${context.getCompany().Id}`);

export async function loadChartsOfAccountsMemoized(context: IAppContext): Promise<IChartOfAccountsEntity[]> {
    return getChartsOfAccountsMemoized(context, CacheStrategy.Company);
}

// export const dataToBindingContextValues = ({ data, bindingContext }: IGetBoundValue) => {
//     let results: TRecordAny = {};
//     for (let [key, value] of Object.entries(data)) {
//         if (key === BindingContext.NEW_ENTITY_ID_PROP || isNotDefined(value)) {
//             continue;
//         }
//
//         if (key === CURRENTVALUE_FIELD_NAME) {
//             // todo: consider we need it
//             // results[`${bindingContext.getFullPath(true)}/${CURRENTVALUE_FIELD_NAME}`] = value;
//             continue;
//         }
//
//         let itemBc = bindingContext.navigate(key);
//         if (key === "Currency") {
//             //todo: handle select pointing to naviagte property not to key property
//             results[itemBc.getFullPath(true)] = (value as Record<string, any>)["Code"];
//         } else if (Array.isArray(value)) {
//             for (let item of value) {
//                 const innerItemBc = itemBc.addKey(item);
//                 results = { ...results, ...dataToBindingContextValues({ data: item, bindingContext: innerItemBc }) };
//             }
//         } else if (typeof value === "object" && !(value instanceof Date)) {
//             // select
//             results = { ...results, ...dataToBindingContextValues({ data: value, bindingContext: itemBc }) };
//         } else {
//             //todo: remove true
//             results[itemBc.getFullPath(true)] = value;
//         }
//     }
//     return results;
// };

/**
 * Recursively parses data and transforms date strings to date objects. Changes data in-place.
 * @param data
 * @param depth
 */
export function parseDateFields<T extends (TRecordAny[] | TRecordAny)>(data: T, depth = 0): void {
    const _parseObject = (obj: TRecordAny) => {
        forEachKey(obj, (key) => {
            const type = typeof obj[key];
            if (key.startsWith("Date") && type === "string") {
                obj[key] = getUtcDate(obj[key]);
            } else if (type === "object" && depth > 0) {
                parseDateFields(obj[key], depth - 1);
            }
        });
    };

    if (Array.isArray(data)) {
        data.forEach(item => _parseObject(item));
    } else {
        _parseObject(data);
    }
}