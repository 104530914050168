/** Date range composed of two fields - select with values like "this fiscal year" + date range picker for custom range */
import React from "react";
import { ISelectionChangeArgs, ISelectItem } from "@components/inputs/select/BasicSelect";
import { formatDateInterval, IDayInterval, isSameInterval, isValidDateInterval } from "@components/inputs/date/utils";
import { CommonReportProps } from "../CommonDefs";
import LocalSettings from "../../../utils/LocalSettings";
import { ReportId } from "../ReportIds";
import Field from "../../../components/inputs/field/Field";
import { BasicInputSizes, FieldType, QueryParam, ValidatorType } from "../../../enums";
import { DatePicker, DateRangePicker } from "../../../components/inputs/date";
import { Select } from "@components/inputs/select";
import i18next from "i18next";
import { IFilterDef } from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { IFieldDefFn } from "@components/smart/FieldInfo";
import { getQueryParameters } from "../../../routes/Routes.utils";
import { fetchCompanyDateRanges, IDateRangeParam, IReportDateRange } from "../Report.utils";
import DateType, { DATE_MAX, DATE_MIN, getUtcDate, getUtcDayjs } from "../../../types/Date";
import { IInputOnChangeEvent } from "@components/inputs/input";
import { ReportStorage } from "../ReportStorage";
import { ValidationError } from "yup";
import { ValidationMessage } from "../../../model/Validator.types";
import { TableStorage } from "../../../model/TableStorage";

import { TDateRangePickerValue } from "@components/inputs/date/popup/Calendar.utils";

export const CUSTOM_DATE_RANGE_ID = "custom";
export const CUSTOM_BY_DATE_ID = "byDate";
export const DEFAULT_RANGE = "ThisFiscalYear";

const findMatchingNamedRange = (namedDateRanges: ISelectItem[], currentDateRange: IDayInterval) => {
    if (!currentDateRange) {
        return namedDateRanges.find(namedRange => namedRange.id === CUSTOM_DATE_RANGE_ID);
    }

    return namedDateRanges.find((namedRange) => {
        return DateType.isSameDay(namedRange.additionalData?.dateRange?.DateStart, currentDateRange?.from)
            && DateType.isSameDay(namedRange.additionalData?.dateRange?.DateEnd, currentDateRange?.to);
    });
};

export const getRenderDateRange = (reportId: ReportId) => {
    return ({ storage, props }: IFieldDefFn) => {
        const dateRangeBc = storage.data.bindingContext.navigate(CommonReportProps.dateRange);
        const dateRangeInfo = storage.getInfo(dateRangeBc);
        const currentDateRange: string = props.value as string;
        const dateRangeItems = dateRangeInfo.fieldSettings.items;
        const customDateRangeItem = dateRangeItems.find(item => item.id === CUSTOM_DATE_RANGE_ID);
        const selectedDateRangeItem = dateRangeInfo.fieldSettings.items.find(item => item.id === currentDateRange);
        const datePickerValue = selectedDateRangeItem?.additionalData ? {
            from: getUtcDate(selectedDateRangeItem.additionalData.dateRange.DateStart),
            //.endOf('day') has to correspond to DateRangePicker.parser, otherwise it would cause problems inside WithFormatter
            to: getUtcDayjs(selectedDateRangeItem.additionalData.dateRange.DateEnd).endOf("day").toDate()
        } : null;
        const isDisabled = !!(storage as TableStorage).data.predefinedFilters?.[CommonReportProps.dateRange];

        const handleNamedRangeChange = (event: ISelectionChangeArgs) => {
            if (event.value === CUSTOM_DATE_RANGE_ID) {
                if (currentDateRange === CUSTOM_DATE_RANGE_ID) {
                    return;
                }
                customDateRangeItem.additionalData = null;
            }

            storage.clearError(dateRangeBc);
            props.parentProps.onChange({
                bindingContext: dateRangeBc,
                parsedValue: event.value,
                value: event.value
            });

            LocalSettings.remove(reportId, "customData");
        };

        const handleDateRangePickerChange = (e: IInputOnChangeEvent<TDateRangePickerValue>) => {
            const dateRange = e.value as IDayInterval;
            if (isSameInterval(dateRange, datePickerValue)) {
                // don't do anything if value didn't change
                return;
            }

            const matchingNamedRange = findMatchingNamedRange(dateRangeItems, dateRange);
            let selectedDateRange = matchingNamedRange?.id;

            if (!matchingNamedRange || matchingNamedRange.id === CUSTOM_DATE_RANGE_ID) {
                selectedDateRange = CUSTOM_DATE_RANGE_ID;

                const dateRangeParam: IDateRangeParam = dateRange ? {
                    DateStart: dateRange?.from,
                    DateEnd: dateRange?.to
                } : null;

                customDateRangeItem.additionalData = dateRangeParam ? { dateRange: dateRangeParam } : null;
                LocalSettings.set(reportId, {
                    customData: {
                        [CUSTOM_DATE_RANGE_ID]: dateRangeParam
                    }
                });
            }

            if (!isValidDateInterval(dateRange)) {
                LocalSettings.remove(reportId, "customData");
            }

            props.parentProps.onChange({
                bindingContext: dateRangeBc,
                parsedValue: selectedDateRange,
                value: selectedDateRange
            });
        };

        const handleDatePickerChange = (e: IInputOnChangeEvent<Date>) => {
            const date = e.value;

            if (getUtcDayjs(date).endOf("day").isSame(datePickerValue?.to)) {
                // don't do anything if value didn't change
                return;
            }

            const selectItem = dateRangeItems.find(item => item.id === CUSTOM_BY_DATE_ID);
            const dateRangeParam: IDateRangeParam = date ? {
                DateStart: DATE_MIN,
                DateEnd: date
            } : null;

            selectItem.additionalData = dateRangeParam ? { dateRange: dateRangeParam } : null;

            LocalSettings.set(reportId, {
                customData: {
                    [CUSTOM_DATE_RANGE_ID]: dateRangeParam
                }
            });

            props.parentProps.onChange({
                bindingContext: dateRangeBc,
                parsedValue: CUSTOM_BY_DATE_ID,
                value: CUSTOM_BY_DATE_ID
            });
        };

        const picker = currentDateRange === CUSTOM_BY_DATE_ID ? (
            <DatePicker value={datePickerValue?.to}
                        onChange={handleDatePickerChange}
                        width={BasicInputSizes.L}
                        isDisabled={isDisabled}
                        error={storage.getError(dateRangeBc)}
                        isSharpLeft/>
        ) : (
            <DateRangePicker
                value={datePickerValue}
                onChange={handleDateRangePickerChange}
                width={BasicInputSizes.L}
                isDisabled={isDisabled}
                error={storage.getError(dateRangeBc)}
                isSharpLeft/>);

        return (
            <>
                <Field
                    name={CommonReportProps.dateRange}
                    isLight
                    label={props.info.label}
                    isDisabled={isDisabled}
                    isSharpRight>
                    <Select
                        name={CommonReportProps.dateRange}
                        items={dateRangeItems}
                        value={currentDateRange}
                        onChange={handleNamedRangeChange}
                        isDisabled={isDisabled}
                        width={BasicInputSizes.M}
                        isSharpRight/>
                </Field>
                <Field
                    name={`${CommonReportProps.dateRange}/DatePicker`}
                    isSharpLeft>
                    {picker}
                </Field>
            </>
        );
    };
};

export const getComposedDateRangeFilterDef = (reportId: ReportId): IFilterDef => {
    return {
        id: CommonReportProps.dateRange,
        type: FieldType.Custom,
        defaultValue: DEFAULT_RANGE,
        filterName: "dateRange",
        label: i18next.t("Reporting:Parameters.DateRange"),
        render: getRenderDateRange(reportId),
        // to show correct label instead of id in read only filter bar
        // (not handled automatically because we use FieldType.Custom, instead of FieldType.Select)
        formatter: (val, args): string => {
            let text: string;

            switch (true) {
                case val === CUSTOM_DATE_RANGE_ID:
                    text = i18next.t("Reporting:AccountingJournal.CustomDateRange");
                    break;
                case val === CUSTOM_BY_DATE_ID:
                    text = i18next.t("Reporting:Parameters.ByDate");
                    break;
                default:
                    text = i18next.t(`Enums:DateRange.${val}`);
            }

            // when used with drill down filters, we want to show the dates as well
            // because we don't show them in filter bar at that moment
            if ((args.storage as TableStorage)?.data.predefinedFilters) {
                const dateRangeInfo = Object.values(args.storage.data.fieldsInfo).find(info => info.bindingContext.getNavigationPath() === CommonReportProps.dateRange);
                const dateRange = dateRangeInfo.fieldSettings.items.find(i => i.id === val).additionalData.dateRange;
                // dayjs use wrong way to create a new Date and even though we pass a string that should create a year 1 (0001),
                // it will return year 1900 instead https://github.com/iamkun/dayjs/issues/1237#issuecomment-1102930539
                // => use new Date and THEN wrap it in dayjs to ensure correct year
                const dateStart = getUtcDayjs(dateRange.DateStart);
                const dateEnd = getUtcDayjs(dateRange.DateEnd);

                if (dateStart.isSameOrBefore(DATE_MIN, "date") && dateEnd.isSameOrAfter(DATE_MAX, "date")) {
                    text = i18next.t("Common:Time.All");
                } else {
                    const dateString = formatDateInterval({ from: dateRange.DateStart, to: dateRange.DateEnd });

                    text += ` (${dateString})`;
                }
            }

            return text;
        },
        validator: {
            type: ValidatorType.Custom,
            settings: {
                customValidator: (value, args, testContext) => {
                    const selectedRangeItem = args.storage.getInfo(args.bindingContext)?.fieldSettings?.items.find(item => item.id === value);
                    const dateRange = selectedRangeItem?.additionalData?.dateRange;

                    if (!dateRange) {
                        return true;
                    }

                    const dateRangeInterval: IDayInterval = {
                        from: dateRange.DateStart,
                        to: dateRange.DateEnd
                    };

                    if (value === CUSTOM_BY_DATE_ID) {
                        if (!DateType.isValid(dateRangeInterval.to)) {
                            return new ValidationError(ValidationMessage.NotADate, false, testContext.path);
                        }
                    } else {
                        if (!isValidDateInterval(dateRangeInterval)) {
                            return new ValidationError(ValidationMessage.NotARange, false, testContext.path);
                        }
                    }

                    return true;
                }
            }
        }
    };
};

export const composedDateRangeOnBeforeLoadCallback = async (storage: ReportStorage, addByDateOption?: boolean, addCustomRangeOption = true): Promise<void> => {
    // we need to date range (DateStart - DateEnd) BEFORE the initial table request => retrieve them here instead of via smart select
    const namedDateRanges = await fetchCompanyDateRanges(storage.context);
    storage.setCustomData({ namedDateRanges });
    const dateRangeBc = storage.data.bindingContext.navigate(CommonReportProps.dateRange);
    const dateRangeInfo = storage.getInfo(dateRangeBc);
    let selectItems: ISelectItem[] = namedDateRanges.map((dateRange: IReportDateRange) => {
        return {
            id: dateRange.Key,
            label: i18next.t(`Enums:DateRange.${dateRange.Key}`),
            additionalData: {
                dateRange: {
                    DateStart: getUtcDate(dateRange.Value.DateStart),
                    DateEnd: getUtcDate(dateRange.Value.DateEnd)
                }
            }
        };
    });
    if (dateRangeInfo.fieldSettings?.transformFetchedItems) {
        selectItems = dateRangeInfo.fieldSettings.transformFetchedItems(selectItems, { storage });
    }

    let savedCustomDateRange: IDateRangeParam = LocalSettings.get(storage.data.definition.id).customData?.[CUSTOM_DATE_RANGE_ID];
    const queryParams = getQueryParameters();

    // enable sending custom date range via custom query
    if (queryParams?.[QueryParam.CustomDateRange]) {
        const drillDownDateRange = JSON.parse(queryParams[QueryParam.CustomDateRange] as string);
        savedCustomDateRange = {
            DateStart: getUtcDate(drillDownDateRange.DateStart),
            DateEnd: getUtcDate(drillDownDateRange.DateEnd)
        };

        // if the custom range corresponds to one of the dateRange values, use it instead
        const matchingNamedRange = findMatchingNamedRange(selectItems, {
            from: savedCustomDateRange.DateStart,
            to: savedCustomDateRange.DateEnd
        });

        if (matchingNamedRange) {
            const value = matchingNamedRange.id;

            storage.setFilterValue(dateRangeBc, value, value);
        }
    }

    // add custom options, before !isValidValue check,
    // so that even when namedDateRanges are empty, there are at least the custom options to choose from
    if (addCustomRangeOption) {
        selectItems.push({
            id: CUSTOM_DATE_RANGE_ID,
            label: storage.t("Reporting:AccountingJournal.CustomDateRange"),
            additionalData: savedCustomDateRange ? {
                dateRange: savedCustomDateRange
            } : null
        });
    }

    if (addByDateOption) {
        selectItems.push({
            id: CUSTOM_BY_DATE_ID,
            label: storage.t("Reporting:Parameters.ByDate"),
            additionalData: savedCustomDateRange ?
                { dateRange: savedCustomDateRange } :
                {
                    dateRange: {
                        DateStart: DATE_MIN,
                        DateEnd: getUtcDate()
                    }
                }
        });
    }

    // change default value if not present
    const currentValue = storage.data.entity[CommonReportProps.dateRange];
    const isValidValue = (currentValue === CUSTOM_DATE_RANGE_ID && addCustomRangeOption)
        || (currentValue === CUSTOM_BY_DATE_ID && addByDateOption)
        || namedDateRanges.find(namedRange => namedRange.Key === currentValue);

    // if no value found, take the first one from the list
    if (!isValidValue) {
        // choose from selectItems instead of namedDateRanges,
        // there might be additional items (custom range) that are not in namedDateRanges
        const value = selectItems?.[0]?.id;

        storage.setFilterValue(dateRangeBc, value, value);
    }

    dateRangeInfo.fieldSettings.items = selectItems;

    storage.refreshField(CommonReportProps.dateRange);
};