import React from "react";
import { commonReportTranslations, fetchCompanyDateRanges, IReportDateRange } from "./Report.utils";
import { Trans, withTranslation } from "react-i18next";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import { CenteredTextPage } from "../../global.style";
import { Link, withRouter } from "react-router-dom";
import { ROUTE_FISCAL_YEAR } from "../../routes";
import Page, { IProps as IPageProps } from "../Page";
import { IReportViewBaseProps } from "./ReportView";
import { ReportSplitPage } from "./ReportSplitPage";
import { withOData } from "@odata/withOData";
import { IReportStorageDefaultCustomData, ReportStorage } from "./ReportStorage";
import { getActiveFiscalYears } from "../fiscalYear/FiscalYear.utils";
import { CommonReportProps } from "./CommonDefs";
import { Sort } from "../../enums";

enum ReportStatus {
    NotReady = "NotReady",
    Ready = "Ready",
    NotAvailable = "NotAvailable"
}

export enum FiscalYearParameterType {
    // select shows the date ranges with relative names, like "this fiscal year"
    DateRanges = "DateRanges",
    // select shows list of active fiscal years
    ActiveFiscalYears = "ActiveFiscalYears"
}

interface IProps extends IPageProps {
    onTableStorageLoad?: (storage: ReportStorage) => void;
    reportViewProps?: Partial<IReportViewBaseProps>;
    fiscalYearParameterType: FiscalYearParameterType;
}

export interface IFiscalYearDependentReportCustomData extends IReportStorageDefaultCustomData {
    dateRanges?: IReportDateRange[];
}


interface IState {
    status: ReportStatus;
}

class FiscalYearDependentReport extends Page<IProps, IState> {
    static contextType = AppContext;

    state = {
        status: ReportStatus.NotReady
    };

    dateRanges: IReportDateRange[] = null;

    componentDidMount() {
        this.initReportStatus();
    }

    companyChange(id: number) {
        super.companyChange(id);

        this.setState({
            status: ReportStatus.NotReady
        }, this.initReportStatus);
    }

    initReportStatus = async () => {
        let status: ReportStatus;

        if (this.props.fiscalYearParameterType === FiscalYearParameterType.DateRanges) {
            const values = await fetchCompanyDateRanges(this.context);

            status = values.length === 0 ? ReportStatus.NotAvailable : ReportStatus.Ready;
            this.dateRanges = values;

        } else {
            const fiscalYears = getActiveFiscalYears(this.context, Sort.Desc);

            status = fiscalYears.length === 0 ? ReportStatus.NotAvailable : ReportStatus.Ready;
        }

        this.setState({
            status
        });
    };

    handleTableStorageLoad = async (storage: ReportStorage<IFiscalYearDependentReportCustomData>) => {
        if (this.props.fiscalYearParameterType === FiscalYearParameterType.DateRanges) {
            storage.setCustomData({
                dateRanges: this.dateRanges
            });
        } else {
            storage.addActiveField(storage.data.bindingContext.navigate(CommonReportProps.fiscalYear));
            storage.refreshFields();
        }


        await this.props.onTableStorageLoad?.(storage);
    };

    render = () => {
        if (!this.isReady() || !this.state.status) {
            return null;
        }

        return (
            <>
                {this.state.status === ReportStatus.Ready &&

                    <ReportSplitPage
                        {...this.getMandatoryProps()}
                        reportViewProps={this.props.reportViewProps}
                        onTableStorageLoad={this.handleTableStorageLoad}
                    />
                }
                {this.state.status === ReportStatus.NotAvailable &&
                    <ReportMissingFiscalYear/>
                }
            </>
        );
    };
}

const ReportMissingFiscalYear = () => {
    return (
        <CenteredTextPage>
            <h3>
                {/*todo use Trans component or split translation into multiple keys?*/}
                <Trans i18nKey={"Reporting:Common.FiscalYearMissing"}>
                    Pro zobrazení reportu musí existovat příslušné <br/>
                    <Link to={ROUTE_FISCAL_YEAR}>
                        účetní období
                    </Link>
                </Trans>
            </h3>


        </CenteredTextPage>
    );
};

export default withRouter(withOData(withTranslation(commonReportTranslations)(FiscalYearDependentReport)));