import React from "react";
import { IGetTileDataArgs } from "@components/dashboard";
import {
    EntitySetName,
    EntityTypeName,
    IFileMetadataEntity,
    ITicketEntity,
    ITicketMessageEntity,
    IUserEntity,
    IUserNotificationEntity,
    TicketEntity,
    TicketMessageEntity,
    UserCompanyRoleEntity,
    UserEntity
} from "@odata/GeneratedEntityTypes";
import { NotificationTypeCode, TicketStatusCode } from "@odata/GeneratedEnums";
import { TValue } from "../../global.types";
import { IFormatOptions } from "@odata/OData.utils";
import { DefaultTheme } from "styled-components";
import { IGetValueArgs } from "@components/smart/FieldInfo";
import { TFormatterFn } from "@components/smart/smartTable/SmartTable.utils";
import { EvalaPlainIcon, TicketStatusDoneIcon, TicketStatusInProgressIcon } from "@components/icon";
import { AvatarSize, IconSize } from "../../enums";
import { getEnumDisplayValue } from "@odata/GeneratedEnums.utils";
import { ITicketMessage, TicketMessageType } from "@components/ticketMessage/TicketMessage.utils";
import { FormStorage } from "../../views/formView/FormStorage";
import { currentUserIsCustomer, isAgendaCustomer } from "../admin/users/Users.utils";
import Avatar from "../../components/avatar/Avatar";
import { getCompanyLogoUrl } from "@utils/CompanyUtils";
import { IAppContext } from "../../contexts/appContext/AppContext.types";
import { TSmartODataTableStorage } from "@components/smart/smartTable/SmartODataTableBase";
import { TEntityKey } from "@evala/odata-metadata/src";
import { ITableStorageDefaultCustomData } from "../../model/TableStorage";
import { INotificationWebsocketMessage, TWebsocketMessage } from "@utils/websocketManager/Websocket.types";
import { fetchNotificationsMemoized, getNotificationArguments } from "../../notifications/Notification.utils";
import { OData } from "@odata/OData";
import { getUtcDate } from "../../types/Date";

export interface IUnreadTicketInfo {
    Id: TEntityKey;
    AuthorIsCustomer: boolean;
    IsNotReadByTicketAuthor: boolean;
    IsNotReadByTicketAssignee: boolean;
}

export interface ITicketsStorageCustomData extends ITableStorageDefaultCustomData {
    unreadTickets?: IUnreadTicketInfo[];
}

export async function getUnfinishedTicketCount(args: IGetTileDataArgs): Promise<number> {
    const { oData, signal } = args;

    const query = oData.getEntitySetWrapper(EntitySetName.Tickets)
            .query()
            .filter(`${TicketEntity.TicketStatusCode} eq '${TicketStatusCode.InProgress}'`)
            .count();

    const data = await query.fetchData(null, { signal });

    return data?._metadata?.count ?? 0;
}

export function getTicketStatusColor(code: TicketStatusCode): keyof DefaultTheme {
    let color: keyof DefaultTheme;

    switch (code) {
        case TicketStatusCode.InProgress:
            color = "C_SEM_text_warning";
            break;
        case TicketStatusCode.Done:
            color = "C_SEM_text_good";
            break;
        default:
            color = "C_TEXT_primary";
            break;
    }

    return color;
}

export function getTicketStatusDisplayValue(code: TicketStatusCode): string {
    return getEnumDisplayValue(EntityTypeName.TicketStatus, code);
}

export function getTicketStatusIcon(code: TicketStatusCode): React.ReactNode {
    let Icon;

    switch (code) {
        case TicketStatusCode.InProgress:
            Icon = TicketStatusInProgressIcon;
            break;
        case TicketStatusCode.Done:
            Icon = TicketStatusDoneIcon;
            break;
    }
    return Icon ? (
            <Icon title={getTicketStatusDisplayValue(code)} height={IconSize.S}/>
    ) : null;
}

export const isSaved = (args: IGetValueArgs): boolean => !args.storage.data.bindingContext.isNew();

export const ticketStatusFormatter: TFormatterFn = (val: TValue, args?: IFormatOptions) => {
    const code = val as TicketStatusCode;
    return { tooltip: getTicketStatusDisplayValue(code), value: getTicketStatusIcon(code) };
};

export async function markThreadAsRead(storage: FormStorage<ITicketEntity>): Promise<boolean> {
    const { context, data: { entity } } = storage;
    const readByPropName = getReadPropName(context, entity.CreatedBy);
    let changed = false;
    const updatedMessages = entity.Messages?.map(m => {
        changed = changed || !m[readByPropName];
        return {
            ...m,
            [readByPropName]: true
        };
    });
    // don't send request when there are no changes
    if (!changed) {
        return false;
    }
    // otherwise update the flags
    storage.setValueByPath(TicketEntity.Messages, updatedMessages);
    const result = await storage.save();

    return !!result;
}

export function hasUnreadMessage(storage: TSmartODataTableStorage<ITicketsStorageCustomData>, entity: ITicketEntity): boolean {
    const { unreadTickets } = storage.getCustomData();
    const key = entity.Id;
    const unreadTicket = unreadTickets?.find((ticket: IUnreadTicketInfo) => ticket.Id === key);
    if (!unreadTicket) {
        return false;
    }
    const _currentUserIsCustomer = currentUserIsCustomer(storage.context);
    const isAuthor = unreadTicket.AuthorIsCustomer === _currentUserIsCustomer;
    return isAuthor ? unreadTicket.IsNotReadByTicketAuthor : unreadTicket.IsNotReadByTicketAssignee;
}

export async function getUnreadCount(storage: TSmartODataTableStorage): Promise<IUnreadTicketInfo[]> {

    const query = storage.oData.getEntitySetWrapper(EntitySetName.Tickets).query();

    query
            .select(TicketEntity.Id)
            .filter(`${TicketEntity.Messages}/any(m: m/${TicketMessageEntity.IsReadByTicketAssignee} eq false OR m/${TicketMessageEntity.IsReadByTicketAuthor} eq false)`)
            .expand(TicketEntity.Messages, (q) => {
                q.filter(`${TicketMessageEntity.IsReadByTicketAssignee} eq false OR ${TicketMessageEntity.IsReadByTicketAuthor} eq false`)
                        .select(TicketMessageEntity.IsReadByTicketAssignee, TicketMessageEntity.IsReadByTicketAuthor);
            })
            .expand(TicketEntity.CreatedBy, (q1) => {
                q1.expand(UserEntity.CompanyRoles, (q2) => {
                    q2
                            .expand(UserCompanyRoleEntity.Company)
                            .expand(UserCompanyRoleEntity.CompanyRole);
                });
            });
    try {
        const result = await query.fetchData<ITicketEntity[]>();
        return result.value.map(ticket => {
            const unreadInfo: IUnreadTicketInfo = {
                Id: ticket.Id,
                AuthorIsCustomer: isAgendaCustomer(storage.context, ticket.CreatedBy),
                IsNotReadByTicketAuthor: !!ticket.Messages?.find(m => m.IsReadByTicketAuthor === false),
                IsNotReadByTicketAssignee: !!ticket.Messages?.find(m => m.IsReadByTicketAssignee === false),
            };
            return unreadInfo;
        });
    } catch (e) {
        // no action
    }
    return [];
}

export function getMessageIcon(context: IAppContext, isAgendaMessage: boolean): React.ReactNode {
    if (isAgendaMessage) {
        const logoURL = getCompanyLogoUrl(context.getCompany());
        return (
                <Avatar src={logoURL} size={AvatarSize.almostM} transparent/>
        );
    }
    return (<EvalaPlainIcon width={IconSize.L} height={IconSize.L}/>);
}

function parseTicketMessage(storage: FormStorage<ITicketEntity>, m: ITicketMessageEntity, _isAgendaUser: boolean, isFirst?: boolean): ITicketMessage {
    const _isAgendaMessage = isAgendaCustomer(storage.context, m.CreatedBy);

    const message: ITicketMessage = {
        icon: getMessageIcon(storage.context, _isAgendaMessage),
        created: getUtcDate(m.DateCreated),
        createdBy: m.CreatedBy?.Name,
        createdById: m.CreatedBy?.Id,
        text: m.Text,
        type: _isAgendaMessage === _isAgendaUser ? TicketMessageType.Sent : TicketMessageType.Received,
        attachments: m.Attachments?.map(attachment => attachment.File)
    };
    if (isFirst) {
        message.subject = storage.data.entity?.Title ?? "";
    }
    return message;
}

function getReadPropName(context: IAppContext, ticketUser: IUserEntity): TicketMessageEntity {
    if (!ticketUser) {
        // if ticketUser is not set, it is a new message -> user is its author
        return TicketMessageEntity.IsReadByTicketAuthor;
    }
    const _currentUserIsCustomer = currentUserIsCustomer(context);
    const _ticketAuthorIsCustomer = isAgendaCustomer(context, ticketUser);
    const isSame = _currentUserIsCustomer === _ticketAuthorIsCustomer;
    return isSame ? TicketMessageEntity.IsReadByTicketAuthor : TicketMessageEntity.IsReadByTicketAssignee;
}

// When user creates new message, it doesn't contain some information as icon, created info - this function adds it
export function getTicketMessageFromCreatedMessage(storage: FormStorage<ITicketEntity>, m: ITicketMessage, files: IFileMetadataEntity[]): ITicketMessageEntity {
    const { context, data: { entity } } = storage;
    const readByPropName = getReadPropName(context, entity.CreatedBy);
    const user = context.getData().userSettings;
    const message: ITicketMessageEntity = {
        Text: m.text,
        DateCreated: new Date(),
        CreatedBy: user,
        [readByPropName]: true,
    };
    if (files?.length) {
        message.Attachments = files.map(f => ({
            File: f
        }));
    }
    return message;
}

export function getStoredMessages(storage: FormStorage<ITicketEntity>): ITicketMessage[] {
    const { entity } = storage.data;
    const _isCustomer = currentUserIsCustomer(storage.context);
    return entity.Messages?.map((m, idx) => parseTicketMessage(storage, m, _isCustomer, idx === 0)) ?? [];
}

interface ITicketWebSocketInfo {
    shouldRefreshTable: boolean;
    shouldRefreshStream: boolean;
    ticketId: TEntityKey;
}

export async function checkTicketMessageNotification(message: TWebsocketMessage, oData: OData): Promise<ITicketWebSocketInfo> {
    const notificationId = (message as INotificationWebsocketMessage).Parameters.Id;
    const result = await fetchNotificationsMemoized(oData, notificationId);
    const { Notification } = (result.value as IUserNotificationEntity) ?? {};
    const args = getNotificationArguments(Notification);
    const ticketId = args.TicketId;
    const shouldRefreshStream = [
        NotificationTypeCode.NewTicketMessageFromACustomerReceivement,
        NotificationTypeCode.NewTicketMessageFromAnAccountantReceivement
    ].includes(Notification.NotificationTypeCode as NotificationTypeCode);
    const shouldRefreshTable = Notification.NotificationTypeCode === NotificationTypeCode.NewTicketCreated;

    return {
        ticketId, shouldRefreshStream, shouldRefreshTable
    };
}