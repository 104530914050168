import { withTranslation, WithTranslation } from "react-i18next";
import React from "react";
import {
    ActionIconsDelimiter,
    ActionItemsWrapper,
    ArrowCover,
    ArrowTop,
    ContentWrapper,
    GroupDivider,
    GroupDividerLine,
    GroupTitle,
    Header,
    HeaderText,
    SelectGroup,
    SelectGroupContent,
    StyledMenu,
    StyledMenuWrapper,
    TableHeaderBottomLine,
    TabularHeader,
    TabularHeaderItem
} from "./Select.styles";
import TestIds from "../../../testIds";
import { handleRefHandlers, isNotDefined } from "@utils/general";
import SelectInput from "./SelectInput";
import { ScrollBar } from "../../scrollBar/ScrollBar";
import {
    _isNoRecord,
    Direction,
    IColumn,
    ISelectGroup,
    ISelectItem,
    ISharedData,
    NO_RECORD_FOUND,
    PAGE_SIZE,
    SelectAdditionalItems,
    SelectGroups,
    TSelectItemId
} from "./BasicSelect";
import { ISelectMenuItemContentArgs, MenuItem } from "./SelectMenuItem";
import { TRecordAny } from "../../../global.types";
import { KeyName } from "../../../keyName";
import { getHighlightedIndex, handleNavigationKey, isSystemKey } from "./SelectAPI";
import { startsWithAccentsInsensitive } from "@utils/string";
import { ValueType } from "../../../enums";
import { PopperChildrenProps } from "react-popper";

interface ISearch {
    timeStamp: number;
    searchTerm: string;
}

interface IParsedGroup {
    def: ISelectGroup;
    items: ISelectItem[];
    isTabular: boolean;
}

export interface IMenuProps extends IPropsBase, WithTranslation {
    ref?: React.RefObject<React.ReactElement>;
    popperProps: Partial<PopperChildrenProps>;
    defaultMenuWidth?: number;
    isSelected?: (item: ISelectItem) => boolean;
    isStandAlone?: boolean;
    currentValue?: string;
    shouldSearchWithKeyboard?: boolean;
    showTabularHeader?: boolean;
    // disable the use of SimpleBar, instead use default browser scroll
    isSimpleBarDisabled?: boolean;

    onSelectionChange?: (item: ISelectItem, triggerAdditionalTasks: boolean) => void;
    onHighlightChange?: (item: ISelectItem) => void;
    onInputKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onInputBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;

    refContent?: React.Ref<HTMLDivElement>;
    refMenu?: React.Ref<HTMLDivElement>;
    refOpener?: React.Ref<HTMLDivElement>;
}

export interface IPropsBase {
    value?: TSelectItemId;
    items?: ISelectItem[];
    initialItems?: ISelectItem[];

    isTabular?: boolean;
    inputValue?: string;
    columns?: IColumn[];
    isMulti?: boolean;
    groups?: ISelectGroup[];
    /** Content rendered in front of the item in the first column  */
    itemContent?: (item: ISelectItem, args: ISelectMenuItemContentArgs) => React.ReactNode;
    /** Pass itemContent of the selected item into Input as contentBefore*/
    passItemContentToInput?: boolean;
    displayArrow?: boolean;
    headerText?: string;
    sharedData?: ISharedData;
    searchType?: ValueType;

    // usable only for solo menus, rest won't have focus
    onKeyDown?: (e: React.KeyboardEvent) => void;

    showSearchBoxInMenu?: boolean;
    renderDefaultGroupWithoutCheckboxes?: boolean;
}

/**
 COMBINATIONS NOT SUPPORTED
 - Hierarchy with table
 */

class Menu extends React.PureComponent<IMenuProps> {
    static defaultProps = {
        isStandAlone: false,
        sharedData: {}
    };

    private _refMenu = React.createRef<HTMLDivElement>();
    private _refContent = React.createRef<HTMLDivElement>();
    protected _refScroll = React.createRef<HTMLDivElement>();
    private _actionItems = React.createRef<HTMLDivElement>();
    private _scrolledIndex: number;
    private _searchTerm = "";

    private _hasActionItems = false;

    private _indexCounter = -1;
    private _isMenuWithIcons = false;
    private _isTabularMenu = false;

    _lastSearch: ISearch = {
        timeStamp: new Date().getTime(),
        searchTerm: ""
    };

    componentDidMount(): void {
        if (this.props.isStandAlone) {
            this.props.sharedData.highlightedIndex = null;
        }

        const selectedItemIndex = this.props.items.findIndex(item => this.isSelected(item));

        if (selectedItemIndex >= 0) {
            // without timeout, the items are not yet properly rendered
            setTimeout(() => {
                this.scrollToItem(selectedItemIndex, this.props.sharedData.scrollDirection);
            });
        }
    }

    getMaxWidth = () => {
        return this.props.defaultMenuWidth && !this._isTabularMenu ? this.props.defaultMenuWidth * 2 : undefined;
    };

    componentDidUpdate() {
        if (!this.props.isStandAlone && this._refMenu.current && this._refContent.current) {

            // scrollbar not always respect changes of width, so far we didn't find a better solution
            const menuWidth = this._refMenu.current.parentElement.offsetWidth;
            let contentWidth = this._refContent.current.scrollWidth;
            const actionItemsWidth = this._actionItems.current?.scrollWidth || 0;

            if (actionItemsWidth > contentWidth) {
                this._refContent.current.style.width = `${actionItemsWidth}px`;
            }

            contentWidth = Math.max(actionItemsWidth, contentWidth);

            // for zooms there may be slight difference in pixels even if div was set to the old value
            if (Math.abs(menuWidth - contentWidth) > 2) {
                this._refMenu.current.parentElement.style.width = `${contentWidth}px`;
                this.props.popperProps.update();
            }
        }

        if (this.props.sharedData.highlightedIndex !== this._scrolledIndex) {
            this.scrollToItem(this.props.sharedData.highlightedIndex, this.props.sharedData.scrollDirection);
        }

        if (this.props.isStandAlone) {
            this._refMenu.current.focus();
        }
    }

    scrollToItem = (index: number, direction: Direction = Direction.Center) => {
        if (direction !== Direction.None) {
            const scroller = this._refScroll.current;

            if (index !== -1 && scroller) {
                if (index === 0) {
                    scroller.scrollTop = 0;
                    return;
                }

                const childNodes = this._refContent.current.getElementsByClassName("select-item");

                const targetItem = childNodes[index] as HTMLDivElement;
                if (targetItem) {
                    const itemTop = targetItem.offsetTop;

                    if (direction === Direction.Center) {
                        scroller.scrollTop = itemTop - PAGE_SIZE / 2;
                    } else {
                        const _isVisible = itemTop > scroller.scrollTop - targetItem.offsetHeight && itemTop < scroller.scrollTop + PAGE_SIZE;
                        if (!_isVisible) {
                            scroller.scrollTop = direction === Direction.Down ? itemTop - PAGE_SIZE + targetItem.offsetHeight :
                                itemTop;
                        }
                    }

                    this._scrolledIndex = this.props.sharedData.highlightedIndex;
                }
            }
        }
    };

    _handleKeyDown(e: React.KeyboardEvent) {
        const valueIndex = getHighlightedIndex(null, this.props.items, this.props.value, this.props.currentValue);
        let highlightedIndex = this.getHighlightedIndex();
        const preventDefault = false;

        this.props.sharedData.scrollDirection = Direction.Center;

        if (isSystemKey(e.key)) {
            const result = handleNavigationKey(this.props.items, highlightedIndex ?? valueIndex, e.key as KeyName);
            if (result) {
                if (isNotDefined(highlightedIndex)) {
                    // first keystroke (both up and down) should just highlight current item
                    // https://solitea-cz.atlassian.net/browse/DEV-10385
                    this.props.sharedData.highlightedIndex = valueIndex === -1 ? 0 : valueIndex;
                    this.forceUpdate();
                    return false;
                } else {
                    highlightedIndex = result.highlightedIndex;
                    this.props.sharedData.scrollDirection = result.scrollDirection;
                }
            } else if (e.key === KeyName.Enter) {
                this.props.onSelectionChange(this.props.items[highlightedIndex ?? valueIndex], true);
                e.preventDefault();
            }
        } else if (this.props.isStandAlone || this.props.shouldSearchWithKeyboard) {
            const newHighlightedIndex = this.getSearchedIndex(e.key, highlightedIndex);
            if (newHighlightedIndex !== highlightedIndex) {
                this.props.onSelectionChange(this.props.items[newHighlightedIndex], false);
                this.props.sharedData.highlightedIndex = newHighlightedIndex;
                this.forceUpdate();
                return false;
            }
        }

        if (this.props.sharedData.highlightedIndex !== highlightedIndex) {
            const item = this.props.items[highlightedIndex];
            if (item && !item.isNotHighlightable) {
                this.props.onHighlightChange?.(this.props.items[highlightedIndex]);
                this.props.sharedData.highlightedIndex = highlightedIndex;
                this.forceUpdate();
            }
        }

        return preventDefault;
    }

    // user for read only selectors, this navigates to item without entering text to input
    getSearchedIndex = (key: string, currentIndex: number) => {
        const DIFF = 500;
        const alphaKeys = RegExp(/\w$/i);

        if (alphaKeys.test(key)) {
            const currentStamp = new Date().getTime();

            this._lastSearch.searchTerm = this._lastSearch.timeStamp + DIFF > currentStamp ?
                    this._lastSearch.searchTerm + key : key;
            this._searchTerm = this._lastSearch.searchTerm;
            this._lastSearch.timeStamp = currentStamp;

            const index = this.props.items.findIndex(item => startsWithAccentsInsensitive(item.label || item.id.toString(), this._lastSearch.searchTerm));

            return index !== -1 ? index : currentIndex;
        }

        return 0;
    };

    getHighlightedIndex = () => {
        return this.props.sharedData?.highlightedIndex;
    };

    renderHeader = () => {
        const item = { id: SelectAdditionalItems.SelectAll };

        return (
                <TabularHeader data-testid={TestIds.SelectMenuHeader}>
                    {!!this.props.itemContent &&
                            <TabularHeaderItem _isContentBefore={true}>
                                {this.props.itemContent(item, { onClick: () => this.props.onSelectionChange?.(item, true) })}
                            </TabularHeaderItem>
                    }
                    {this._isMenuWithIcons &&
                            <TabularHeaderItem/>
                    }
                    {this.props.columns.map((col, index) => {
                        return (
                                <TabularHeaderItem
                                        column={col}
                                        key={index}>
                                    {col.label}
                                </TabularHeaderItem>
                        );
                    })}
                </TabularHeader>
        );
    };

    handleItemMouseEnter = (index: number) => {
        this.props.sharedData.scrollDirection = Direction.None;
        this.props.sharedData.highlightedIndex = index;
        this.forceUpdate();
    };

    handleItemMouseLeave = () => {
        this.props.sharedData.highlightedIndex = null;
        this.forceUpdate();
    };

    handleContentRef = (ref: HTMLInputElement) => {
        handleRefHandlers(ref, this._refContent, this.props.refContent);
    };

    handleMenuRef = (ref: HTMLElement) => {
        handleRefHandlers(ref, this._refMenu, this.props.refMenu);
    };

    isSelected = (item: ISelectItem) => {
        if (this.props.isSelected) {
            return this.props.isSelected(item);
        }

        if (isNotDefined(item.id)) {
            return isNotDefined(this.props.value) && (isNotDefined(this.props.currentValue) || this.props.currentValue === item.label);
        }

        return this.props.value === item.id || item.isSelected;
    };

    renderMenuItem = (item: ISelectItem, additionalProps: TRecordAny = {}) => {
        this._indexCounter++;
        const isSelected = this.isSelected(item);
        const isItalic = item.additionalData?.isNoRecord || item.id === NO_RECORD_FOUND;
        const isHighlighted = this.getHighlightedIndex() === this._indexCounter;

        return (
                <MenuItem isSelected={isSelected}
                          isItalic={isItalic}
                          key={this._indexCounter}
                          isHighlighted={isHighlighted}
                          isDisabled={item.isDisabled}
                          item={item}
                          inputValue={this.props.inputValue || this._searchTerm}
                          defaultWidth={this._isTabularMenu ? undefined : this.props.defaultMenuWidth}
                          index={this._indexCounter}
                          isMulti={this.props.isMulti && !additionalProps?.renderWithoutCheckboxes}
                          renderItemWithoutCheckboxes={additionalProps?.renderWithoutCheckboxes}
                          itemContent={_isNoRecord(item) ? null : this.props.itemContent}
                          sharedData={this.props.sharedData}
                          onMouseClick={this.props.onSelectionChange}
                          onMouseEnter={this.handleItemMouseEnter}
                          onMouseLeave={this.handleItemMouseLeave}
                          useIconOffset={this._isMenuWithIcons}
                          searchType={this.props.searchType}
                          {...additionalProps}
                />
        );
    };

    renderActionItems = (groups: IParsedGroup[], hasContentBefore: boolean) => {
        const actionGroup = groups.find(group => group.def?.id === SelectGroups.Action);
        const renderDivider = groups.length > 1; // render divider only when there are additional groups
        this._hasActionItems = false;

        if (actionGroup) {
            this._hasActionItems = true;
            return (
                    <ActionItemsWrapper ref={this._actionItems} $hasContentBefore={hasContentBefore} data-testid={TestIds.ActionItemWrapper}>
                        {renderDivider && <ActionIconsDelimiter/>}
                        {actionGroup.items.map(item => {
                            return (
                                    <React.Fragment key={item.id.toString()}>
                                        {this.renderMenuItem(item)}
                                    </React.Fragment>
                            );
                        })}
                    </ActionItemsWrapper>
            );
        }

        return null;
    };

    handleKeyDown = (e: React.KeyboardEvent) => {
        this.props.onKeyDown?.(e);
    };

    isMenuWithIcons = () => {
        return !!this.props.items?.find(item => !!item.iconName);
    };

    createGroup = (item: ISelectItem) => {
        return item.groupId ? {
            id: item.groupId
        } : undefined;
    };

    renderMenuHeader = () => {
        return (
                <Header data-testid={TestIds.SelectMenuHeader}><HeaderText>{this.props.headerText}</HeaderText>
                    <TableHeaderBottomLine/>
                </Header>
        );
    };

    createGroups = () => {
        const groups: IParsedGroup[] = [];
        let currentGroup = null;

        this._isTabularMenu = false;

        const items = this.props.items;
        for (let i = 0; i < items?.length; i++) {
            const item = items[i];
            this._isTabularMenu = this._isTabularMenu || item.tabularData?.length > 1;

            const isFirstGroupItem = i === 0 || item.groupId !== items[i - 1]?.groupId;
            if (isFirstGroupItem) {
                const group = (this.props.groups || []).find(group => group.id === item?.groupId);
                currentGroup = {
                    def: group || this.createGroup(item),
                    items: [item],
                    isTabular: item.tabularData?.length > 1
                };

                groups.push(currentGroup);
            } else {
                currentGroup?.items.push(item);
            }
        }

        const _getGroupIndex = (group: ISelectGroup) =>
                (group?.id && this.props.groups?.findIndex(g => g.id === group.id)) ?? -1;

        groups.sort((a, b) => {
            return _getGroupIndex(a.def) - _getGroupIndex(b.def);
        });

        return groups;
    };

    renderGroup = (group: IParsedGroup) => {
        const showHeader = this.props.showTabularHeader && group.isTabular;
        const renderWithoutCheckboxes = group.def?.id === SelectGroups.Default && this.props.renderDefaultGroupWithoutCheckboxes;
        return (
                <>
                    {showHeader && this.renderHeader()}
                    {group.items.map((item) => {
                        return (
                                this.renderMenuItem(item, {
                                    renderWithoutCheckboxes,
                                    isTabular: group.isTabular,
                                    columns: this.props.columns
                                }));
                    })}
                </>
        );
    };

    renderGroupDivider = (group: ISelectGroup) => {
        return (
                <GroupDivider
                        _dividerSize={group?.dividerSize}>
                    {!group?.hideDivider &&
                            <GroupDividerLine/>
                    }
                </GroupDivider>
        );
    };

    renderGroupTitle = (title: string) => {
        return title ? (
                <GroupTitle data-testid={TestIds.SelectMenuGroupTitle}>
                    {title}
                </GroupTitle>
        ) : null;
    };

    renderGroups = (groups: IParsedGroup[]) => {
        let isFirst = true;
        return (
                <>
                    {groups.map((group, index) => {
                        const r = (
                                <SelectGroup key={group.def?.id ?? index}
                                             data-testid={TestIds.SelectMenuGroup}>
                                    {!isFirst && this.renderGroupDivider(group.def)}
                                    {this.renderGroupTitle(group.def?.title)}

                                    <SelectGroupContent
                                            _isTabular={group.isTabular}>
                                        {this.renderGroup(group)}
                                    </SelectGroupContent>
                                    {group.def?.id === SelectGroups.Default && this.props.renderDefaultGroupWithoutCheckboxes && this.renderGroupDivider(group.def)}
                                </SelectGroup>
                        );
                        isFirst = false;
                        return r;
                    })}
                </>
        );
    };

    render() {
        const groups = this.createGroups();
        // action items are rendered in special section on the bottom of the menu
        const basicGroups = groups.filter(group => group.def?.id !== SelectGroups.Action);

        this._isMenuWithIcons = this.isMenuWithIcons();
        this._indexCounter = -1;

        const { showSearchBoxInMenu, defaultMenuWidth, isSimpleBarDisabled, headerText, displayArrow } = this.props;

        let scrollContent = (
                <>
                    <ContentWrapper
                            _minWidth={defaultMenuWidth}
                            _maxWidth={this.getMaxWidth()}
                            ref={this.handleContentRef}>
                        {this.renderGroups(basicGroups)}
                    </ContentWrapper>
                    {this.props.currentValue &&
                            <div>
                                {this.props.sharedData.bottomMenuCustomContent}
                            </div>
                    }
                </>
        );

        if (!isSimpleBarDisabled) {
            scrollContent = (
                    <ScrollBar
                            scrollableNodeProps={{
                                ref: this._refScroll
                            }}
                            style={{
                                overflowX: "hidden",
                                maxHeight: PAGE_SIZE + "px"
                            }}>
                        {scrollContent}
                    </ScrollBar>
            );
        }

        const menuWrapperHasContents = !!(basicGroups.length || showSearchBoxInMenu || headerText || displayArrow);

        return (
                <StyledMenu
                        tabIndex={0}
                        onKeyDown={this.handleKeyDown}
                        ref={this.props.popperProps.ref}
                        style={this.props.popperProps.style}
                        data-popper-placement={this.props.popperProps.placement}
                        data-testid={TestIds.SelectMenu}>

                    <StyledMenuWrapper ref={this.handleMenuRef}
                                       tabIndex={0}>
                        {headerText && this.renderMenuHeader()}
                        {showSearchBoxInMenu &&
                                <SelectInput
                                        onKeyDown={this.props.onInputKeyDown}
                                        onBlur={this.props.onInputBlur}
                                        onChange={this.props.onInputChange}/>
                        }
                        {displayArrow &&
                                <ArrowCover
                                        style={this.props.popperProps.arrowProps.style}
                                        ref={this.props.popperProps.arrowProps.ref}>
                                    <ArrowTop/>
                                </ArrowCover>
                        }
                        {scrollContent}
                    </StyledMenuWrapper>
                    {this.renderActionItems(groups, menuWrapperHasContents)}
                </StyledMenu>
        );
    }
}

const MenuWithTranslation = withTranslation("Common", { withRef: true })(Menu);
export { MenuWithTranslation as Menu };
