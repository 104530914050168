import React from "react";
import { StyledTodoListTile } from "./TodoList.styles";
import { IDashboardTileComponentProps } from "../../dashboard";
import { WithOData, withOData } from "@odata/withOData";
import DashboardTileHeader from "../../dashboard/DashboardTileHeader";
import TodoList from "./TodoList";
import { WithTranslation, withTranslation } from "react-i18next";
import {
    createTodoListItem,
    isNewItem,
    ITodoListItem,
    loadTodoListItems,
    removeItem,
    saveItem,
    sortTodoListItems,
    updateItemInList
} from "./TodoList.utils";
import { IEditEndArgs } from "./TodoListItem";
import memoizeOne from "../../../utils/memoizeOne";
import { TRecordType } from "../../../global.types";
import { AppContext } from "../../../contexts/appContext/AppContext.types";

enum TodoListHeaderAction {
    ShowCompleted = "ShowCompleted",
    AddNew = "AddNew"
}

interface IProps extends IDashboardTileComponentProps, WithOData, WithTranslation {
}

interface IState {
    items?: ITodoListItem[];
    toBeCompleted?: string[];
    active?: string;
    isCompletedVisible?: boolean;
    isLoading?: boolean;
    hasError?: boolean;
}

class TodoListTile extends React.PureComponent<IProps, IState> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;

    state: IState = {
        isCompletedVisible: false,
        items: [],
        active: null,
        isLoading: true,
        hasError: false
    };

    _newItemPromises: TRecordType<Promise<ITodoListItem>> = {};
    _mounted = true;
    _companyId: string = null;

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.context.getCompanyId() !== this._companyId) {
            this.init();
        }
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    async init(): Promise<void> {
        let items: ITodoListItem[] = [];
        let hasError = false;
        this._companyId = this.context.getCompanyId();

        try {
            items = await loadTodoListItems(this.props.oData);
        } catch (e) {
            hasError = true;
        }
        if (this._mounted) {
            this.setState({ items, isLoading: false, hasError });
        }
    }

    getUncompletedItems = memoizeOne(() => this.state.items.filter((item) => !item.DateCompleted || this.state.toBeCompleted?.includes(item.TemporaryGuid)),
            () => [this.state.items, this.state.toBeCompleted]);

    getSortedItems = memoizeOne(() => sortTodoListItems(this.state.isCompletedVisible ? this.state.items : this.getUncompletedItems(), this.state.toBeCompleted),
            () => [this.state.isCompletedVisible, this.state.items, this.getUncompletedItems(), this.state.toBeCompleted]);

    getActions = memoizeOne(() => {
        return [{
            id: TodoListHeaderAction.ShowCompleted,
            label: this.props.t("Home:TodoList.ShowCompleted"),
            iconName: this.state.isCompletedVisible ? "VisibleFilled" : "Visible",
            isVisible: this.getUncompletedItems()?.length !== this.state.items.length,
            isDisabled: this.props.inEditMode
        }, {
            id: TodoListHeaderAction.AddNew,
            label: this.props.t("Home:TodoList.AddNew"),
            iconName: "Plus",
            isDisabled: this.props.inEditMode
        }];
    }, () => [this.state.isCompletedVisible, this.getUncompletedItems(), this.state.items, this.props.inEditMode]);

    saveItem(currentItem: ITodoListItem, diff: Partial<ITodoListItem>) {
        if (isNewItem(currentItem) && this._newItemPromises[currentItem.TemporaryGuid]) {
            this._newItemPromises[currentItem.TemporaryGuid]
                .then(savedItem => this.saveItem(savedItem, diff));
            return;
        }

        const promise = saveItem(this.props.oData, currentItem, diff);

        if (isNewItem(currentItem)) {
            this._newItemPromises[currentItem.TemporaryGuid] = promise;
            // update Id in the list
            promise.then((savedItem) => {
                this.setState(({ items }) => ({
                    items: updateItemInList(items, currentItem.TemporaryGuid, { Id: savedItem.Id })
                }));
            });
        }
    }

    handleEditEnd = (guid: string, value: string, args: IEditEndArgs) => {
        const { addNext, remove, hasChanged } = args;

        this.setState((state) => {
            const sortedItems = sortTodoListItems(state.items, state.toBeCompleted);
            const currentIdx = sortedItems.findIndex(item => item.TemporaryGuid === guid);
            const itemToChange = sortedItems[currentIdx];

            if (remove) {
                removeItem(this.props.oData, itemToChange.Id);
                // set previous as active, or nothing if the removed item was the first one
                const nextActive = currentIdx > 0 ? sortedItems[currentIdx - 1].TemporaryGuid : null;
                return {
                    items: state.items.filter(i => i.TemporaryGuid !== guid),
                    active: nextActive
                };
            }

            let changedItems: ITodoListItem[] = state.items;
            let active: string = null;

            if (hasChanged) {
                const diff = { Description: value };
                changedItems = updateItemInList(state.items, guid, diff);
                this.saveItem(itemToChange, diff);
            }
            if (addNext) {
                const newItem = createTodoListItem(changedItems, itemToChange);
                changedItems = [newItem, ...changedItems];
                active = newItem.TemporaryGuid;
            }

            return {
                items: changedItems,
                active
            };
        });
    };

    handleNavigation = (guid: string, dir: number) => {
        const sortedItems = this.getSortedItems();
        const currentIdx = sortedItems.findIndex((item) => item.TemporaryGuid === guid);
        const newIdx = currentIdx - dir;
        if (newIdx >= 0 && newIdx < sortedItems.length) {
            this.setState({ active: sortedItems[newIdx].TemporaryGuid });
        }
    };

    handleComplete = (guid: string, value: boolean) => {
        this.setState(({ items, isCompletedVisible, toBeCompleted }) => {
            const itemToChange = items.find(item => item.TemporaryGuid === guid);
            const DateCompleted = value ? new Date() : null;
            const diff = { DateCompleted };
            this.saveItem(itemToChange, diff);

            if (!isCompletedVisible) {
                toBeCompleted = [...(toBeCompleted ?? []), guid];
            }

            // when completion is removed
            if (!DateCompleted) {
                const hasAnotherCompletedTask = items?.find(item => item.DateCompleted && item.TemporaryGuid !== guid);
                if (!hasAnotherCompletedTask) {
                    isCompletedVisible = false;
                }
                // -> if it was removed recently, clear it from toBeCompleted list
                toBeCompleted = toBeCompleted?.filter(id => id !== guid);
            }

            return {
                items: updateItemInList(items, guid, diff),
                isCompletedVisible,
                toBeCompleted
            };
        });
    };

    handleAnimationEnd = (guid: string) => {
        this.setState(({ toBeCompleted }) => ({
            toBeCompleted: toBeCompleted.filter(id => id !== guid)
        }));
    };

    handleHeaderAction = (actionId: string) => {
        if (actionId === TodoListHeaderAction.ShowCompleted) {
            this.setState(({ isCompletedVisible }) => ({ isCompletedVisible: !isCompletedVisible }));
        } else if (actionId === TodoListHeaderAction.AddNew) {
            this.setState(({ items }) => {
                const newItem = createTodoListItem(items);
                return {
                    items: [newItem, ...items],
                    active: newItem.TemporaryGuid
                };
            });
        }
    };

    render() {
        return (
            <StyledTodoListTile backgroundColor={"C_ACT_hover_without_opacity"}
                                overflowed
                                isLoading={this.state.isLoading || this.state.hasError/*todo: may be different look for error state*/}>
                <DashboardTileHeader onHeaderAction={this.handleHeaderAction}
                                     icons={this.getActions()}
                                     addPadding>
                    {this.props.t("Home:TodoList.Heading")}
                </DashboardTileHeader>

                <TodoList items={this.getSortedItems()}
                          active={this.state.active}
                          toBeRemoved={this.state.toBeCompleted}
                          isDisabled={this.props.inEditMode}
                          onEditEnd={this.handleEditEnd}
                          onNavigate={this.handleNavigation}
                          onAnimationEnd={this.handleAnimationEnd}
                          onComplete={this.handleComplete}/>
            </StyledTodoListTile>
        );
    }
}

export default withOData(withTranslation("Home")(TodoListTile));