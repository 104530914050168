import React, { lazy } from "react";
import PrivateRoute from "../routes/PrivateRoute";
import FormRoute from "../routes/FormRoute";
import { CompanyPermissionCode } from "@odata/GeneratedEnums";
import {
    ROUTE_ABSENCE_CATEGORIES,
    ROUTE_ATTENDANCE,
    ROUTE_EMPLOYEES,
    ROUTE_EMPLOYMENT,
    ROUTE_EMPLOYMENT_TEMPLATE,
    ROUTE_EXTRA_SALARIES, ROUTE_GOVERNMENT_INSTITUTION,
    ROUTE_HEALTH_INSURANCE_COMPANIES,
    ROUTE_LIFE_INSURANCE_COMPANIES, ROUTE_PAYSLIP,
    ROUTE_PENSION_INSURANCE_COMPANIES,
    ROUTE_SALARY_ADVANCES,
    ROUTE_SALARY_COMPONENTS,
    ROUTE_WORKING_PATTERNS
} from "../routes";
import { EntityTypeName } from "@odata/GeneratedEntityTypes";

const AbsenceCategory = lazy(() => import(/* webpackChunkName: "payroll" */"../pages/payroll/absenceCategory/AbsenceCategory"));
const HealthInsuranceCompany = lazy(() => import(/* webpackChunkName: "payroll" */"../pages/payroll/healthInsuranceCompany/HealthInsuranceCompany"));
const LifeInsuranceCompany = lazy(() => import(/* webpackChunkName: "payroll" */"../pages/payroll/lifeInsuranceCompany/LifeInsuranceCompany"));
const PensionInsuranceCompany = lazy(() => import(/* webpackChunkName: "payroll" */"../pages/payroll/pensionInsuranceCompany/PensionInsuranceCompany"));
const WorkingPatterns = lazy(() => import(/* webpackChunkName: "payroll" */"../pages/payroll/workingPatterns/WorkingPatterns"));
const Employment = lazy(() => import(/* webpackChunkName: "payroll" */"../pages/payroll/employment/Employment"));
const EmploymentTemplate = lazy(() => import(/* webpackChunkName: "payroll" */"../pages/payroll/employmentTemplate/EmploymentTemplate"));
const Employee = lazy(() => import(/* webpackChunkName: "payroll" */"../pages/payroll/employee/Employee"));
const SalaryComponent = lazy(() => import(/* webpackChunkName: "payroll" */"../pages/payroll/salaryComponent/SalaryComponent"));
const SalaryAdvance = lazy(() => import(/* webpackChunkName: "payroll" */"../pages/payroll/salaryAdvance/SalaryAdvance"));
const ExtraSalary = lazy(() => import(/* webpackChunkName: "payroll" */"../pages/payroll/extraSalary/ExtraSalary"));
const Attendance = lazy(() => import(/* webpackChunkName: "payroll" */"../pages/payroll/attendance/Attendance"));
const Payslip = lazy(() => import(/* webpackChunkName: "payroll" */"../pages/payroll/payslip/Payslip"));
const GovernmentInstitution = lazy(() => import(/* webpackChunkName: "payroll" */"../pages/payroll/governmentInstitution/GovernmentInstitution"));

export default function payrollBundle() {
    return [
        <FormRoute
                path={ROUTE_ABSENCE_CATEGORIES}
                key={ROUTE_ABSENCE_CATEGORIES}
                permission={CompanyPermissionCode.PayrollSettings}
                entityType={EntityTypeName.PrAbsenceCategory}
                page={AbsenceCategory}/>,
        <FormRoute
                path={ROUTE_HEALTH_INSURANCE_COMPANIES}
                key={ROUTE_HEALTH_INSURANCE_COMPANIES}
                permission={CompanyPermissionCode.PayrollSettings}
                entityType={EntityTypeName.PrHealthInsuranceCompany}
                page={HealthInsuranceCompany}/>,
        <FormRoute
                path={ROUTE_LIFE_INSURANCE_COMPANIES}
                key={ROUTE_LIFE_INSURANCE_COMPANIES}
                permission={CompanyPermissionCode.PayrollSettings}
                entityType={EntityTypeName.PrLifeInsuranceCompany}
                page={LifeInsuranceCompany}/>,
        <FormRoute
                path={ROUTE_PENSION_INSURANCE_COMPANIES}
                key={ROUTE_PENSION_INSURANCE_COMPANIES}
                permission={CompanyPermissionCode.PayrollSettings}
                entityType={EntityTypeName.PrPensionInsuranceCompany}
                page={PensionInsuranceCompany}/>,
        <FormRoute
                path={ROUTE_WORKING_PATTERNS}
                key={ROUTE_WORKING_PATTERNS}
                permission={CompanyPermissionCode.Payroll}
                entityType={EntityTypeName.PrWorkingPattern}
                page={WorkingPatterns}/>,
        <FormRoute
                path={ROUTE_EMPLOYMENT}
                key={ROUTE_EMPLOYMENT}
                permission={CompanyPermissionCode.Payroll}
                entityType={EntityTypeName.PrEmployment}
                page={Employment}/>,
        <FormRoute
                path={ROUTE_EMPLOYMENT_TEMPLATE}
                key={ROUTE_EMPLOYMENT_TEMPLATE}
                permission={CompanyPermissionCode.Payroll}
                entityType={EntityTypeName.PrEmploymentTemplate}
                page={EmploymentTemplate}/>,
        <FormRoute
                path={ROUTE_EMPLOYEES}
                key={ROUTE_EMPLOYEES}
                permission={CompanyPermissionCode.Payroll}
                entityType={EntityTypeName.PrEmployee}
                page={Employee}/>,
        <FormRoute
                path={ROUTE_SALARY_COMPONENTS}
                key={ROUTE_SALARY_COMPONENTS}
                permission={CompanyPermissionCode.Payroll}
                entityType={EntityTypeName.PrSalaryComponent}
                page={SalaryComponent}/>,
        <FormRoute
                path={ROUTE_SALARY_ADVANCES}
                key={ROUTE_SALARY_ADVANCES}
                permission={CompanyPermissionCode.Payroll}
                entityType={EntityTypeName.PrSalaryAdvance}
                page={SalaryAdvance}/>,
        <FormRoute
                path={ROUTE_EXTRA_SALARIES}
                key={ROUTE_EXTRA_SALARIES}
                permission={CompanyPermissionCode.Payroll}
                entityType={EntityTypeName.PrExtraSalary}
                page={ExtraSalary}/>,
        <FormRoute
                path={ROUTE_ATTENDANCE}
                key={ROUTE_ATTENDANCE}
                permission={CompanyPermissionCode.Payroll}
                entityType={EntityTypeName.PrAttendance}
                page={Attendance}/>,
        <PrivateRoute
                path={ROUTE_PAYSLIP}
                key={ROUTE_PAYSLIP}
                permission={CompanyPermissionCode.Payroll}
                component={Payslip}/>,
        <PrivateRoute
                path={ROUTE_GOVERNMENT_INSTITUTION}
                key={ROUTE_GOVERNMENT_INSTITUTION}
                permission={CompanyPermissionCode.PayrollSettings}
                component={GovernmentInstitution}/>
    ];
}