import React, { memo, useContext } from "react";
import { IDashboardTileComponentProps } from "../../dashboard";
import { useTranslation } from "react-i18next";
import { withOData, WithOData } from "@odata/withOData";
import DashboardTileHeader from "../../dashboard/DashboardTileHeader";
import {
    GroupedInfoTileBackground,
    GroupedInfoTileContentWrapper,
    GroupInfoTileHeader
} from "../infoTile/InfoTile.styles";
import { ROUTE_AGENDA_WORK_OVERVIEW } from "../../../routes";
import InfoTile from "../infoTile";
import {
    getEstimatedAccrualsDef,
    getFutureExpensesAndRevenuesDef,
    getInboxTileDef,
    getTicketsInfoTileDef,
    isOnOrganizationLevel
} from "@pages/home/Home.utils";
import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { CompanyPermissionCode } from "@odata/GeneratedEnums";
import { isCashBasisAccountingCompany } from "@utils/CompanyUtils";

interface IProps extends IDashboardTileComponentProps, WithOData {
}

const AgendaWorkOverview: React.FC<IProps> = ({ inEditMode }) => {
    const { t } = useTranslation("Home");
    const context = useContext(AppContext);
    const futureExpensesAndRevenuesInfo = getFutureExpensesAndRevenuesDef(true);
    const estimatedAccrualsInfo = getEstimatedAccrualsDef(true);
    const ticketsInfo = getTicketsInfoTileDef(true);
    const inboxInfo = getInboxTileDef(true);
    const isCrossCompany = isOnOrganizationLevel({ context });

    const showInternalDocRelated = isCrossCompany || context.getCompanyPermissions().has(CompanyPermissionCode.InternalAndCorrectiveDocuments);
    const showInboxRelated = isCrossCompany || context.getCompanyPermissions().has(CompanyPermissionCode.SortingFilesFromAPhotoFile);
    const isCashBasis = isCashBasisAccountingCompany(context);
    const cols = isCashBasis ? 1 : 2;

    return (
            <GroupedInfoTileBackground
                    inEditMode={inEditMode}
                    overflowed
                    to={isCrossCompany ? ROUTE_AGENDA_WORK_OVERVIEW : undefined}>
                <GroupInfoTileHeader>
                    <DashboardTileHeader>{t(`Home:AgendaWorkOverview.${isCrossCompany ? "Title" : "SingleCompanyTitle"}`)}</DashboardTileHeader>
                </GroupInfoTileHeader>

                <GroupedInfoTileContentWrapper cols={cols} rows={2}>
                    {!isCashBasis &&
                        <>
                            {showInternalDocRelated &&
                                <InfoTile inEditMode={inEditMode} info={futureExpensesAndRevenuesInfo} isGrouped/>}
                            {showInternalDocRelated &&
                                <InfoTile inEditMode={inEditMode} info={estimatedAccrualsInfo} isGrouped/>}
                        </>
                    }
                    <InfoTile inEditMode={inEditMode} info={ticketsInfo} isGrouped/>
                    {showInboxRelated && <InfoTile inEditMode={inEditMode} info={inboxInfo} isGrouped/>}
                </GroupedInfoTileContentWrapper>
            </GroupedInfoTileBackground>
    );
};

export default memo(withOData(AgendaWorkOverview));