import { removeWhiteSpace } from "../string";
import { SizeUnit } from "../../enums";

export enum PrintType {
    SinglePage = "SinglePage",
    MultiplePages = "MultiplePages"
}

export enum PrintOrientation {
    Portrait = "Portrait",
    Landscape = "Landscape"
}

export enum PaperSize {
    A3 = "A3",
    A4 = "A4",
    A5 = "A5",
    B4 = "B4",
    B5 = "B5",
    Letter = "Letter",
    Legal = "Legal",
    Tabloid = "Tabloid",
    Custom = "Custom"
}

export enum PrintPageSelection {
    All = "All",
    Custom = "Custom"
}

export enum PrintMargin {
    None = "None",
    Custom = "Custom"
}

interface IPaperSize {
    // in mm
    width: number;
    // in mm
    height: number;
}

export const PaperSizes: Record<PaperSize, IPaperSize> = {
    [PaperSize.A5]: {
        width: 148,
        height: 210
    },
    [PaperSize.A4]: {
        width: 210,
        height: 297
    },
    [PaperSize.A3]: {
        width: 297,
        height: 420
    },
    [PaperSize.B5]: {
        width: 176,
        height: 250
    },
    [PaperSize.B4]: {
        width: 250,
        height: 353
    },
    [PaperSize.Letter]: {
        width: 215.9,
        height: 279.4
    },
    [PaperSize.Legal]: {
        width: 215.9,
        height: 355.6
    },
    [PaperSize.Tabloid]: {
        width: 279.4,
        height: 431.8
    },
    [PaperSize.Custom]: {
        width: null,
        height: null
    }
};

// DPI used for export - bigger DPI => more quality => bigger files
export const DPI = 200;
// usual screen DPI standard
export const STANDARD_DPI = 96;
const ONE_INCH_IN_MM = 25.4;

export const mmToPx = (mm: number) => {
    return mm * DPI / ONE_INCH_IN_MM;
};

export const pxToMm = (px: number) => {
    // 1 millimeter = 3.78 pixel
    return px * ONE_INCH_IN_MM / DPI;
};

export const inToPx = (inches: number) => {
    return inches * DPI;
};

export const pxToIn = (px: number) => {
    return px / DPI;
};

export const ptToPx = (pt: number): number => {
    return pt / 0.75 / STANDARD_DPI * DPI;
};

export const pxToPt = (px: number): number => {
    return px * 0.75 / STANDARD_DPI * DPI;
};

export const ptToMm = (pt: number): number => {
    // one pt is 1/72 inch
    return pt * ONE_INCH_IN_MM / 72;
};

export const convertFromPxToUnit = (value: number, unit: SizeUnit) => {
    switch (unit) {
        case SizeUnit.Inches:
            return pxToIn(value);
        case SizeUnit.Centimeters:
            return pxToMm(value) / 10;
        case SizeUnit.Millimeters:
        default:
            return pxToMm(value);
    }
};

export const convertFromUnitToPx = (value: number, unit: SizeUnit) => {
    switch (unit) {
        case SizeUnit.Inches:
            return inToPx(value);
        case SizeUnit.Centimeters:
            return mmToPx(value * 10);
        case SizeUnit.Millimeters:
        default:
            return mmToPx(value);
    }
};

export const adjustPxScale = (px: number) => {
    return px / STANDARD_DPI * DPI;
};

export const customPagesRangeRegex = /^((\d+)|(\d+-\d+))(,((\d+)|(\d+-\d+)))*$/;

export const parsePagesRange = (pagesRange: string): number[] => {
    const pages: number[] = [];
    // remove all white space
    pagesRange = removeWhiteSpace(pagesRange);

    if (!(customPagesRangeRegex.test(pagesRange))) {
        return pages;
    }

    const ranges = pagesRange.split(",");

    for (const range of ranges) {
        if (range.includes("-")) {
            // range
            const [fromPage, toPage] = range.split("-");
            let fromPageNumber = parseInt(fromPage);
            let toPageNumber = parseInt(toPage);

            if (fromPageNumber <= 0) {
                fromPageNumber = 1;
            }

            if (toPageNumber < fromPageNumber) {
                toPageNumber = fromPageNumber;
            }

            for (let i = fromPageNumber; i <= toPageNumber; i++) {
                pages.push(i);
            }
        } else {
            // single page
            pages.push(parseInt(range));
        }
    }

    return pages;
};

/** Compare aspect ratios of two rectangles. Useful when trying to fit image into new rectangle. */
export const compareRatios = (width1: number, height1: number, width2: number, height2: number) => {
    const ratio1 = width1 / height1;
    const ratio2 = width2 / height2;

    return ratio1 <= ratio2;
};

/** Returns size of the first rectangle so that it fits into the second. */
export const fitRectangle = (width1: number, height1: number, width2: number, height2: number, preventEnlarge?: boolean) => {
    if (preventEnlarge && width2 >= width1 && height2 >= height1) {
        return [width1, height1];
    }

    const shrinkByWidth = compareRatios(width2, height2, width1, height1);
    const ratio = shrinkByWidth ? width1 / width2 : height1 / height2;

    return [width1 / ratio, height1 / ratio];
};