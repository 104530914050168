import { withTranslation } from "react-i18next";
import TableView from "../../views/table/TableView";
import { getDefinitions } from "./BusinessPartnerDef";
import { withRouter } from "react-router-dom";
import Page, { IProps } from "../Page";
import { withOData } from "@odata/withOData";

class BusinessPartner extends Page<IProps> {
    static defaultProps = {
        getDef: getDefinitions,
        tableView: TableView
    };
}

export default withRouter(withOData(withTranslation([...getDefinitions.translationFiles])(BusinessPartner)));