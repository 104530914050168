import { IGetValueArgs } from "@components/smart/FieldInfo";
import {
    getAddressFields,
    hasOptionalPostalCode,
    PhoneNumberDef,
    SingleBusinessPartnerDef
} from "@components/smart/GeneralFieldDefinition";
import { getCollapsedGroupId } from "@components/smart/Smart.utils";
import { ISmartFieldBlur, ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { IFormGroupDef } from "@components/smart/smartFormGroup/SmartFormGroup";
import { IEntity } from "@odata/BindingContext";
import { setNestedValue } from "@odata/Data.utils";
import {
    AccountAssignmentSelectionEntity,
    BankTransactionEntity,
    BusinessPartnerEntity,
    DocumentBusinessPartnerEntity,
    DocumentEntity,
    EntitySetName,
    IBankAccountEntity,
    IBusinessPartnerBankAccountEntity,
    IBusinessPartnerContactEntity,
    IBusinessPartnerEntity,
    IBusinessPartnerIssuedDocumentDefaultEntity,
    IBusinessPartnerReceivedDocumentDefaultEntity,
    ICompanyBankAccountEntity,
    ICountryEntity,
    IDocumentBusinessPartnerEntity,
    IDocumentEntity,
    IDocumentItemEntity
} from "@odata/GeneratedEntityTypes";
import {
    CountryCode,
    CurrencyCode,
    DocumentTypeCode,
    PaymentMethodCode,
    SelectionCode,
    VatStatusCode
} from "@odata/GeneratedEnums";
import { BatchRequest, OData } from "@odata/OData";
import { isAccountAssignmentCompany } from "@utils/CompanyUtils";
import { areObjectsEqual, isDefined, isObjectEmpty } from "@utils/general";
import { logger } from "@utils/log";
import i18next from "i18next";

import { ARES_GET_API } from "../../constants";
import { BasicInputSizes, CacheStrategy, FieldType, GroupedField, TextAlign, ValidatorType } from "../../enums";
import { setGroupStatus } from "../../views/formView/Form.utils";
import { FormStorage, IFormStorageDefaultCustomData } from "../../views/formView/FormStorage";
import {
    convertAccountAssignmentIntoSelection,
    loadAccountAssignments,
    rewriteAccountAssignment
} from "../accountAssignment/AccountAssignment.utils";
import {
    appendAdditionalItemsToSavedBankAccounts,
    BankAccountDependentFields,
    hasEmptyBankAccount,
    IBankAccountArgs,
    IBankAccountsCustomData,
    prepareSavedAccounts,
    updateAllSavedAccountsOnPartnerChange
} from "../banks/bankAccounts/BankAccounts.utils";
import { getCompanyCountryCode, getCompanyCurrencyCode, getCompanyUsedCurrencyCodes } from "../companies/Company.utils";
import { isReceived, refreshExchangeRate } from "../documents/Document.utils";
import { IDocumentExtendedEntity } from "../documents/DocumentInterfaces";
import { TFieldsDefinition } from "../PageUtils";


export const ReceivedBankAccountDefaultsPath = "##ReceivedBankAccount##";
export const ShowReceivedDefaultsPath = "##ShowReceivedDefaults##";
export const ShowIssuedDefaultsPath = "##ShowIssuedDefaults##";

export const BusinessPartnerLegalNumberPath = "BusinessPartner/LegalNumber";

export interface IBusinessPartnerEntityExtended extends IBusinessPartnerEntity {
    [ReceivedBankAccountDefaultsPath]?: number;
    [ShowReceivedDefaultsPath]?: boolean;
    [ShowIssuedDefaultsPath]?: boolean;
}

export interface IBusinessPartnerCustomData extends IFormStorageDefaultCustomData {
    businessPartnerContacts?: IBusinessPartnerContactEntity[];
    // true if the current business partner was selected from ARES select
    isBusinessPartnerFromAres?: boolean;
}

export const getBusinessPartnerGroup = (file?: string): IFormGroupDef => {
    return {
        id: "partner",
        title: i18next.t(file ? `${file}:FormGroup.BusinessPartner` : "Common:General.BusinessPartner"),
        rows: [[{ id: "BusinessPartner/Name" }, { id: BusinessPartnerLegalNumberPath }]],
    };
};
export const businessPartnerDependentFields = [
    {
        from: { id: "Id" },
        to: { id: "BusinessPartner/BusinessPartner" }
    }, {
        from: { id: "LegalNumber" },
        to: { id: BusinessPartnerLegalNumberPath }
    }, {
        from: { id: "Name" },
        to: { id: "BusinessPartner/Name" }
    }, {
        from: { id: "TaxNumber" },
        to: { id: "BusinessPartner/TaxNumber" }
    }, {
        from: { id: "VatStatus" },
        to: { id: "BusinessPartner/VatStatus" }
    }, {
        from: { id: "Contacts/0/FirstName" },
        to: { id: "BusinessPartner/FirstName" }
    }, {
        from: { id: "Contacts/0/LastName" },
        to: { id: "BusinessPartner/LastName" }
    }, {
        from: { id: "Contacts/0/PhoneNumber" },
        to: { id: "BusinessPartner/PhoneNumber" }
    }, {
        from: { id: "Contacts/0/Email" },
        to: { id: "BusinessPartner/Email" }
    }, {
        from: { id: "BillingAddress/Street" },
        to: { id: "BusinessPartner/Street" }
    }, {
        from: { id: "BillingAddress/City" },
        to: { id: "BusinessPartner/City" }
    }, {
        from: { id: "BillingAddress/Country" },
        to: { id: "BusinessPartner/Country" }
    }, {
        from: { id: "BillingAddress/PostalCode" },
        to: { id: "BusinessPartner/PostalCode" }
    }
];

export const accountPartnerDepFields = businessPartnerDependentFields.map(field => ({
    ...field,
    from: { id: `BusinessPartner/${field.from.id}` }
}));

export const BusinessPartnerDef = {
    ...SingleBusinessPartnerDef,
    fieldSettings: {
        ...SingleBusinessPartnerDef.fieldSettings,
        localDependentFields: businessPartnerDependentFields,
        loadFromDb: true
    },
    columns: [{ id: BusinessPartnerEntity.Name }, { id: BusinessPartnerEntity.LegalNumber, textAlign: TextAlign.Right }]
};

const isSubFieldRequired = (args: IGetValueArgs, isBPRequired: boolean) => {
    const doc = args.storage.data.entity as IDocumentEntity; // some document or bankTransaction
    if (doc.BusinessPartner?.VatStatus?.Code !== VatStatusCode.NotVATRegistered || args.bindingContext.getPath() === BusinessPartnerEntity.VatStatus) {
        return isBPRequired || !!(doc.BusinessPartner?.Name || doc.BusinessPartner?.LegalNumber);
    }
    return false;
};


export const getBusinessPartnerFieldDef = (isRequired: boolean, file?: string): TFieldsDefinition => {
    const addressFields = getAddressFields("BusinessPartner");
    const creatingKey = file && i18next.exists(`${file}:FormGroup.NewPartner`) ? `${file}:FormGroup.NewPartner` : "Common:General.NewPartner";

    return {
        "BusinessPartner/Name": {
            groupedField: GroupedField.MultiStart,
            ...BusinessPartnerDef,
            isRequired
        },
        [BusinessPartnerLegalNumberPath]: {
            groupedField: GroupedField.MultiEnd,
            ...BusinessPartnerDef,
            creatingTitle: i18next.t(creatingKey),
            collapsedRows: [
                [
                    { id: "BusinessPartner/VatStatus" },
                    { id: "BusinessPartner/TaxNumber" },
                    { id: "BusinessPartner/Street" },
                    { id: "BusinessPartner/City" },
                    { id: "BusinessPartner/PostalCode" },
                    { id: "BusinessPartner/Country" }
                ],
                [
                    { id: "BusinessPartner/Email" },
                    { id: "BusinessPartner/PhoneNumber" },
                    { id: "BusinessPartner/FirstName" },
                    { id: "BusinessPartner/LastName" }
                ]
            ]
        },
        "BusinessPartner/TaxNumber": {},
        "BusinessPartner/Street": {
            ...addressFields["BusinessPartner/Street"],
            isRequired: (args) => isSubFieldRequired(args, isRequired)
        },
        "BusinessPartner/City": {
            ...addressFields["BusinessPartner/City"],
            isRequired: (args) => isSubFieldRequired(args, isRequired)
        },
        "BusinessPartner/PostalCode": {
            ...addressFields["BusinessPartner/PostalCode"],
            isRequired: (args) => isSubFieldRequired(args, isRequired) && !hasOptionalPostalCode(args)
        },
        "BusinessPartner/Country": {
            ...addressFields["BusinessPartner/Country"],
            isRequired: (args) => isSubFieldRequired(args, isRequired)
        },
        "BusinessPartner/Email": {
            width: BasicInputSizes.L,
            validator: {
                type: ValidatorType.Email
            }
        },
        "BusinessPartner/PhoneNumber": {
            ...PhoneNumberDef
        },
        "BusinessPartner/FirstName": {},
        "BusinessPartner/LastName": {},
        "BusinessPartner/VatStatus": {
            type: FieldType.ComboBox,
            isRequired: (args) => isSubFieldRequired(args, isRequired),
            cacheStrategy: CacheStrategy.EndOfTime,
            fieldSettings: {
                displayName: "Name"
            }
        }
    };
};

export const isBusinessPartnerField = (name: string): boolean => {
    return name === "BusinessPartner/Name" || name === BusinessPartnerLegalNumberPath;
};

export const loadPartner = async (partnerId: string, oData: OData): Promise<IBusinessPartnerEntity> => {
    const response = await oData
        .getEntitySetWrapper(EntitySetName.BusinessPartners)
        .query(partnerId)
        .expand("BankAccounts", (q) => {
            q.select("Id", "AccountNumber", "AbaNumber", "BankCode", "IBAN", "SWIFT", "IsDefaultForReceivedDocuments");
            q.expand("Country");
        })
        .expand("Contacts", (q) => {
            q.select("Id", "FirstName", "LastName", "Email", "PhoneNumber").orderBy("Order");
        })
        .expand("ReceivedDocumentDefault", q => {
            q.expand("AccountAssignment");
            q.expand("VatClassification");
        })
        .expand("BillingAddress", q => {
            q.expand("Country");
        })
        .expand("IssuedDocumentDefault", q => {
            q.expand("AccountAssignment");
            q.expand("VatClassification");
            q.expand("CompanyBankAccount", qq => {
                qq.expand("Country");
            });
        })
        .expand("VatStatus")
        .fetchData<IBusinessPartnerEntity>();

    return response?.value ?? {};
};

export const fetchAresDetail = async (legalNumber: string, countryCode: CountryCode.CzechRepublic | CountryCode.Slovakia): Promise<IBusinessPartnerEntity & {
    MainActivity: string
}> => {
    const response = await fetch(`${ARES_GET_API}?country=${countryCode}&identificationNumber=${legalNumber}`);

    if (!response.ok) {
        logger.error("couldn't fetch from ARES API");
        return null;
    }

    const item = await response.json();
    return {
        Id: null, // clears businessPartner relation as this is a new one
        BillingAddress: {
            City: item.Record.Address.City,
            CountryCode: item.Record.Address.Country,
            Country: {
                Code: item.Record.Address.Country,
                IsEuMember: true // we support only SK and CZ now...
            },
            PostalCode: item.Record.Address.PostalCode,
            Street: `${item.Record.Address.Street || item.Record.Address.City || ""} ${item.Record.Address.HouseNumber || ""}`.trim()
        },
        TaxNumber: item.Record.VatInfo?.VatIdentificationNumber,
        VatStatus: {
            Code: item.Record.VatInfo?.VatPayer ? VatStatusCode.VATRegistered : item.Record.IsIdentifiedPersonForVat ? VatStatusCode.IdentifiedPerson : VatStatusCode.NotVATRegistered
        },
        MainActivity: item.Record.MainNaceActivity
    };
};


export function setBusinessPartnerGroupStatus(storage: FormStorage, isCreating: boolean): void {
    if (storage.isReadOnly) {
        // when storage is in read-only mode, we don't want to show group status as creating, even there is no BP ID
        isCreating = false;
    }
    setGroupStatus(getCollapsedGroupId({ id: BusinessPartnerLegalNumberPath }), storage, isCreating);
}

interface IBusinessPartnerChangeArgs {
    showsAllBankAccounts?: boolean;
    allowCreate?: boolean;
    currencyDatePropPath?: string;
}

export const handleBusinessPartnerChange = async (args: IBankAccountArgs, e: ISmartFieldChange, opts?: IBusinessPartnerChangeArgs): Promise<void> => {
    const { showsAllBankAccounts, allowCreate, currencyDatePropPath } = opts ?? {};
    const isBPMainField = isBusinessPartnerField(e.bindingContext.getNavigationPath(true));

    if (isBPMainField) {
        if (e.triggerAdditionalTasks) {
            const { Id } = e.additionalData ?? {};

            if (allowCreate !== false) {
                setBusinessPartnerGroupStatus(args.storage, !Id);
            }

            appendAdditionalItemsToSavedBankAccounts(args);
            setSavedContactItems(args.storage, e.additionalData.Contacts);
            setSavedBankAccounts(args.storage, e.additionalData.BankAccounts);

            if (showsAllBankAccounts) {
                updateAllSavedAccountsOnPartnerChange({ ...args, businessPartner: e.additionalData });
            }

            args.storage.refresh();
        } else if (args.storage.data.entity.BusinessPartner?.BusinessPartner) {
            // User changes only Name/LegalNumber without picking from list -> remove the relation
            args.storage.data.entity.BusinessPartner.BusinessPartner = undefined;
        }
    }
    const isBPCountry = e.bindingContext.getPath() === DocumentBusinessPartnerEntity.Country && e.bindingContext.getParent().getPath(true) === BankTransactionEntity.BusinessPartner;
    if ((isBPMainField || isBPCountry) && e.triggerAdditionalTasks) {
        // change currency if there are no changed items
        if (!args.storage.data.entity.Items?.some((item: IDocumentItemEntity) => item.TransactionAmount)) {
            await setDefaultBPCurrency(args, e, currencyDatePropPath);
        }
    }

    if (e.bindingContext.getPath() === DocumentBusinessPartnerEntity.VatStatus) {
        args.storage.refreshGroupByKey("partner");
    }
};


interface ISetDefaultsFromBusinessPartner {
    storage: FormStorage<IDocumentExtendedEntity>;
    type?: DocumentTypeCode;
    businessPartner?: IBusinessPartnerEntity;
}

export const setDefaultsFromBusinessPartner = async ({
                                                         storage,
                                                         businessPartner,
                                                         type
                                                     }: ISetDefaultsFromBusinessPartner, refreshDateDue?: (dueDays?: number) => void): Promise<void> => {
    let defaultBankAccount;
    const isReceivedDoc = isReceived(type);
    const propName = isReceivedDoc ? BusinessPartnerEntity.ReceivedDocumentDefault : BusinessPartnerEntity.IssuedDocumentDefault;

    // common properties for both ReceivedDocumentDefault and IssuedDocumentDefault
    if (businessPartner[propName] && !isObjectEmpty(businessPartner[propName])) {
        if (businessPartner[propName].DefaultPaymentMethodCode) {
            storage.clearAndSetValueByPath("PaymentMethod", businessPartner[propName].DefaultPaymentMethodCode);
        }

        const props: (keyof (IBusinessPartnerIssuedDocumentDefaultEntity | IBusinessPartnerReceivedDocumentDefaultEntity))[] =
            ["SymbolSpecific", "SymbolVariable", "SymbolConstant", "RemittanceInformation"];

        props.forEach(prop => {
            if (businessPartner[propName][prop]) {
                storage.setValueByPath(prop, businessPartner[propName][prop]);
            }
        });

        if (isDefined(businessPartner[propName].DaysDue)) {
            // DaysDue depends on DateAccountingTransaction -> call common method if present
            refreshDateDue?.(businessPartner[propName].DaysDue);
        }

        if (isAccountAssignmentCompany(storage.context)) {
            const accountAssignment = businessPartner[propName]?.AccountAssignment;

            if (!isObjectEmpty(businessPartner[propName].AccountAssignment)) {
                storage.setValueByPath(DocumentEntity.AccountAssignmentSelection, convertAccountAssignmentIntoSelection(accountAssignment));
                storage.setValueByPath(`${DocumentEntity.AccountAssignmentSelection}/${AccountAssignmentSelectionEntity.Selection}`, SelectionCode.Copy);

                const fiscalYear = storage.data.entity.FiscalYear;
                const items = fiscalYear ? (await loadAccountAssignments(storage)) : [];

                await rewriteAccountAssignment({
                    storage,
                    choaId: fiscalYear?.ChartOfAccounts?.Id,
                    itemBc: storage.data.bindingContext,
                    accountAssignments: items
                });
            }
        }
    }


    // some other things?
    if (isReceivedDoc) {
        defaultBankAccount = businessPartner.BankAccounts?.find(
            (account: IBusinessPartnerBankAccountEntity) => account.IsDefaultForReceivedDocuments
        );
    } else if (businessPartner.IssuedDocumentDefault?.CompanyBankAccount?.IsActive) {
        defaultBankAccount = businessPartner.IssuedDocumentDefault?.CompanyBankAccount;
    } else if (businessPartner.IssuedDocumentDefault?.DefaultPaymentMethodCode === PaymentMethodCode.PaymentService) {
        const defAcc = storage.context.getCompanyBankAccounts()?.filter((acc: ICompanyBankAccountEntity) => acc.IsDefault)?.[0];
        if (defAcc && defAcc.PaymentServiceID) {
            // as we can't store default acc for payment service in business partner we use default one if it is the case
            defaultBankAccount = defAcc;
        }
    }

    if (defaultBankAccount && !defaultBankAccount?.PaymentServiceID) {
        storage.processDependentField(BankAccountDependentFields, defaultBankAccount);
    }

    prepareSavedAccounts({
        storage: storage,
        type: type,
        businessPartner,
        account: defaultBankAccount
    });

    storage.refresh();
};

export function isNewPartnerWithData(storage: FormStorage, path = "BusinessPartner"): boolean {
    const value = storage.getValueByPath(path);
    return !value?.BusinessPartner?.Id && !!(value?.Name || value?.LegalNumber);
}

const setDefaultBPCurrency = async (args: IBankAccountArgs, e: ISmartFieldChange, datePropPath?: string): Promise<void> => {
    const currencyBc = args.storage.data.bindingContext.navigate("TransactionCurrency");
    // country or BP change
    const countryCode = e.bindingContext.getPath() === "Country" ? e.additionalData.Code : e.additionalData.Country?.Code;
    if (countryCode) {
        let currencyCode: CurrencyCode;
        if (countryCode === getCompanyCountryCode(args.storage.context)) {
            // Czech republic is default, we don't have it "currenciesUsedByCompany" and always switch it back
            currencyCode = getCompanyCurrencyCode(args.storage.context);
        } else {
            const res = await args.storage.oData.getEntitySetWrapper(EntitySetName.Countries).query(`'${countryCode}'`).select("DefaultCurrencyCode").fetchData<ICountryEntity>();
            const defaultCurrency = res.value?.DefaultCurrencyCode as CurrencyCode;
            const usedCurrencies = getCompanyUsedCurrencyCodes(args.storage.context);
            const isUsed = usedCurrencies.includes(defaultCurrency);
            if (isUsed) {
                currencyCode = defaultCurrency;
            }
        }
        if (currencyCode) {
            args.storage.setValue(currencyBc, currencyCode);
            await refreshExchangeRate(args.storage, currencyCode, datePropPath);
            args.storage.addActiveGroupByKey("Items");
            args.storage.refresh();
        }
    }
};

export const handleBusinessPartnerBlur = async (args: IBankAccountArgs, e: ISmartFieldBlur, showsAllBankAccounts?: boolean, allowCreateBP = true): Promise<void> => {
    const isBusinessPartnerField = e.bindingContext.getParent().getPath(true) === "BusinessPartner";

    if (isBusinessPartnerField && e.wasChanged) {
        // no id is set, but there are some data in inputs => we expect creating new item
        const isNewWithData = isNewPartnerWithData(args.storage);
        if (allowCreateBP) {
            setBusinessPartnerGroupStatus(args.storage, isNewWithData);
        }

        const bp = args.storage.getValue(e.bindingContext.getParent());
        // TODO: not sure about this, in troubles we need further investigation
        // const isEmpty = !bp?.Id && !bp?.BusinessPartner?.Id;
        const isNew = !bp?.BusinessPartner?.Id;

        if (isNew) {
            // clear accounts and BP related data -> businessPartner relation was removed
            if (showsAllBankAccounts) {
                updateAllSavedAccountsOnPartnerChange({ ...args, businessPartner: bp as IBusinessPartnerEntity });
            } else if (!showsAllBankAccounts) {
                prepareSavedAccounts({ ...args, businessPartner: {} });
            }

            setSavedContactItems(args.storage);
        }

        args.storage.refreshGroup(e.bindingContext);
        return;
    }
};

export const addBankAccountToBusinessPartner = (storage: FormStorage, data: IEntity, preparedData: IEntity): void => {
    let canAddAccount;

    if (storage.data.bindingContext.getPath(true) === EntitySetName.BankTransactions) {
        // for bank transaction
        canAddAccount = true;
    } else {
        // for documents
        const hasPaymentMethod = storage.data.bindingContext.getEntityType().getProperty("PaymentMethod");
        const paymentMethod = hasPaymentMethod && storage.getValueByPath("PaymentMethod", { useDirectValue: false });

        canAddAccount = paymentMethod === PaymentMethodCode.WireTransfer;
    }

    if (canAddAccount) {
        const isEmpty = hasEmptyBankAccount(storage);
        if (!isEmpty) {
            const currentBankAccount = preparedData.BankAccount;

            const newBankAccount = { ...currentBankAccount };
            delete newBankAccount.Id;

            data.BankAccounts = [newBankAccount];
        }
    }
};


export const prepareNewBusinessPartner = (storage: FormStorage, data: IEntity, addBankAccount?: boolean): void => {
    if (data.BusinessPartner?.BusinessPartner?.Id) {
        // BusinessPartner already exists
        return;
    }

    if (!data.BusinessPartner || (!data.BusinessPartner.Name && !data.BusinessPartner.LegalNumber)) {
        // not a new BusinessPartner
        // BusinessPartner can be optional, but there can be some default values in its object, like Currency
        // => remove it completely, so that it isn't send to BE at all
        data.BusinessPartner = null;
        return;
    }

    const newBusinessPartner: IBusinessPartnerEntity = {
        ...storage.extractFields(businessPartnerDependentFields),
        Company: {
            Id: storage.context.getCompany().Id
        }
    };

    // have to make sure there is no undefined contacts
    if (isEmptyContact(newBusinessPartner.Contacts?.[0])) {
        newBusinessPartner.Contacts = [];
    }

    delete newBusinessPartner.Id;

    if (addBankAccount) {
        addBankAccountToBusinessPartner(storage, newBusinessPartner, data);
    }

    if (!hasValidBillingAddress(data.BusinessPartner)) {
        delete newBusinessPartner.BillingAddress;
    }

    setNestedValue(newBusinessPartner, "BusinessPartner", data.BusinessPartner);
};

const compareContact = (c1: IBusinessPartnerContactEntity, c2: IBusinessPartnerContactEntity) => {
    return areObjectsEqual<IBusinessPartnerContactEntity>(c1, c2,
        ["FirstName", "LastName", "PhoneNumber", "Email"]);
};

export const setSavedContactItems = (storage: FormStorage<unknown, IBusinessPartnerCustomData>, contacts: IBusinessPartnerContactEntity[] = []): void => {
    storage.setCustomData({ businessPartnerContacts: contacts });
};

export const setSavedBankAccounts = (storage: FormStorage<unknown, IBankAccountsCustomData>, bankAccounts: IBankAccountEntity[] = []): void => {
    storage.setCustomData({ bankAccounts: bankAccounts });
};


export const hasBusinessPartner = (storage: FormStorage, partner?: IBusinessPartnerEntity): boolean => {
    partner = !isObjectEmpty(partner) ? partner : storage.data.entity.BusinessPartner;
    return !!(partner?.Id || partner?.Name || partner?.LegalNumber);
};


export const hasMatchingBusinessPartnerContact = (storage: FormStorage<any, IBusinessPartnerCustomData>, currectContact: IBusinessPartnerContactEntity): boolean => {
    const findSameContact = (contacts: IBusinessPartnerContactEntity[]) => {
        return contacts.find((contact: IBusinessPartnerContactEntity) => compareContact(currectContact, contact));
    };

    return !!findSameContact(storage.getCustomData().businessPartnerContacts) || !!findSameContact(storage.data.entity.BusinessPartner?.BusinessPartner?.Contacts ?? []);
};

const isEmptyContact = (contact: IBusinessPartnerContactEntity): boolean => {
    return contact && !contact.FirstName && !contact.LastName && !contact.Email && !contact.PhoneNumber;
};

export const addBusinessPartnerContactRequest = (storage: FormStorage, isNewBusinessPartner: boolean, batch: BatchRequest): void => {
    const extractedBusinessPartner = storage.extractFields<IBusinessPartnerEntity>(businessPartnerDependentFields);
    const newContact = extractedBusinessPartner.Contacts[0];

    if (isEmptyContact(newContact) || isNewBusinessPartner || hasMatchingBusinessPartnerContact(storage, newContact)) {
        return null;
    }

    delete newContact.Id;
    // Put to the end
    newContact.Order = 0;

    batch.fromPath(`BusinessPartners(${storage.data.entity.BusinessPartner.BusinessPartner.Id})/Contacts`).create(newContact);

    return null;
};

export const hasValidBillingAddress = (bp: IDocumentBusinessPartnerEntity): boolean => {
    return !!bp && !!bp.Street && !!bp.City && !isObjectEmpty(bp.Country);
};
