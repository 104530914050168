import React from "react";
import i18next from "i18next";
import {
    BasicInputSizes,
    FastEntryInputSizes,
    FieldType,
    LabelStatus,
    Sort,
    TextAlign,
    ValidatorType
} from "../../../enums";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition } from "../../PageUtils";
import BindingContext, { createPath, IEntity } from "../../../odata/BindingContext";
import { IFormDef } from "../../../views/formView/Form";
import MinorAssetFormView from "./MinorAssetFormView";
import { FilterBarGroup, getDefaultFilterGroupDef } from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { ISummaryItem } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import {
    getNumberOursSummaryDef,
    getNumberRangeFieldDefs,
    NumberRangeAdditionalProperties
} from "../../numberRange/NumberRange.utils";
import { TValue } from "../../../global.types";
import { IFormatOptions } from "@odata/OData.utils";
import CurrencyType, { formatCurrency, formatCurrencyVariableDecimals } from "../../../types/Currency";
import {
    AttachmentsAdditionalProperties,
    AttachmentsDef,
    getCommonTableColumnsDefs,
    getLabelsDef,
    getLabelsFilterDef,
    LabelsTableColumnDef,
    withDisplayName
} from "@components/smart/GeneralFieldDefinition";
import {
    EntitySetName,
    EntityTypeName,
    IDocumentEntity,
    IMinorAssetEntity,
    MinorAssetEntity,
    MinorAssetHistoryEntryEntity,
    MinorAssetHistoryItemEntity,
    MinorAssetItemEntity
} from "@odata/GeneratedEntityTypes";
import { setDefByEntityType } from "../../getDefByEntityType";
import { isFullForm } from "../Asset.utils";
import { TSmartFormGroupAction } from "@components/smart/smartFormGroup/SmartFormGroup";
import { AssetItemTypeCode, DocumentTypeCode, MinorAssetStatusCode } from "@odata/GeneratedEnums";
import { ISplitPageTableDef } from "../../../views/table/TableView.utils";
import Field from "../../../components/inputs/field";
import { IReadOnlyListItem } from "@components/readOnlyList/ReadOnlyListItem";
import SmartPairingSelect from "../../../components/smart/smartPairingSelect/SmartPairingSelect";
import { getDefinitions as getPairDefinitions, getMinorAssetPairingFilter } from "./MinorAssetDocumentPairDef";
import { transformPairingSelectItems } from "@components/smart/smartPairingSelect/SmartPairingSelect.utils";
import { getInfoValue, IGetValueArgs } from "@components/smart/FieldInfo";
import { FormStorage } from "../../../views/formView/FormStorage";
import * as yup from "yup";
import { ValidationError } from "yup";
import { ValidationMessage } from "../../../model/Validator.types";
import { getCorrectValidationArgs } from "../../../model/Validator";
import { getBoundValue } from "@odata/Data.utils";
import { ISelectionChangeArgs, ISelectItem } from "@components/inputs/select/BasicSelect";
import { IStorageModelData } from "../../../model/StorageModel";
import { getCompanyCurrency } from "@utils/CompanyUtils";
import { Model } from "../../../model/Model";
import { getMinorAssetTabs, getStatusColor, minorAssetStatusFormatter } from "./MinorAsset.utils";
import { getEnumDisplayValue } from "@odata/GeneratedEnums.utils";
import { getUtcDate } from "../../../types/Date";
import { DASH_CHARACTER } from "../../../constants";

export enum MinorAssetAction {
    // keep also the suffix, it's used in common line items actions, so it should not clash with other actions
    Pair = "PairMinorAsset",
    Create = "CreateMinorAsset",
    Dispose = "DisposeMinorAssetLineItem"
}

export function getDocumentTypeByPairingType(pairingType: AssetItemTypeCode): DocumentTypeCode[] {
    switch (pairingType) {
        case AssetItemTypeCode.Acquisition:
            return [
                DocumentTypeCode.InvoiceReceived,
                DocumentTypeCode.OtherLiability
            ];
        case AssetItemTypeCode.Disposal:
            return [
                DocumentTypeCode.InvoiceIssued,
                DocumentTypeCode.OtherReceivable
            ];
        default:
            return [] as DocumentTypeCode[];
    }
}

export function getPairingTypeByDocumentType(docType: DocumentTypeCode): AssetItemTypeCode {
    return [AssetItemTypeCode.Acquisition, AssetItemTypeCode.Disposal]
        .find(key => getDocumentTypeByPairingType(key).includes(docType));
}


const IS_ITEM_REMOVED_PATH = BindingContext.localContext("IS_ITEM_REMOVED");

export function setItemIsRemoved(storage: FormStorage, bindingContext: BindingContext): void {
    storage.setValue(bindingContext.navigate(IS_ITEM_REMOVED_PATH), true);
}

function isItemRemoved({ storage, bindingContext }: IGetValueArgs): boolean {
    const quantityBc = bindingContext.navigate("Quantity");
    const data = (storage as FormStorage).data.origEntity;
    const savedQuantity = getBoundValue({
        bindingContext: quantityBc,
        data,
        dataBindingContext: storage.data.bindingContext
    });
    return storage.getValue(bindingContext.navigate(IS_ITEM_REMOVED_PATH)) || savedQuantity === 0;
}

function getMinValueSchema(isInteger: boolean, min = 0, args: IGetValueArgs) {
    const fieldInfo = args.storage.getInfo(args.bindingContext);
    let schema = yup.number().required(ValidationMessage.Required).typeError(ValidationMessage.NotANumber);
    schema = schema.nullable();

    if (isInteger) {
        schema = schema.integer(ValidationMessage.Integer);
    }

    return schema.test("minimumValueOrRemoved", "", function(this: yup.TestContext, value: TValue) {
        const { storage, bindingContext } = getCorrectValidationArgs(args.storage, fieldInfo, this.path);
        const isRemoved = isItemRemoved({
            storage,
            bindingContext: bindingContext.getParent()
        });
        if (!isRemoved && value < min) {
            const error = new ValidationError(ValidationMessage.SmallNumber, value, this.path);
            error.params = { min };
            return error;
        }
        return true;
    });
}

export const getAdditionalLineItemActions = (isReceivedDocument: boolean): TSmartFormGroupAction[] => {
    return isReceivedDocument ? [
        {
            id: MinorAssetAction.Create,
            label: i18next.t("Document:Form.CreateMinorAsset"),
            iconName: "AddMinorAsset",
            confirmLabel: i18next.t("Document:Form.CreateMinorAssetConfirm"),
            ignoreTheme: true
        },
        {
            id: MinorAssetAction.Pair,
            label: i18next.t("Document:Form.PairMinorAsset"),
            iconName: "AddToMinorAsset",
            confirmLabel: i18next.t("Document:Form.PairMinorAssetConfirm"),
            ignoreTheme: true
        }
    ] : [
        // {
        //         id: MinorAssetAction.Pair,
        //         label: i18next.t("Document:Form.PairMinorAsset"),
        //         iconName: "AddToMinorAsset",
        //         confirmLabel: i18next.t("Document:Form.PairMinorAssetConfirm")
        //     }
    ];
};

export const getEditableWindowDefinition: IGetDefinition = (...args): IDefinition => {
    const def = getDefinitions(...args);
    def.table.preventStoreVariant = true;
    return def;
};

function isItemVisible(args: IGetValueArgs<IStorageModelData<IMinorAssetEntity>>): boolean {
    const { bindingContext, storage, item } = args;
    if (!bindingContext.isNew()) {
        const items = ((storage as FormStorage).data.origEntity as IMinorAssetEntity).Items;
        const origItem = items.find(i => i.Id === item.Id);
        return origItem?.Quantity > 0 && !isItemRemoved(args);
    }
    return true;
}

function isItemRemovable({ bindingContext }: IGetValueArgs): boolean {
    // only new items are removable, saved items have to be only disposed through menu
    return bindingContext.isNew();
}

function isItemSelectable({ bindingContext }: IGetValueArgs): boolean {
    // selectable items for disposal
    return !bindingContext.isNew();
}

export const getDefinitions: IGetDefinition = (): IDefinition => {

    const table: ISplitPageTableDef = {
        filterBarDef: [{
            ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
            isValueHelp: true,
            defaultFilters: [
                "Description", "NumberOurs", "DateOfLastTransaction"
            ],
            filterDefinition: {
                Description: {},
                NumberOurs: {},
                DateOfLastTransaction: {},
                ...withDisplayName("Status"),
                Amount: {},
                Quantity: {},
                Note: {},
                ...getLabelsFilterDef("Labels")
            }
        }],
        id: "minorAsset",
        tabs: getMinorAssetTabs(),
        tabsSettings: {
            filterFieldName: "StatusCode"
        },

        massEditableDef: {
            Description: {},
            Note: {},
            Labels: getLabelsDef("MinorAsset")
        },
        columns: [
            "NumberOurs", "Description", "Amount", "Status", "DateOfLastTransaction"
        ],
        columnDefinition: {
            NumberOurs: {},
            Description: {},
            Quantity: {},
            Amount: {},
            DateOfLastTransaction: {},
            Note: {},
            Status: {
                fieldSettings: {
                    displayName: "Name"
                },
                formatter: minorAssetStatusFormatter
            },
            ...LabelsTableColumnDef,
            ...getCommonTableColumnsDefs()
        },
        title: i18next.t("MinorAsset:Title"),
        initialSortBy: [{ id: "DateCreated", sort: Sort.Desc }]
    };

    const summary: ISummaryItem[] = [
        getNumberOursSummaryDef("MinorAsset"),
        {
            label: i18next.t("MinorAsset:Form.Status"),
            id: "StatusCode",
            formatter: (val: TValue, data?: IEntity) => {
                return val ? getEnumDisplayValue(EntityTypeName.MinorAssetStatus, val as string) : DASH_CHARACTER;
            },
            colorFormatter: (val: TValue, data?: IEntity) => {
                return getStatusColor(val as MinorAssetStatusCode);
            }
        },
        {
            id: "Amount",
            label: i18next.t("MinorAsset:Summary.Amount"),
            textAlign: TextAlign.Right,
            formatter: (val: TValue, args: IFormatOptions) => {
                return val ? formatCurrencyVariableDecimals(val as number, args.entity["Currency"]["Code"]) : DASH_CHARACTER;
            },
            isVisible: isFullForm
        }
    ];

    const form: IFormDef = {
        id: "minorAssetForm",
        getItemBreadCrumbText: (storage: Model) =>
                getItemBreadCrumbsText(storage, i18next.t("MinorAsset:New"), storage.data.entity?.NumberOurs),
        summary,
        files: AttachmentsDef,
        isDeletable: true,
        formControl: MinorAssetFormView,
        translationFiles: getDefinitions.translationFiles,
        additionalProperties: [
            { id: "Company" },
            ...AttachmentsAdditionalProperties,
            ...NumberRangeAdditionalProperties,
            {
                id: MinorAssetEntity.Items,
                additionalProperties: [{
                    id: MinorAssetItemEntity.Items,
                    additionalProperties: [
                        { id: MinorAssetHistoryItemEntity.Id },
                        { id: MinorAssetHistoryItemEntity.Amount },
                        { id: MinorAssetHistoryItemEntity.Quantity },
                        { id: MinorAssetHistoryItemEntity.DateOfTransaction },
                    ]
                }]
            }
        ],
        groups: [{
            id: "Note",
            title: i18next.t("Common:General.Note"),
            rows: [[{ id: "Note" }]]
        }, {
            id: "Asset",
            title: i18next.t("MinorAsset:Form.Asset"),
            rows: [
                [{ id: "Description" }, { id: "Labels" }]
            ]
        }, {
            id: "Items",
            title: i18next.t("MinorAsset:Form.Items"),
            lineItems: {
                collection: "Items",
                order: "Order",
                canReorder: false,
                isItemCloneable: true,
                showLineNumbers: false,
                isItemVisible,
                isItemRemovable,
                isItemSelectable,
                isTheOnlyItemRemovable: true,
                columns: [{ id: "Description" }, { id: "Quantity" }, { id: "Amount" }, { id: "Document" }, { id: "Note" }],
                disableFieldsOnActiveAction: true,
                actions: [{
                    id: MinorAssetAction.Dispose,
                    isDisabled: (args) => args.storage.data.bindingContext.isNew(),
                    label: i18next.t("MinorAsset:Form.DisposeMinorAssetItem"),
                    iconName: "AssetDisposal",
                    confirmLabel: i18next.t("MinorAsset:Form.Dispose")
                }]
            }
        }, {
            id: "Movements",
            title: i18next.t("MinorAsset:Form.Movements"),
            isVisible: (args) => {
                return !!(args.storage as FormStorage).data.origEntity.Items?.length;
            },
            table: {
                id: "MovementsTable",
                columns: [
                    {
                        id: "MinorAssetHistoryItem/ItemType",
                        fieldSettings: {
                            displayName: "Name"
                        }
                    },
                    {
                        id: "MinorAssetItem",
                        fieldSettings: {
                            displayName: "Description"
                        }
                    },
                    {
                        id: "MinorAssetHistoryItem/Quantity",
                        textAlign: TextAlign.Right
                    },
                    {
                        id: "MinorAssetHistoryItem/Amount",
                        textAlign: TextAlign.Right,
                        formatter: (val: TValue, args: IFormatOptions) => {
                            const currency = (args.storage.data.entity as IMinorAssetEntity).CurrencyCode ?? getCompanyCurrency(args.context);
                            return formatCurrency((val as number) ?? 0, currency);
                        }
                    },
                    { id: createPath(MinorAssetHistoryEntryEntity.MinorAssetItem, MinorAssetItemEntity.DateAcquired) }
                ],
                initialSortBy: [{ id: "MinorAssetHistoryItem/DateCreated", sort: Sort.Desc }],
                entitySet: EntitySetName.MinorAssetHistoryEntries,
                parentKey: "MinorAsset/Id",
                isSimplified: true
            }
        }],
        fieldDefinition: {
            ...getNumberRangeFieldDefs("MinorAsset"),
            Note: {
                isConfirmable: true,
                type: FieldType.EditableText,
                labelStatus: LabelStatus.Removed
            },
            Description: {
                width: BasicInputSizes.XL
            },
            Labels: getLabelsDef("MinorAsset"),
            "Items/Description": {
                width: BasicInputSizes.L
            },
            "Items/Quantity": {
                width: FastEntryInputSizes.XS,
                fieldSettings: {
                    min: 0,
                    showSteppers: true
                },
                validator: {
                    type: ValidatorType.Custom,
                    settings: {
                        customSchema: getMinValueSchema.bind(null, true, 0)
                    }
                }
            },
            "Items/Amount": {
                width: FastEntryInputSizes.S,
                fieldSettings: {
                    min: 1,
                    unit: (args: IGetValueArgs): string => {
                        const currencyCode = args.data?.Currency?.Code;
                        return CurrencyType.getCurrencyUnit(currencyCode ?? "CZK");
                    }
                },
                validator: {
                    type: ValidatorType.Custom,
                    settings: {
                        customSchema: getMinValueSchema.bind(null, false, 0)
                    }
                }
            },
            "Items/Note": {
                width: FastEntryInputSizes.XS,
                label: i18next.t("MinorAsset:Form.Note")
            },
            "Items/Document": {
                type: FieldType.Custom,
                fieldSettings: {
                    entitySet: EntitySetName.Documents,
                    displayName: "NumberOurs",
                    transformFetchedItems: transformPairingSelectItems,
                    additionalProperties: [
                        { id: "DocumentTypeCode" }
                    ]
                },
                filter: {
                    select: getMinorAssetPairingFilter
                },
                render: ({ storage, props, events }) => {
                    const { info, name, parentProps } = props;
                    const auditTrailData = props?.auditTrailData ?? props.storageData?.additionalFieldData?.auditTrailData;
                    const headerData: IReadOnlyListItem[] = [{
                        label: storage.t("MinorAsset:PairingTableView.Name"),
                        value: storage.data.entity.Description
                    }];

                    if (!props.info.fieldSettings.initialItems) {
                        const value = storage.getValue(props.parentProps.bindingContext);
                        if (value?.Id) {
                            const preparedItem: ISelectItem = {
                                id: value.Id,
                                label: value.NumberOurs,
                                additionalData: { ...value }
                            };
                            props.info.fieldSettings.initialItems = transformPairingSelectItems([preparedItem], { storage });
                        }
                    }

                    const updatePairedDocument = (entity: IDocumentEntity) => {
                        storage.clearAndSetValue(props.parentProps.bindingContext, entity);
                        storage.clearAndSetValue(props.parentProps.bindingContext.getParent().navigate("DocumentItem"), null);
                        storage.refreshFields();
                    };

                    const handleSelectChange = (args: ISelectionChangeArgs) => {
                        events.onSelectChange(args);
                        if (args.triggerAdditionalTasks) {
                            updatePairedDocument(args.additionalData);
                        }
                    };

                    const handlePairing = (selected: BindingContext[], entities: IEntity[]) => {
                        const entity = entities[0];

                        updatePairedDocument(entity);
                    };
                    const labelStatus = getInfoValue(info, "labelStatus", { storage });
                    return (
                        <Field label={info?.label}
                               {...(parentProps.fieldProps ?? {})}
                               isDisabled={props.isDisabled || parentProps.fieldProps?.isDisabled}
                               labelStatus={parentProps.fieldProps?.labelStatus ?? labelStatus}
                               auditTrailData={auditTrailData}
                               name={name}>
                            <SmartPairingSelect storage={storage}
                                                bindingContext={props.parentProps.bindingContext}
                                                fieldInfo={props.info}
                                                isDisabled={props.isDisabled}
                                                value={props.value}
                                                onChange={handleSelectChange}
                                                onPair={handlePairing}

                                                dialogTableViewProps={{
                                                    title: storage.t("MinorAsset:PairingTableView.Title"),
                                                    secondaryTitle: storage.t("MinorAsset:PairingTableView.PairedDocument"),
                                                    headerData: headerData,
                                                    confirmText: storage.t("MinorAsset:PairingTableView.Pair")
                                                }}
                                                getDefinition={getPairDefinitions}
                                                {...props.parentProps.fieldProps}
                            />
                        </Field>
                    );
                },
                additionalProperties: [{ id: "/Items/DocumentItem" }, { id: "/Items/Document/DocumentTypeCode" }]
            },
            "Items/DateAcquired": {
                defaultValue: () => getUtcDate()
            },
            "Items/DateGuarantee": {},
            "Items/DateUsefulLife": {}
        }
    };

    return {
        entitySet: EntitySetName.MinorAssets,
        table,
        form
    };
};
getDefinitions.translationFiles = ["MinorAsset"];
getEditableWindowDefinition.translationFiles = getDefinitions.translationFiles;
setDefByEntityType(EntityTypeName.MinorAsset, getDefinitions);