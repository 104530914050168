import React from "react";
import Page, { IProps } from "../Page";
import { withRouter } from "react-router-dom";
import { withOData } from "@odata/withOData";
import { withTranslation } from "react-i18next";
import { getDefinitions } from "./CustomerPortalPaymentDef";
import { CustomerPortalPaymentTableView } from "./CustomerPortalPaymentTableView";

class CustomerPortalPayment extends Page<IProps> {
    static defaultProps = {
        usePrompt: true,
        getDef: getDefinitions,
        tableView: CustomerPortalPaymentTableView
    };
}

export default withRouter(withOData(withTranslation(["Common", "Banks", "NumberRange", "Document", "CustomerPortalPayment"])(CustomerPortalPayment)));