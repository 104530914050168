import React, { useCallback, useMemo } from "react";
import { useTranslation, withTranslation, WithTranslation } from "react-i18next";
import Field from "../../components/inputs/field/Field";
import RadioButtonGroup from "../../components/inputs/radioButtonGroup/RadioButtonGroup";
import Input, { IInputOnChangeEvent } from "../../components/inputs/input";
import {
    BasicInputSizes,
    FastEntryInputSizes,
    FieldType,
    LabelStatus,
    SizeUnit,
    ValidationErrorType
} from "../../enums";
import Switch, { SwitchType } from "../../components/inputs/switch/Switch";
import Checkbox, { ICheckboxChange } from "../../components/inputs/checkbox/Checkbox";
import CollapsibleSection from "../../components/collapsibleSection/CollapsibleSection";
import { Select } from "@components/inputs/select";
import GroupedField from "../../components/groupedFields/GroupedField";
import {
    convertFromPxToUnit,
    convertFromUnitToPx,
    customPagesRangeRegex,
    fitRectangle,
    mmToPx,
    PaperSize,
    PaperSizes,
    parsePagesRange,
    PrintMargin,
    PrintOrientation,
    PrintPageSelection,
    PrintType,
    ptToPx,
    pxToMm
} from "./Print.utils";
import TestIds from "../../testIds";
import { ISelectionChangeArgs } from "@components/inputs/select/BasicSelect";
import Dialog from "../../components/dialog/Dialog";
import { Button, IconButton } from "../../components/button";
import {
    CanvasWrapper,
    Delimiter,
    PagesControls,
    PagesCount,
    PreviewCanvas,
    SeparatedInputs,
    StyledPrintDialogVariantSelect,
    VariantLabel
} from "./PrintDialog.styles";
import { CaretIcon, DoubleCaretIcon } from "@components/icon";
import BusyIndicator from "../../components/busyIndicator/BusyIndicator";
import PdfPrinting, { ITextListGroup, PdfPrintOptions } from "./PdfPrinting";
import CustomResizeObserver from "../../components/customResizeObserver/CustomResizeObserver";
import animationFrameThrottle from "../animationFrameThrottle";
import { clamp, isDefined, isObjectEmpty, isOverflowing } from "../general";
import { SubmittedNumericInput } from "@components/inputs/field/SubmittedInput";
import Slider from "../../components/slider/Slider";
import { SliderLabel } from "@components/slider/SliderBase";
import IconSelect from "../../components/inputs/select/IconSelect";
import { TRecordType } from "../../global.types";
import * as yup from "yup";
import { removeWhiteSpace } from "../string";
import { IValidationError, ValidationMessage } from "../../model/Validator.types";
import DialogTwoColumnContentView from "../../components/dialog/DialogTwoColumnContentView";
import FieldsWrapper from "../../components/inputs/field/FieldsWrapper";
import memoize from "../memoize";

export interface IProps extends WithTranslation {
    element: HTMLElement;
    onClose?: () => void;
    onConfirm?: () => void;

    fileName?: string;
    textListAppendix?: ITextListGroup[];

    /** Settings for all the possible variants, excluding the Default one, which uses values from DEFAULT_VARIANT */
    variants?: TRecordType<IPrintDialogVariant>;
    /** Ids of the variants currently shown in selection. If not used, only DEFAULT_VARIANT is shown. */
    visibleVariants?: string[];
    /** Id of the current variant in selection dialog */
    variant?: string;
    /** Called on variant selection change */
    onVariantChange?: (variant: string) => void;
    /** Called when save variant button is clicked on */
    onVariantSave?: (currentVariant: IPrintDialogSettings) => void;
    /** Called whenever some field has changed */
    onSettingsChanged?: (currentSettings: IPrintDialogSettings) => void;
}

export interface IPrintDialogVariant {
    id: string;
    label: string;
    settings: IPrintDialogSettings;
}

export interface IPrintDialogSettings {
    printType: PrintType;
    orientation: PrintOrientation;
    paperSize: PaperSize;
    paperWidth: number;
    paperHeight: number;
    sizeUnit: SizeUnit;
    scale: number;
    pageSelection: PrintPageSelection;
    customPageSelection: string;
    printFilterSettings: boolean;
    printPageNumbers: boolean;
    printHeader: boolean;
    header: string;
    printFooter: boolean;
    footer: string;
    printMargin: PrintMargin;
    marginTop: number;
    marginBottom: number;
    marginLeft: number;
    marginRight: number;
    printCropMarks: boolean;
    cropMarksIndentation: number;
    cropMarksThickness: number;
}

export interface IPrintDialogSettingsErrors {
    paperWidth?: IValidationError;
    paperHeight?: IValidationError;
    customPageSelection?: IValidationError;
    marginTop?: IValidationError;
    marginBottom?: IValidationError;
    marginLeft?: IValidationError;
    marginRight?: IValidationError;
    cropMarksIndentation?: IValidationError;
    cropMarksThickness?: IValidationError;
}

interface IState extends IPrintDialogSettings {
    busy: boolean;
    currentPreviewPage: number;
    errors: IPrintDialogSettingsErrors;
    // errors coming from PdfPrinting instead from validation
    printingErrors: IPrintDialogSettingsErrors;
}

export const PRINT_DIALOG_DEFAULT_VARIANT_ID = "default";
export const PRINT_DIALOG_DEFAULT_SETTINGS: IPrintDialogSettings = {
    // number values are always stored in current unit and converted to px on demand
    printType: PrintType.MultiplePages,
    orientation: PrintOrientation.Portrait,
    paperSize: PaperSize.A4,
    paperWidth: PaperSizes[PaperSize.A4].width,
    paperHeight: PaperSizes[PaperSize.A4].height,
    sizeUnit: SizeUnit.Millimeters,
    scale: 100,
    pageSelection: PrintPageSelection.All,
    customPageSelection: "",
    printFilterSettings: true,
    printPageNumbers: false,
    printHeader: false,
    header: "",
    printFooter: false,
    footer: "",
    printMargin: PrintMargin.None,
    marginTop: null, marginBottom: null,
    marginLeft: null, marginRight: null,
    printCropMarks: false,
    cropMarksIndentation: 3,
    cropMarksThickness: 0.25
};


class PrintDialog extends React.PureComponent<IProps, IState> {
    static defaultProps = {
        variant: PRINT_DIALOG_DEFAULT_VARIANT_ID,
        fileName: "export"
    };

    state: IState;

    canvasRef = React.createRef<HTMLCanvasElement>();
    wrapperRef = React.createRef<HTMLDivElement>();
    pdfPrinting: PdfPrinting;
    yupSchema: yup.ObjectSchema;

    constructor(props: IProps) {
        super(props);

        const settings: IPrintDialogSettings = props.variant && props.variant !== PRINT_DIALOG_DEFAULT_VARIANT_ID ? props.variants[props.variant]?.settings : PRINT_DIALOG_DEFAULT_SETTINGS;

        // we removed internal cloning from yup schema => use callback instead of object to always create new schema
        const getNumberSchema = () => yup.number().nullable(true).min(0, ValidationMessage.SmallNumber).typeError(ValidationMessage.NotANumber);
        const getRequiredNumberSchema = () => getNumberSchema().required(ValidationMessage.Required);
        // margin can be max half of the current page width/height
        const marginSchema = getNumberSchema().test("margin", ValidationMessage.BigNumber, function(this: yup.TestContext, value) {
            let size = ["Right", "Left"].includes(this.path.replace("margin", "")) ? this.parent.paperWidth : this.parent.paperHeight;
            size = pxToMm(convertFromUnitToPx(size, this.parent.sizeUnit));
            // try to remove trailing zeroes caused by javascript floating point imprecision
            const max = parseFloat((size / 2).toFixed(3));

            if (isNaN(size) || value <= max) {
                return true;
            }

            return this.createError({
                params: {
                    max
                }
            });
        });
        const cropMarksSchema = yup.mixed()
            .when("printCropMarks", {
                is: true,
                then: getRequiredNumberSchema().max(100, ValidationMessage.BigNumber)
            });


        this.yupSchema = yup.object().shape({
            paperWidth: getRequiredNumberSchema(),
            paperHeight: getRequiredNumberSchema(),
            customPageSelection: yup.mixed()
                .when(["pageSelection", "printType"], {
                    is: (pageSelection, printType) => pageSelection !== PrintPageSelection.All && printType !== PrintType.SinglePage,
                    then: yup.string().nullable(true).test("customPageSelection", ValidationMessage.InvalidValue, (value) => {
                        if (!value) {
                            return true;
                        }

                        return customPagesRangeRegex.test(removeWhiteSpace(value));
                    })
                }),
            marginTop: marginSchema,
            marginBottom: marginSchema,
            marginLeft: marginSchema,
            marginRight: marginSchema,
            cropMarksIndentation: cropMarksSchema,
            cropMarksThickness: cropMarksSchema
        });

        this.state = {
            ...settings,
            busy: true,
            currentPreviewPage: 1,
            errors: {},
            printingErrors: {}
        };
    }

    componentDidMount() {
        if (this.props.element && this.canvasRef.current) {
            this.init();
        }
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (!prevProps.element && this.props.element && this.canvasRef.current) {
            this.init();
        }
    }

    init = async () => {
        // give the element content time to rerender (SimpleBar inside table otherwise has zero height)
        setTimeout(async () => {
            this.pdfPrinting = new PdfPrinting(this.getPrintOptions());

            await this.pdfPrinting.init(this.props.element);
            this.updatePreviewSize(this.state.paperWidth, this.state.paperHeight);
            this.updatePreview(this.state.currentPreviewPage);

            this.setState({
                busy: false
            });
        });
    };

    getPrintOptions = (): PdfPrintOptions => {
        return {
            width: this.convertFromUnitToPx(this.state.paperWidth),
            height: this.convertFromUnitToPx(this.state.paperHeight),
            orientation: this.state.orientation,
            printType: this.state.printType,

            scale: this.state.scale / 100,

            header: this.state.printHeader ? this.state.header : "",
            footer: this.state.printFooter ? this.state.footer : "",
            printPageNumbers: this.state.printPageNumbers,

            printCropMarks: this.state.printCropMarks,
            cropMarksMargin: mmToPx(this.state.cropMarksIndentation),
            cropMarksThickness: ptToPx(this.state.cropMarksThickness),

            marginTop: mmToPx(this.state.marginTop),
            marginBottom: mmToPx(this.state.marginBottom),
            marginLeft: mmToPx(this.state.marginLeft),
            marginRight: mmToPx(this.state.marginRight),

            textListAppendix: this.state.printFilterSettings ? this.props.textListAppendix : null
        };
    };

    getCurrentSettings = (): IPrintDialogSettings => {
        return Object.entries(this.state).reduce((variant, [name, value]) => {
            if (!["currentPreviewPage", "busy"].includes(name)) {
                (variant as any)[name as keyof IPrintDialogSettings] = value;
            }

            return variant;
        }, {} as IPrintDialogSettings);
    };

    setSettings = (variant: IPrintDialogSettings) => {
        this.setState({
            ...variant
        }, () => {
            // wait for the settings to be stored in state, so that we can collect everything with "getPrintOptions"
            this.updateOptions(this.getPrintOptions());
            this.updatePreviewSize(this.state.paperWidth, this.state.paperHeight);
            // we need to cause one more rerender after "updateOptions" was called
            // otherwise "getMaxPageCount" wouldn't return correct value
            this.forceUpdate();
        });
    };

    updateSettings = <T extends keyof IPrintDialogSettings>(settings: Pick<IPrintDialogSettings, T>) => {
        this.setState(settings as IState);

        const newSettings = {
            ...this.getCurrentSettings(),
            ...settings
        };

        const noError = this.validateSettings(newSettings);

        if (noError && this.getMaxPageCount() > 0) {
            this.props.onSettingsChanged?.(newSettings);
        }
    };

    validateSettings = (settings: IPrintDialogSettings) => {
        let noError = true;
        const errors: IPrintDialogSettingsErrors = {};

        try {
            this.yupSchema.validateSync(settings, { abortEarly: false });
        } catch (error) {
            noError = false;
            for (const err of error.inner) {
                errors[err.path as keyof IPrintDialogSettingsErrors] = {
                    params: err.params,
                    errorType: err.type,
                    message: err.message
                };
            }
        }

        this.setState({
            errors
        });

        return noError;
    };

    hasErrors = () => {
        return !isObjectEmpty(this.state.errors);
    };

    handleVariantChange = (variantId: string) => {
        let settings = null;

        if (variantId === PRINT_DIALOG_DEFAULT_VARIANT_ID) {
            settings = PRINT_DIALOG_DEFAULT_SETTINGS;
        } else if (this.props.variants?.[variantId]) {
            settings = this.props.variants[variantId]?.settings;
        }

        this.setSettings(settings);
        this.props.onVariantChange?.(variantId);
    };

    handleVariantSave = () => {
        this.animateSaving();

        this.props.onVariantSave?.(this.getCurrentSettings());
    };

    // prone to change
    animateSaving = () => {
        const ghost = this.wrapperRef.current.cloneNode(true) as HTMLDivElement;
        const wrapperDiv = document.createElement("div");
        const canvas = ghost.querySelectorAll("canvas")[0];

        canvas.parentElement?.removeChild?.(canvas);

        wrapperDiv.style.zIndex = "100";
        wrapperDiv.style.boxShadow = "0 0 12.7px 0.3px rgb(0 0 0 / 15%)";
        wrapperDiv.style.position = "absolute";
        wrapperDiv.style.width = "calc(100% - 38px)";
        wrapperDiv.style.transition = "0.7s transform";
        wrapperDiv.style.transformOrigin = "top left";
        wrapperDiv.style.transform = "scale(1)";
        wrapperDiv.append(ghost);

        wrapperDiv.addEventListener("transitionend", () => {
            this.wrapperRef.current.parentElement?.removeChild?.(wrapperDiv);

        });

        this.wrapperRef.current.parentElement.prepend(wrapperDiv);

        setTimeout(() => {
            wrapperDiv.style.transform = "scale(0)";
        });
    };

    // js is the only way i found to have responsive canvas with correct proportions
    // and have border directly around it
    updatePreviewSize = (paperWidth: number, paperHeight: number) => {
        // dialog could get closed during pdfPrinting.init
        if (!this.canvasRef.current) {
            return;
        }

        const paperWidthPx = this.convertFromUnitToPx(paperWidth);
        const paperHeightPx = this.convertFromUnitToPx(paperHeight);
        const canvasParent = this.canvasRef.current.parentElement;
        const [canvasWidth, canvasHeight] = fitRectangle(paperWidthPx, paperHeightPx, canvasParent.offsetWidth, canvasParent.offsetHeight);

        this.canvasRef.current.style.width = `${Math.round(canvasWidth)}px`;
        this.canvasRef.current.style.height = `${Math.round(canvasHeight)}px`;
    };

    handleResize = animationFrameThrottle((entries: readonly ResizeObserverEntry[]) => {
        if (!this.wrapperRef.current || this.wrapperRef.current.children.length !== 3) {
            return;
        }

        const [previewElement, lineElement, formElement] = Array.from(this.wrapperRef.current.children) as HTMLDivElement[];

        previewElement.style.display = "flex";
        lineElement.style.display = "block";
        formElement.style.flexGrow = "0";

        if (isOverflowing(this.wrapperRef.current)) {
            previewElement.style.display = "none";
            lineElement.style.display = "none";
            formElement.style.flexGrow = "1";
        }

        this.updatePreviewSize(this.state.paperWidth, this.state.paperHeight);
    });

    updatePreview = (page: number) => {
        this.pdfPrinting.getCanvasOfPage(page, this.canvasRef.current);
    };

    updateOptions = (options: Partial<PdfPrintOptions>) => {
        this.pdfPrinting.updateOptions(
            { ...options }
        );

        let previewPage = this.state.currentPreviewPage;
        const maxPage = this.getMaxPageCount();

        if (maxPage <= 0) {
            this.setState({
                printingErrors: {
                    paperHeight: {
                        errorType: ValidationErrorType.TypeError,
                        message: this.props.t("Components:PrintDialog.HeightTooSmall")
                    }
                }
            });
        } else {
            this.setState({ printingErrors: {} });
            if (previewPage > maxPage) {
                previewPage = maxPage;
                this.setState({
                    currentPreviewPage: previewPage
                });
            }

            this.updatePreview(previewPage);
        }
    };

    getMaxPageCount = () => {
        const maxPageCount = this.pdfPrinting?.getMaxPageCount();

        return maxPageCount;
    };

    handleExport = () => {
        if (this.hasErrors()) {
            return;
        }

        let pages;

        if (this.state.printType === PrintType.SinglePage) {
            pages = [1];
        } else {
            pages = this.state.pageSelection === PrintPageSelection.Custom && this.state.customPageSelection ? parsePagesRange(this.state.customPageSelection) : null;
        }

        this.pdfPrinting.savePdf(`${this.props.fileName}.pdf`, pages);
        this.props.onConfirm?.();
    };

    handlePrintTypeChange = (id: string) => {
        const printType = id as PrintType;

        this.updateOptions({ printType });

        this.updateSettings({
            printType
        });
    };

    handleOrientationChange = (id: string) => {
        const orientation = id as PrintOrientation;
        let paperWidth = this.state.paperWidth;
        let paperHeight = this.state.paperHeight;

        if (this.state.orientation !== orientation) {
            // noinspection JSSuspiciousNameCombination
            paperWidth = this.state.paperHeight;
            // noinspection JSSuspiciousNameCombination
            paperHeight = this.state.paperWidth;
        }

        this.updateOptions({
            orientation,
            width: this.convertFromUnitToPx(paperWidth),
            height: this.convertFromUnitToPx(paperHeight)
        });
        this.updatePreviewSize(paperWidth, paperHeight);

        this.updateSettings({
            orientation,
            paperWidth,
            paperHeight
        });
    };

    handlePaperSizeChange = (event: ISelectionChangeArgs) => {
        const paperSize = event.value as PaperSize;
        // can return nulls for Custom size, values are in mm
        const { width, height } = PaperSizes[paperSize];

        let newWidth = (width && this.convertFromPxToUnit(mmToPx(width))) ?? this.state.paperWidth;
        let newHeight = (height && this.convertFromPxToUnit(mmToPx(height))) ?? this.state.paperHeight;

        if (this.state.orientation === PrintOrientation.Landscape) {
            const tmp = newWidth;

            // noinspection JSSuspiciousNameCombination
            newWidth = newHeight;
            newHeight = tmp;
        }

        this.updateOptions({
            width: this.convertFromUnitToPx(newWidth),
            height: this.convertFromUnitToPx(newHeight)
        });
        this.updatePreviewSize(newWidth, newHeight);

        this.updateSettings({
            paperSize,
            paperWidth: newWidth,
            paperHeight: newHeight
        });
    };

    getMatchingPaperSize = (width: number, height: number, orientation: PrintOrientation): PaperSize => {
        const paperSizeType = Object.entries(PaperSizes)
            .find(([paperType, paperTypeSize]) => {
                const paperWidth = orientation === PrintOrientation.Portrait ? paperTypeSize.width : paperTypeSize.height;
                const paperHeight = orientation === PrintOrientation.Portrait ? paperTypeSize.height : paperTypeSize.width;

                return mmToPx(paperWidth) === this.convertFromUnitToPx(width) && mmToPx(paperHeight) === this.convertFromUnitToPx(height);
            })?.[0] as PaperSize;

        return paperSizeType ?? PaperSize.Custom;
    };

    getPaperOrientation = (width: number, height: number) => {
        return height > width ? PrintOrientation.Portrait : PrintOrientation.Landscape;
    };

    handlePaperWidthChange = (event: IInputOnChangeEvent) => {
        const width = event.value as any;
        const orientation = this.getPaperOrientation(width, this.state.paperHeight);
        const paperSizeType = this.getMatchingPaperSize(width, this.state.paperHeight, orientation);

        this.updateOptions({
            width: this.convertFromUnitToPx(width)
        });

        this.updateSettings({
            paperWidth: width,
            paperSize: paperSizeType,
            orientation
        });

        this.updatePreviewSize(width, this.state.paperHeight);
    };

    handlePaperHeightChange = (event: IInputOnChangeEvent) => {
        const height = event.value as any;
        const orientation = this.getPaperOrientation(this.state.paperWidth, height);
        const paperSizeType = this.getMatchingPaperSize(this.state.paperWidth, height, orientation);

        this.updateOptions({
            height: this.convertFromUnitToPx(height)
        });

        this.updateSettings({
            paperHeight: height,
            paperSize: paperSizeType,
            orientation

        });

        this.updatePreviewSize(this.state.paperWidth, height);
    };

    /** Formats value from pixels into current unit */
    convertFromPxToUnit = (value: number, unit: SizeUnit = this.state.sizeUnit) => {
        return convertFromPxToUnit(value, unit);
    };

    /** Parses value from current unit into pixels */
    convertFromUnitToPx = (value: number, unit: SizeUnit = this.state.sizeUnit) => {
        return convertFromUnitToPx(value, unit);
    };

    handleSizeUnitChange = (event: ISelectionChangeArgs) => {
        const sizeUnit = event.value as SizeUnit;

        if (sizeUnit === this.state.sizeUnit) {
            return;
        }

        // this conversion can cause floating point rounding errors, can we do something about it?
        const paperWidth = this.convertFromPxToUnit(this.convertFromUnitToPx(this.state.paperWidth), sizeUnit);
        const paperHeight = this.convertFromPxToUnit(this.convertFromUnitToPx(this.state.paperHeight), sizeUnit);

        this.updateSettings({
            sizeUnit,
            paperWidth, paperHeight
        });
    };

    setScale = animationFrameThrottle((scale: number) => {
        this.updateOptions({
            scale: scale / 100
        });
        this.updateSettings({
            scale
        });
    });

    handleScaleChange = (scale: number) => {
        this.setScale(scale);
    };

    handlePageSelectionChange = (id: string) => {
        const pageSelection = id as PrintPageSelection;

        this.updateSettings({
            pageSelection
        });
    };

    handleCustomPageSelectionChange = (event: IInputOnChangeEvent) => {
        const customPageSelection = event.value as string;

        this.updateSettings({
            customPageSelection
        });
    };

    handlePrintFilterSettingsChange = (checked: boolean) => {
        this.updateSettings({
            printFilterSettings: checked
        });

        this.updateOptions({
            textListAppendix: checked ? this.props.textListAppendix : null
        });
    };

    handlePrintPageNumbersChange = (checked: boolean) => {
        this.updateOptions({
            printPageNumbers: checked
        });

        this.updateSettings({
            printPageNumbers: checked
        });
    };

    handlePrintHeaderChange = (event: ICheckboxChange) => {
        const printHeader = event.value;
        const header = printHeader ? this.state.header : "";

        this.updateOptions({
            header
        });

        this.updateSettings({
            printHeader
        });
    };

    handlePrintFooterChange = (event: ICheckboxChange) => {
        const printFooter = event.value;
        const footer = printFooter ? this.state.footer : "";

        this.updateOptions({
            footer
        });

        this.updateSettings({
            printFooter
        });
    };

    handleHeaderChange = (event: IInputOnChangeEvent) => {
        const header = event.value as string;

        this.updateOptions({
            header
        });


        this.updateSettings({
            header
        });
    };

    handleFooterChange = (event: IInputOnChangeEvent) => {
        const footer = event.value as string;

        this.updateOptions({
            footer
        });

        this.updateSettings({
            footer
        });
    };

    handleMarginTypeChange = (event: ISelectionChangeArgs) => {
        const printMargin = event.value as PrintMargin;
        const defaultMargin = 5;

        let marginTop, marginBottom, marginLeft, marginRight;

        if (printMargin === PrintMargin.None) {
            marginTop = marginBottom = marginLeft = marginRight = null;
        } else {
            marginTop = marginBottom = marginLeft = marginRight = defaultMargin;
        }

        this.updateOptions({
            marginTop: mmToPx(marginTop),
            marginBottom: mmToPx(marginBottom),
            marginLeft: mmToPx(marginLeft),
            marginRight: mmToPx(marginRight)
        });

        this.updateSettings({
            printMargin,
            marginTop, marginBottom,
            marginLeft, marginRight
        });
    };

    // avoid using anonymous handler functions (thus having different reference on each rerender)
    // by memoizing handle function based on marginPos
    handleMarginChange = memoize((marginName: "marginTop" | "marginBottom" | "marginLeft" | "marginRight") =>
            (event: IInputOnChangeEvent) => {
                const margin = event.value as number;

                this.updateOptions({
                    [marginName]: mmToPx(margin)
                });

                const otherMargins = ["marginTop", "marginBottom", "marginLeft", "marginRight"].filter(margin => margin !== marginName);
                const printMargin = otherMargins.some(otherMargin => isDefined(this.state[otherMargin as keyof IState])) || isDefined(margin) ? PrintMargin.Custom : PrintMargin.None;

                // @ts-ignore
                this.updateSettings({
                    [marginName]: margin,
                    printMargin
                });
            }
    );

    handlePrintCropMarksChange = (checked: boolean) => {
        this.updateOptions({
            printCropMarks: checked
        });

        this.updateSettings({
            printCropMarks: checked
        });
    };

    handleIndentationChange = (event: IInputOnChangeEvent) => {
        const cropMarksIndentation = event.value as number;

        this.updateOptions({
            cropMarksMargin: mmToPx(cropMarksIndentation)
        });

        this.updateSettings({
            cropMarksIndentation
        });
    };

    handleTensileStrengthChange = (event: IInputOnChangeEvent) => {
        const cropMarksThickness = event.value as number;

        this.updateOptions({
            cropMarksThickness: ptToPx(cropMarksThickness)
        });

        this.updateSettings({
            cropMarksThickness
        });
    };

    setCurrentPage = (page: number) => {
        const currentPreviewPage = clamp(page, 1, this.getMaxPageCount());

        this.updatePreview(currentPreviewPage);
        this.setState({
            currentPreviewPage
        });
    };

    handleFirstPage = () => {
        this.setCurrentPage(1);
    };

    handleLastPage = () => {
        this.setCurrentPage(this.getMaxPageCount());
    };

    handlePreviousPage = () => {
        this.setCurrentPage(this.state.currentPreviewPage - 1);
    };

    handleNextPage = () => {
        this.setCurrentPage(this.state.currentPreviewPage + 1);
    };

    isCurrentPageFirst = () => {
        return this.state.currentPreviewPage === 1;
    };

    isCurrentPageLast = () => {
        return this.state.currentPreviewPage === this.getMaxPageCount();
    };

    renderPreview = () => {
        return (
            <>
                <PrintDialogVariantSelect
                    id={this.props.variant}
                    onChange={this.handleVariantChange}
                    variants={this.props.variants}
                    visibleVariants={this.props.visibleVariants}
                />
                <CanvasWrapper>
                    <PreviewCanvas ref={this.canvasRef} isVisible={!this.state.busy}
                                   data-testid={TestIds.PreviewCanvas}/>
                </CanvasWrapper>
                <PagesControls isVisible={!this.state.busy && this.state.printType === PrintType.MultiplePages}
                               data-testid={TestIds.PageControls}>
                    <IconButton title={this.props.t("Components:PrintDialog.FirstPage")}
                                testid={TestIds.PageControlFirstPage}
                                onClick={this.handleFirstPage}
                                isDisabled={this.isCurrentPageFirst()}
                                isDecorative>
                        <DoubleCaretIcon style={{ transform: "rotate(180deg)" }}/>
                    </IconButton>

                    <IconButton title={this.props.t("Components:PrintDialog.PreviousPage")}
                                testid={TestIds.PageControlPreviousPage}
                                onClick={this.handlePreviousPage}
                                isDisabled={this.isCurrentPageFirst()}
                                isDecorative>
                        <CaretIcon style={{ transform: "rotate(90deg)" }}/>
                    </IconButton>

                    <PagesCount data-testid={TestIds.PageCount}>
                        {this.state.currentPreviewPage} / {this.getMaxPageCount()}
                    </PagesCount>

                    <IconButton title={this.props.t("Components:PrintDialog.NextPage")}
                                testid={TestIds.PageControlNextPage}
                                onClick={this.handleNextPage}
                                isDisabled={this.isCurrentPageLast()}
                                isDecorative>
                        <CaretIcon style={{ transform: "rotate(-90deg)" }}/>
                    </IconButton>

                    <IconButton title={this.props.t("Components:PrintDialog.LastPage")}
                                testid={TestIds.PageControlLastPage}
                                onClick={this.handleLastPage}
                                isDisabled={this.isCurrentPageLast()}
                                isDecorative>
                        <DoubleCaretIcon/>
                    </IconButton>
                </PagesControls>
            </>
        );
    };

    renderForm = () => {
        return (
            <FieldsWrapper isColumn>
                <CustomResizeObserver onResize={this.handleResize}/>
                <Field label={this.props.t("Components:PrintDialog.Pagination")} hasPadding name={"Pagination"}>
                    <RadioButtonGroup
                        checkedButton={this.state.printType}
                        onChange={this.handlePrintTypeChange}
                        definition={[
                            {
                                id: PrintType.MultiplePages,
                                label: this.props.t("Components:PrintDialog.MultiplePages")
                            },
                            { id: PrintType.SinglePage, label: this.props.t("Components:PrintDialog.SinglePage") }
                        ]}/>
                </Field>

                <Field label={this.props.t("Components:PrintDialog.Orientation")} hasPadding name={"Orientation"}>
                    <RadioButtonGroup
                        checkedButton={this.state.orientation}
                        onChange={this.handleOrientationChange}
                        definition={[
                            {
                                id: PrintOrientation.Portrait,
                                label: this.props.t("Components:PrintDialog.Portrait")
                            },
                            {
                                id: PrintOrientation.Landscape,
                                label: this.props.t("Components:PrintDialog.Landscape")
                            }
                        ]}/>
                </Field>
                <Field label={this.props.t("Components:PrintDialog.Paper")} name={"PaperFormat"}>
                    <Select
                        value={this.state.paperSize}
                        onChange={this.handlePaperSizeChange}
                        items={[
                            { id: PaperSize.A4, label: "A4" },
                            { id: PaperSize.A5, label: "A5" },
                            { id: PaperSize.A3, label: "A3" },
                            { id: PaperSize.B4, label: "B4" },
                            { id: PaperSize.B5, label: "B5" },
                            { id: PaperSize.Letter, label: this.props.t("Components:PrintDialog.LetterSize") },
                            { id: PaperSize.Legal, label: this.props.t("Components:PrintDialog.LegalSize") },
                            { id: PaperSize.Tabloid, label: this.props.t("Components:PrintDialog.TabloidSize") },
                            { id: PaperSize.Custom, label: this.props.t("Components:PrintDialog.CustomSize") }
                        ]}
                        width={BasicInputSizes.S}/>
                </Field>
                <Field labelStatus={LabelStatus.Removed} name={"PaperSize"}>
                    <SeparatedInputs>
                        <SubmittedNumericInput
                            value={this.state.paperWidth}
                            error={this.state.errors.paperWidth}
                            onChange={this.handlePaperWidthChange}
                            name={"paperWidth"}
                            width={FastEntryInputSizes.XS} isSharpRight/>
                        <Delimiter>x</Delimiter>
                        <SubmittedNumericInput
                            value={this.state.paperHeight}
                            error={this.state.printingErrors.paperHeight ?? this.state.errors.paperHeight}
                            onChange={this.handlePaperHeightChange}
                            name={"paperHeight"}
                            width={FastEntryInputSizes.XS} isSharpLeft/>
                        <Select
                            value={this.state.sizeUnit}
                            onChange={this.handleSizeUnitChange}
                            items={[
                                { id: SizeUnit.Millimeters, label: this.props.t("Common:Units.Millimeters") },
                                { id: SizeUnit.Centimeters, label: this.props.t("Common:Units.Centimeters") },
                                { id: SizeUnit.Inches, label: this.props.t("Common:Units.Inches") }
                            ]}
                            width={BasicInputSizes.M}
                            style={{ marginLeft: "32px" }}/>
                    </SeparatedInputs>
                </Field>
                {this.state.printType === PrintType.MultiplePages &&
                    <Field label={this.props.t("Components:PrintDialog.ContentSize")} hasPadding name={"ContentSize"}
                           style={{ width: "100%" }}>
                        <Slider
                                value={this.state.scale}
                                onChange={this.handleScaleChange}
                                labelMode={SliderLabel.VisibleOnHover}
                                min={0}
                                max={200}
                                step={1}
                        />
                    </Field>
                }

                {this.state.printType === PrintType.MultiplePages && <FieldsWrapper>
                    <Field label={this.props.t("Components:PrintDialog.Pages")} type={FieldType.RadioButtonGroup}
                           hasPadding name={"Pages"} style={{ marginRight: "14px" }}>
                        <RadioButtonGroup
                                checkedButton={this.state.pageSelection}
                                onChange={this.handlePageSelectionChange}
                                style={{ paddingLeft: "12px" }}
                                definition={[
                                    {
                                        id: PrintPageSelection.All,
                                        label: this.props.t("Components:PrintDialog.AllPages")
                                    },
                                    {
                                        id: PrintPageSelection.Custom,
                                        label: this.props.t("Components:PrintDialog.CustomPages")
                                    }
                                ]}/>
                    </Field>
                    <Field name={"CustomPageSelection"}>
                        <Input
                                value={this.state.customPageSelection}
                                error={this.state.errors.customPageSelection}
                                onChange={this.handleCustomPageSelectionChange}
                                name={"customPageSelection"}
                                isDisabled={this.state.pageSelection === PrintPageSelection.All}
                                width={BasicInputSizes.M}
                                placeholder={this.props.t("Components:PrintDialog.PagesPlaceholder")}/>
                    </Field>
                </FieldsWrapper>}
                <Field labelStatus={LabelStatus.Hidden} hasPadding name={"PrintFilterSettings"}>
                    <Switch
                        checked={this.state.printFilterSettings}
                        onChange={this.handlePrintFilterSettingsChange}
                        label={this.props.t("Components:PrintDialog.PrintFilterSettings")} type={SwitchType.YesNo}/>
                </Field>
                {this.state.printType === PrintType.MultiplePages &&
                    <Field labelStatus={LabelStatus.Removed} hasPadding name={"PrintPageNumbers"}>
                        <Switch
                            checked={this.state.printPageNumbers}
                            onChange={this.handlePrintPageNumbersChange}
                            label={this.props.t("Components:PrintDialog.PrintPageNumbers")} type={SwitchType.YesNo}/>
                    </Field>
                }

                <Field label={this.props.t("Components:PrintDialog.HeaderAndFooter")} hasPadding name={"HeaderAndFooter"}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Checkbox
                                checked={this.state.printHeader}
                                onChange={this.handlePrintHeaderChange}
                                label={this.props.t("Components:PrintDialog.Header")}
                                // todo this margins looks like something that should be used somehow more generally for checkboxes and radio buttons
                                style={{
                                    marginRight: "24px"
                                }}/>
                            <Input
                                value={this.state.header}
                                onChange={this.handleHeaderChange}
                                name={"header"}
                                isDisabled={!this.state.printHeader}
                                width={BasicInputSizes.L}/>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", marginTop: "12px" }}>
                            <Checkbox
                                checked={this.state.printFooter}
                                onChange={this.handlePrintFooterChange}
                                label={this.props.t("Components:PrintDialog.Footer")}
                                style={{
                                    marginRight: "24px"
                                }}/>

                            <Input
                                value={this.state.footer}
                                onChange={this.handleFooterChange}
                                name={"footer"}
                                isDisabled={!this.state.printFooter}
                                width={BasicInputSizes.L}/>
                        </div>
                    </div>
                </Field>

                <CollapsibleSection title={this.props.t("Components:PrintDialog.MarginsSettings")}
                                    defaultIsOpen={false}
                                    usedAsField
                                    removeBodyPadding>
                    <FieldsWrapper>
                        <Field label={this.props.t("Components:PrintDialog.Settings")} width={BasicInputSizes.M}
                               name={"MarginSettings"}>
                            <Select
                                    value={this.state.printMargin}
                                    onChange={this.handleMarginTypeChange}
                                    width={BasicInputSizes.M}
                                    items={[
                                        {
                                            id: PrintMargin.None,
                                            label: this.props.t("Components:PrintDialog.MarginNone")
                                        },
                                        {
                                            id: PrintMargin.Custom,
                                            label: this.props.t("Components:PrintDialog.MarginCustom")
                                        }
                                    ]}/>
                        </Field>
                        <GroupedField>
                            <Field label={this.props.t("Components:PrintDialog.MarginTop")}>
                                <SubmittedNumericInput
                                        value={this.state.marginTop}
                                        error={this.state.errors.marginTop}
                                        onChange={this.handleMarginChange("marginTop")}
                                        placeholder={"mm"}
                                        name={"marginTop"}
                                        min={0}
                                        width={FastEntryInputSizes.XS}
                                        showSteppers/>
                            </Field>
                            <Field label={this.props.t("Components:PrintDialog.MarginBottom")}>
                                <SubmittedNumericInput
                                        value={this.state.marginBottom}
                                        error={this.state.errors.marginBottom}
                                        onChange={this.handleMarginChange("marginBottom")}
                                        placeholder={"mm"}
                                        name={"marginBottom"}
                                        min={0}
                                        width={FastEntryInputSizes.XS}
                                        showSteppers/>
                            </Field>
                            <Field label={this.props.t("Components:PrintDialog.MarginLeft")}>
                                <SubmittedNumericInput
                                        value={this.state.marginLeft}
                                        error={this.state.errors.marginLeft}
                                        onChange={this.handleMarginChange("marginLeft")}
                                        placeholder={"mm"}
                                        name={"marginLeft"}
                                        min={0}
                                        width={FastEntryInputSizes.XS}
                                        showSteppers/>
                            </Field>
                            <Field label={this.props.t("Components:PrintDialog.MarginRight")}>
                                <SubmittedNumericInput
                                        value={this.state.marginRight}
                                        error={this.state.errors.marginRight}
                                        onChange={this.handleMarginChange("marginRight")}
                                        placeholder={"mm"}
                                        name={"marginRight"}
                                        min={0}
                                        width={FastEntryInputSizes.XS}
                                        showSteppers/>
                            </Field>
                        </GroupedField>
                    </FieldsWrapper>
                </CollapsibleSection>

                <CollapsibleSection title={this.props.t("Components:PrintDialog.CropMarks")}
                                    defaultIsOpen={false}
                                    usedAsField
                                    removeBodyPadding>
                    <FieldsWrapper>
                        <Field labelStatus={LabelStatus.Removed} hasPadding name={"CropMarks"}>
                            <Switch
                                    checked={this.state.printCropMarks}
                                    onChange={this.handlePrintCropMarksChange}
                                    label={this.props.t("Components:PrintDialog.CropMarks")} type={SwitchType.YesNo}/>
                        </Field>

                        {this.state.printCropMarks &&
                                <div style={{ display: "flex" }}>
                                    <Field label={this.props.t("Components:PrintDialog.Indentation")}
                                           width={BasicInputSizes.S}>
                                        <SubmittedNumericInput
                                                value={this.state.cropMarksIndentation}
                                                error={this.state.errors.cropMarksIndentation}
                                                onChange={this.handleIndentationChange}
                                                unit={"mm"}
                                                name={"cropMarksIndentation"}
                                                min={0}
                                                width={BasicInputSizes.S} showSteppers/>
                                    </Field>

                                    <Field label={this.props.t("Components:PrintDialog.TensileStrength")}
                                           width={BasicInputSizes.S}>
                                        <SubmittedNumericInput
                                                value={this.state.cropMarksThickness}
                                                error={this.state.errors.cropMarksThickness}
                                                onChange={this.handleTensileStrengthChange}
                                                unit={"pt"}
                                                name={"cropMarksThickness"}
                                                min={0.25}
                                                step={0.25}
                                                width={BasicInputSizes.S} showSteppers/>
                                    </Field>
                                </div>
                        }
                    </FieldsWrapper>
                </CollapsibleSection>
            </FieldsWrapper>
        );
    };

    render() {
        return (
            <Dialog removePadding
                    width={"100%"}
                    height={"100%"}
                    minWidth={"428px"}
                    minHeight={"320px"}
                    title={this.props.t("Components:PrintDialog.PdfExport")}
                    onConfirm={this.handleExport}
                    onClose={this.props.onClose}
                    footer={<>
                        <Button isTransparent
                                onClick={this.props.onClose}>{this.props.t("Common:General.Cancel")}</Button>
                        <Button isTransparent
                                isDisabled={this.hasErrors()}
                                onClick={this.handleVariantSave}>{this.props.t("Components:PrintDialog.SaveSettings")}</Button>
                        <Button onClick={this.handleExport}
                                isDisabled={this.hasErrors()}>
                            {this.props.t("Components:PrintDialog.Export")}
                        </Button>
                    </>}>
                <DialogTwoColumnContentView
                    passRef={this.wrapperRef}
                    wrapperContent={<>{this.state.busy && <BusyIndicator/>}</>}
                    leftContent={this.renderPreview()}
                    rightContent={this.renderForm()}
                    rightWidth={"428px"}
                />
            </Dialog>
        );
    }
}

export default withTranslation(["Components", "Common"])(PrintDialog);

interface IPrintDialogVariantSelectProps {
    id: string;
    onChange: (id: string) => void;
    variants: TRecordType<IPrintDialogVariant>;
    visibleVariants: string[];
}

const PrintDialogVariantSelect: React.FunctionComponent<IPrintDialogVariantSelectProps> = React.memo((props: IPrintDialogVariantSelectProps) => {
    const { t, ready } = useTranslation(["Components"]);
    const items = useMemo(() => {
        return [
            {
                id: PRINT_DIALOG_DEFAULT_VARIANT_ID,
                label: t("Components:PrintDialog.VariantDefault")
            },
            ...(props.visibleVariants ?? []).map(variantId => {
                const variant = props.variants[variantId];

                return {
                    id: variant.id,
                    label: variant.label
                };
            })
        ];
    }, [props.variants, props.visibleVariants, ready]);
    const label = (props.variants ?? {})[props.id]?.label ?? items.find(item => item.id === props.id)?.label;
    const handleChange = useCallback((args: ISelectionChangeArgs) => {
        props.onChange(args.value as string);
    }, []);

    return (
        <StyledPrintDialogVariantSelect data-testid={TestIds.VariantSelector}>
            <VariantLabel data-testid={TestIds.VariantLabel}>
                {label}
            </VariantLabel>
            <IconSelect
                hotspotId={"PrintDialogSettings"}
                title={t("Components:PrintDialog.Settings")}
                icon={<CaretIcon/>}
                items={items}
                value={props.id}
                onChange={handleChange}
            />
        </StyledPrintDialogVariantSelect>
    );
});