import { getWorkDate, isMaxDay, isMinDay, stripAllDelimiters } from "@components/inputs/date/utils";
import { isDefined } from "@utils/general";
import { DevelLocalSettings } from "@utils/LocalSettings";
import { removeWhiteSpace } from "@utils/string";
import dayjs, { Dayjs } from "dayjs";
import i18next from "i18next";

export type TDateLike = Dayjs | Date | string;

/* Date formats used on BE */
export enum DisplayFormat {
    DateShort = "DateShort",
    DateMedium = "DateMedium",
    DateLong = "DateLong",
    DateFull = "DateFull",
    TimeShort = "TimeShort",
    TimeMedium = "TimeMedium",
    TimeLong = "TimeLong",
    TimeFull = "TimeFull",
    // todo: BE can combine any of the formats above - what each format means?
    //  E.g. we have 2 possible time formats: LT and LTS... What is expected in each case?
    DateShortTimeShort = "DateShort-TimeShort",
    DateMediumTimeMedium = "DateMedium-TimeMedium",
    DateLongTimeLong = "DateLong-TimeLong",
    DateFullTimeFull = "DateFull-TimeFull",
    DateMediumTimeFull = "DateMedium-TimeFull"
}

const displayFormatRe = /^(Date|Time)(Short|Medium|Long|Full)(-Time(Short|Medium|Long|Full))?$/;
// constants that should be same for FE and BE.
// we should use them to limit date pickers and can be used as special values.
// use string in constructor instead of number value, otherwise js sets year as 1970 instead of 1
export const DATE_MIN = new Date("0001-01-01");
export const DATE_MAX = new Date("9999-12-31");

/**
 * Function checks if given format is one of BE displayFormat, which has to be parsed (transformed to classic format)
 * before use for formatting through date functions.
 * @param format
 */
const CombinedFormatSeparator = ", ";

export function isDisplayFormat(format: string): boolean {
    return format.split(CombinedFormatSeparator).every(f => displayFormatRe.test(f));
}

const parseDisplayFormat = (displayFormat: DisplayFormat) => {
    switch (displayFormat) {
        case DisplayFormat.DateShort:
            return "l";
        case DisplayFormat.DateMedium:
            return "L";
        case DisplayFormat.DateLong:
        case DisplayFormat.DateFull:
            return "LL";
        case DisplayFormat.TimeShort:
            return "LT";
        case DisplayFormat.TimeMedium:
        case DisplayFormat.TimeLong:
        case DisplayFormat.TimeFull:
            return "LTS";
        case DisplayFormat.DateShortTimeShort:
            return "lll";
        case DisplayFormat.DateMediumTimeMedium:
            return "llll";
        case DisplayFormat.DateLongTimeLong:
            return "LLL";
        case DisplayFormat.DateFullTimeFull:
            return "LLLL";
        default:
            return "L";
    }
};

/* FE specific date formats - should be already dayJS format strings */
export enum DateFormat {
    /** Short month format string @example "2018 January" */
    yearAndMonth = "YYYY MMMM",
    /** Short month format string @example "January 2018" */
    monthAndYear = "MMMM YYYY",
    /** Day format string @example "12" */
    dayOfMonth = "D",
    /** Month format string @example "January" */
    month = "MMMM",
    /** Year format string @example "2019" */
    year = "YYYY",
    /** Hours format string @example "23" */
    hours24h = "HH",
    /** Minutes format string @example "59" */
    minutes = "mm",
    /** Localized date and time */
    dateAndTime = "L, LT",
}

/**
 * Returns standard date format used by dayjs library
 * @param format
 */
export const getDateFormat = (format: DisplayFormat | DateFormat | string): string => {
    if (isDisplayFormat(format)) {
        return format.split(CombinedFormatSeparator)
            .map(f => parseDisplayFormat(f as DisplayFormat))
            .join(CombinedFormatSeparator);
    }
    return format as string;
};

/**
 * Currently all agendas are in CET timezone
 */
export function getAgendaTZ(): string {
    return "Europe/Prague";
}

/** Returns date from local storage if set via devtools/timeTravel */
export const getDevelTimeTravelDate = (): string => {
    return DevelLocalSettings.get("timeTravel")?.timeTravelDate ?? undefined;
};

// ! IMPORTANT !
// only use these methods to obtain date/dayjs objects!
// never create dayjs() or new Date() by yourself!!
// this allows us to always create correct dates and use timeTravel in devtools

/**
 * Creates UTC date from given date
 *  - for given date it creates its representation in +00 timezone (UTC),
 *      which is different from browsers new Date() which creates it in local timezone (typically +01 in europe/prague)
 * @param date
 */
export function getUtcDayjs(date?: TDateLike): Dayjs {
    if (!date) {
        const timeTravelDate = getDevelTimeTravelDate();
        // new date in current timezone
        const now = timeTravelDate ? new Date(timeTravelDate) : new Date();
        // convert to UTC date with same day, month and year
        date = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`;
    }
    if (typeof date === "string" && date.startsWith("0001-")) {
        // min date -> the only way how we can create correct date is to call Date contructor in this case
        return dayjs.utc(new Date(date));
    }
    return dayjs.utc(date);
}

/**
 * Creates UTC date from given date string
 * @param dateString
 * @param dateOnly
 */
export function getUtcDate(dateString?: TDateLike, dateOnly = false): Date {
    if (dateOnly && typeof dateString === "string") {
        // for string params, takes only date part without time
        dateString = dateString.split("T")[0];
    }
    return getUtcDayjs(dateString).toDate();
}

export function getUtcDateBy(year: number, monthIndex = 0, day = 1, hour = 0, minute = 0, sec = 0): Date {
    return new Date(Date.UTC(year, monthIndex, day, hour, minute, sec));
}

// TODO refactor code where needed to actually use these two methods
/**
 * Creates LOCAL date from given date
 *  - for given date it creates its representation in LOCAL timezone,
 *      which is SAME as browsers new Date()
 * @param date
 */
export function getLocalDayjs(date?: TDateLike): Dayjs {
    if (!date) {
        const timeTravelDate = getDevelTimeTravelDate();

        return timeTravelDate ? dayjs(getDevelTimeTravelDate()) : dayjs();
    }

    if (typeof date === "string" && date.startsWith("0001-")) {
        // min date -> the only way how we can create correct date is to call Date contructor in this case
        return dayjs(new Date(date));
    }

    return dayjs(date);
}

/**
 * Creates LOCAL date from given date string
 * @param dateString
 */
export function getLocalDate(dateString?: TDateLike): Date {
    return getLocalDayjs(dateString).toDate();
}

interface IParseDayArgs {
    date: string;
    format?: string | string[];
    strictMode?: boolean;
    workDate?: Date;
}

export function parseDayjs(args: IParseDayArgs): Dayjs {
    const format = args.format ?? Object.values(dayjs.Ls[dayjs.locale()].formats);
    const strictMode = args.strictMode !== false;

    if (args.date) {
        const date = args.date.toLowerCase();
        // note: parsing is case-sensitive, e.g. in czech we need lowercase months however in english uppercase. Formatting
        // works in the same way. The format should be exact in strict mode, including delimiters,
        // so strip additional spaces to help user a little.
        let parsedDate = dayjs(date.trim().replace(/\s+/g, " "), format, dayjs.locale(), strictMode).tz("GMT", true);

        // if the parsed string doesn't contain a year, dayjs use current year by default,
        // use work date year instead, if set
        if (parsedDate.isValid() && removeWhiteSpace(stripAllDelimiters(date)).length === 4) {
            const workDate = args.workDate ?? getWorkDate();

            if (workDate) {
                parsedDate = parsedDate.year(workDate.getFullYear());
            }
        }

        return parsedDate;
    }
    return null;
}

interface IDateTypeFormatOptions {
    isReadOnly?: boolean;
    local?: boolean;        // format in user's local timezone
}

class DateType {
    // those just default values that will change based on the UserSetting
    static defaultDateFormat: string = DisplayFormat.DateMedium;
    static defaultTimeFormat: string = DisplayFormat.TimeShort;

    static get defaultDateTimeFormat(): string {
        return `${DateType.defaultDateFormat}${CombinedFormatSeparator}${DateType.defaultTimeFormat}`;
    }

    static parse(args: IParseDayArgs): Date {
        return parseDayjs(args)?.toDate();
    }

    static format(value: Date | Dayjs, format?: DisplayFormat | DateFormat | string, options?: IDateTypeFormatOptions): string {
        if (!value) {
            return "";
        }

        if (isMaxDay(value) && options?.isReadOnly) {
            return i18next.t("Common:Time.WithoutEnd");
        } else if (isMinDay(value) && options?.isReadOnly) {
            return i18next.t("Common:Time.WithoutStart");
        }

        if (!isDefined(format)) {
            format = DateType.defaultDateFormat;
        }
        if (options?.local) {
            const a = dayjs;

            return dayjs.tz(value, dayjs.tz.guess()).format(getDateFormat(format));
        }
        return dayjs.utc(value).format(getDateFormat(format));
    }

    static localFormat(value: Date | Dayjs, format?: DisplayFormat | DateFormat | string, options?: IDateTypeFormatOptions): string {
        return DateType.format(value, format, { ...(options ?? {}), local: true });
    }

    static isValid(value: Date | Dayjs | string): boolean {
        return !!value && dayjs(value).isValid();
    }

    static compare(date1: Date, date2: Date): number {
        return date1.getTime() - date2.getTime();
    }

    static isSame(date1: Date, date2: Date): boolean {
        return date1?.getTime() === date2?.getTime();
    }

    static isSameDay(date1: TDateLike, date2: TDateLike): boolean {
        return getUtcDayjs(date1).isSame(date2, "day");
    }

    static isToday(date: TDateLike): boolean {
        return DateType.isSameDay(getUtcDayjs(), date);
    }
}

export default DateType;