import React, { useCallback, useContext } from "react";
import { InnerRect, SelectedOverlay, StyledOurOrganizationTile, Subtitle, Title } from "./OurOrganizationTile.styles";
import { useTranslation } from "react-i18next";
import ourOrganizationTile from "./ourOrganizationTile.svg";
import { KeyName } from "../../keyName";
import { getCompanySelectorTitleKey } from "@utils/CompanyUtils";
import { AppContext } from "../../contexts/appContext/AppContext.types";

export interface IOurOrganizationTileProps {
    onClick?: () => void;
    isSelected?: boolean;
}

const OurOrganizationTile = React.memo(({ ...props }: React.PropsWithChildren<IOurOrganizationTileProps>) => {
    const { t } = useTranslation("Components");
    const context = useContext(AppContext);

    const handleKeyDown = useCallback(
        (e: React.KeyboardEvent) => {
            if (e.key === KeyName.Enter || e.key === KeyName.Space) {
                e.preventDefault();
                props.onClick?.();
            }
        }, []);

    return (
        <StyledOurOrganizationTile $backgroundSrc={ourOrganizationTile}
                                   $isSelected={props.isSelected}
                                   onClick={props.onClick}
                                   onKeyDown={handleKeyDown}
                                   tabIndex={0}>
            {props.isSelected &&
                <SelectedOverlay/>
            }
            <InnerRect $backgroundSrc={ourOrganizationTile}
                       $isSelected={props.isSelected}>
                <Title>
                    {t(`Components:OurOrganizationTile.${getCompanySelectorTitleKey(context)}`)}
                </Title>
                <Subtitle>
                    {t("Components:OurOrganizationTile.Subtitle")}
                </Subtitle>
            </InnerRect>
        </StyledOurOrganizationTile>
    );
});


export default OurOrganizationTile;