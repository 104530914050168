import styled, { css, FlattenSimpleInterpolation } from "styled-components/macro";
import GridLayout from "react-grid-layout";
import { GridGap, GridTileSize } from "./Dashboard.types";
import { PropsWithTheme, Theme } from "../../theme";
import {
    getCustomizedDashedBorderBackground,
    LocalStackOrder,
    T_TITLE_3_normal,
    T_TITLE_4_NORMAL
} from "../../global.style";
import { Link } from "react-router-dom";
import { ellipsis } from "polished";
import { IconButton } from "../button";
import { TooltipIconInfo } from "../tooltipIcon";

export const TilePadding = GridGap / 2;
export const DashboardTileHorizontalPadding = 20;
export const DashboardTileVerticalPadding = 16;
export const DashboardTileRadius = 30;
export const DashboardTileRadiusPx = `${DashboardTileRadius}px`;

export const StyledDashboard = styled.div`
    display: block;
    position: relative;
    margin: -${TilePadding}px;
    width: calc(100% + ${2 * TilePadding}px);
`;

export const StyledGridLayout = styled(GridLayout)<{ $showGrid?: boolean, width: number }>`
    ${props => props.$showGrid && css`
        ${props => getCustomizedDashedBorderBackground(props.theme, {
            rx: DashboardTileRadius,
            borderColor: "C_CUST_DASHBOARD_TILE_BORDER",
            borderOpacity: props.theme["C_CUST_DASHBOARD_TILE_BORDER_OPACITY"],
            backgroundColor: "C_CUST_DASHBOARD_TILE_BG",
            backgroundOpacity: props.theme["C_CUST_DASHBOARD_TILE_BG_OPACITY"],
            w: GridTileSize, h: GridTileSize,
            padding: TilePadding + 1
        })};
        background-size: ${GridTileSize}px ${GridTileSize}px;
    `}

    max-width: ${props => props.width}px;
    position: relative;

    .react-grid-placeholder {
        opacity: 1;
        box-shadow: none;
        position: relative;
        background-color: transparent;
        z-index: 0;

        &::after {
            content: "";
            position: absolute;
            top: ${TilePadding}px;
            bottom: ${TilePadding}px;
            left: ${TilePadding}px;
            right: ${TilePadding}px;
            border-radius: ${DashboardTileRadiusPx};

            background-size: 100% 100%;
            ${props => getCustomizedDashedBorderBackground(props.theme, {
                rx: DashboardTileRadius,
                borderColor: "C_DASHBOARD_TILE_PLACEHOLDER_BORDER",
                backgroundColor: "C_DASHBOARD_TILE_PLACEHOLDER_BG",
                // when rendered as 100%, it's scaled differently, so make the stroke bigger...
                strokeWidth: 4,
                // ...change also the dashArray, because the bolder stroke makes the dashArray look different because of the line caps
                dashArray: [2, 8]
            })};
        }
    }
`;

export const StyledDashboardTile = styled.div`
    padding: ${TilePadding}px;
    box-shadow: none;
    background-color: transparent;
`;

export function getDashboardBoxShadow(props: PropsWithTheme): FlattenSimpleInterpolation {
    return css`
        box-shadow: 0 0 18px 0 rgba(0, 0, 0, ${props.theme.dashboard_tile_shadow_opacity});
    `;
}

export const StyledDashboardTileBackground = styled.div<{
    $backgroundColor?: keyof Theme;
    $isLoading?: boolean;
    $overflowed?: boolean;
    $hideBackground?: boolean;
    $cursor?: "pointer" | "default";
}>`
    height: 100%;
    padding: ${DashboardTileVerticalPadding}px ${DashboardTileHorizontalPadding}px;

    ${props => !props.$hideBackground && css`
        ${getDashboardBoxShadow(props)};
        border-radius: ${DashboardTileRadiusPx};
        background-color: ${props.theme[props.$backgroundColor ?? "C_BG_menu"]};
    `};
    position: relative;
    overflow: ${props => props.$overflowed ? "hidden" : "visible"};
    cursor: ${props => props.$cursor};
    display: flex;
    flex-direction: column;

    ${props => props.$isLoading && css`
        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background-color: ${props.theme.C_ACT_hover_without_opacity};
            opacity: .75;
            z-index: ${LocalStackOrder.Overlay};
            border-radius: ${props.$hideBackground ? 0 : DashboardTileRadiusPx};
        }
    `};

    .MI_4L_gradient, .MI_4L_stroke, .MI_4L_main,
    .a, .b, .c, .d, .e, .f, .g, .h, .i, .j, .k {
        // lighter strokes for dashboard icons...
        stroke-width: 1.5px;
    }
`;

export const DashboardTileHeading = styled.h3<{ centered?: boolean; small?: boolean; }>`
    ${props => props.small ? T_TITLE_4_NORMAL : T_TITLE_3_normal};
    text-align: ${props => props.centered ? "center" : "left"};
`;

export const TooltipIconInfoStyled = styled(TooltipIconInfo)`
    margin-right: auto;
    margin-left: 4px;
`;

export const IconsWrapper = styled.div`
    display: flex;
    width: fit-content;
    flex: 0 0 auto;
    margin-left: auto;
`;

export const IconButtonStyled = styled(IconButton)`
    margin-left: 3px;
`;

export const StyledDashboardTileHeader = styled.div<{ $addPadding?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    padding: ${props => props.$addPadding && `${DashboardTileVerticalPadding}px ${DashboardTileHorizontalPadding}px`};

    ${DashboardTileHeading} {
        ${ellipsis()};
        width: fit-content;
        flex: 0 1 auto;
    }
`;

export const LinkStyled = styled(Link)<{ $isEdit: boolean }>`
    border-radius: ${DashboardTileRadiusPx};

    ${props => props.$isEdit && css`
        cursor: default;
        pointer-events: none;
    `}
`;
