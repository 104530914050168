import React from "react";
import Dialog from "../../dialog/Dialog";
import { WithTranslation, withTranslation } from "react-i18next";
import ConfirmationButtons from "../../../views/table/ConfirmationButtons";
import { Button } from "../../button";
import { ArrowIcon } from "../../icon";
import { IconSize, TextAlign, ValidationErrorType } from "../../../enums";
import BindingContext, { IEntity } from "../../../odata/BindingContext";
import { TableStorage } from "../../../model/TableStorage";
import { FormStorage } from "../../../views/formView/FormStorage";
import { getDefByEntityType } from "@pages/getDefByEntityType";
import { BulkChangeForm } from "./BulkChangeForm";
import { ISmartFieldBlur, ISmartFieldChange, ISmartFieldTempDataActionArgs } from "../smartField/SmartField";
import { FormFooterStyled, Header } from "./BulkChange.styles";
import { isDefined } from "@utils/general";
import { IRow } from "../../table";
import SimpleTable from "../../simpleTable/SimpleTable";
import { IValidationError } from "../../../model/Validator.types";
import { IFormGroupDef } from "../smartFormGroup/SmartFormGroup";
import { flatten } from "lodash";
import { IFieldDef } from "../FieldInfo";
import { TFieldsDefinition } from "@pages/PageUtils";
import {
    BulkChangeAction,
    getBulkChangeActionTranslation,
    getDefaultBulkChangeAction,
    IBulkChangeDialogStorageCustomData
} from "./SmartBulkChange.utils";
import { TRecordType } from "../../../global.types";
import { NEW_ITEM_DETAIL } from "../../../constants";
import { getEntityTypeFromBindingContext } from "@odata/EntityTypes";

export type TBulkConfirmData = { data: IEntity, collectionBulkActions: TRecordType<BulkChangeAction> };

interface IProps extends WithTranslation {
    onConfirm: (args: TBulkConfirmData) => void;
    onCancel: () => void;
    bindingContext: BindingContext;
    tableStorage: TableStorage;
}


interface IState {
    isConfirmationPage: boolean;
    storage?: FormStorage<IEntity, IBulkChangeDialogStorageCustomData>;
}

class BulkChangeDialog extends React.Component<IProps, IState> {
    _formRef = React.createRef<any>();

    state: IState = {
        isConfirmationPage: false
    };

    constructor(props: IProps) {
        super(props);

        this.state.storage = this.createFormStorage();
        // init as new (empty) form storage
        this.state.storage.init({
            bindingContext: this.props.bindingContext.addKey(NEW_ITEM_DETAIL, true),
            data: {} // just empty form without default values
        })
            .then(() => this.forceUpdate());
    }

    get entityType() {
        return getEntityTypeFromBindingContext(this.props.bindingContext);
    }

    get confirmText() {
        const num = this.props.tableStorage.getCustomData().affectedRows?.length;
        return this.props.t(`Common:General.${this.state.isConfirmationPage ? "ChangeWithCount" : "Continue"}`, { num });
    }

    get dialogTitle() {
        const { t, tableStorage } = this.props;
        return t("Common:BulkChange.Title", { type: tableStorage.data.definition.title });
    }

    get dialogHeader() {
        return this.props.t(`Common:BulkChange.${this.state.isConfirmationPage ? "ConfirmationHeader" : "Header"}`);
    }

    reduceBulkChangeFormGroups = (groups: IFormGroupDef[], bulkChangeDef: TFieldsDefinition) => {
        return groups.reduce((newGroups, group) => {
            const allRows = [...(group.rows || [])];
            const rows = flatten<IFieldDef>(allRows || [])
                .filter(row => !!bulkChangeDef[row.id]);

            if (!rows.length) {
                // we are showing only groups with rows in bulk change
                return newGroups;
            }

            const newGroup: IFormGroupDef = {
                ...group,
                rows: [rows]
            };

            newGroups.push(newGroup);
            return newGroups;
        }, []);
    };

    createFormStorage = () => {
        const definition = getDefByEntityType(this.entityType)(this.props.tableStorage.context);
        // create form definition from massEditable fields' def merged with form group definitions
        const formDef = definition.form;
        // todo: according to DEV-21949 we currently support only Note and Labels
        // formDef.fieldDefinition = definition.table.massEditableDef;
        const { Note, Labels } = definition.table.massEditableDef;

        // each field is supposed to be required
        // make generic if more than two fields are used
        Note.isRequired = true;
        Labels.isRequired = true;

        formDef.fieldDefinition = {
            Note, Labels
        };
        // todo: DEV-21949 (end)
        formDef.groups = this.reduceBulkChangeFormGroups(definition.form.groups, formDef.fieldDefinition);

        const formStorage = new FormStorage({
            oData: this.props.tableStorage.oData,
            t: this.props.t,
            context: this.props.tableStorage.context,
            id: formDef.id,
            definition: formDef,
            refresh: (refreshPage) => {
                this._formRef.current?.forceUpdate();
                if (refreshPage) {
                    this.forceUpdate();
                }
            }
        }) as FormStorage<IEntity, IBulkChangeDialogStorageCustomData>;

        formStorage.setCustomData({
            isChecked: {}
        });

        return formStorage;
    };

    getCheckedRows = () => {
        return this.state.storage.getCustomData().isChecked;
    };

    /** Validates all fields that has been selected to be used by the user */
    async validateSelected(): Promise<boolean> {
        const { storage } = this.state;
        const promises: Array<Promise<IValidationError>> = [];
        // Go through all values that use has selected, checks if value is set
        const checkedRows = this.getCheckedRows();

        for (const [path, isChecked] of Object.entries(checkedRows)) {
            const bindingContext = this.state.storage.data.bindingContext.navigate(path);
            const action = this.state.storage.getAdditionalFieldData(bindingContext, "parsedValue") as BulkChangeAction;
            const isCollection = bindingContext.isCollection();

            if (!isChecked || action === BulkChangeAction.RemoveAll || (action === BulkChangeAction.Remove && !isCollection)) {
                continue;
            }

            if (isCollection) {
                const value = storage.getValue(bindingContext);

                if (!value || value.length === 0) {
                    const error = {
                        errorType: ValidationErrorType.Field,
                        message: this.state.storage.t("Validation.Required")
                    };
                    promises.push(Promise.resolve(error));
                    storage.setError(bindingContext, error);
                }

                continue;
            }

            if (storage.data.definition.fieldDefinition[path]) {
                promises.push(storage.validateField(bindingContext));
            }
        }

        const results = await Promise.all(promises);

        return !results.find(error => isDefined(error));
    }

    handleConfirm = async () => {
        const { isConfirmationPage, storage } = this.state;

        if (isConfirmationPage) {
            const { entity, bindingContext } = storage.data;
            const data = storage.injectTemporaryGuids(storage.clearHiddenFields(entity));
            const collectionBulkActions: TRecordType<BulkChangeAction> = {};
            Object.keys(data).forEach(path => {
                const bc = bindingContext.navigate(path);
                if (bc.isCollection()) {
                    collectionBulkActions[path] = storage.getAdditionalFieldData(bc, "parsedValue") as BulkChangeAction;
                }
            });
            this.props.onConfirm({ data, collectionBulkActions });
        } else {
            const isValid = await this.validateSelected();

            if (isValid) {
                this.setState({ isConfirmationPage: true });
            } else {
                // displays validation errors in fields
                this.forceUpdate();
            }
        }
    };

    handleCancel = () => {
        this.props.onCancel();
    };

    handleBack = () => {
        if (this.state.isConfirmationPage) {
            this.setState({ isConfirmationPage: false });
        }
    };

    renderConfirmationButtons(isDisabled: boolean) {
        return (
            <FormFooterStyled>
                {this.state.isConfirmationPage &&
                    <Button icon={<ArrowIcon width={IconSize.S}/>} onClick={this.handleBack}
                            isTransparent>
                        {this.props.t("Common:General.Back")}
                    </Button>
                }

                <ConfirmationButtons confirmText={this.confirmText}
                                     onConfirm={this.handleConfirm}
                                     onCancel={this.handleCancel}
                                     isDisabled={isDisabled}/>
            </FormFooterStyled>
        );
    }

    handleBlur = (args: ISmartFieldBlur) => {
        this.state.storage.handleBlur(args)
            .then(() => this.state.storage.refreshFields());
    };

    handleChange = (e: ISmartFieldChange) => {
        this.state.storage.handleChange(e);
        this.state.storage.refreshFields();
    };

    handleTemporalChange = (e: ISmartFieldChange) => {
        this.state.storage.handleTemporalChange(e);
        this.state.storage.refreshFields();
    };

    handleFieldConfirm = async (args: ISmartFieldTempDataActionArgs) => {
        await this.state.storage.handleConfirm(args, this.handleChange);
        await this.handleFieldCancel(args);
    };

    handleFieldCancel = async (args: ISmartFieldTempDataActionArgs) => {
        await this.state.storage.handleCancel(args);
        this.state.storage.refreshFields();
    };

    get columns() {
        const { t } = this.props;
        return [
            { id: "label", label: t("Common:BulkChange.Label"), textAlign: TextAlign.Left },
            { id: "value", label: t("Common:BulkChange.Value"), textAlign: TextAlign.Left }
        ];
    }

    get rows(): IRow[] {
        const { storage } = this.state;
        const rows: IRow[] = [];
        const checkedRows = this.getCheckedRows();

        // Go through all checked rows
        for (const [path, isChecked] of Object.entries(checkedRows)) {
            if (!isChecked) {
                continue;
            }

            const bindingContext = this.state.storage.data.bindingContext.navigate(path);
            const info = storage.getInfo(bindingContext);

            if (info && storage.data.definition.fieldDefinition[info.id]) {
                const origDisplayValue = storage.getDisplayValue(info);
                const isCollection = bindingContext.isCollection();

                // Labels, etc...
                const parsedValue = storage.getAdditionalFieldData(bindingContext, "parsedValue") as string ?? getDefaultBulkChangeAction(isCollection);
                let displayValue = getBulkChangeActionTranslation(parsedValue as BulkChangeAction, isCollection, this.props.t);

                if (parsedValue !== BulkChangeAction.RemoveAll && origDisplayValue) {
                    displayValue = `${displayValue}: ${origDisplayValue}`;
                }

                rows.push({
                    id: bindingContext.getFullPath(),
                    values: {
                        label: info?.label,
                        value: displayValue
                    }
                });
            }
        }

        return rows;
    }

    renderContent(rows: IRow[]) {
        return (<>
            <Header>
                {this.dialogHeader}
            </Header>
            {this.state.isConfirmationPage ? (
                <SimpleTable rows={rows} columns={this.columns}/>
            ) : (
                <BulkChangeForm ref={this._formRef}
                                storage={this.state.storage}
                                onBlur={this.handleBlur}
                                onCancel={this.handleFieldCancel}
                                onConfirm={this.handleFieldConfirm}
                                onChange={this.handleChange}
                                onTemporalChange={this.handleTemporalChange}
                />
            )}
        </>);
    }

    render() {
        const isLoaded = this.state.storage.loaded && this.props.tReady;
        const rows = this.state.isConfirmationPage ? this.rows : [];
        const checkedRows = Object.values(this.getCheckedRows());
        const isAnyChecked = checkedRows?.length > 0 && checkedRows.some(v => v);
        const isConfirmDisabled = (this.state.isConfirmationPage && !rows.length) || !isAnyChecked;


        return (
            <Dialog title={this.dialogTitle}
                    onConfirm={this.handleConfirm}
                    onClose={this.handleCancel}
                    minWidth={"695px"}
                    busy={!isLoaded}
                    footer={this.renderConfirmationButtons(isConfirmDisabled)}
            >
                {isLoaded && this.renderContent(rows)}
            </Dialog>
        );
    }
}

export default withTranslation(["Common"])(BulkChangeDialog);