import dayjs, { Dayjs } from "dayjs";
import { DASH_CHARACTER } from "../../../../constants";
import DateType, {
    DATE_MAX,
    DATE_MIN,
    getUtcDate,
    getUtcDayjs,
    parseDayjs,
    TDateLike
} from "../../../../types/Date";
import i18next from "i18next";
import memoizeOne from "../../../../utils/memoizeOne";
import LocalSettings from "../../../../utils/LocalSettings";
import { roundToDecimalPlaces } from "@utils/general";
import memoize from "../../../../utils/memoize";

/** Custom date util methods */
export const parseDateInterval = (value: string, format: string | string[] = undefined, strictMode = true): IDayInterval => {
    const interval: IDayInterval = { from: null, to: null };

    if (value) {
        const [firstDate, secondDate] = value.replace("-", DASH_CHARACTER).split(DASH_CHARACTER).map(str => str.trim());

        const args = { format, strictMode };
        const fromDayjsDate = parseDayjs({ date: firstDate, ...args });
        const toJsDate = parseDayjs({ date: secondDate, ...args });

        if (fromDayjsDate) {
            interval.from = fromDayjsDate.toDate();
        }

        if (toJsDate) {
            interval.to = toJsDate.toDate();
        }
    }

    return interval;
};

export const isValidDateInterval = (value: any): value is IDayInterval => {
    return value?.from && value?.to && DateType.isValid(value.from) && DateType.isValid(value.to) && getUtcDayjs(value.from).isBefore(value.to);
};

export const isSameInterval = (interval1: IDayInterval, interval2: IDayInterval): boolean => {
    return getUtcDayjs(interval1?.from).isSame(getUtcDayjs(interval2?.from)) && getUtcDayjs(interval1?.to).isSame(getUtcDayjs(interval2?.to));
};

export const maxDate = (...args: (Date | string | Dayjs)[]): Date => {
    return dayjs.max(...args.map(arg => getUtcDayjs(arg))).toDate();
};

export const formatDateInterval = (value: IDayInterval, format?: string): string => {
    let newValue = "";

    if (!value) {
        return newValue;
    }

    const from = getUtcDayjs(value.from);
    const to = getUtcDayjs(value.to);
    const formattedFrom = DateType.format(value.from, format);
    const formattedTo = DateType.format(value.to, format);

    if (from.isSameOrBefore(DATE_MIN, "date") && to.isSameOrAfter(DATE_MAX, "date")) {
        newValue = i18next.t("Common:Time.All");
    } else if (from.isSameOrBefore(DATE_MIN, "date")) {
        newValue = `${i18next.t("Common:Time.To")} ${formattedTo}`;
    } else if (to.isSameOrAfter(DATE_MAX, "date")) {
        newValue = `${i18next.t("Common:Time.From")} ${formattedFrom}`;
    } else {
        newValue = `${formattedFrom} ${DASH_CHARACTER} ${formattedTo}`;
    }

    return newValue;
};

/** Format day interval using default format, without year in the "from" part if it is the same for both values.
 * 20.1. – 20.2.2021 but 20.1.2020 – 20.2.2021 */
export const formatDateIntervalWithoutSameYear = (value: IDayInterval): string => {
    let newValue = "";

    if (isValidDateInterval(value)) {
        const intlObj = new Intl.DateTimeFormat(i18next.language, {
            day: "2-digit",
            month: "2-digit",
            year: value.from.getFullYear() === value.to.getFullYear() ? undefined : "numeric"
        });
        newValue = intlObj.format(value.from).replace(" ", "");
        newValue += ` ${DASH_CHARACTER} ${DateType.format(value.to)}`;
    }
    return newValue;
};

export const longDateFormat = memoize((format: string) => {
    // RegEx describes locale specific date formats, e.g. L, lll, LTS, etc...
    const localDateStringRe = /(l{1,4}|lt|lts)([^lts]|$)/ig;
    const matches = [...format.matchAll(localDateStringRe)];

    /**
     * Whole format can be combined, e.g. locale specific DateTime - "L, LT", but method longDateFormat
     * can work only with the basic types, so, we need to loop through all the matches and replace them one by one.
     *
     * Additionally calling the method with non-locale specific format, e.g. localeData.longDateFormat("DD/MM/YYYY")
     * will raise an error. Therefore if we are replacing only exact matches, our method will work
     * with any format (it just returns the original one)
     */
    if (matches) {
        const localeData = dayjs.localeData();
        matches.forEach(([, localeFormat]) => {
            format = format.replace(localeFormat, localeData.longDateFormat(localeFormat));
        });
    }

    return format;
}, (format: string) => {
    // cache by format + locale as the format is locale specific
    return `${dayjs.locale()}+${format}`;
});

const ALLOWED_DELIMITERS = [".", "/", "-", ","];

export const stripAllDelimiters = (format: string) => {
    return format.trim().replace(/[\.,\/]/g, " ");
};

// replaces delimiter of the given formats with every possible delimiter (. , / -)
export const formatDelimiterVariants = memoizeOne((formats: string[]): string[] => {
    const allFormats = new Set<string>(formats);

    for (const delim of ALLOWED_DELIMITERS) {
        for (const format of formats) {
            const clean = stripAllDelimiters(format).split(" ");

            allFormats.add(clean.join(delim));
        }
    }

    return [...allFormats];
});

/**
 use multiple formats, because dayJs doesn't support strict mode parsing correctly
 D.M.YYYY won't parse multi digit days and months, like 02.02.2020 even though it should
 so we need to create all possible combinations from basic format, e.g.
 DD.MM.YYYY -> ["D.M.YYYY", "DD.MM.YYYY", "DD.M.YYYY", "D.MM.YYYY"]

 @see specification https://solitea-cz.atlassian.net/wiki/spaces/IRIS/pages/2193981441/Datumov+fieldy+-+textov+vstup
 */
export const formatVariants = memoize((longDateFormat: string, withoutDelimiterVariants?: boolean): string[] => {
    const formats = new Set<string>();
    formats.add(longDateFormat);
    // Format characters, which can be used in format string solo or doubled
    const possibleReplacements = ["D", "M", "H", "h", "m"];

    const createRegExp = (c: string, size: number) => new RegExp(`(^|[^${c}])${c}{${size}}([^${c}]|$)`, "g");

    possibleReplacements.forEach((c) => {
        formats.forEach(format => {
            formats.add(format.replace(createRegExp(c, 1), `$1${c}${c}$2`));
            formats.add(format.replace(createRegExp(c, 2), `$1${c}$2`));
        });
    });

    if (withoutDelimiterVariants) {
        return [...formats];
    }

    return formatDelimiterVariants([...formats]);
}, (longDateFormat, withoutDelimiterVariants) => `${longDateFormat}${withoutDelimiterVariants}`);

export const formatDateVariants = memoize((longDateFormat: string, withoutDelimiterVariants?: boolean): string[] => {
    const formats = new Set<string>(formatVariants(longDateFormat, withoutDelimiterVariants));

    // format with 2-digit year and without delimiters
    const shortYearFormat = longDateFormat.replace("YYYY", "YY");
    const sortedFormats = ["DD", "MM", "YY"].sort((a, b) => shortYearFormat.indexOf(a) - shortYearFormat.indexOf(b));
    const twoDigitsNoDelimFormat = sortedFormats.join("");
    const forDigitsYearNoDelimFormat = twoDigitsNoDelimFormat.replace("YY", "YYYY");

    formats.add(twoDigitsNoDelimFormat);
    formats.add(forDigitsYearNoDelimFormat);

    // format without year, keeping delimiters
    const noYearDelimiter = sortedFormats.filter(format => format !== "YY").join(".");
    // format with 2-digits year with delimiters
    const twoDigitsYearDelims = sortedFormats.join(".");
    const noYearAllDelims = formatDelimiterVariants([noYearDelimiter, twoDigitsYearDelims]);

    noYearAllDelims.forEach(format => formats.add(format));

    // format completely without years and without delimiters
    const noDelimWithoutYears = twoDigitsNoDelimFormat.replaceAll("Y", "");

    formats.add(noDelimWithoutYears);

    return [...formats];
});

export const formatTimeVariants = memoize((longDateFormat: string): string[] => {
    const formats = new Set<string>(formatVariants(longDateFormat));

    // format with 2-digit minutes and hours without delimiters
    const sortedFormats = ["HH", "mm"].sort((a, b) => longDateFormat.indexOf(a) - longDateFormat.indexOf(b));
    const noDelimFormat = sortedFormats.join("");

    formats.add(noDelimFormat);

    // use reverse to prioritize noDelimFormat,
    // so that when the parse is called with isStrict=false, it will still try to use the noDelimFormat
    return [...formats].reverse();
});

export const isMaxDay = (day: TDateLike): boolean => {
    return DateType.isSameDay(day, DATE_MAX);
};

export const isMinDay = (day: TDateLike): boolean => {
    return DateType.isSameDay(day, DATE_MIN);
};

export const isCurrentDay = (day: TDateLike): boolean => {
    return DateType.isToday(day);
};

export const isSameDay = (day: TDateLike, day2: TDateLike): boolean => {
    return !!(day2 && DateType.isSameDay(day, day2));
};

export const getNextYear = (day: Dayjs): Dayjs => {
    return day.add(1, "year");
};

export const getPreviousYear = (day: Dayjs): Dayjs => {
    return day.subtract(1, "year");
};

export const getNextMonth = (day: Dayjs): Dayjs => {
    return day.add(1, "month");
};

export const getPreviousMonth = (day: Dayjs): Dayjs => {
    return day.subtract(1, "month");
};

export const isOutsideMonth = (day: Dayjs, month: Dayjs) => {
    return day.month() !== month.month();
};

export const getNextHour = (day: Dayjs) => {
    return day.add(1, "hour");
};

export const getPreviousHour = (day: Dayjs) => {
    return day.subtract(1, "hour");
};

export const getNextMinute = (day: Dayjs) => {
    return day.add(1, "minute");
};

export const getPreviousMinute = (day: Dayjs) => {
    return day.subtract(1, "minute");
};

export const setTimeToDate = (date: Dayjs, time?: Dayjs) => {
    if (!time) {
        return date;
    }

    let newDate = date.second(time.second());
    newDate = newDate.minute(time.minute());
    newDate = newDate.hour(time.hour());

    return newDate;
};

export const getWeekArray = (date: Dayjs): Dayjs[][] => {
    const start = getUtcDayjs(date).clone().startOf("month").startOf("week");
    const end = getUtcDayjs(date).clone().endOf("month").endOf("week");
    const currentMonth = date.month();

    let count = 0;
    let current = start;
    const nestedWeeks: Dayjs[][] = [];

    while (current.isBefore(end)) {
        const weekNumber = Math.floor(count / 7);
        nestedWeeks[weekNumber] = nestedWeeks[weekNumber] || [];

        // only return days from this month,
        // days in from other months should be empty
        nestedWeeks[weekNumber].push(current.month() === currentMonth ? current : null);

        current = current.clone().add(1, "day");
        count += 1;
    }

    return nestedWeeks;
};

export const getWeekdays = (): string[] => {
    const start = dayjs().startOf("week");
    return [0, 1, 2, 3, 4, 5, 6].map((diff) =>
            start.add(diff, "day").format("dd"));
};

/**
 * If valid date given, returns its dayjs equivalent.
 * If no or invalid date given, returns dayjs object representing new Date().
 */
export const getValidDayjsDate = (date: Date) => {
    let validDate = getUtcDayjs(date);

    if (!validDate || !(validDate.isValid())) {
        validDate = getUtcDayjs();
    }

    return validDate;
};

export interface IDayInterval {
    from: Date;
    to: Date;
}

export interface IDayjsInterval {
    from?: Dayjs;
    to?: Dayjs;
}

export type IDateInterval = IDayInterval | IDayjsInterval;

export const dateIntervalToDayjsInterval = ({ from, to }: IDayInterval): IDayjsInterval => {
    return {
        from: from ? getUtcDayjs(from) : null,
        to: to ? getUtcDayjs(to) : null
    };
};

export const dayjsIntervalToDateInterval = ({ from, to }: IDayjsInterval): IDayInterval => {
    return {
        from: from.toDate(),
        to: to.toDate()
    };
};

export const isSameMonth = (first: Date, second: Date): boolean => {
    return !!first && !!second && getUtcDayjs(first).isSame(second, "month");
};

// format Date to DateTimeOffset format (string with timezone)
export const formatDateToDateTimeOffsetString = (date: TDateLike): string => {
    if (!date) {
        return null;
    }

    return getUtcDayjs(date).toISOString();
};

export const formatDateToDateString = (date: TDateLike): string => {
    if (!date) {
        return null;
    }

    // todo: most likely should not be called with invalid date?? Fix AgingAP report...
    if (!DateType.isValid(date)) {
        return date as string;
    }
    // dayjs doesn't add leading zeroes to year if the year is shorter than 4 characters
    // to ensure that the places where the string is used created correct Date, we need to add the zeroes,
    // otherwise years like "1" could result in Date with year 1970 or similar issues
    // ==> format year, add leading zeroes, merge with rest of the string
    return getUtcDayjs(date).toISOString().substring(0, 10);
};


export const formatDateToTimeString = (date: TDateLike): string => {
    if (!date) {
        return null;
    }

    return getUtcDayjs(date).toISOString().substring(11, 16);
};

export const isValidDate = (date: Date): boolean => {
    return !!date && dayjs(date).isValid();
};

export const getWorkDateFromLocalStorage = (): Date => {
    const workDate = LocalSettings.get("WorkDate")?.customData?.workDate;

    return workDate ? getUtcDate(workDate) : null;
};

export const getWorkDate = (): Date => {
    return getWorkDateFromLocalStorage() ?? getUtcDate();
};
export const getWorkDateCallback = () => (getWorkDate());

interface ISlidingIntervals {
    current: IDayjsInterval;
    previous: IDayjsInterval;
}

export function getSlidingInterval(to: Date | Dayjs, unit: "year" | "month" | "quarter"): ISlidingIntervals {
    const toParsed = getUtcDayjs(to);
    const prevTo = toParsed.subtract(1, unit);
    const from = prevTo.add(1, "day");
    const prevFrom = from.subtract(1, unit);
    const current: IDayjsInterval = {
        from, to: toParsed
    };
    const previous: IDayjsInterval = {
        from: prevFrom, to: prevTo
    };

    return {
        current, previous
    };
}

export const getMonthDays = (date: Dayjs): Dayjs[] => {
    const start = getUtcDayjs(date).clone().startOf("month");
    const end = getUtcDayjs(date).clone().endOf("month");

    let current = start;
    const days: Dayjs[] = [];

    while (current.isBefore(end)) {
        days.push(current);
        current = current.clone().add(1, "day");
    }
    return days;
};

// week day offset for czech localization
export const getMonthFirstDayWeekOffset = (date: Dayjs): number => {
    const start = getUtcDayjs(date).clone().startOf("month");
    return (start.day() + 6) % 7;
};

export const getDaysInterval = (date: Dayjs, length: number): Dayjs[] => {
    const start = date.clone();
    let current = start;
    const days: Dayjs[] = [];

    for (let i = 0; i < length; i++) {
        days.push(current);
        current = current.clone().add(1, "day");
    }
    return days;
};

export const getNextMonday = (date = getUtcDayjs(), startWithEvenWeek?: boolean): Dayjs => {
    let res: Dayjs;
    if (date.day() === 1) {
        res = date.clone();
    } else {
        res = date.clone().startOf("week").add(1, "week");
    }

    if (startWithEvenWeek) {
        if (res.week() % 2 === 0) {
            return res;
        }
        return res.add(1, "week");
    }
    return res;
};

export const getHourDifference = (time1: Dayjs, time2: Dayjs, overMidnight = true, inMinutes = false): number => {
    const totalMinutes1 = time1.hour() * 60 + time1.minute();
    const totalMinutes2 = time2.hour() * 60 + time2.minute();
    let diff = totalMinutes2 - totalMinutes1;
    if (!inMinutes) {
        diff = diff / 60;
    }
    return overMidnight && diff < 0 ? (inMinutes ? 3600 : 24) + diff : diff;
};

export const formatHoursToTimeFormat = (hours: number): string => {
    const hour = Math.floor(hours);
    const minutes = roundToDecimalPlaces(0, (hours % 1) * 60);
    return `${hour}:${minutes.toString().padStart(2, "0")}`;
};

/** Much faster than dayjs.isBetween.
 * Use this in places that are called lots of times instead of dayjs. */
export const isDateBetween = (startDate: Date, endDate: Date, checkDate: Date): boolean => {
    // Create new Date objects to avoid modifying the original dates
    const check = new Date(checkDate);
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Set the time of all dates to the same value to ignore timezone
    check.setHours(0, 0, 0, 0);
    start.setHours(0, 0, 0, 0);
    end.setHours(0, 0, 0, 0);

    const dateTime = check.getTime();

    return dateTime >= start.getTime() && dateTime <= end.getTime();
};

export const getDateFromDateOrDayjs = (date?: Date | Dayjs): Date => {
    if (!date) {
        return getUtcDate();
    }

    if (date instanceof Date) {
        return date;
    }

    return (date as Dayjs).toDate();
};