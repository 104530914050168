import styled from "styled-components/macro";
import { ColoredIcon } from "../../icon";
import { Status } from "../../../enums";
import { getSemanticTextColor, T_PLAIN_big, T_PLAIN_big_hig, T_PLAIN_small, T_TITLE_1 } from "../../../global.style";
import DashboardTileBackground from "../../dashboard/DashboardTileBackground";
import {
    DashboardTileHorizontalPadding,
    DashboardTileRadiusPx,
    DashboardTileVerticalPadding,
    StyledDashboardTileBackground
} from "../../dashboard/Dashboard.styles";

export const StyledInfoTile = styled(DashboardTileBackground)`
    justify-content: flex-start;
    gap: 14px;
`;

export const StyledInfoTileCell = styled.div`
    position: relative;
    flex: 1 1 auto;
`;

export const InfoTileBody = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: stretch;
    align-items: stretch;

    ${StyledInfoTileCell} + ${StyledInfoTileCell} {
        padding-left: 30px;

        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 2px;
            left: 0;
            bottom: 2px;
            border-left: 2px solid ${props => props.theme.C_TEXT_primary};
            opacity: .2;
        }
    }
`;

interface ISeverity {
    $severity: Status;
}

export const ValueWrapper = styled.div`
    display: flex;
    align-items: end;
    justify-content: flex-start;
`;

export const Value = styled.div<ISeverity>`
    min-height: 36px;
    color: ${props => getSemanticTextColor(props.theme, props.$severity)};
    ${T_TITLE_1};
`;

export const Unit = styled.span`
    color: ${props => props.theme.C_TEXT_primary};
    ${T_PLAIN_big};
    opacity: 0.5;
`;

export const Fraction = styled.span`
    ${T_PLAIN_big_hig};
`;

export const Label = styled.span`
    display: block;
    ${T_PLAIN_small};
    opacity: 0.5;

    &::first-letter {
        text-transform: lowercase;
    }
`;

export const ColoredIconStyled = styled(ColoredIcon)<ISeverity>`
    color: ${props => getSemanticTextColor(props.theme, props.$severity)};
    align-self: center;
    margin-left: auto;
`;

export const GroupedInfoTileBackground = styled(DashboardTileBackground)`
    position: relative;
    padding: 0;
`;

export const GroupInfoTileHeader = styled.div`
    position: relative;
    z-index: 1;
    padding: ${DashboardTileVerticalPadding}px ${DashboardTileHorizontalPadding}px;

    border-top-left-radius: ${DashboardTileRadiusPx};
    border-top-right-radius: ${DashboardTileRadiusPx};
`;

export const GroupedInfoTileWrapper = styled.div`
    padding: 20px 20px 14px 20px;
`;

function genGridTemplate(template: string, items: number): string {
    return Array(items).fill(template).join(" ");
}

export const GroupedInfoTileContentWrapper = styled.div<{
    cols: number;
    rows: number;
}>`
    display: grid;
    grid-template-columns: ${props => genGridTemplate("auto", props.cols)};
    grid-template-rows: ${props => genGridTemplate("auto", props.rows)};
    grid-column-gap: 20px;
    grid-row-gap: 18px;

    padding: 6px 32px 28px 32px;

    ${StyledDashboardTileBackground} {
        height: 88px;
        justify-content: flex-start;
        padding: 0;
    }
`;