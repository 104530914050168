import React, { AnchorHTMLAttributes } from "react";
import { KeyName } from "../../keyName";
import { ClickableText } from "./Clickable.styles";
import TestIds from "../../testIds";
import { THistoryLocation } from "../drillDown/DrillDown.utils";
import { isExternalLink } from "./Clickable.utils";
import { Link, LinkProps } from "react-router-dom";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import { addCompanyIdToUrl } from "../../contexts/appContext/AppContext.utils";

interface IProps {
    link?: THistoryLocation;
    onClick?: (event: React.SyntheticEvent) => void;
    tabIndex?: number;
    passRef?: React.RefObject<HTMLAnchorElement>;
    isLink?: boolean;
    isLight?: boolean;
    isDisabled?: boolean;
    // styled by parent component, that only wants the functionality
    isWithoutStyle?: boolean;

    onMouseEnter?: (event: React.MouseEvent) => void;
    onMouseLeave?: (event: React.MouseEvent) => void;
    onFocus?: (e: React.FocusEvent) => void;
    onBlur?: (e: React.FocusEvent) => void;

    testId?: string;
    className?: string;
    style?: React.CSSProperties;
    companyId?: number;
}

/** Main navigation component.
 * If you need (blue/purple) text that can be clicked on, that either navigates or triggers some action,
 * USE THIS!
 * */
class Clickable extends React.PureComponent<IProps> {
    static contextType = AppContext;

    handleKeyDown = (event: React.KeyboardEvent): void => {
        if (event.key === KeyName.Enter) {
            this.handleClick(event);
        }
    };

    handleClick = (event: React.SyntheticEvent): void => {
        if (this.props.isDisabled) {
            return;
        }
        if (this.props.link) {
            event.stopPropagation();
            // click is handled by browser with the href attribute
        }
        if (!event.defaultPrevented) {
            this.props.onClick?.(event);
        }
    };

    handleContextMenu = (event: React.SyntheticEvent): void => {
        event.stopPropagation();
    };

    render() {
        const optProps: AnchorHTMLAttributes<HTMLAnchorElement> | LinkProps = {};

        const { isDisabled, link, companyId } = this.props;
        const isExternal = typeof link === "string" && isExternalLink(link);
        const isLink = this.props.isLink || !!link;
        let clickableElement: React.ElementType = "a";
        const fullLink = addCompanyIdToUrl(link, companyId ?? this.context);

        if (isLink && fullLink && !this.props.isDisabled) {
            if (isExternal) {
                optProps.href = fullLink as string;
                optProps.target = "_blank";
            } else {
                (optProps as LinkProps).to = fullLink;
                clickableElement = Link;
            }
        }

        return (
            <ClickableText className={this.props.className}
                           style={this.props.style}
                           ref={this.props.passRef}
                           tabIndex={this.props.tabIndex}
                           as={clickableElement}
                           aria-disabled={this.props.isDisabled}
                           onClick={this.handleClick}
                           onContextMenu={this.handleContextMenu}
                           onKeyDown={this.handleKeyDown}
                           onMouseEnter={this.props.onMouseEnter}
                           onMouseLeave={this.props.onMouseLeave}
                           onFocus={this.props.onFocus}
                           onBlur={this.props.onBlur}
                           $hasLinkStyle={isLink}
                           $disabled={isDisabled}
                           $isLight={this.props.isLight}
                           $isWithoutStyle={this.props.isWithoutStyle}
                           data-testid={this.props.testId ?? TestIds.ClickableText}
                           {...optProps}>
                {this.props.children}
            </ClickableText>
        );
    }
}

export default Clickable;