import React from "react";
import {
    DateLabelButton,
    DayLabels,
    Header,
    HeaderPart,
    HeaderTextButton,
    HeaderTextLabel,
    HeaderTextWrapper,
    HeaderVerticalSplit,
    MonthsGrid,
    SpecialValue,
    StyledCalendar,
    StyledDatePickerPopup,
    TimePickerCalendarStyled,
    WeekDayLabel,
    Weeks,
    YearsGrid
} from "./Calendar.styles";
import { Dayjs } from "dayjs";
import { DatePickerView, TextAlign } from "../../../../enums";
import { getWeekArray, getWeekdays, isCurrentDay, isOutsideMonth, isSameDay } from "../utils";
import { WithTranslation, withTranslation } from "react-i18next";
import TestIds from "../../../../testIds";
import { EmptyObject } from "../../../../global.types";
import { handleRefHandlers } from "@utils/general";
import FocusManager, {
    FOCUSABLE_DISABLED_ITEM_ATTR,
    FocusDirection,
    IFocusableItemProps,
    TFocusManagerFocus
} from "../../../focusManager/FocusManager";
import DateType, { DATE_MAX, DATE_MIN, DateFormat, getUtcDate, getUtcDayjs } from "../../../../types/Date";
import { StyledDay } from "./Day.styles";
import { capitalize } from "@utils/string";
import {
    BottomButtons,
    CaretIconButton,
    DateRangeSpecialValue,
    IDatePopupSharedProps,
    MONTH_COLS,
    MONTH_ROWS,
    NUM_WEEKS,
    setNextMonth,
    setNextYear,
    setPreviousMonth,
    setPreviousYear,
    TDisabledDateUnit,
    WEEK_LENGTH,
    YEAR_COLS,
    YEAR_ROWS
} from "./Calendar.utils";
import { Day } from "./Day";
import { IInputOnChangeEvent } from "../../input";
import { INFINITY } from "../../../../constants";
import memoize from "../../../../utils/memoize";

interface IProps extends Pick<IDatePopupSharedProps, "minDate" | "maxDate" | "isDateDisabled"> {
    // date that changes what is currently shown in calendar.
    previewDay: Dayjs;
    // actually selected value - used for TimePicker, and selected days
    // DATE_MIN/DATE_MAX can be used as special values with DateRangeSpecialValue.WithoutEnd/WithoutStart meaning
    value: Dayjs;
    view: DatePickerView;
    // This view triggers onChange when one of its value is selected,
    // other views redirects to this view when their value is selected.
    mainView: DatePickerView;
    getNextView?: (currentView: DatePickerView) => DatePickerView;
    // called on value (not previewDay) change
    // isTimeChange is set to true if the change is triggered by TimePicker
    onChange: (date: Dayjs, isTimeChange: boolean) => void;
    disableViewChange?: boolean;
    showSpecialValue?: DateRangeSpecialValue;
    renderDay?: (args: IRenderDayArgs) => React.ReactElement;
    renderBottom?: () => React.ReactElement;
    onPreviewChange?: (day: Dayjs) => void;
    onViewChange?: (view: DatePickerView) => void;
    hideBackArrow?: boolean;
    hideNextArrow?: boolean;
    showTime?: boolean;
    showConfirmButtons?: boolean;
    disabledOk?: boolean;
    onOk?: () => void;
    onCancel?: () => void;
    embedded?: boolean;
    // otherwise doesn't work for typescript, because of withTranslation HOC
    ref?: React.RefObject<React.ReactElement>;
    disableAutoFocus?: boolean;
}

export interface IRenderDayArgs {
    day: Dayjs;
    index: number;
    key: number;
    onClick: (day: Dayjs) => void;
    isDisabled: boolean;
    isSelected: boolean;
    isCurrent: boolean;
    isOutsideMonth: boolean;
}

interface IExtendedProps extends IProps, WithTranslation {
}

// cannot be pure component, because it needs to be re-rendered to call renderDay on parent on selected day change
class Calendar extends React.Component<IExtendedProps> {
    _selectedDateIndex: number;
    _previewDateIndex: number;
    _preventNextAutoFocus: boolean;
    _weeksRef = React.createRef<HTMLDivElement>();

    _focusManagerFocus: TFocusManagerFocus;

    static defaultProps: Partial<IExtendedProps> = {
        // Dayjs doesn't parse years smaller than 1000 properly, use 1000 as min date instead of DATE_MIN const
        // https://github.com/iamkun/dayjs/issues/1237
        minDate: getUtcDate("1000-01-01"),
        // subtract 1 day so that we can use DATE_MAX as constant for WithoutEnd
        maxDate: getUtcDayjs(DATE_MAX).subtract(1, "day").toDate()
    };

    componentDidMount(): void {
        if (!this.props.disableAutoFocus) {
            setTimeout(() => this.focus(), 0);
        }
    }

    componentDidUpdate(prevProps: IExtendedProps, prevState: EmptyObject): void {
        if (!this._preventNextAutoFocus && prevProps.view !== this.props.view) {
            this.focus();
        }

        if (this._preventNextAutoFocus) {
            this._preventNextAutoFocus = false;
        }
    }

    get previewDay(): Dayjs {
        return this.props.previewDay ?? getUtcDayjs();
    }

    isDateSelected = (date: Dayjs): boolean => {
        if (!this.props.value) {
            return false;
        }

        return DateType.isSameDay(this.props.value, date);
    };

    isMonthSelected = (date: Dayjs): boolean => {
        if (!this.props.value) {
            return false;
        }

        return getUtcDayjs(this.props.value).isSame(date, "month");
    };

    isYearSelected = (date: Dayjs): boolean => {
        if (!this.props.value) {
            return false;
        }

        return getUtcDayjs(this.props.value).isSame(date, "year");
    };

    focus = () => {
        this.focusOnInitialDate();
    };

    isDisabledDate = (date: Dayjs, unit: TDisabledDateUnit): boolean => {
        if (!this.props.minDate && !this.props.maxDate && !this.props.isDateDisabled) {
            return false;
        }

        let isDisabled = false;

        if (this.props.minDate) {
            isDisabled = isDisabled || date.isBefore(this.props.minDate, unit);
        }

        if (this.props.maxDate) {
            isDisabled = isDisabled || date.isAfter(this.props.maxDate, unit);
        }

        if (this.props.isDateDisabled) {
            isDisabled = isDisabled || this.props.isDateDisabled(date.toDate(), unit);
        }

        return isDisabled;
    };

    updateDateWithSetTime = (day: Dayjs) => {
        if (this.props.showTime) {
            return day.set("hour", this.props.value.get("hour")).set("minute", this.props.value.get("minute"));
        }

        return day;
    };

    handleDayClick = (day: Dayjs): void => {
        this.props.onChange(this.updateDateWithSetTime(day), false);
    };

    renderWeeks = (weeks: Dayjs[][], itemProps: IFocusableItemProps) => {
        this._selectedDateIndex = null;
        this._previewDateIndex = null;

        return weeks.map((week: Dayjs[], i: number) => {
            return (
                <DayLabels key={i}>
                    {week.map((day: Dayjs, j: number) => {
                        if (!day) {
                            return (
                                <StyledDay key={j}>
                                    {/*fake day just so that FocusManager grid works correctly*/}
                                    <div {...itemProps}
                                         {...{ [FOCUSABLE_DISABLED_ITEM_ATTR]: true }}/>
                                </StyledDay>
                            );
                        }

                        const dayIndex = i * WEEK_LENGTH + j;
                        const dayArgs: IRenderDayArgs = {
                            day,
                            index: j,
                            key: j,
                            onClick: this.handleDayClick,
                            isDisabled: this.isDisabledDate(day, "day"),
                            isCurrent: isCurrentDay(day),
                            isSelected: this.isDateSelected(day),
                            isOutsideMonth: isOutsideMonth(day, this.props.previewDay)
                        };
                        let renderedDay = this.props.renderDay ? this.props.renderDay(dayArgs) :
                            <Day {...dayArgs}/>;

                        // enrich renderedDay with FocusManager itemProps
                        renderedDay = React.cloneElement(renderedDay, { passProps: itemProps });

                        if (!this._selectedDateIndex && renderedDay.props.isSelected) {
                            this._selectedDateIndex = dayIndex;
                        }

                        if (!this._previewDateIndex && isSameDay(renderedDay.props.day, this.props.previewDay)) {
                            this._previewDateIndex = dayIndex;
                        }

                        return renderedDay;
                    })}
                </DayLabels>
            );
        });
    };

    handleSpecialNullValueClick = (): void => {
        const value = this.props.showSpecialValue === DateRangeSpecialValue.WithoutEnd ? DATE_MAX : DATE_MIN;

        this.props.onChange(this.updateDateWithSetTime(getUtcDayjs(value)), false);
    };

    renderSpecialValue = (style: React.CSSProperties = {}): React.ReactElement => {
        if (!this.props.showSpecialValue) {
            return null;
        }

        const isSelected = DateType.isSameDay(this.props.showSpecialValue === DateRangeSpecialValue.WithoutEnd ? DATE_MAX : DATE_MIN, this.props.value);

        return (
            <SpecialValue onClick={this.handleSpecialNullValueClick}
                          $isSelected={isSelected}
                          style={style}>
                {INFINITY} {this.props.t(`Common:Time.${this.props.showSpecialValue === DateRangeSpecialValue.WithoutEnd ? "WithoutEnd" : "WithoutStart"}`)}
            </SpecialValue>
        );
    };

    getMonthLabel = (date: Dayjs): string => {
        return capitalize(DateType.format(date, DateFormat.month));
    };

    getYearLabel = (date: Dayjs): string => {
        return DateType.format(date, DateFormat.year);
    };

    setMonthYearView = () => {
        // !this.props.disableViewChange && this.props.onViewChange(DatePickerView.MonthYear);
    };

    setMonthView = (): void => {
        !this.props.disableViewChange && this.props.onViewChange(DatePickerView.Months);
    };

    setYearView = (): void => {
        !this.props.disableViewChange && this.props.onViewChange(DatePickerView.Years);
    };

    setDaysView = () => {
        this.props.onViewChange(DatePickerView.Days);
    };

    handleBackArrowClick = (): void => {
        switch (this.props.view) {
            case DatePickerView.Days:
                setPreviousMonth(this.previewDay, this.props.onPreviewChange);
                break;
            case DatePickerView.Months:
                setPreviousYear(this.previewDay, this.props.onPreviewChange);
                break;
            case DatePickerView.Years:
                setPreviousMonth(this.previewDay, this.props.onPreviewChange);
                break;
        }
    };

    handleNextArrowClick = (): void => {
        switch (this.props.view) {
            case DatePickerView.Days:
                setNextMonth(this.previewDay, this.props.onPreviewChange);
                break;
            case DatePickerView.Months:
                setNextYear(this.previewDay, this.props.onPreviewChange);
                break;
            case DatePickerView.Years:
                setNextMonth(this.previewDay, this.props.onPreviewChange);
                break;
        }
    };

    renderHeader = (content: React.ReactNode): React.ReactElement => {
        return (
            <Header>
                <HeaderPart alignment={TextAlign.Left} aboveContent>
                    {!this.props.hideBackArrow &&
                        <CaretIconButton title={this.props.t("Calendar.PreviousMonth")}
                                         testid={TestIds.DatePickerPrevMonth}
                                         onClick={this.handleBackArrowClick}
                                         style={{
                                             transform: "rotate(90deg)"
                                         }}/>
                    }
                </HeaderPart>
                <HeaderPart alignment={TextAlign.Center}>
                    {content}
                </HeaderPart>
                <HeaderPart alignment={TextAlign.Right} aboveContent>
                    {!this.props.hideNextArrow &&
                        <CaretIconButton title={this.props.t("Calendar.NextMonth")}
                                         testid={TestIds.DatePickerNextMonth}
                                         onClick={this.handleNextArrowClick}
                                         style={{
                                             transform: "rotate(-90deg)"
                                         }}/>
                    }
                </HeaderPart>
            </Header>
        );
    };

    handleTimePickerChange = (event: IInputOnChangeEvent<Date>) => {
        this.props.onChange?.(getUtcDayjs(event.value), true);
    };

    renderHeaderButton = (label: string, testId: string, alignment: TextAlign, onClick: () => void): React.ReactElement => {
        if (this.props.disableViewChange) {
            return (
                <HeaderTextLabel data-testid={testId}>
                    {label}
                </HeaderTextLabel>
            );
        }

        return (
            <HeaderTextButton data-testid={testId}
                              alignment={alignment}
                              onClick={onClick}>
                {label}
            </HeaderTextButton>
        );
    };

    renderDaysView = (): React.ReactElement => {
        const previewDay = this.previewDay;
        const weeks = getWeekArray(previewDay);

        while (weeks.length < NUM_WEEKS) {
            // insert 7 null for week length,
            // we want to keep the grid with correct number of days for FocusManager to work correctly
            weeks.push([null, null, null, null, null, null, null]);
        }

        // const header = DateType.format(previewDay, DateFormat.monthAndYear);
        const month = this.getMonthLabel(previewDay);
        const year = this.getYearLabel(previewDay);
        const withSplitter = !this.props.disableViewChange;

        return (
            <>
                {this.renderHeader(
                    <HeaderTextWrapper>
                        {this.renderHeaderButton(month, TestIds.DatePickerMonthLabel, TextAlign.Left, this.setMonthView)}
                        {withSplitter &&
                            <HeaderVerticalSplit></HeaderVerticalSplit>
                        }
                        {this.renderHeaderButton(year, TestIds.DatePickerYearLabel, TextAlign.Right, this.setYearView)}
                    </HeaderTextWrapper>
                )}

                <DayLabels hasTopMargin={true}>
                    {getWeekdays().map((weekDay: string) => {
                        return (
                            <WeekDayLabel key={weekDay}>{weekDay}</WeekDayLabel>
                        );
                    })}
                </DayLabels>
                <FocusManager onFocusChange={this.handleDayFocusChange}
                              direction={FocusDirection.Grid}
                              columnsCount={WEEK_LENGTH}>
                    {({ itemProps, wrapperProps, focus }) => {
                        this._focusManagerFocus = focus;

                        return (
                            <Weeks {...wrapperProps}
                                   data-testid={TestIds.DatePickerWeeks}
                                   ref={(ref: HTMLDivElement) => {
                                       this.handleWeekRef(ref, wrapperProps.ref);
                                   }}>
                                {this.renderWeeks(weeks, itemProps)}
                                {this.renderSpecialValue()}
                            </Weeks>
                        );
                    }}
                </FocusManager>
                {this.props.showTime &&
                    <TimePickerCalendarStyled value={this.props.value?.toDate()}
                                              onChange={this.handleTimePickerChange}/>
                }
            </>
        );
    };

    getHandleMonthClick = memoize((date: Dayjs) => {
        return () => {
            if (this.props.mainView === DatePickerView.Months) {
                this.props.onChange(this.updateDateWithSetTime(date), false);
            } else {
                this.props.onPreviewChange(this.previewDay.month(date.month()));
                this.props.onViewChange(this.props.mainView);
            }
        };
    }, (date) => date.toString());

    renderMonth = (date: Dayjs, index: number, itemProps: IFocusableItemProps): React.ReactNode => {
        const month = this.getMonthLabel(date);
        const isDisabled = this.isDisabledDate(date, "month");
        const isSelected = this.isMonthSelected(date);

        if (!this._previewDateIndex && isSelected) {
            this._previewDateIndex = index;
        }

        return (
            <DateLabelButton key={date.toString()}
                             $isSelected={isSelected}
                             $isDisabled={isDisabled}
                             disabled={isDisabled}
                             onClick={this.getHandleMonthClick(date)}
                             {...itemProps}>
                <span>
                    {month}
                </span>
            </DateLabelButton>
        );
    };

    renderMonthsView = (): React.ReactElement => {
        this._selectedDateIndex = null;
        this._previewDateIndex = null;

        const year = DateType.format(this.previewDay, DateFormat.year);
        const startOfYear = getUtcDayjs(this.previewDay).startOf("year");
        const months: React.ReactNode[] = [];

        return (
            <>
                {this.renderHeader((
                    <HeaderTextButton disabled={this.props.mainView === DatePickerView.Days}
                                      onClick={this.setYearView}>
                        {year}
                    </HeaderTextButton>
                ))}
                <FocusManager direction={FocusDirection.Grid}
                              columnsCount={MONTH_COLS}>
                    {({ itemProps, wrapperProps, focus }) => {
                        this._focusManagerFocus = focus;

                        for (let i = 0; i < MONTH_COLS; i++) {
                            for (let j = 0; j < MONTH_ROWS; j++) {
                                const index = i * MONTH_ROWS + j;
                                const month = startOfYear.add(index, "month");

                                months.push(this.renderMonth(month, index, itemProps));
                            }
                        }

                        return (
                            <MonthsGrid {...wrapperProps}
                                        ref={wrapperProps.ref as React.RefObject<HTMLDivElement>}
                                        data-testid={TestIds.MonthsGrid}>
                                {months}
                                {this.renderSpecialValue({
                                    right: "-9px",
                                    bottom: "-30px"
                                })}
                            </MonthsGrid>
                        );
                    }}
                </FocusManager>
            </>
        );
    };

    getHandleYearClick = memoize((date: Dayjs) => {
        return () => {
            this.props.onPreviewChange(this.previewDay.year(date.year()));
            this.props.onViewChange(this.props.mainView);
        };
    }, (date) => date.toString());

    renderYear = (date: Dayjs, index: number, itemProps: IFocusableItemProps): React.ReactNode => {
        const year = this.getYearLabel(date);
        const isDisabled = this.isDisabledDate(date, "year");
        const isSelected = this.isYearSelected(date);

        if (!this._previewDateIndex && isSelected) {
            this._previewDateIndex = index;
        }

        return (
            <DateLabelButton key={date.toString()}
                             $isSelected={isSelected}
                             $isDisabled={isDisabled}
                             disabled={isDisabled}
                             onClick={this.getHandleYearClick(date)}
                             {...itemProps}>
                <span>
                    {year}
                </span>
            </DateLabelButton>
        );
    };


    renderYearsView = (): React.ReactElement => {
        this._selectedDateIndex = null;
        this._previewDateIndex = null;

        const month = this.getMonthLabel(this.previewDay);
        const startYear = getUtcDayjs(this.previewDay).year(this.previewDay.year() - 7);
        const years: React.ReactNode[] = [];

        return (
            <>
                {this.renderHeader((
                    <HeaderTextButton disabled={this.props.mainView === DatePickerView.Days}
                                      onClick={this.setMonthView}>
                        {month}
                    </HeaderTextButton>
                ))}
                <FocusManager direction={FocusDirection.Grid}
                              columnsCount={YEAR_COLS}>
                    {({ itemProps, wrapperProps, focus }) => {
                        this._focusManagerFocus = focus;

                        for (let i = 0; i < YEAR_COLS; i++) {
                            for (let j = 0; j < YEAR_ROWS; j++) {
                                const index = i * YEAR_ROWS + j;
                                const year = startYear.add(index, "year");

                                years.push(this.renderYear(year, index, itemProps));
                            }
                        }

                        return (
                            <YearsGrid {...wrapperProps}
                                       ref={wrapperProps.ref as React.RefObject<HTMLDivElement>}
                                       data-testid={TestIds.YearsGrid}>
                                {years}
                            </YearsGrid>
                        );
                    }}
                </FocusManager>
            </>
        );
    };

    handleWeekRef = (ref: HTMLDivElement, wrapperRef: React.RefObject<HTMLElement>) => {
        handleRefHandlers(ref, wrapperRef, this._weeksRef);
    };

    handleDayFocusChange = (cleanIndex: number, index: number): boolean => {
        let isChanging = false;

        if (index >= WEEK_LENGTH * NUM_WEEKS) {
            setNextMonth(this.previewDay, this.props.onPreviewChange);
            isChanging = true;
        } else if (index < 0) {
            setPreviousMonth(this.previewDay, this.props.onPreviewChange);
            isChanging = true;
        }

        if (isChanging) {
            this._preventNextAutoFocus = true;
        }

        return isChanging;
    };

    focusOnInitialDate = () => {
        this._focusManagerFocus(this._selectedDateIndex || this._previewDateIndex || 0);
    };

    render = () => {
        let view;

        switch (this.props.view) {
            case DatePickerView.Days:
                view = this.renderDaysView();
                break;
            case DatePickerView.Months:
                view = this.renderMonthsView();
                break;
            case DatePickerView.Years:
                view = this.renderYearsView();
                break;
        }

        return (
            <StyledDatePickerPopup embedded={this.props.embedded}
                                   data-testid={TestIds.DatePickerPopup}
                // for HideOnBlur to work
                                   tabIndex={-1}>
                <StyledCalendar data-testid={TestIds.DatePickerCalendar}>
                    {view}
                    {this.props.showConfirmButtons &&
                        <BottomButtons onOk={this.props.onOk}
                                       onCancel={this.props.onCancel}
                                       disabledOk={this.props.disabledOk}
                        />
                    }
                    {this.props.renderBottom ? this.props.renderBottom() : null}
                </StyledCalendar>
            </StyledDatePickerPopup>
        );
    };
}

const CalendarWithTranslation = withTranslation(["Components"], { withRef: true })(Calendar);
export { CalendarWithTranslation as Calendar };