import React, { useCallback, useRef, useState } from "react";
import { getIcon } from "../icon";
import { IconSize } from "../../enums";
import { composeRefHandlers } from "@utils/general";
import useHideOnBlur from "../../hooks/HideOnBlurHook";
import { BUTTON_POPPER_TOOLTIP_OFFSET_Y, IconButton } from "../button";
import PopperTooltip from "../popperTooltip/PopperTooltip";

export interface ITooltipIconProps {
    /** Render in the current DOM instead of React.Portal to support different cases  */
    inPlace?: boolean;
    offsetY?: number;
    size?: string;
    className?: string;
    style?: React.CSSProperties;
}

interface IProps extends ITooltipIconProps {
    iconName: string;
    onClick?: () => void;
    onKeyDown?: (args: React.KeyboardEvent) => void;
    cursor?: string;
    title?: string;
    autoHide?: boolean;
}

const TooltipIcon = React.memo(({ size = IconSize.S, ...props }: React.PropsWithChildren<IProps>) => {
    const tooltipRef = useRef(null);
    const referenceElement = useRef(null);
    const [useFade, setUseFade] = useState(false);
    const { isVisible, setIsVisible } = useHideOnBlur({
        refs: [tooltipRef, referenceElement],
        initialIsVisible: false,
        onHide: () => {
            if (useFade) {
                setUseFade(false);
            }
        }
    });

    const hideTooltipFn = () => {
        setIsVisible(false);
        if (useFade) {
            setUseFade(false);
        }
    };

    const handleClick = useCallback((e) => {
        const nextIsVisible = !isVisible;

        if (nextIsVisible && props.autoHide) {
            setUseFade(true);
        }

        props.onClick?.();
        e.stopPropagation();
        e.preventDefault();

        if (nextIsVisible) {
            setIsVisible(true);
        } else {
            hideTooltipFn();
        }
    }, [isVisible]);

    const handleMouseDown = useCallback((e) => {
        // used to disable text selection on mouse click
        // but this prevents the button from getting focus, and we need that for HideOnBlur to work.
        // find another way to prevent text selection if really needed
        // e.preventDefault();
    }, []);


    const Icon = getIcon(props.iconName);
    const cursor = props.cursor;

    return (
        <PopperTooltip content={props.children}
                       passRef={tooltipRef}
                       inPlace={props.inPlace}
                       useFade={useFade}
                       offsetY={props.offsetY ?? BUTTON_POPPER_TOOLTIP_OFFSET_Y}
                       onAnimationEnd={hideTooltipFn}
                       isHidden={!isVisible}>
            {(ref) => {
                return (
                    <IconButton onClick={handleClick}
                                onMouseDown={handleMouseDown}
                                onKeyDown={props.onKeyDown}
                                className={props.className}
                                style={props.style}
                                cursor={cursor}
                                isDecorative
                                title={props.title}
                                passRef={composeRefHandlers(ref, referenceElement)}>
                        <Icon isLightHover width={size} height={size}/>
                    </IconButton>
                );
            }}
        </PopperTooltip>
    );
});

export default TooltipIcon;