import React from "react";
import autosize from "autosize";

import { StyledInput, StyledTextArea, Wrapper } from "./TextArea.styles";
import { IInputOnChangeEvent, ISharedInputProps } from "../input";
import { renderReadOnlyText } from "../utils";
import { composeRefHandlers } from "@utils/general";
import { WithErrorAndTooltip, withErrorAndTooltip } from "../formError/WithErrorAndTooltip";
import TestIds from "../../../testIds";

interface TextAreaProps extends ISharedInputProps, WithErrorAndTooltip {
    value: string;
    maxRows?: number;
    minRows?: number;
    passRef?: React.Ref<HTMLTextAreaElement>;
    outerRef?: React.RefObject<HTMLDivElement>;
    onChange: (e: IInputOnChangeEvent<string>) => void;
    onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
    onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
    onKeyDown?: (event: React.KeyboardEvent) => void;
    className?: string;
}

class TextArea extends React.PureComponent<TextAreaProps> {
    textAreaRef = React.createRef<HTMLTextAreaElement>();
    _autoSizedValue: string; // value autosize has been calculated according to

    componentDidMount() {
        if (!this.props.isReadOnly) {
            autosize(this.textAreaRef.current);
            this._autoSizedValue = this.getValue();
        }
    }

    componentDidUpdate(prevProps: Readonly<TextAreaProps>) {
        if (!this.props.isReadOnly && this.getValue() !== this._autoSizedValue) {
            autosize.update(this.textAreaRef.current);
            this._autoSizedValue = this.getValue();
        }
    }

    getValue = () => {
        // return empty string if null given, otherwise textarea has problem differentiate if its controlled or uncontrolled
        return this.props.value ?? "";
    };

    handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this._autoSizedValue = e.target.value;
        this.props?.onChange({
            value: e.target.value,
            origEvent: e
        });
    };

    renderTextArea(): React.ReactNode {
        const isSingleLine = this.props.maxRows === 1;
        const Component: any = isSingleLine ? StyledInput : StyledTextArea;

        return (
            <Wrapper ref={this.props.outerRef} className={this.props.className} data-testid={TestIds.Input}>
                {this.props.errorAndTooltip}
                <Component
                    disabled={this.props.isDisabled}
                    maxRows={this.props.maxRows}
                    ref={composeRefHandlers(this.textAreaRef, this.props.passRef)}
                    placeholder={this.props.placeholder}
                    value={this.getValue()}
                    name={this.props.name}
                    rows={this.props.minRows}
                    onFocus={this.props.onFocus}
                    onBlur={this.props.onBlur}
                    autoComplete={"off"}
                    onKeyDown={this.props.onKeyDown}
                    onChange={this.handleChange}/>
            </Wrapper>
        );
    }

    render(): React.ReactNode {
        return this.props.isReadOnly && !this.props.auditTrailData?.type ? renderReadOnlyText(this) : this.renderTextArea();
    }
}

export { TextArea as PureTextArea };
export default withErrorAndTooltip(TextArea, { preventValueTooltip: true });