/**
 * Returns wrapped fetch function that only allows one fetch to be called at a time.
 * If another fetch is called during the previous is still pending, the previous request is canceled and 'AbortError' exception is thrown for it.
 * Use one fetch with try catch block and handle 'AbortError'.
 * @returns {function(...[*]=)}
 */
import customFetch from "./customFetch";

export type TFetchFn = (url: string, options?: RequestInit) => Promise<Response>;

export interface IOneFetch {
    fetch: TFetchFn;
    abort: () => void;
    isFetching: () => boolean;
}

const getOneFetch = (): IOneFetch => {
    let abortController: AbortController;

    return {
        fetch: async (url: string, options?: RequestInit): Promise<Response> => {
            if (abortController) {
                abortController.abort();
            }

            abortController = new AbortController();

            const res = await customFetch(url, {
                ...options,
                signal: abortController.signal
            });

            abortController = null;

            return res;
        },
        abort() {
            abortController?.abort();
        },
        isFetching() {
            return !!abortController;
        }
    };
};

export { getOneFetch };