import styled from "styled-components/macro";
import { VIEW_PADDING_VALUE } from "../../views/View.styles";
import { SmartHeaderStyled } from "../../views/formView/FormView.styles";
import { Header } from "@components/formGroup";
import {
    getSemanticTextColor,
    LayoutStackOrder,
    LocalStackOrder,
    T_HEADER_bold,
    T_PLAIN_big_hig,
    T_PLAIN_tiny,
    T_TITLE_1,
    T_TITLE_3_normal
} from "../../global.style";
import Checkbox from "../../components/inputs/checkbox";
import { StyledCheckbox } from "@components/inputs/checkbox/Checkbox.styles";
import { ellipsis } from "polished";
import { TilePadding } from "@components/dashboard/Dashboard.styles";
import { Status } from "../../enums";
import { Button } from "../../components/button";
import { StyledSeparator } from "@components/separator/Separator.styles";

export const StyledHome = styled.div<{ $inEditMode: boolean }>`
    position: relative;
    height: 0;
    flex: 1 1 auto;
    /* Over bottom wave illustration */
    z-index: ${props => props.$inEditMode ? LayoutStackOrder.DashboardConfiguration : LocalStackOrder.Tooltips};
    margin-top: 70px;
`;

export const StyledHomeBackground = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.C_BG_menu};

    display: flex;
    flex-direction: column;
`;

export const HomeSmartHeaderStyled = styled(SmartHeaderStyled)`
    padding: ${VIEW_PADDING_VALUE}px ${VIEW_PADDING_VALUE}px 0;
`;

export const InnerWrapper = styled.div`
    max-height: 100%;
    overflow: auto;
`;

export const UserResearchWrapper = styled.div`
    width: 100%;
    max-width: 500px;
    min-width: 300px;
    margin: 0 auto 0;
`;

export const HomeDashboardHeaderStyled = styled(Header)`
    ${T_HEADER_bold};
    margin: 30px 0 20px;

    &:first-child {
        // there is additional padding in the wrapper, which is needed to keep shadow of tiles 
        // visible if there is no header
        margin-top: ${30 - TilePadding}px;
    }
`;

export const HomeEditButtonFooter = styled.div`
    padding: ${VIEW_PADDING_VALUE}px;
    text-align: right;
    z-index: ${LayoutStackOrder.DashboardConfiguration};
`;

export const CloseButton = styled(Button) `
    margin-right: 14px;
`;

export const ConfigurationHeader = styled.h3`
    ${T_TITLE_1};
    color: ${props => props.theme.C_TEXT_dialogue};
`;

export const ConfigurationCheckboxStyled = styled(Checkbox)`
    height: 70px;
    padding: 0 16px 0 67px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.2);
    margin-bottom: 12px;
    color: ${props => props.theme.C_TEXT_dialogue};
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;

    &:hover {
        background-color: ${props => props.theme.C_ACT_hover}
    }

    ${StyledCheckbox} {
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translate(0, -50%);
    }

    &:last-child {
        margin-bottom: 0;
    }
`;

export const StyledTitle = styled.div`
    ${T_TITLE_3_normal};
    ${ellipsis()};
`;

export const StyledSubtitle = styled.div`
    ${T_PLAIN_tiny};
    ${ellipsis()};
    margin-top: 4px;
`;

export const ConfigurationSectionHeaderStyled = styled(Header)`
    ${T_PLAIN_big_hig};
    margin: 30px 0 20px;
    color: ${props => props.theme.C_TEXT_dialogue};
    text-transform: uppercase;

    ${StyledSeparator} {
        margin-right: 0;
    }
`;

export const SemanticText = styled.span<{ severity: Status; isBold?: boolean; }>`
    color: ${props => getSemanticTextColor(props.theme, props.severity)};
    font-weight: ${props => props.isBold ? "bold" : "normal"};
`;

export const PercentageWrapper = styled.span`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const CloseCustomerPortalButtonStyled = styled(Button)`
    position: absolute;
    right: ${VIEW_PADDING_VALUE}px;
    top: ${VIEW_PADDING_VALUE}px;
`;