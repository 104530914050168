import styled, { css } from "styled-components/macro";
import {
    getDisabledStyle,
    getFocusBorderDefinition,
    getSemanticElColor,
    LocalStackOrder,
    T_PLAIN_big
} from "../../../global.style";
import { Status, TextAlign } from "../../../enums";
import { transparentize } from "polished";
import { AuditTrailFieldType } from "../../smart/FieldInfo";
import { Circle, StyledError } from "../formError/FormError.styles";
import TestIds from "../../../testIds";
import { StyledBusyIndicator, StyledBusyIndicatorWrapper } from "../../busyIndicator/BusyIndicator.styles";
import { StyledStepper } from "../numericValueInputBase/Stepper.styles";

export interface ISharpBorders {
    isSharpLeft?: boolean;
    isSharpRight?: boolean;
}

export const InputBorderRadiusLeft = css<ISharpBorders>`
    border-top-left-radius: ${props => props.isSharpLeft ? props.theme.borderRadius : props.theme.borderLargeRadius};
    border-bottom-left-radius: ${props => props.isSharpLeft ? props.theme.borderRadius : props.theme.borderLargeRadius};
`;

export const InputBorderRadiusRight = css<ISharpBorders>`
    border-top-right-radius: ${props => props.isSharpRight ? props.theme.borderRadius : props.theme.borderLargeRadius};
    border-bottom-right-radius: ${props => props.isSharpRight ? props.theme.borderRadius : props.theme.borderLargeRadius};
`;

export const InputBorderRadius = css`
    ${InputBorderRadiusLeft};
    ${InputBorderRadiusRight};
`;

export const StyledContent = styled.div`
    margin-left: 12px;
    align-self: center;
    
    ${StyledStepper} {
        // position absolutely to prevent jumping when there is error in the field
        position: absolute;
        top: 0;
        right: 7px;
    }
`;

export const StyledValueUnit = styled.div<{ hasErrorText: boolean }>`
    ${T_PLAIN_big};
    color: ${props => props.theme.C_ACT_thick_line};
    margin-top: ${props => props.hasErrorText ? "9px" : 0};
    height: ${props => props.hasErrorText ? "14px" : "100%"};
    white-space: nowrap;
`;

interface IStyledInput {
    showErrorText?: boolean;
    showError?: boolean;
    textAlign?: TextAlign;
    hasIcon?: boolean;
    _cursor?: string;
    _width?: string;
    isDisabled?: boolean;
    auditTrailType?: AuditTrailFieldType;
}

const StyledInputCss = css<IStyledInput>`
    border: none;
    outline: none;
        // margin-right: ${props => props.hasIcon ? "42px" : (props.textAlign === TextAlign.Right ? "0" : "8px")};
    text-align: ${props => props.textAlign || TextAlign.Left};
    width: 100%;
    padding: 0;
    background-color: ${props => props.theme.C_FIELD_fill};
    caret-color: ${({ theme }) => theme.C_TEXT_primary};
    border-radius: ${({ theme }) => theme.borderRadius};
    height: ${props => props.showErrorText ? "14px" : "100%"};
    cursor: ${props => props._cursor || "auto"};

    /* Chrome, Safari, Edge, Opera */

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* background-color transition delays default chrome style so we can use transparent background for autofill */

    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 1000px ${props => transparentize(0.5, props.theme.C_BTN_4L_trans_bg_selected)} inset !important;
        -webkit-transition: background-color 9999s ease-out;
        -webkit-text-fill-color: ${props => props.theme.C_TEXT_primary};
    }

    &[type="search"]::-webkit-search-decoration,
    &[type="search"]::-webkit-search-cancel-button,
    &[type="search"]::-webkit-search-results-button,
    &[type="search"]::-webkit-search-results-decoration {
        display: none;
    }

    -moz-appearance: textfield;

    &::placeholder {
        font-style: italic;
        color: ${props => props.theme.C_ACT_thick_line};
    }
`;

export const AuditTrailInputCss = css<IStyledInput>`
    ${props => (props.auditTrailType === AuditTrailFieldType.NoDifference || props.auditTrailType === AuditTrailFieldType.HoveredNoDifference) && {
        opacity: 0,
        background: props.theme.C_AUDIT_TRAIL_NO_DIFF
    }}

    ${props => (props.auditTrailType === AuditTrailFieldType.HoveredNoDifference) && {
        opacity: props.theme.C_AUDIT_TRAIL_DIFF_VISIBLE_OPACITY
    }}
`;


interface IStyledTextArea extends IStyledInput {
    maxRows: number;
}

const INPUT_FONT_SIZE = 14;


interface IStyledInputParts {
    isSharpLeft: boolean;
    isSharpRight: boolean;
    isActive?: boolean;
    hasIcon?: boolean;
    cursor?: string;
    isDisabled?: boolean;
}

export const StyledTextArea = styled.textarea.attrs(() => ({
    rows: 1
}))<IStyledTextArea>`
    ${StyledInputCss};

    margin-top: ${props => props.showErrorText ? "5px" : 0};

    // "em" resulted in rounding errors, using exact pixels instead    
        // max-height: ${props => `${(props.maxRows * 1.2).toFixed(1)}px`};
    max-height: ${props => `${Math.ceil(props.maxRows * 1.2 * INPUT_FONT_SIZE)}px`};

    ${AuditTrailInputCss};
    resize: none;
`;

export const InputBorder = css<IStyledInputParts>`
    border-color: ${props => props.isActive ?
            "transparent" :
            props.isDisabled ? transparentize(0.5, props.theme.C_ACT_der) : props.theme.C_ACT_main
    };
`;

export const InputStatusCommonCSS = css`
    height: 100%;
    top: 0;
    position: absolute;
    border-radius: 2px;
    width: 2px;
`;

export interface IStatus {
    status: Status;
}

export const InputStatusStraight = styled.div<IStatus & {
    left?: string;
}>`
    ${InputStatusCommonCSS};
    left: ${props => props.left ?? 0};
    background: ${props => getSemanticElColor(props.theme, props.status)};
`;

const borderCircle = css<IStatus & { isSharpLeft: boolean }>`
    content: "";
    width: 2px;
    height: 2px;
    border-radius: 2px;
    background: ${props => getSemanticElColor(props.theme, props.status)};
    position: absolute;
    left: ${props => props.isSharpLeft ? 0 : "14px"};
`;

export const InputStatus = styled.div<IStatus & {
    isSharpLeft: boolean;
}>`
    ${InputStatusCommonCSS};
    width: ${props => props.isSharpLeft ? props.theme.borderRadius : props.theme.borderLargeRadius};
    left: 0;
    border: 2px solid ${props => getSemanticElColor(props.theme, props.status)};
    border-right: none;
    ${InputBorderRadiusLeft};

    &:before {
        ${borderCircle};
        top: -2px;
    }

    &:after {
        ${borderCircle};
        bottom: -2px;
    }
`;

export const IconWrapper = styled.div <{
    isSharpRight?: boolean;
    isActive?: boolean;
}>`
    position: absolute;
    cursor: pointer;
    right: 0;
    top: 0;
    height: 100%;
    width: 34px;
    padding-left: 3px;
    ${InputBorderRadiusRight};
    background-color: ${props => props.isActive ? props.theme.C_ACT_hover_without_opacity : ""};
`;

export const StyledInput = styled.div<ISharpBorders & {
    _width: string;
    _height: string;
    _cursor?: string;
    _hasIcon?: boolean;
    _showErrorText?: boolean;
    isDisabled?: boolean;
    isMultiLine?: boolean;
    auditTrailType?: AuditTrailFieldType;
    isSynchronized?: boolean;
}>`
    position: relative;
    width: ${props => props._width};
    flex: 0 0 auto;
    align-items: center;
    min-height: 34px;
    height: ${props => props._height || "fit-content"};
    display: flex;
    background: ${props => props.auditTrailType === AuditTrailFieldType.NoDifference || props.auditTrailType === AuditTrailFieldType.HoveredNoDifference ?
        props.theme.C_AUDIT_TRAIL_NO_DIFF : props.theme.C_FIELD_fill};
    border: ${props => (props.auditTrailType === AuditTrailFieldType.NoDifference || props.auditTrailType === AuditTrailFieldType.HoveredNoDifference) ?
        `1px solid ${props.theme.C_AUDIT_TRAIL_NO_DIFF}` : `1px solid ${props.theme.C_FIELD_line}`};
    cursor: ${props => props._cursor || ((props.isDisabled || props.isSynchronized) ? "default" : "text")};
    ${InputBorderRadius};
    ${props => getDisabledStyle(props.isDisabled)};

    ${props => (props.auditTrailType === AuditTrailFieldType.Difference || props.auditTrailType === AuditTrailFieldType.HoveredDifference) && {
    border: `3px solid ${props.theme.C_AUDIT_TRAIL_DIFF}`
}}
    ${props => props.auditTrailType === AuditTrailFieldType.NoDifference && `
        & svg {
            display: none !important
        };
        & ${StyledValueUnit} {
            opacity: 0;
        };     
    `}
    ${props => props.auditTrailType === AuditTrailFieldType.HoveredNoDifference && `
        & svg {
            opacity: ${props.theme.C_AUDIT_TRAIL_DIFF_VISIBLE_OPACITY}
        }
    `}
    ${StyledError} {
        width: ${props => props.isSharpRight ? "calc(100% - 14px)" : "calc(100% - 28px)"};
        top: -1px; // negates input border

        ${Circle} {
            transform: translate(-100%, -50%);
        }

        [data-testid="${TestIds.ErrorText}"] {
            width: ${props => !props._hasIcon ? "100%" : "calc(100% - 18px)"};
            text-align: left;
        }
    }

    ${StyledBusyIndicator} {
        background-color: transparent;
        justify-content: flex-end;
        padding-right: 3px;
    }

    ${StyledBusyIndicatorWrapper}[aria-hidden="true"] + ${IconWrapper} {
        display: none;
    }

`;

export const LinkWrapper = styled.div<{
    hasIcon: boolean;
    auditTrailType?: AuditTrailFieldType;
}>`
    padding-left: 13px; // 12 input padding + 1 for border 
    padding-top: 8px;
    position: absolute;
    width: ${props => props.hasIcon ? "calc(100% - 50px)" : "calc(100% - 15px)"};
    z-index: ${props => props.auditTrailType === AuditTrailFieldType.NoDifference ? null : LocalStackOrder.AboveContent};
    
    ${T_PLAIN_big};
`;

export const InputHoverContainer = styled.div <{
    isSharpRight: boolean;
    isSharpLeft: boolean;
    _hasIcon: boolean;
    _hasUnit?: boolean;
    isActive?: boolean;
    textAlign?: TextAlign;
    isSynchronized?: boolean;
}>`
    width: 100%;
    height: fit-content;
    padding: 5px ${props => props._hasIcon ? "40px" : "10px"} 5px 12px;
    overflow: hidden;
    ${T_PLAIN_big};

    input, textarea {
        padding-right: ${props => props._hasIcon || props._hasUnit || props.textAlign === TextAlign.Right ? "0" : "10px"};
        padding-left: ${props => props.textAlign === TextAlign.Right ? "8px" : "0"};
    }

    display: flex;
    align-items: flex-start;

    &:after {
        display: ${props => props.isActive ? "block" : "none"};
        content: '';
        position: absolute;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        border: 1px solid ${props => props.theme.C_ACT_main};
        left: 2px;
        top: 2px;
        ${InputBorderRadius};
        pointer-events: none;
    }

    &:hover:after,
    &:focus-within:after {
        display: block;
    }

    ${props => props.isSynchronized && getDisabledStyle(true)};
`;

export const StyledHtmlInput = styled.input <IStyledInput>`
    ${StyledInputCss};

    margin-top: ${props => props.showErrorText ? "8px" : 0};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: ${props => props._width};
    ${AuditTrailInputCss};
`;

export const IconDelimiter = styled.div`
    width: 2px;
    left: 0;
    top: 3px;
    height: calc(100% - 6px);
    background-color: ${props => props.theme.C_ACT_hover_without_opacity};
    display: none;
    position: absolute;

    ${IconWrapper}:hover & {
        display: block;
    }
`;

export const SyncSymbolDefaultLeft = -20;

export const FakeTabbingButton = styled.button`
    width: 0;
    height: 0;
    opacity: 0;
    &:focus + div:before {
        ${getFocusBorderDefinition({
            offset: -3,
            borderRadius: "50%"
        })};
        left: 1px;
        right: 5px;
    }
`;