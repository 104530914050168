import React, { useCallback, useRef, useState } from "react";
import { Omit, useTranslation } from "react-i18next";
import { ActionType, ISmartFastEntriesActionEvent } from "@components/smart/smartFastEntryList";
import {
    FormStorage,
    IValidateAndSave,
    TCustomResponseHandler,
    TShouldUseCustomResHandler
} from "../../../../views/formView/FormStorage";
import { getDeferredPlanForItem, REMOVE_TIME_RES_ACTION, TIME_RES_ACTION } from "./TimeResolution.utils";
import PlugAndPlayForm from "../../../../views/formView/PlugAndPlayForm";
import { getDefinition } from "./TimeResolutionDef";
import TimeResolutionDialogFormView from "./TimeResolutionDialogFormView";
import { Status } from "../../../../enums";
import { useConfirmationDialog } from "@components/dialog/withConfirmationDialog";
import CancelTimeResDialogFormView from "./CloseTimeResDialogFormView";
import { getDefinition as getCancelTimeResDefinition } from "./CloseTimeResDef";
import { EntitySetName, IDeferredPlanEntity, IDocumentEntity, IDocumentItemEntity } from "@odata/GeneratedEntityTypes";
import { getFiscalDataCorrespondingToDateAccountingTransaction, isReceived } from "../../Document.utils";
import { FiscalYearStatusCode } from "@odata/GeneratedEnums";
import { BatchRequest, IBatchResult } from "@odata/OData";
import { isEqual } from "lodash";
import { getUtcDayjs } from "../../../../types/Date";

import { ODataError } from "@odata/Data.types";

export interface WithTimeResolution {
    onLineItemsAction?: (args: ISmartFastEntriesActionEvent, storage: FormStorage) => Promise<boolean>;
    customResponseHandler: TCustomResponseHandler;
    shouldUseCustomResponseHandler: TShouldUseCustomResHandler;
}

interface WithTimeResolutionProps {
    onLineItemsAction?: (args: ISmartFastEntriesActionEvent, storage: FormStorage) => Promise<boolean>;
    customResponseHandler?: TCustomResponseHandler;
    shouldUseCustomResponseHandler?: TShouldUseCustomResHandler;
}

type TWithTimeResolution<P> = Omit<P, keyof WithTimeResolution> & WithTimeResolutionProps;

enum TimeResDialogType {
    New = "New",
    End = "End"
}

export const withTimeResolution = <P extends WithTimeResolution>(Component: React.ComponentType<P>): React.ComponentType<TWithTimeResolution<P>> => {
    return React.forwardRef((props: TWithTimeResolution<P>, ref) => {
        const { t, ready: tReady } = useTranslation(getDefinition.translationFiles);
        const documentStorage = useRef<FormStorage>(null);
        const confDialog = useConfirmationDialog();

        const [dialogType, setDialogType] = useState<TimeResDialogType>(null);
        const [initialData, setInitialData] = useState<Partial<IDeferredPlanEntity>>(null);

        const fnRemoveDeferredPlans = async (plansForRemoval: IDeferredPlanEntity[]) => {
            documentStorage.current.setBusy(true);
            documentStorage.current.refresh();

            const batch = documentStorage.current.oData.batch();
            const entityWrapper = batch.getEntitySetWrapper(EntitySetName.DeferredPlans);

            for (const deferredPlan of plansForRemoval) {
                entityWrapper.delete(deferredPlan.Id);
            }

            const result = await batch.execute();

            if (result[0]?.status === 200) {
                // docStorage.clearAndSetValueByPath("DeferredPlans", []);
                // docStorage.data.origEntity.DeferredPlans = [];
                // docStorage.refresh();
                // this way, SmartTable in deferred plans tab will get updated as well
                await documentStorage.current.reload({
                    preserveInfos: true
                });
                documentStorage.current.setFormAlert({
                    status: Status.Success,
                    title: t("Common:Validation.SuccessTitle"),
                    subTitle: t("TimeResolution:CloseForm.Removed")
                });
            } else {
                documentStorage.current.handleOdataError({
                    error: result[0].body as ODataError,
                    bindingContext: documentStorage.current.data.bindingContext
                });
                documentStorage.current.setBusy(false);
                documentStorage.current.refresh();
            }
        };

        const handleLineItemsAction = useCallback(async (args: ISmartFastEntriesActionEvent, docStorage: FormStorage) => {
            let shouldInterrupt = false;

            documentStorage.current = docStorage;

            if (args.actionType === ActionType.Custom && args.customActionType === TIME_RES_ACTION) {
                // we should work with originalData => find original items for the selected items
                const items = docStorage.data.origEntity.Items.filter((origItem: IDocumentItemEntity) => args.items.find((item: IDocumentItemEntity) => item.Id === origItem.Id));

                setInitialData({
                    OriginalDocument: docStorage.data.origEntity,
                    OriginalDocumentItems: items.map((item: IDocumentItemEntity) => ({
                        OriginalDocumentItem: item
                    })),
                    DateStart: docStorage.data.origEntity.DateAccountingTransaction,
                    DateEnd: getUtcDayjs(docStorage.data.origEntity.DateAccountingTransaction).add(1, "year").toDate()
                });
                setDialogType(TimeResDialogType.New);
                shouldInterrupt = true;
            } else if (args.actionType === ActionType.Custom && args.customActionType === REMOVE_TIME_RES_ACTION) {
                const deferredPlans = docStorage.data.entity.DeferredPlans;

                // it should be enough to check that DateStart of each plan
                // is in Active FiscalYear
                const arePlansRemovable = () => {
                    let plansRemovable = true;

                    for (const plan of deferredPlans as IDeferredPlanEntity[]) {
                        // it should be enough to check just first item
                        const fiscalYearData = getFiscalDataCorrespondingToDateAccountingTransaction(docStorage, plan.DateStart);

                        if (fiscalYearData?.fiscalYear && fiscalYearData.fiscalYear.StatusCode !== FiscalYearStatusCode.Active) {
                            plansRemovable = false;
                            break;
                        }
                    }

                    return plansRemovable;
                };

                if (arePlansRemovable()) {
                    handleTimeResRemove(docStorage);
                } else {
                    handleTimeResEnd(docStorage);
                }

                shouldInterrupt = true;
            } else if (props.onLineItemsAction) {
                if (args.actionType === ActionType.Remove) {
                    // if the line item has time resolution remove it
                    const [removedItem] = args.affectedItems as IDocumentItemEntity[]; // todo: should we handle here multiple items?
                    const deferredPlans = docStorage.data.entity.DeferredPlans as IDeferredPlanEntity[];
                    const plan = deferredPlans?.find(plan => plan.OriginalDocumentItems.find(item => item.OriginalDocumentItem.Id === removedItem.Id));

                    if (plan) {
                        const isConfirmed = await confDialog.open({
                            content: (
                                <>
                        <span>
                            {t("TimeResolution:CloseForm.CancelSingleText")}
                        </span>
                                    <br/> <br/>
                                    <span>
                            {t("TimeResolution:CloseForm.ConfirmationText")}
                        </span>
                                </>
                            )
                        });

                        if (!isConfirmed) {
                            shouldInterrupt = true;
                        } else {
                            await fnRemoveDeferredPlans([plan]);
                        }
                    }
                }

                if (!shouldInterrupt) {
                    // to enable chaining of multiple HOC wrappers, we need to propagate onLineItemsAction
                    shouldInterrupt = await props.onLineItemsAction(args, docStorage);
                }
            }

            return shouldInterrupt;
        }, [tReady]);

        const handleTimeResRemove = useCallback(async (docStorage: FormStorage) => {
            documentStorage.current = docStorage;

            const isConfirmed = await confDialog.open({
                content: (
                    <>
                        <span>
                            {t("TimeResolution:CloseForm.CancelText")}
                        </span>
                        <br/> <br/>
                        <span>
                            {t("TimeResolution:CloseForm.ConfirmationText")}
                        </span>
                    </>
                )
            });

            if (!isConfirmed) {
                return;
            }

            await fnRemoveDeferredPlans(documentStorage.current.data.entity.DeferredPlans as IDeferredPlanEntity[]);
        }, [tReady]);

        const handleTimeResEnd = useCallback(async (docStorage: FormStorage) => {
            documentStorage.current = docStorage;

            setInitialData({
                OriginalDocument: docStorage.data.entity
            });

            setDialogType(TimeResDialogType.End);
        }, []);

        const handleConfirm = useCallback(async (data: IDeferredPlanEntity) => {
            if (data) {
                const subTitle = dialogType === TimeResDialogType.New ? t("TimeResolution:Form.SaveSuccess") : t("TimeResolution:EndForm.SaveSuccess");

                setDialogType(null);
                await documentStorage.current.reload({
                    preserveInfos: true
                });
                documentStorage.current.setFormAlert({
                    status: Status.Success,
                    title: t("Common:Validation.SuccessTitle"),
                    subTitle: subTitle
                });
                // TODO don't reload whole form, just fill the data with the returned "data"
                // once BE is able to return even navigation properties as a result of POST request
                // const deferredPlans = [...(documentStorage.current.data.entity.DeferredPlans ?? []), data];
                // documentStorage.current.setValueByPath("DeferredPlans", deferredPlans);
                // documentStorage.current.data.origEntity.DeferredPlans = deferredPlans;
                // documentStorage.current.refresh();
            }
        }, [tReady]);

        const handleClose = useCallback(() => {
            setDialogType(null);
        }, []);

        const shouldUseCustomResponseHandler = useCallback(
            (batchResults: IBatchResult[]) => {
                // to enable chaining of multiple HOC wrappers, we need to propagate shouldUseCustomResponseHandler
                if (props.shouldUseCustomResponseHandler) {
                    const should = props.shouldUseCustomResponseHandler(batchResults);

                    if (should) {
                        return true;
                    }
                }

                if (batchResults[0].status < 300) {
                    return false;
                }

                const err = batchResults[0].body as ODataError;
                const code = err?._validationMessages?.[0]?.code;

                return ["DeferredPlanNeedsToBeRecalculated", "AccountAssignmentSelectionOfDocumentItemIsAssociatedWithDeferredPlanCannotBeChanged"].includes(code);

            }, []);

        const customResponseHandler: TCustomResponseHandler = useCallback(
            async (batchResults: IBatchResult[], args: IValidateAndSave, docStorage: FormStorage) => {
                const err = batchResults[0].body as ODataError;
                const code = err?._validationMessages?.[0]?.code;

                if (code === "DeferredPlanNeedsToBeRecalculated") {
                    const isConfirmed = await confDialog.open({
                        confirmText: t("TimeResolution:DialogRecalculate.ConfirmAction"),
                        content: (
                            <>
                                {t("TimeResolution:DialogRecalculate.Description")}
                                <br/><br/>
                                {t("TimeResolution:DialogRecalculate.ConfirmQuestion")}
                            </>
                        )
                    });

                    if (!isConfirmed) {
                        return null;
                    }

                    return await docStorage.validateAndSave({
                        ...args,
                        queryParams: {
                            ...args.queryParams,
                            forceDeferredPlanRecalculation: true
                        }
                    });
                } else if (code === "AccountAssignmentSelectionOfDocumentItemIsAssociatedWithDeferredPlanCannotBeChanged") {
                    const isConfirmed = await confDialog.open({
                        confirmText: t("TimeResolution:DialogAccAssChanged.ConfirmAction"),
                        content: (
                            <>
                                {t("TimeResolution:DialogAccAssChanged.Description")}
                                <br/><br/>
                                {t("TimeResolution:DialogAccAssChanged.ConfirmQuestion")}
                            </>
                        )
                    });

                    if (!isConfirmed) {
                        return null;
                    }

                    return await docStorage.validateAndSave({
                        ...args,
                        onBeforeExecute: async (batch: BatchRequest) => {
                            args.onBeforeExecute?.(batch);

                            const queryableEntity = batch.fromPath(EntitySetName.DeferredPlans);
                            const entity: IDocumentEntity = docStorage.data.entity;
                            const items: IDocumentItemEntity[] = docStorage.data.entity.Items;
                            const origItems: IDocumentItemEntity[] = docStorage.data.origEntity.Items;
                            const plansForRemoval: number[] = [];

                            // only remove deferredPlans for items with changed account assignment
                            // or for removed items.
                            // the item also has to have plan...
                            for (const origItem of origItems) {
                                const currentItem = items.find(item => item.Id === origItem.Id);
                                const origAccAss = { ...origItem.AccountAssignmentSelection };
                                const currentAccAss = { ...currentItem.AccountAssignmentSelection };

                                // don't compare AccountAssignment.Id, that is somehow changed dynamically in doc form view
                                delete origAccAss.AccountAssignment.Id;
                                delete currentAccAss.AccountAssignment.Id;

                                if (!currentItem || !isEqual(origItem.AccountAssignmentSelection, currentItem.AccountAssignmentSelection)) {
                                    const plan = getDeferredPlanForItem(entity.DeferredPlans, origItem.Id);

                                    if (plan && !plansForRemoval.includes(plan.Id)) {
                                        plansForRemoval.push(plan.Id);
                                    }
                                }
                            }

                            for (const deferredPlanId of plansForRemoval) {
                                queryableEntity.delete(deferredPlanId, { index: 0 });
                            }
                        }
                    });
                } else if (props.customResponseHandler) {
                    // to enable chaining of multiple HOC wrappers, we need to propagate customResponseHandler
                    return props.customResponseHandler(batchResults, args, docStorage);
                }
            }, []);

        return (
            <>
                <Component {...props as P}
                           onLineItemsAction={handleLineItemsAction}
                           customResponseHandler={customResponseHandler}
                           shouldUseCustomResponseHandler={shouldUseCustomResponseHandler}
                           ref={ref}/>
                {dialogType &&
                    <PlugAndPlayForm
                        getDef={(dialogType === TimeResDialogType.New ? getDefinition : getCancelTimeResDefinition).bind(null, documentStorage.current?.context, isReceived(documentStorage.current.data.entity.DocumentTypeCode))}
                        t={documentStorage.current.t}
                        bindingContext={documentStorage.current.data.bindingContext.createNewBindingContext(EntitySetName.DeferredPlans).addKey("dialog", true)}
                        formView={dialogType === TimeResDialogType.New ? TimeResolutionDialogFormView : CancelTimeResDialogFormView}
                        initialData={{
                            entity: initialData
                        }}
                        formViewProps={{
                            dialogProps: {
                                title: dialogType === TimeResDialogType.New ? t("TimeResolution:Title") : t("TimeResolution:CloseForm.Title"),
                                isEditableWindow: false
                            },
                            confirmText: dialogType === TimeResDialogType.New ? t("TimeResolution:Confirm") : t("TimeResolution:CloseForm.Confirm"),
                            onAfterConfirm: handleConfirm,
                            onClose: handleClose
                        }}
                    />
                }
            </>
        );
    }) as any;
};