import React from "react";
import Dialog from "../../components/dialog";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button, ButtonGroup } from "../../components/button";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import WebsocketManager from "../../utils/websocketManager/WebsocketManager";
import { TWebsocketMessage } from "@utils/websocketManager/Websocket.types";
import { isBackgroundJobWebsocketMessage } from "@utils/websocketManager/Websocket.utils";
import { getBackgroundJobFromWebsocketMessage } from "../../contexts/backgroundJobsContext/BackgroundJobsContext.utils";
import { BackgroundJobStatusCode, WebSocketMessageTypeCode } from "@odata/GeneratedEnums";
import { RossumProgressAnim } from "../../animations/Animations";
import {
    EntitySetName,
    IBackgroundJobEntity,
    IDocumentDraftExtractRossumParameters,
    IFileMetadataEntity,
    OdataActionName
} from "@odata/GeneratedEntityTypes";
import { WithOData, withOData } from "@odata/withOData";
import { ODataQueryResult } from "@odata/ODataParser";
import { getOneFetch } from "@utils/oneFetch";
import { FETCH_ABORT_ERROR } from "../../constants";

// 2 mins
const RossumTimeoutLimit = 120000;

enum RossumProgressDialogScreen {
    Progress = "Progress",
    TimeOutFail = "TimeOutFail"
}

interface IProps extends WithTranslation, WithOData {
    draftId: number;
    file: IFileMetadataEntity;
    onFinish: (rossumFinished: boolean) => void;
}


interface IState {
    screen: RossumProgressDialogScreen;
}

class RossumProgressDialog extends React.PureComponent<IProps, IState> {
    static contextType = AppContext;

    rossumBackgroundJobId: number = null;
    timeout: ReturnType<typeof setTimeout>;
    oneFetch = getOneFetch();

    state: IState = {
        screen: RossumProgressDialogScreen.Progress
    };

    unsubscribeWebsocket: () => void;
    resolveFn: (value: boolean) => void;

    constructor(props: IProps) {
        super(props);

        this.handleMessage = this.handleMessage.bind(this);
    }

    componentDidMount() {
        this.init();
    }

    componentWillUnmount() {
        this.oneFetch.abort();
    }

    handleMessage(message: TWebsocketMessage) {
        if (!isBackgroundJobWebsocketMessage(message)) {
            return;
        }

        const backgroundJob = getBackgroundJobFromWebsocketMessage(message);

        if (backgroundJob.Id === this.rossumBackgroundJobId && backgroundJob.StatusCode === BackgroundJobStatusCode.Finished) {
            this.rossumBackgroundJobId = null;
            clearTimeout(this.timeout);
            this.resolveFn(true);
        }
    }

    init = async () => {
        let promise = new Promise<boolean>((resolve) => {
            this.resolveFn = resolve;
        });
        const fnFailed = () => {
            this.resolveFn(false);
            this.setState({
                screen: RossumProgressDialogScreen.TimeOutFail
            });
        };

        this.timeout = setTimeout(fnFailed, RossumTimeoutLimit);

        try {
            const wrapper = this.props.oData.getEntitySetWrapper(EntitySetName.DocumentDrafts);
            const backgroundJob = await wrapper.action(OdataActionName.DocumentDraftExtractRossum, this.props.draftId, {
                FileMetadata: {
                    "@odata.id": `${EntitySetName.FilesMetadata}(${this.props.file.Id})`
                }
            } as IDocumentDraftExtractRossumParameters, { fetchFn: this.oneFetch.fetch }) as ODataQueryResult<IBackgroundJobEntity>;

            this.rossumBackgroundJobId = backgroundJob.value.Id;

            this.unsubscribeWebsocket = WebsocketManager.subscribe({
                callback: this.handleMessage,
                types: [WebSocketMessageTypeCode.BackgroundJob]
            });
        } catch (error) {
            if (error.name === FETCH_ABORT_ERROR) {
                return;
            } else {
                fnFailed();
            }
        }

        // wait until the background job finishes
        const res = await promise;

        if (res) {
            this.props.onFinish(res);
        }
    };

    handleCancel = async () => {
        this.resolveFn(false);

        if (this.rossumBackgroundJobId !== null) {
            const wrapper = this.props.oData.getEntitySetWrapper(EntitySetName.DocumentDrafts);

            await wrapper.action(OdataActionName.DocumentDraftCancelRossum, this.props.draftId, {
                BackgroundJobId: this.rossumBackgroundJobId
            });
        }

        this.props.onFinish(false);
    };

    handleTryAgain = () => {
        this.setState({
            screen: RossumProgressDialogScreen.Progress
        });
        this.init();
    };

    renderContent = (): React.ReactElement => {
        if (this.state.screen === RossumProgressDialogScreen.Progress) {
            return (
                <div>
                    {this.props.t("Inbox:RossumProgressDialog.Description")}
                    <RossumProgressAnim style={{ marginTop: "19px", marginBottom: "-9px", height: "84px" }}/>
                </div>
            );
        } else {
            return this.props.t("Inbox:RossumProgressDialog.FailDescription");
        }

    };

    renderButtons = (): React.ReactElement => {
        return (
            <ButtonGroup>
                <Button onClick={this.handleCancel}
                        isTransparent={this.state.screen === RossumProgressDialogScreen.TimeOutFail}>
                    {this.props.t("Common:General.Cancel")}
                </Button>
                {this.state.screen === RossumProgressDialogScreen.TimeOutFail &&
                    <Button onClick={this.handleTryAgain}>
                        {this.props.t("Inbox:RossumProgressDialog.TryAgain")}
                    </Button>
                }
            </ButtonGroup>
        );
    };

    render() {
        return (
            <Dialog
                isConfirmation
                onConfirm={null}
                onClose={this.handleCancel}
                footer={this.renderButtons()}
                width={"380px"}
            >
                {this.renderContent()}
            </Dialog>
        );
    }
}

export default withTranslation(["Inbox", "Common"])(withOData(RossumProgressDialog));