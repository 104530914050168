import { DocumentLinkTypeCode } from "@odata/GeneratedEnums";
import {
    creditAndDebitAccount,
    getDocumentFilterByUsedAccountNumber
} from "@pages/accountAssignment/AccountAssignment.utils";
import { DocumentAccountAssignmentEntity } from "@odata/GeneratedEntityTypes";

const initialAccountBalancesNumbers = ["701", "702"];

export const getInitialBalancesAccountFilter = (): string => {
    const conditions = initialAccountBalancesNumbers.reduce((filters, account) => {
        filters.push(...(creditAndDebitAccount.map(type => getDocumentFilterByUsedAccountNumber(type, account))));
        return filters;
    }, []);

    return conditions.join(" OR ");
};

export const FUTURE_EXPENSES_ACCOUNT = "383";
export const FUTURE_REVENUES_ACCOUNT = "385";

export const ACCRUAL_ACCOUNT_ACTIVE = "388";
export const ACCRUAL_ACCOUNT_PASSIVE = "389";

export const excludeInitialBalancesFilter = `not(${getInitialBalancesAccountFilter()})`;
export const getOpenAccrualFilter = (isOpen = true): string =>
        `DocumentLinks/${isOpen ? "all" : "any"}(link: link/TypeCode in ('${DocumentLinkTypeCode.EstimatedAccruedRevenue}', '${DocumentLinkTypeCode.EstimatedAccruedExpense}')${isOpen ? " eq false" : ""})`;
export const accruedRevenueFilter = getDocumentFilterByUsedAccountNumber(DocumentAccountAssignmentEntity.DebitAccount, ACCRUAL_ACCOUNT_ACTIVE);
export const accruedExpenseFilter = getDocumentFilterByUsedAccountNumber(DocumentAccountAssignmentEntity.CreditAccount, ACCRUAL_ACCOUNT_PASSIVE);
