import React from "react";
import { withTranslation } from "react-i18next";
import { getDefinitions } from "./Tickets.def";
import { withRouter } from "react-router-dom";
import Page, { IProps } from "../Page";
import { withOData } from "@odata/withOData";
import TicketsTableView from "./TicketsTableView";

class Tickets extends Page<IProps> {
    static defaultProps = {
        getDef: getDefinitions,
        tableView: TicketsTableView,
        hasPreview: false
    };
}

export default withOData(withRouter(withTranslation([...getDefinitions.translationFiles])(Tickets)));