import React from "react";
import { IDefinition, TFieldsDefinition } from "../PageUtils";
import {
    countryDef,
    getDocumentItemVatDef,
    vatRateFormatter,
    withDisplayName
} from "@components/smart/GeneralFieldDefinition";
import {
    getBusinessPartnerFieldDef,
    getBusinessPartnerGroup
} from "../businessPartner/BusinessPartner.utils";
import {
    DocumentBusinessPartnerAdditionalProperties
} from "@pages/businessPartner/BusinessPartner.shared.utils";
import {
    buildComplexBankAccountFilterFromBankAccountString,
    getBankAccountFieldsDef,
    getBankAccountGroupDef
} from "../banks/bankAccounts/BankAccounts.utils";
import {
    correctiveDocumentLinkTypes,
    DefaultDueDays,
    documentTableAmountFormatter,
    draftInfoFormatter,
    getDocumentPairedTable,
    isSavedDocument
} from "./Document.utils";
import { BankPaymentOrderStateCode, DocumentTypeCode, UnitOfMeasureCode } from "@odata/GeneratedEnums";
import { IFieldDef, IFieldInfoProperties, IGetValueArgs, not, TInfoValue } from "@components/smart/FieldInfo";
import {
    BasicInputSizes,
    CacheStrategy,
    FastEntryInputSizes,
    FieldType,
    TextAlign,
    ValidatorType,
    ValueType
} from "../../enums";
import { documentStatusExportFormatter, DocumentStatusFilterNames, documentStatusFormatter } from "./DocumentDef";
import i18next from "i18next";
import { TValue } from "../../global.types";
import { ICreateFilterStringSettings, IFormatOptions, transformToODataString } from "@odata/OData.utils";
import { TCellValue } from "@components/table";
import BindingContext from "../../odata/BindingContext";
import {
    DocumentEntity,
    IBankAccountEntity,
    IBankPaymentOrderStateEntity,
    IOppositeDocumentDocumentLinkEntity
} from "@odata/GeneratedEntityTypes";
import { getTableIntentLink } from "@components/drillDown/DrillDown.utils";
import { getRouteByDocumentType } from "@odata/EntityTypes";
import { TFilterDef } from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { uniqBy } from "lodash";
import { EMPTY_VALUE } from "../../constants";
import { currentAgendaVatsFilter } from "../accountAssignment/AccountAssignment.utils";
import { IFormGroupDef } from "@components/smart/smartFormGroup/SmartFormGroup";
import { ColoredText } from "../../global.style";
import { formatCurrencyVariableDecimals } from "../../types/Currency";
import { isNumberTheirsAndVatIdMandatory } from "../admin/vatRules/VatRules.utils";
import { getWorkDate } from "@components/inputs/date/utils";
import { DocumentStatusLocalPath } from "../reports/CommonDefs";
import { generalBankAccountFormatter } from "@utils/BankUtils";
import { IChangedFilter, isDraftView } from "../../views/table/TableView.utils";
import { IFilterQuery, TableStorage } from "../../model/TableStorage";
import { isComplexFilterArr } from "@components/conditionalFilterDialog/ConditionalFilterDialog.utils";
import { getUtcDayjs } from "../../types/Date";
import { TSmartODataTableStorage } from "@components/smart/smartTable/SmartODataTableBase";
import { isInVatPayerVariantContext } from "@components/variantSelector/VariantOdata";

function getDocumentItemsVatFilter(): TFilterDef {
    const groupId = "items";
    return {
        type: FieldType.MultiSelect,
        cacheStrategy: CacheStrategy.Company,
        valueType: ValueType.Number,
        fieldSettings: {
            displayName: "Rate",
            transformFetchedItems: (items) => {
                const uniqItems = uniqBy(items, (i) => i.additionalData?.Rate);
                return uniqItems.map(item => ({
                    ...item,
                    id: item.additionalData?.Rate,
                    groupId
                }));
            },
            additionalItems: [{
                id: EMPTY_VALUE,
                label: i18next.t("Common:Select.NoVat"),
                groupId
            }]
        },
        additionalProperties: [{ id: "Rate" }],
        filter: {
            select: currentAgendaVatsFilter
        },
        filterName: ["Rate"],
        columns: [{
            id: "Rate",
            formatter: vatRateFormatter
        }],
        defaultValue: [],
        isValueHelp: false
    };
}

// Combined column of all document statuses (PostedStatus, ClearedStatus, VatSubmissionStatus
export const getDocumentStatusColumnDefinition = (): TFieldsDefinition => {
    return {
        [DocumentStatusLocalPath]: {
            label: i18next.t("Document:Table.Status"),
            textAlign: TextAlign.Center,
            fieldSettings: {
                disableSort: true
            },
            additionalProperties: [
                { id: DocumentEntity.VatStatementStatus },
                { id: DocumentEntity.ClearedStatus },
                { id: DocumentEntity.PostedStatus }
            ],
            formatter: documentStatusFormatter,
            exportFormatter: documentStatusExportFormatter,
            isVisible: not(isDraftViewCallback),
        }
    };
};

export function getDocumentAmountColumnDef(suffix?: string): Partial<IFieldDef> {
    return {
        formatter: (val, args) => documentTableAmountFormatter(args.entity, args.context, suffix),
        additionalProperties: [{
            id: `/TransactionAmount${suffix ?? ""}`
        }, {
            id: "/TransactionCurrencyCode"
        }, {
            id: "/CurrencyCode"
        }]
    };
}

export const addPaymentOrderTableDefs = (definition: IDefinition): void => {
    definition.table.columnDefinition = {
        ...definition.table.columnDefinition,
        BankPaymentOrderStateCode: {
            label: i18next.t("Document:Table.PaymentOrder"),
            formatter: (value): TCellValue => {
                let title = i18next.t("Common:General.Yes");
                switch (value) {
                    case BankPaymentOrderStateCode.Exported:
                        return {
                            tooltip: title,
                            value: <ColoredText color={"C_SEM_text_good"}><b>{title}</b></ColoredText>
                        };
                    case BankPaymentOrderStateCode.Sent:
                        return {
                            tooltip: i18next.t("Common:General.Yes") + " " + i18next.t("Document:Table.API"),
                            value: <ColoredText
                                color={"C_SEM_text_good"}><b>{title}</b>&nbsp;{i18next.t("Document:Table.API")}
                            </ColoredText>
                        };
                    default:
                        title = i18next.t("Common:General.No");
                        return {
                            tooltip: title,
                            value: <ColoredText color={"C_SEM_text_bad"}><b>{title}</b></ColoredText>
                        };
                }
            }
        },
        DateBankApiPaymentOrder: {}
    };

    definition.table.additionalProperties = [...(definition.table.additionalProperties || []), {
        id: "DocumentStatusCode"
    }, {
        id: "TransactionCurrency/Code"
    }, {
        id: "TransactionCurrency/Name"
    }, {
        id: "PaymentMethodCode"
    }, {
        id: "BankAccount/Country/IsSepa"
    }, {
        id: "TransactionAmountToPay"
    }];

    definition.table.filterBarDef[0].filterDefinition = {
        ...definition.table.filterBarDef[0].filterDefinition,
        "BankPaymentOrderState": {
            label: i18next.t("Document:Table.PaymentOrder"),
            type: FieldType.MultiSelect,
            fieldSettings: {
                displayName: "Name"
            },
            formatter: (val): string => {
                let label;
                // ReadOnlyGroup in filterBar call this with code as value only (only code is stored)
                const code = (val as IBankPaymentOrderStateEntity)?.Code ?? val;
                switch (code) {
                    case BankPaymentOrderStateCode.Exported:
                        label = i18next.t("Common:General.Yes");
                        break;
                    case BankPaymentOrderStateCode.Sent:
                        label = i18next.t("Common:General.Yes") + " " + i18next.t("Document:Table.API");
                        break;
                }
                return (label ?? i18next.t("Common:General.No")).toString();
            }
        },
        "DateBankApiPaymentOrder": {}
    };
};

export const addBusinessPartnerFieldDefs = (definition: IDefinition, type: DocumentTypeCode, transFile: string, isBusinessPartnerOptional?: boolean): void => {
    const businessPartnerDef: Record<string, IFieldInfoProperties> = getBusinessPartnerFieldDef(!isBusinessPartnerOptional, transFile);

    businessPartnerDef["BusinessPartner/Country"] = {
        ...businessPartnerDef["BusinessPartner/Country"],
        additionalProperties: [{ id: "IsEuMember" }, { id: "Code" }],
        columns: [{
            additionalProperties: [{ id: "IsEuMember" }],
            id: "Name"
        }, { id: "Code" }],
        fieldSettings: {
            ...countryDef.fieldSettings,
            localDependentFields: [{
                from: { id: "IsEuMember" },
                to: { id: "BusinessPartner/Country/IsEuMember" }
            }]
        }
    };

    businessPartnerDef["BusinessPartner/TaxNumber"] = {
        ...businessPartnerDef["BusinessPartner/TaxNumber"],
        isRequired: isNumberTheirsAndVatIdMandatory
    };

    definition.form.fieldDefinition = {
        ...definition.form.fieldDefinition,
        ...businessPartnerDef,
        ...getBankAccountFieldsDef({ addPaymentMethod: true, type })
    };

    definition.form.groups.splice(1, 0, getBusinessPartnerGroup(transFile));
    definition.form.groups.splice(3, 0, getBankAccountGroupDef(true));

    definition.form.additionalProperties = [...(definition.form.additionalProperties || []), DocumentBusinessPartnerAdditionalProperties];
};

// definitions common for all but internal document types
export const addCommonDocumentDefs = (definition: IDefinition, type: DocumentTypeCode): void => {
    definition.table.columnDefinition = {
        ...definition.table.columnDefinition,
        DateDue: { id: "DateDue" },
        ...withDisplayName("PaymentMethod"),
        SymbolVariable: { id: "SymbolVariable" },
        SymbolSpecific: { id: "SymbolSpecific" },
        SymbolConstant: { id: "SymbolConstant" },
        RemittanceInformation: { id: "RemittanceInformation" },
        TransactionAmountToReceive: { id: "TransactionAmountToReceive" },
        TransactionAmountToPay: { id: "TransactionAmountToPay" },
        TextBeforeItems: {},
        SymbolMatching: {}
    };

    definition.table.columns.splice(definition.table.columns?.length - 3, 0, "DateDue", DocumentEntity.DateCreated);
    definition.table.filterBarDef[0].defaultFilters.push(...DocumentStatusFilterNames);
    definition.table.filterBarDef[0].defaultFilters.push("Items/Quantity");
    definition.table.filterBarDef[0].defaultFilters.push("DateDue");
    definition.table.filterBarDef[0].filterDefinition = {
        ...definition.table.filterBarDef[0].filterDefinition,
        "Items/Quantity": { isValueHelp: false },
        "Items/UnitPrice": { isValueHelp: false },
        "Items/UnitPriceNet": { isValueHelp: false },
        "Items/UnitPriceVat": { isValueHelp: false },
        "Items/Vat": getDocumentItemsVatFilter(),
        "Items/DiscountPercent": { isValueHelp: false },
        "Items/Discount": { isValueHelp: false },
        "DateDue": {},
        ...withDisplayName("PaymentMethod"),
        "SymbolVariable": {},
        "SymbolSpecific": {},
        "SymbolConstant": {},
        "TransactionAmountToReceive": {},
        "TransactionAmountToPay": {},
        TextBeforeItems: {},
        // Note: we should keep the same value as in table cause there is caching, see also comment in OData/Utils getFieldInfoMemoized
        // todo: refactor how fieldInfo is cached and merged definition from several defs (form, table, filter...)
        ...withDisplayName("TransactionCurrency", "Name")
    };

    if (definition.table.massEditableDef) {
        definition.table.massEditableDef = {
            ...definition.table.massEditableDef,
            ...getBankAccountFieldsDef({ addPaymentMethod: true, type }),
            DateDue: {}
        };
    }

    definition.form.fieldDefinition["Items/Quantity"] = {
        defaultValue: 1,
        width: FastEntryInputSizes.XS,
        validator: {
            type: ValidatorType.Number,
            settings: {
                min: 0,
                excludeMin: true
            }
        }
    };

    definition.form.fieldDefinition["Items/Vat"] = {
        ...getDocumentItemVatDef(type),
        customizationData: {
            isRequired: isInVatPayerVariantContext
        },
    };

    definition.form.fieldDefinition["Items/UnitOfMeasure"] = {
        type: FieldType.ComboBox,
        fieldSettings: {
            displayName: "ShortName"
        },
        defaultValue: UnitOfMeasureCode.Piece,
        width: FastEntryInputSizes.XS
    };

    definition.form.fieldDefinition.DateDue = {
        defaultValue: () => getUtcDayjs(getWorkDate()).add(DefaultDueDays, "day"),
        clearIfInvisible: false
    };

    definition.form.fieldDefinition.TextBeforeItems = {
        type: FieldType.TextArea,
        width: BasicInputSizes.XL,
        fieldSettings: {
            minRows: 2,
            maxRows: 6
        }
    };

    definition.form.fieldDefinition.SymbolMatching = {};

    definition.form.additionalProperties.push(
        { id: "Items/TransactionUnitPrice" },
        { id: "Items/TransactionUnitPriceNet" },
        { id: "Items/TransactionUnitPriceVat" }
    );
};

export const addBankAccountTableDefs = (definition: IDefinition, filterIndex = 0, prefix = "BankAccount"): void => {
    const bankAccountAdditionalKeys: (keyof IBankAccountEntity)[] = [
        "AbaNumber", "AccountNumber", "BankCode", "CountryCode", "IBAN", "PaymentServiceID", "PaymentServiceName", "SWIFT"
    ];
    const bankAccountAdditionalProperties = bankAccountAdditionalKeys.map(key => ({ id: key }));

    definition.table.columnDefinition = {
        ...definition.table.columnDefinition,
        [`${prefix}`]: {
            formatter: generalBankAccountFormatter,
            additionalProperties: bankAccountAdditionalProperties,
            label: i18next.t("Banks:Statements.AccountNumber")
        },
        [`${prefix}/BankCode`]: {},
        [`${prefix}/AbaNumber`]: {},
        [`${prefix}/IBAN`]: {},
        [`${prefix}/SWIFT`]: {},
        ...withDisplayName(`${prefix}/Country`)
    };

    definition.table.filterBarDef[filterIndex].filterDefinition = {
        ...definition.table.filterBarDef[filterIndex].filterDefinition,
        [`${prefix}/AccountNumber`]: {
            formatter: generalBankAccountFormatter,
            filter: {
                groupByProperties: [`${prefix}/BankCode`, `${prefix}/CountryCode`, `${prefix}/AbaNumber`, `${prefix}/PaymentServiceID`, `${prefix}/IBAN`],
                buildFilter: (item: IChangedFilter, settings: ICreateFilterStringSettings, storage: TableStorage): IFilterQuery | string => {
                    if (isComplexFilterArr(item.value)) {
                        return buildComplexBankAccountFilterFromBankAccountString(item, storage);
                    } else {
                        // call original function that build filter automatically
                        return storage.createFilterQueryRecursive([{
                            ...item,
                            info: {
                                ...item.info,
                                filter: {
                                    ...item.info.filter,
                                    // but prevent it from calling this callback again
                                    buildFilter: null
                                }
                            }
                        }], item.bindingContext);
                    }
                }
            }
        },
        [`${prefix}/BankCode`]: {},
        [`${prefix}/AbaNumber`]: {},
        [`${prefix}/IBAN`]: {},
        [`${prefix}/SWIFT`]: {},
        ...withDisplayName(`${prefix}/Country`)
    };
};

export const addBusinessPartnerTableDefs = (definition: IDefinition, filterIndex = 0, businessPartnerAsDefault = true): void => {
    definition.table.columnDefinition = {
        ...definition.table.columnDefinition,
        BusinessPartner: {
            id: "BusinessPartner",
            fieldSettings: {
                displayName: "Name"
            }
        },
        "BusinessPartner/Street": {},
        "BusinessPartner/City": {},
        "BusinessPartner/PostalCode": {},
        ...withDisplayName("BusinessPartner/Country"),
        "BusinessPartner/FirstName": {},
        "BusinessPartner/LastName": {},
        "BusinessPartner/Email": {},
        "BusinessPartner/PhoneNumber": {},
        "BusinessPartner/LegalNumber": {},
        "BusinessPartner/TaxNumber": {},
        ...withDisplayName("BusinessPartner/VatStatus")
    };

    if (businessPartnerAsDefault) {
        definition.table.filterBarDef[filterIndex].defaultFilters.splice(2, 0, "BusinessPartner");
    }

    definition.table.filterBarDef[filterIndex].filterDefinition = {
        ...definition.table.filterBarDef[filterIndex].filterDefinition,
        ...withDisplayName("BusinessPartner"),
        "BusinessPartner/Street": {},
        "BusinessPartner/City": {},
        "BusinessPartner/PostalCode": {},
        ...withDisplayName("BusinessPartner/Country"),
        "BusinessPartner/FirstName": {},
        "BusinessPartner/LastName": {},
        "BusinessPartner/Email": {},
        "BusinessPartner/PhoneNumber": {},
        "BusinessPartner/LegalNumber": {},
        "BusinessPartner/TaxNumber": {},
        ...withDisplayName("BusinessPartner/VatStatus")
    };
};

export const addAccountAssignmentTableDefs = (definition: IDefinition, filterIndex = 0): void => {
    definition.table.columnDefinition = {
        ...definition.table.columnDefinition,
        "AccountAssignmentSelection/AccountAssignment/ShortName": {},
        "AccountAssignmentSelection/AccountAssignment/Name": {},
        ...withDisplayName("AccountAssignmentSelection/AccountAssignment/DebitAccount", "Number"),
        ...withDisplayName("AccountAssignmentSelection/AccountAssignment/CreditAccount", "Number")
    };

    definition.table.filterBarDef[filterIndex].filterDefinition = {
        ...definition.table.filterBarDef[filterIndex].filterDefinition,
        "AccountAssignmentSelection/AccountAssignment/ShortName": {},
        "AccountAssignmentSelection/AccountAssignment/Name": {},
        ...withDisplayName("AccountAssignmentSelection/AccountAssignment/DebitAccount", "Number"),
        ...withDisplayName("AccountAssignmentSelection/AccountAssignment/CreditAccount", "Number")
    };
};

export const addTransactionAmountDue = (definition: IDefinition, isDefault?: boolean, withoutAmountDueSummary?: boolean): void => {
    definition.table.columnDefinition = {
        ...definition.table.columnDefinition,
        TransactionAmountDue: {}
    };

    definition.table.filterBarDef[0].filterDefinition = {
        ...definition.table.filterBarDef[0].filterDefinition,
        TransactionAmountDue: {}
    };

    if (isDefault) {
        let amountIndex = definition.table.columns.findIndex(colName => colName === "Amount");
        let index = amountIndex >= 0 ? amountIndex + 1 : definition.table.columns.length - 1;

        definition.table.columns.splice(index, 0, "TransactionAmountDue");

        amountIndex = definition.table.filterBarDef[0].defaultFilters.findIndex(colName => colName === "Amount");
        index = amountIndex >= 0 ? amountIndex + 1 : definition.table.columns.length - 1;

        definition.table.filterBarDef[0].defaultFilters.splice(index, 0, "TransactionAmountDue");
    }

    if (!withoutAmountDueSummary) {
        definition.form.summary.splice(2, 0, {
            id: "TransactionAmountDue",
            label: i18next.t("Document:Summary.AmountDue"),
            formatter: (val: TValue, args: IFormatOptions) => {
                return formatCurrencyVariableDecimals(val ?? 0, args.storage.data.entity.TransactionCurrency.Code);
            },
            updateFromLiveValue: true,
            isVisible: isSavedDocument
        });
    }
};

export const addAmountsTableDefs = (definition: IDefinition, withoutAmountDueSummary?: boolean): void => {
    definition.table.columnDefinition = {
        ...definition.table.columnDefinition,
        TransactionAmountVat: {},
        AmountVat: {},
        TransactionAmountNet: {},
        AmountNet: {}
    };

    definition.table.filterBarDef[0].filterDefinition = {
        ...definition.table.filterBarDef[0].filterDefinition,
        "AmountVat": {},
        "AmountNet": {},
        "TransactionAmountVat": {},
        "TransactionAmountNet": {},
        TransactionAmount: {},
        "Items/TransactionAmount": { isValueHelp: false },
        "Items/TransactionAmountNet": { isValueHelp: false },
        "Items/TransactionAmountVat": { isValueHelp: false }
    };

    addTransactionAmountDue(definition, true, withoutAmountDueSummary);
};

export const CorrectiveDocumentColumnPath = BindingContext.localContext("CorrectiveDocument");

export const addCorrectiveDocument = (definition: IDefinition, hasAccountAssignment: boolean): void => {
    definition.table.columnDefinition[CorrectiveDocumentColumnPath] = {
        label: i18next.t("Document:CorrectiveDocument.Label"),
        formatter: (val, args) => {
            const correctiveDocLink = args.entity.OppositeDocumentLinks?.[0] as IOppositeDocumentDocumentLinkEntity;

            if (!correctiveDocLink) {
                return "";
            }

            const correctiveDoc = correctiveDocLink.SourceDocument;

            return getTableIntentLink(correctiveDoc.NumberOurs, {
                route: `${getRouteByDocumentType(correctiveDoc.DocumentTypeCode as DocumentTypeCode)}/${correctiveDoc.Id}`,
                context: args.storage.context,
                storage: args.storage
            });
        }
    };

    const additionalProperties = [
        { id: "OppositeDocumentLinks/TypeCode" },
        { id: "OppositeDocumentLinks/SourceDocument/NumberOurs" },
        { id: "OppositeDocumentLinks/SourceDocument/DocumentTypeCode" }
    ];
    const querySettings = {
        "OppositeDocumentLinks": {
            filter: `TypeCode in (${transformToODataString(correctiveDocumentLinkTypes, ValueType.String)})`
        }
    };

    definition.table.additionalProperties = [
        ...(definition.table.additionalProperties ?? []),
        ...additionalProperties
    ];

    definition.table.querySettings = {
        ...definition.table.querySettings,
        ...querySettings
    };

    definition.form.additionalProperties = [
        ...(definition.form.additionalProperties ?? []),
        ...additionalProperties
    ];

    definition.form.querySettings = {
        ...definition.table.querySettings,
        ...querySettings
    };

    const tabsGroup: IFormGroupDef = definition.form.groups.find(group => group.id === "Tabs");

    tabsGroup.tabs.push({
        id: "correctiveDocs",
        title: i18next.t("Document:FormTab.Corrections"),
        table: getDocumentPairedTable({
            selectQuery: `TypeCode in (${transformToODataString(correctiveDocumentLinkTypes, ValueType.String)})`,
            isCbaCompany: !hasAccountAssignment
        })
    });
};

export const draftInfoPath = BindingContext.localContext("DraftInfo");

export const isDraftViewCallback: TInfoValue<boolean> = (args: IGetValueArgs) => {
    return isDraftView(args.storage as TSmartODataTableStorage);
};

export const getDraftInfoColumnDef = (isBankTransaction?: boolean): TFieldsDefinition => {
    return {
        [draftInfoPath]: {
            label: i18next.t(`Document:Table.Info`),
            disableSort: true,
            isVisible: isDraftViewCallback,
            formatter: draftInfoFormatter,
            additionalProperties: [{
                id: "/LastModifiedBy/Name"
            }, {
                id: "/DateLastModified"
            }, {
                id: isBankTransaction ? "/PaymentDocumentDraft" : "/DocumentDraft"
            }]
        }
    };
};