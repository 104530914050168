import { IFieldDef, IFieldInfoProperties } from "@components/smart/FieldInfo";
import { PropertyTranslationCache } from "./PropertyTranslationCache";
import BindingContext from "./BindingContext";
import { IAppContext } from "../contexts/appContext/AppContext.types";
import { isNotDefined } from "@utils/general";
import i18next from "i18next";
import { isBooleanType, isDateType, isNumericType, Property } from "@evala/odata-metadata/src";
import { FieldType, ValidatorType, ValueType } from "../enums";
import memoize from "../utils/memoize";

/** This file tries to solve some weird circular dependency errors */

export interface IGetFieldInfo {
    bindingContext: BindingContext;
    context: IAppContext;
    fieldDef?: IFieldDef;
}

export interface IFieldInfo extends IFieldDef {
    // todo completely remove IFieldInfo?
    // bindingContext has to be required for IFieldInfo to be at least somehow different than IFieldDef
    bindingContext: BindingContext;
}

export const getConfigItemDescription = async (bindingContext: BindingContext): Promise<string> => {
    const parentBc = bindingContext.getParent();

    return !parentBc.isRoot() && !parentBc?.isEnum() ? await PropertyTranslationCache.getTranslation(parentBc.getProperty(), parentBc.getParent()?.getEntityType()) : null;
};

export function getFieldTypeFromProperty(property: Property): FieldType {
    if (isNumericType(property)) {
        return FieldType.NumberInput;
    } else if (isDateType(property)) {
        return FieldType.Date;
    } else if (isBooleanType(property)) {
        return FieldType.Switch;
    } else {
        return FieldType.Input;
    }
}

export function getValueTypeFromProperty(property: Property): ValueType {
    if (isNumericType(property)) {
        return ValueType.Number;
    } else if (isDateType(property)) {
        return ValueType.Date;
    } else if (isBooleanType(property)) {
        return ValueType.Boolean;
    } else {
        return ValueType.String;
    }
}

export function getValidatorTypeFromProperty(property: Property): ValidatorType {
    if (isNumericType(property)) {
        return ValidatorType.Number;
    } else if (isDateType(property)) {
        return ValidatorType.Date;
    } else if (isBooleanType(property)) {
        return ValidatorType.Boolean;
    } else {
        return ValidatorType.String;
    }
}

export const getFieldInfoMemoized = memoize(
        async ({ bindingContext, context }: IGetFieldInfo): Promise<IFieldInfo> => {
            let label, property, description;

            const defaultFieldProps: IFieldInfoProperties = {};

            if (!bindingContext.isLocal()) {
                property = bindingContext.getProperty();
                if (property) {
                    label = await PropertyTranslationCache.getTranslation(property, bindingContext.getParent()?.getEntityType());
                    description = await getConfigItemDescription(bindingContext);

                    // we can identify some properties from the metadata definition

                    // todo rename type => fieldType?
                    defaultFieldProps.type = getFieldTypeFromProperty(property);
                    defaultFieldProps.valueType = getValueTypeFromProperty(property);
                    defaultFieldProps.validator = {
                        type: getValidatorTypeFromProperty(property)
                    };
                }
        }

        return {
            id: bindingContext.getPath(),
            bindingContext,
            label, description,
            ...defaultFieldProps
        };
    },
    ({ bindingContext, context }: IGetFieldInfo) => {
        return `${i18next.language}/${bindingContext.toString()}`;
    }
);

// this file is trying to prevent some circular dependencies
export const getFieldInfo = async (args: IGetFieldInfo): Promise<IFieldInfo> => {
    // we can use definition with same bindingContext in different places, with different fieldDef
    // always inject fieldDef to cached object retrieved from getFieldMetadataMemoized, so that the fieldDef isn't cached as well
    let fieldInfo = await getFieldInfoMemoized(args);

    if (args.fieldDef) {
        fieldInfo = {
            ...fieldInfo,
            // merges props that can be different for every places where the same binding context is used
            // e.g. same fields in multiple forms with different custom label
            ...args.fieldDef,
            fieldSettings: {
                ...fieldInfo.fieldSettings,
                ...args.fieldDef?.fieldSettings
            }
        };
    } else if (isNotDefined(fieldInfo.fieldSettings)) {
        fieldInfo.fieldSettings = {};
    }

    return fieldInfo;
};