import { Dayjs } from "dayjs";
import {
    areValuesEqual,
    forEachKey,
    isDefined,
    isNotDefined,
    isObjectEmpty,
    isTwoPrimitiveArraysEqual
} from "@utils/general";
import { ifAny, IFieldDef, IGetValueArgs, TGetValueFn, TTemporalDialogSettings } from "@components/smart/FieldInfo";
import { FormStorage, IFormStorageDefaultCustomData } from "../views/formView/FormStorage";
import React from "react";
import BindingContext, { createPath, IEntity } from "./BindingContext";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { DateRangeSpecialValue } from "@components/inputs/date/popup/Calendar.utils";
import { TFieldDefinition, TFieldsDefinition } from "@pages/PageUtils";
import { TRecordAny } from "../global.types";
import { DATE_MAX, getUtcDayjs } from "../types/Date";
import { updateDataOnChange } from "./Data.utils";
import { SimpleTimeLineStatus } from "@components/simpleTimeline/SimpleTimeline.utils";
import SmartTemporalPropertyButton from "../components/smart/smartTemporalPropertyButton/SmartTemporalPropertyButton";
import { PrEntityValueSourceCode } from "./GeneratedEnums";
// keep exact path because of circular dependencies
import {
    ActionType,
    ISmartFastEntriesActionEvent
} from "@components/smart/smartFastEntryList";
import { getEnumSelectItems } from "./GeneratedEnums.utils";
import memoize from "../utils/memoize";

export const systemProperties = ["Id", "DateCreated", "CreatedBy", "DateLastModified", "LastModifiedBy", "TemporaryGuid"];

export enum TemporalEntityProp {
    CurrentTemporalPropertyBag = "CurrentTemporalPropertyBag",
    TemporalPropertyBag = "TemporalPropertyBag",
}

export type TTemporal<T = Record<string, unknown>> = T & {
    DateValidFrom: Date;
    DateValidTo: Date;
    // FE only flag to distinguish extracted history items in temporalPropertyDialog
    SourceCode?: PrEntityValueSourceCode;
}

export type TTemporalLineItem<T = Record<string, unknown>> = TTemporal<{
    [TemporalEntityProp.CurrentTemporalPropertyBag]: T;
    [TemporalEntityProp.TemporalPropertyBag]: TTemporal<T>[];
}>

export interface ISmartTemporalPropertyDialogCustomData extends IFormStorageDefaultCustomData {
    // bc for which the current SmartTemporalPropertyDialog is opened,
    // needed for callbacks used for DateValidFrom/DateValidTo,
    // because those fields have one definition shared across all the dialogs,
    // but we need to be able to target those callback for a specific one
    openedDialogBindingContext?: BindingContext;
}

// TODO Temporal component si generic, maybe it shouldn't be connected to "last wage"?
export function lastWageDate(): Dayjs {
    return getUtcDayjs();
}

export function groupTemporalRanges<T>(propertyBags: TTemporal<T>[], properties: (keyof T)[], withoutValidations = false): TTemporal<Partial<T>>[] {
    if (propertyBags.length === 0) {
        return [];
    }

    const bags = [...propertyBags];
    const result: TTemporal<Partial<T>>[] = [];

    bags.sort((a, b) => a.DateValidFrom.getTime() - b.DateValidFrom.getTime());

    if (!withoutValidations) {
        validateRanges(bags);
    }

    // Add sourceCode, so it is copied and also don't merge rows without different value of it
    properties = [...properties, ("SourceCode" as keyof T)];

    let bag = bags[0];
    let currentProperties = getProperties(bag, properties);
    let currentFrom = bag.DateValidFrom, currentTo = bag.DateValidTo;
    const addResult = () => {
        result.push({
            DateValidFrom: currentFrom,
            DateValidTo: currentTo,
            ...currentProperties
        });
    };

    for (let i = 1; i < bags.length; i++) {
        bag = bags[i];
        const props = getProperties(bag, properties);

        if (anyPropsChanged(properties, currentProperties, props) || !areOneDayApart(bag.DateValidFrom, bags[i - 1].DateValidTo)) {
            addResult();
            currentProperties = props;
            currentFrom = bag.DateValidFrom;
            currentTo = bag.DateValidTo;
        } else {
            currentTo = bag.DateValidTo;
        }
    }

    if (!isObjectEmpty(currentProperties)) {
        addResult();
    }

    return result;
}

export function ungroupTemporalRanges<T>(groupedRanges: TTemporal<T>[], originalPropertyBags: TTemporal<T>[], properties: string[]): TTemporal<Partial<T>>[] {
    groupedRanges = [...groupedRanges];
    const originalPropertyBagsCopy = originalPropertyBags ? [...originalPropertyBags] : [];
    groupedRanges.sort((a, b) => a.DateValidFrom.getTime() - b.DateValidFrom.getTime());
    originalPropertyBagsCopy.sort((a, b) => a.DateValidFrom.getTime() - b.DateValidFrom.getTime());
    const bagsStack = [...originalPropertyBagsCopy];
    validateRanges(groupedRanges);
    validateRanges(originalPropertyBagsCopy);
    const result: TTemporal<Partial<T>>[] = [];

    while (groupedRanges.length > 0) {
        let currentGroup = groupedRanges.shift();
        let groupFrom = getUtcDayjs(currentGroup.DateValidFrom);
        const groupTo = getUtcDayjs(currentGroup.DateValidTo);

        if (bagsStack?.length === 0) {
            result.push(Object.assign({}, currentGroup));
        } else {
            while (bagsStack.length > 0 && currentGroup !== null) {
                const currentBag = bagsStack.shift();
                const bagFrom = getUtcDayjs(currentBag.DateValidFrom);
                const bagTo = getUtcDayjs(currentBag.DateValidTo);

                if (bagTo.isBefore(groupFrom, "day")) {
                    const obj = Object.assign({}, currentBag);
                    setProperties(obj, {}, properties);
                    result.push(obj);
                    continue;
                }
                if (bagFrom.isSame(groupFrom, "day") && bagTo.isSame(groupTo, "day")) {
                    const obj = Object.assign({}, currentBag);
                    setProperties(obj, currentGroup, properties);
                    result.push(obj);
                    currentGroup = null;
                    break;
                }
                if (bagFrom.isBefore(groupFrom, "day")) {
                    const obj = Object.assign({}, currentBag);
                    const newBag = Object.assign({}, currentBag);
                    setProperties(obj, {}, properties);
                    obj.DateValidTo = groupFrom.subtract(1, "day").toDate();
                    removeSystemProperties(newBag);
                    newBag.DateValidFrom = groupFrom.toDate();
                    bagsStack.unshift(newBag);
                    result.push(obj);
                    continue;
                }
                if (groupFrom.isBefore(bagFrom, "day")) {
                    bagsStack.unshift(currentBag);
                    const obj: TTemporal = {
                        DateValidFrom: groupFrom.toDate(),
                        DateValidTo: bagFrom.subtract(1, "day").toDate()
                    };
                    setProperties(obj, currentGroup, properties);
                    if (groupTo.isBefore(bagFrom, "day")) {
                        obj.DateValidTo = groupTo.toDate();
                        currentGroup = null;
                    } else {
                        groupFrom = bagFrom;
                        currentGroup.DateValidFrom = groupFrom.toDate();
                    }
                    result.push(obj as TTemporal<Partial<T>>);
                    continue;
                }
                //Now groupFrom === bagFrom
                if (bagTo.isBefore(groupTo, "day")) {
                    const obj = Object.assign({}, currentBag);
                    setProperties(obj, currentGroup, properties);
                    groupFrom = bagTo.add(1, "day");
                    currentGroup.DateValidFrom = groupFrom.toDate();
                    result.push(obj);
                    continue;
                }
                if (groupTo.isBefore(bagTo)) {
                    const obj = Object.assign({}, currentBag);
                    const newBag = Object.assign({}, currentBag);
                    removeSystemProperties(newBag);
                    newBag.DateValidFrom = groupTo.add(1, "day").toDate();
                    setProperties(obj, currentGroup, properties);
                    obj.DateValidTo = groupTo.toDate();
                    currentGroup = null;
                    bagsStack.unshift(newBag);
                    result.push(obj);
                    continue;
                }
                throw new Error("Combination I didn't think of.");
            }
        }
    }

    while (bagsStack.length > 0) {
        const currentBag = bagsStack.shift();
        const obj = Object.assign({}, currentBag);
        setProperties(obj, {}, properties);
        result.push(obj);
    }

    return result;
}

export function optimizeRanges(ranges: TTemporal[]): void {
    // empty ranges are synchronized ones. We have to skip this until we are able to distinguish synchronized
    // and not synchronized ranges -> may be some explicit flag in the model??
    // removeEmptyRanges(ranges);
    mergeSameRanges(ranges);
}

export function groupAndOptimizeRanges<T>(propertyBags: TTemporal<T>[], properties: (keyof T)[], withoutValidations = false): TTemporal[] {
    const result: TTemporal[] = groupTemporalRanges(propertyBags ?? [], properties, withoutValidations);

    optimizeRanges(result);
    return result;
}

export function ungroupAndOptimizeRanges(groupedRanges: TTemporal[], originalPropertyBags: TTemporal[], properties: string[]): TTemporal[] {
    const result: TTemporal[] = ungroupTemporalRanges(groupedRanges, originalPropertyBags, properties);

    optimizeRanges(result);
    return result;
}

function mergeSameRanges(ranges: any[]) {
    let pos = 1;
    while (pos < ranges.length) {
        if (hasSameProperties(ranges[pos - 1], ranges[pos]) && areOneDayApart(ranges[pos - 1].DateValidTo, ranges[pos].DateValidFrom)) {
            let removePos;
            if (!Number.isInteger(ranges[pos - 1].Id)) {
                removePos = pos - 1;
                ranges[pos].DateValidFrom = ranges[removePos].DateValidFrom;
            } else {
                removePos = pos;
                ranges[pos - 1].DateValidTo = ranges[pos].DateValidTo;
            }
            ranges.splice(removePos, 1);
        } else {
            pos++;
        }
    }
}

function areOneDayApart(date1: Date, date2: Date) {
    return Math.abs(getUtcDayjs(date1).startOf("day").diff(getUtcDayjs(date2).startOf("day"), "day")) <= 1;
}

function removeEmptyRanges(ranges: any[]) {
    let pos = 0;
    while (pos < ranges.length) {
        if (isEmptyRange(ranges[pos]) && !isSynchronizedRange(ranges[pos])) {
            ranges.splice(pos, 1);
        } else {
            pos++;
        }
    }
}

export function cleanUpTemporalValues(ranges: any[]): void {
    ranges.forEach(range => {
        // delete sourceCode, which was added to distinguish history values
        delete range.SourceCode;
    });
}

function hasSameProperties(range1: any, range2: any) {
    // check that the objects has same properties
    // if one has keys that the other doesn't it wouldn't be caught by the Object.keys(range1) check
    const range1Keys = Object.keys(range1).sort().filter(prop => !isSystemProperty(prop));
    const range2Keys = Object.keys(range2).sort().filter(prop => !isSystemProperty(prop));

    if (!isTwoPrimitiveArraysEqual(range1Keys, range2Keys)) {
        return false;
    }

    for (const property of Object.keys(range1)) {
        if (!isPropertySame(range1, range2, property)) {
            return false;
        }
    }
    return true;
}

function isPropertySame(range1: any, range2: any, property: string) {
    if (isSystemProperty(property)) {
        return true;
    }

    const val1 = range1[property];
    const val2 = range2[property];

    return areValuesEqual(val1, val2);
}

function isEmptyRange(range: any): boolean {
    for (const property of Object.keys(range)) {
        if (!isPropertyEmpty(range, property)) {
            return false;
        }
    }
    return true;
}

function isSynchronizedRange(range: TTemporal) {
    return (range.SourceCode && range.SourceCode !== PrEntityValueSourceCode.Entity);
}

function isPropertyEmpty(range: any, property: string) {
    return isSystemProperty(property) || isNotDefined(range[property]) ||
            (typeof range[property] === "object" && isObjectEmpty(range[property]));
}

function isSystemProperty(property: string) {
    return systemProperties.includes(property) || property === "DateValidFrom" || property === "DateValidTo";
}

function removeSystemProperties(obj: IEntity) {
    for (const p of systemProperties) {
        delete obj[p];
    }
}

function setProperties(obj: any, from: any, properties: string[]) {
    for (const p of properties) {
        if (!isSystemProperty(p)) {
            if (from[p] !== undefined) {
                obj[p] = from[p];
            } else {
                delete obj[p];
            }
        }

    }
}

export function validateRanges(groupedRanges: TTemporal[]): void {
    for (const group of groupedRanges) {
        if (getUtcDayjs(group.DateValidFrom).isAfter(group.DateValidTo, "day")) {
            throw new Error("From must be less then to.");
        }
    }
    for (let pos = 1; pos < groupedRanges.length; pos++) {
        if (getUtcDayjs(groupedRanges[pos - 1].DateValidTo).isSameOrAfter(groupedRanges[pos].DateValidFrom, "day")) {
            throw new Error("Intervals cannot overlap.");
        }
    }
}


function anyPropsChanged<T>(properties: (keyof T)[], bag1: Partial<T>, bag2: Partial<T>): boolean {
    for (const p of properties) {
        if (!isPropertySame(bag1, bag2, p.toString())) {
            return true;
        }
    }
    return false;
}

function getProperties<T>(bag: TTemporal<T>, properties: (keyof T)[]): Partial<T> {
    const result: Partial<T> = {};
    for (const p of properties) {
        if (bag[p] !== undefined) {
            result[p] = bag[p];
        }
    }
    return result;
}

export const getGroupingProperties = memoize((bindingContext: BindingContext, storage: FormStorage) => {
    const properties = new Set<string>();
    const codeProperty = bindingContext.isEnum() ? bindingContext.getProperty()?.getReferentialConstraint()?.property : null;

    properties.add(bindingContext.getPath());

    // for enums, add code property so that we have both the navigation object and code string correctly updated when grouping/ungrouping
    if (codeProperty) {
        properties.add(codeProperty);
    }

    // multiple fields can be grouped into one temporal multi property
    const dialogSettingsColumns = storage.getInfo(bindingContext)?.fieldSettings?.temporalDialog?.columns;

    if (dialogSettingsColumns) {
        for (const column of dialogSettingsColumns) {
            properties.add(column);

            const colBc = bindingContext.getParent().navigate(column);

            // add Code property for enum navigations
            if (colBc.isEnum()) {
                const codeProperty = colBc.getProperty()?.getReferentialConstraint()?.property;

                properties.add(codeProperty);
            }
        }
    }

    return Array.from(properties);
}, (bc: BindingContext) => {
    return bc.getFullPath();
});

export const setTemporalRangeNewValue = (storage: FormStorage, e: ISmartFieldChange, range: TTemporal): TTemporal => {
    const bc = e.bindingContext;
    let updatedRange = { ...range };

    if (bc.isEnum()) {
        updatedRange[bc.getPath()] = e.additionalData;
        updatedRange[bc.getProperty().getReferentialConstraint().property] = e.value;
    } else {
        updatedRange = updateDataOnChange({
            storage,
            dataBindingContext: bc.getParent(),
            bindingContext: bc,
            data: range,
            newValue: e.value,
            preventCloning: true
        });
    }

    return updatedRange;
};

export const getTemporalPropertyExtraFieldContentAfter = (customTemporalNavigationPath?: string, customTemporalPropertyPath?: string): TGetValueFn<React.ReactElement> => {
    return (args: IGetValueArgs) => (
            <SmartTemporalPropertyButton storage={args.storage as FormStorage}
                                         bindingContext={args.bindingContext}
                                         customTemporalNavigationPath={customTemporalNavigationPath}
                                         customTemporalPropertyPath={customTemporalPropertyPath}
            />
    );
};

export const getTimelineStatus = (temporalItem: TTemporal): SimpleTimeLineStatus => {
    const _lastWage = lastWageDate();
    return _lastWage.isBetween(temporalItem.DateValidFrom, temporalItem.DateValidTo, "day", "[]") ? SimpleTimeLineStatus.Current
            : _lastWage.isAfter(temporalItem.DateValidTo) ? SimpleTimeLineStatus.History : SimpleTimeLineStatus.Future;

};

const getTemporalItemTimeLineStatus = (storage: FormStorage, bindingContext: BindingContext, dataStore = storage.data.origEntity) => {
    // saved items that are in the past should no longer be editable
    const key = bindingContext.getKey();

    if (isNotDefined(key)) {
        return null;
    }

    const item = storage.getValue(bindingContext, { dataStore: dataStore });

    if (!item) {
        return null;
    }

    return getTimelineStatus(item);
};

export const checkTemporalItemTimeLineStatus = (storage: FormStorage, bindingContext: BindingContext, status: SimpleTimeLineStatus[], dataStore?: TRecordAny): boolean => {
    return status.includes(getTemporalItemTimeLineStatus(storage, bindingContext, dataStore));
};

const isEditableRangeDateField = (row: TTemporal, bindingContext: BindingContext) => {
    const path = bindingContext.getPath();
    if (path === "DateValidTo") {
        // todo: distinguish somehow last row of the expanded history,
        //  where we want to keep the DateValidTo editable
        const isLastExpandedHistoryRange = true;
        // todo: check the original value, not the current one... Or make it editable if dirty
        const isEditableDate = getUtcDayjs(row.DateValidTo).isAfter(lastWageDate());
        return isLastExpandedHistoryRange && isEditableDate;
    }
    return false;
};

export const isTemporalFieldReadOnly: TGetValueFn<boolean> = (args: IGetValueArgs) => {
    const itemBc = args.bindingContext.getParent();

    const row = args.storage.getValue(itemBc);
    if (row?.SourceCode && row.SourceCode !== PrEntityValueSourceCode.Entity) {
        return !isEditableRangeDateField(row, args.bindingContext);
    }

    return false; // todo: checkTemporalItemTimeLineStatus(args.storage as FormStorage, itemBc, [SimpleTimeLineStatus.History]);
};

export const isTemporalLineItemRemovable: TGetValueFn<boolean> = (args: IGetValueArgs) => {
    return !checkTemporalItemTimeLineStatus(args.storage as FormStorage, args.bindingContext, [SimpleTimeLineStatus.History, SimpleTimeLineStatus.Current]);
};


export const TemporalValidityFieldsDef: TFieldsDefinition = {
    "TemporalPropertyBag/DateValidFrom": {
        fieldSettings: {
            showSpecialValue: DateRangeSpecialValue.WithoutStart
        },
        isReadOnly: isTemporalFieldReadOnly
    },
    "TemporalPropertyBag/DateValidTo": {
        fieldSettings: {
            showSpecialValue: DateRangeSpecialValue.WithoutEnd
        },
        isReadOnly: isTemporalFieldReadOnly
    }
};

export const getGranularity = (dialogSettings: TTemporalDialogSettings): TTemporalDialogSettings["granularity"] => {
    return dialogSettings?.granularity ?? "month";
};

export function getTemporalAdditionalProperties<T extends TRecordAny, E extends TRecordAny>(tempEntity: T, entity: E): IFieldDef[] {
    // we always have to load all TemporalProperties for the range (un)grouping to work properly
    // and so that we are able to show correct (un)filled icon next to temporary property fields
    return Object.keys(tempEntity)
            .filter(key => !systemProperties.includes(key))
            .map(key => ({ id: `${entity.TemporalPropertyBag}/${key}` }));
}

// return start of current month,
// or start of next month after the latest item
export const getDefaultTemporalDateValidFrom = (dialogSettings: TTemporalDialogSettings, items?: TTemporal[]): Date => {
    if (dialogSettings?.customDateValidFrom) {
        return dialogSettings.customDateValidFrom(items);
    }

    const granularity = getGranularity(dialogSettings);
    const startOfCurrentGranularity = getUtcDayjs().startOf(granularity);

    if (!items || items.length === 0) {
        return startOfCurrentGranularity.toDate();
    }

    const previousItemDateValidFrom = getUtcDayjs(items[items.length - 2].DateValidFrom);
    const diff = previousItemDateValidFrom.startOf("day").diff(startOfCurrentGranularity, "day");

    if (diff >= -1) {
        return previousItemDateValidFrom.startOf(granularity).add(diff <= 0 && granularity === "day" ? 2 : 1, granularity).toDate();
    }

    return startOfCurrentGranularity.toDate();
};

export const getDefaultTemporalDateValidTo = (dialogSettings: TTemporalDialogSettings, items?: TTemporal[]): Date => {
    return DATE_MAX;
};

export const getCurrentRangeIndex = (ranges: TTemporal[], date?: Date): number => {
    const today = getUtcDayjs(date);

    return ranges.findIndex(range => today.isSameOrAfter(range.DateValidFrom, "date") && today.isSameOrBefore(range.DateValidTo, "date"));
};

export const updateTemporalRangesAfterCurrentTempPropChange = (e: ISmartFieldChange, storage: FormStorage, temporalBagProperty: string = TemporalEntityProp.TemporalPropertyBag): void => {
    const properties = getGroupingProperties(e.bindingContext, storage);
    const temporalBag = storage.getValueByPath(temporalBagProperty) as TTemporal[] ?? [];
    const groupedRanges = groupAndOptimizeRanges(temporalBag, properties);
    const today = getUtcDayjs();
    const dialogSettings = storage.getInfo(e.bindingContext)?.fieldSettings?.temporalDialog;
    const fromDate = getUtcDayjs(getDefaultTemporalDateValidFrom(dialogSettings));
    let toDate = getDefaultTemporalDateValidTo(dialogSettings);
    let currentRangeIndex: number;
    let currentRange: typeof groupedRanges[number];

    if (groupedRanges.length !== 0) {
        currentRangeIndex = getCurrentRangeIndex(groupedRanges);
        currentRange = groupedRanges[currentRangeIndex];

        if (currentRange) {
            if (fromDate.isSame(currentRange.DateValidFrom, "day")) {
                // same range, just change value and don't create new one
                toDate = null;
                currentRange = setTemporalRangeNewValue(storage, e, currentRange);
            } else {
                // split the range and create a new one
                currentRange.DateValidTo = fromDate.subtract(1, "day").toDate();
            }
        }

        const nextRangeIndex = groupedRanges.findIndex(range => today.isBefore(range.DateValidFrom));
        const nextRange = groupedRanges[nextRangeIndex];

        if (nextRange && toDate) {
            toDate = getUtcDayjs(nextRange.DateValidFrom).subtract(1, "day").toDate();

            // if next range starts tomorrow, move it by day
            if (fromDate.isSame(toDate, "day")) {
                toDate = nextRange.DateValidFrom;
                groupedRanges[nextRangeIndex] = {
                    ...nextRange,
                    DateValidFrom: fromDate.add(2, "day").toDate()
                };
            }
        }
    }

    if (toDate) {
        const newRange = setTemporalRangeNewValue(storage, e, {
            ...(currentRange ?? {}),
            DateValidFrom: fromDate.toDate(),
            DateValidTo: toDate
        });
        groupedRanges.push(newRange);
    }

    if (isDefined(currentRangeIndex)) {
        groupedRanges[currentRangeIndex] = currentRange;
    }

    const ungroupedRanges = ungroupTemporalRanges(groupedRanges, temporalBag, properties);
    storage.setValueByPath(temporalBagProperty, ungroupedRanges);
};

export const handleCurrentTemporalPropChange = (e: ISmartFieldChange, storage: FormStorage, temporalBagProperty: string = TemporalEntityProp.TemporalPropertyBag): void => {
    if (e.bindingContext.getParent().getPath() !== "CurrentTemporalPropertyBag") {
        return;
    }

    updateTemporalRangesAfterCurrentTempPropChange(e, storage, temporalBagProperty);
};

// has to be called for required select values that has default value
// in CurrentTemporalPropertyBag, this value has to be propagated to TemporalPropertyBag
export const setTemporalRequiredSelectDefaultPropertyBagValue = (bindingContext: BindingContext, storage: FormStorage): void => {
    const value = storage.getValue(bindingContext)?.Code;
    const additionalData = getEnumSelectItems(bindingContext.getEntityType().getName()).find(item => item.id === value).additionalData;

    handleCurrentTemporalPropChange({
        bindingContext,
        additionalData,
        value: value
    }, storage);
};

export const EntityValueSourcePropNameSuffix = "SourceCode";

export function isFieldSynchronized({ storage, bindingContext }: IGetValueArgs): boolean {
    const propPath = bindingContext.getPath();
    const enumBc = bindingContext.getParent().navigate(`${propPath}${EntityValueSourcePropNameSuffix}`);
    const sourceCode = storage.getValue(enumBc) as PrEntityValueSourceCode;
    return isDefined(sourceCode) && sourceCode !== PrEntityValueSourceCode.Entity;
}

interface IGetTemporalPropertyFieldDefinitionArgs {
    propName: string;
    // definition that will be applied to both TemporalPropertyBag and CurrentTemporalPropertyBag
    definition?: TFieldDefinition;
    // definition that will be applied only to the CurrentTemporalPropertyBag field shown in form
    currentDefinition?: TFieldDefinition;
    withOpeningButton?: boolean;
    isSynchronized?: boolean;
}

interface IGetTemporalPropertyLineItemFieldDefinitionArgs extends IGetTemporalPropertyFieldDefinitionArgs {
    collectionName: string;
}

export function getTemporalPropertyLineItemFieldDefinition(args: IGetTemporalPropertyLineItemFieldDefinitionArgs): TFieldsDefinition {
    const def: TFieldDefinition = args.definition ?? {};
    const currentDef: TFieldDefinition = {
        ...(args.definition ?? {}),
        ...(args.currentDefinition ?? {}),
        extraFieldContentAfter: args.withOpeningButton === true ? getTemporalPropertyExtraFieldContentAfter() : null
    };

    const currentPropertyBagPath = createPath(args.collectionName, TemporalEntityProp.CurrentTemporalPropertyBag, args.propName);

    const additionalProperties = currentDef.additionalProperties ?? [];
    const fieldSettings: IFieldDef["fieldSettings"] = {
        ...(currentDef.fieldSettings ?? {})
    };

    if (args.isSynchronized) {
        additionalProperties.push({ id: `/${currentPropertyBagPath}${EntityValueSourcePropNameSuffix}` });
        fieldSettings.isSynchronized = isFieldSynchronized;
    }

    const tmpFieldsDef: TFieldsDefinition = {};
    forEachKey(TemporalValidityFieldsDef, (key) => {
        const collectionKey = createPath(args.collectionName, key);
        tmpFieldsDef[collectionKey] = TemporalValidityFieldsDef[key];
    });

    return {
        [createPath(args.collectionName, TemporalEntityProp.TemporalPropertyBag, args.propName)]: {
            ...def,
            isReadOnly: def.isReadOnly ? ifAny(def.isReadOnly, isTemporalFieldReadOnly) : isTemporalFieldReadOnly
        },
        [currentPropertyBagPath]: {
            ...currentDef,
            fieldSettings,
            additionalProperties
        },
        ...tmpFieldsDef
    };
}

export function getTemporalPropertyFieldDefinition(args: IGetTemporalPropertyFieldDefinitionArgs): TFieldsDefinition {
    const currentDefinition = {
        ...(args.currentDefinition ?? {})
    };

    return getTemporalPropertyLineItemFieldDefinition({
        ...args,
        collectionName: null,
        currentDefinition,
        withOpeningButton: args.withOpeningButton !== false
    });
}

function getRangeWithDefault(temporalSettings: TTemporalDialogSettings, range: TTemporal): TTemporal {
    let { DateValidFrom, DateValidTo } = range ?? {};

    if (!DateValidFrom) {
        DateValidFrom = getDefaultTemporalDateValidFrom(temporalSettings);
    }
    if (!DateValidTo) {
        DateValidTo = getDefaultTemporalDateValidTo(temporalSettings);
    }
    return { DateValidFrom, DateValidTo };
}

export function createNewTemporalLineItem<T extends IEntity = IEntity, TemporalT = TTemporal>(temporalSettings: TTemporalDialogSettings, values: T, range?: TTemporal<TemporalT>): T {
    const { DateValidFrom, DateValidTo } = getRangeWithDefault(temporalSettings, range);

    return {
        ...values,
        DateValidFrom, DateValidTo,
        TemporalPropertyBag: [{
            ...range,
            DateValidFrom, DateValidTo
        }]
    } as T;
}

/**
 * Enhances new line item entity with temporal settings, changes the entity given in params.
 * @param temporalSettings
 * @param item
 * @param range
 */
export function enhanceNewTemporalLineItem<T extends TTemporalLineItem = TTemporalLineItem, TemporalT = TTemporal>(temporalSettings: TTemporalDialogSettings, item: T, range?: TTemporal<TemporalT>): void {
    const { DateValidFrom, DateValidTo } = getRangeWithDefault(temporalSettings, range);

    item.DateValidFrom = DateValidFrom;
    item.DateValidTo = DateValidTo;
    item.TemporalPropertyBag = [{
        ...item[TemporalEntityProp.CurrentTemporalPropertyBag],
        DateValidFrom, DateValidTo
    }];
}

export function handleTemporalLineItemsActions<T extends TTemporalLineItem = TTemporalLineItem>(storage: FormStorage, args: ISmartFastEntriesActionEvent<T>): void {

    const _defaultBehavior = () => {
        storage.handleLineItemsAction(args);
        storage.refresh();
    };

    switch (args.actionType) {
        case ActionType.Add:
            // new item has to be enhanced with temporal property bag and other props
            const { definition } = storage.data;
            const groupId = args.bindingContext.getPath(true);
            const groupDef = definition.groups.find(item => item.id === groupId);
            const [item] = args.affectedItems;
            enhanceNewTemporalLineItem(groupDef.lineItems.temporalDialog, item);

            _defaultBehavior();
            break;

        case ActionType.Remove:
            // todo: if line item is not fully editable, just set the DateValidTo to last editable date
            _defaultBehavior();
            break;

        case ActionType.Custom:
        case ActionType.Clone:
            // clone actions are not defined for temporal line items, customAction should be handled in caller
            throw new Error(`ActionType '${args.actionType}' not handled in common method`);

        default:
            _defaultBehavior();
    }
}

export function replaceSyncedValueWithHistoryValues<T extends Partial<TTemporal<IEntity>>>(value: T, historyData: T[], sourceCode: PrEntityValueSourceCode = PrEntityValueSourceCode.Default): T[] {
    if (!isEmptyRange(value) && !isSynchronizedRange(value as TTemporal)) {
        return [value];
    }
    const newRanges: T[] = [];

    const fromDayjs = getUtcDayjs(value.DateValidFrom);
    const toDayjs = getUtcDayjs(value.DateValidTo);

    historyData.forEach((range, idx) => {
        // if value range overlaps with history data (at least partly)
        if (fromDayjs.isBefore(range.DateValidTo) && toDayjs.isAfter(range.DateValidFrom)) {
            newRanges.push({
                ...range,
                DateValidFrom: fromDayjs.isBefore(range.DateValidFrom) ? range.DateValidFrom : value.DateValidFrom,
                DateValidTo: toDayjs.isAfter(range.DateValidTo) ? range.DateValidTo : value.DateValidTo,
                // range might be already expanded from TemplateDefault, so keep the original source
                SourceCode: range.SourceCode ?? sourceCode,
            });
        }
    });

    return newRanges;
}

export function cleanupHistoryValues<T extends Partial<TTemporal<IEntity>>>(temporalPropertyBag: T[]): T[] {
    return temporalPropertyBag.map(item => {
        if (item.SourceCode) {
            // if this is synced row from another entity, we clear all the values and use just the range
            return {
                DateValidFrom: item.DateValidFrom,
                DateValidTo: item.DateValidTo,
            } as T;
        }
        return item;
    });
}
