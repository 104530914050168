import React, { MouseEvent, ReactElement } from "react";
import {
    CheckboxContainer,
    CheckboxLabel,
    CheckIconStyled,
    HiddenCheckbox,
    InnerLabel,
    StyledCheckbox
} from "./Checkbox.styles";
import { KeyName } from "../../../keyName";
import TestIds from "../../../testIds";
import { IAuditTrailData } from "../../../model/Model";
import { getTabIndex } from "../../auditTrail/Utils";
import { IValueInputComponentProps } from "../input";
import { InputStatusStraight } from "../input/Input.styles";
import { Status } from "../../../enums";
import { FIELD_CONTENT_PADDING } from "../field/Field.styles";

export interface ICheckboxChange {
    origEvent: Event;
    value: boolean;
}

export interface ICheckboxRenderChildProps {
    checkbox: React.Component;
}

export interface IProps extends IValueInputComponentProps {
    onChange: (args: ICheckboxChange) => void;
    checked?: boolean;
    auditTrailData?: IAuditTrailData;

    /** Label rendered after the checkbox*/
    label?: React.ReactNode;
    title?: string;
    isLight?: boolean;
    className?: string;
    style?: React.CSSProperties;
    passProps?: any;
    decorative?: boolean;
}

export default class Checkbox extends React.PureComponent<IProps> {
    _checkboxRef = React.createRef<HTMLInputElement>();

    handleClick = (e: MouseEvent<HTMLElement> | React.ChangeEvent): void => {
        if (!e.defaultPrevented) {
            this.focus();
            this.select(e);
        }
    };

    handleMouseDown = (e: React.MouseEvent): void => {
        e.preventDefault();
        this.focus();
    };

    handleKeyDown = (e: React.KeyboardEvent): void => {
        // check only enter, space is already handled by browser as onClick
        if ((e.key === KeyName.Space || e.key === KeyName.Enter) && !e.defaultPrevented) {
            this.select(e);
        }
    };

    focus = (): void => {
        this._checkboxRef.current.focus();
    };

    select = (e: MouseEvent<HTMLElement> | React.ChangeEvent | React.KeyboardEvent): void => {
        // prevents second call of handleClick (one from HiddenCheckbox, one from CheckboxContainer) when space is pressed
        e.preventDefault();
        // prevents from causing multiple different events when used inside other component (e.g. in select)
        e.stopPropagation();
        this.props.onChange({
            origEvent: e as unknown as Event,
            // use this.props.checked instead of this._checkboxRef.current
            // this._checkboxRef has already changed checked state from browser when 'space' is pressed
            value: !this.props.checked
        });
    };

    isDisabled = (): boolean => {
        // CheckBox in readOnly state is supposed to look the same as in disabled state
        return this.props.isDisabled || this.props.isReadOnly;
    };

    renderCheckbox(): ReactElement {
        return (
            <StyledCheckbox
                auditTrailType={this.props.auditTrailData?.type}
                checked={this.props.checked}
                hasLabel={!!this.props.label}
                $isLight={this.props.isLight}
                $decorative={this.props.decorative}
                data-testid={TestIds.Checkbox}>
                <HiddenCheckbox checked={this.props.checked}
                                {...this.props.passProps}
                                tabIndex={this.props.passProps?.tabIndex ?? getTabIndex(this.props)}
                                onChange={this.handleClick}
                                disabled={this.isDisabled()}
                                ref={this._checkboxRef}/>
                {this.props.checked &&
                <CheckIconStyled
                    $decorative={this.props.decorative}
                    auditTrailType={this.props.auditTrailData?.type}
                    checked={this.props.checked}/>
                }
            </StyledCheckbox>
        );
    }

    renderLabel(): ReactElement {
        return this.props.label && (
            <CheckboxLabel
                auditTrailType={this.props.auditTrailData?.type}
                isLight={this.props.isLight}>
                <InnerLabel
                    auditTrailType={this.props.auditTrailData?.type}
                    data-testid={TestIds.CheckboxLabel}>
                    {this.props.label}
                </InnerLabel>
            </CheckboxLabel>
        );
    }

    render() {
        return (
            <CheckboxContainer className={this.props.className} style={this.props.style} onKeyDown={this.handleKeyDown}
                               hasLabel={!!this.props.label} isDisabled={this.isDisabled()}
                               title={this.props.title}
                               onMouseDown={this.handleMouseDown}
                               onClick={this.handleClick}
                               data-testid={TestIds.CheckboxContainer}>
                {typeof this.props.children === "function"
                    ? this.props.children({ checkbox: this.renderCheckbox() })
                    : (
                        <>
                            {this.renderCheckbox()}
                            {this.props.children}
                        </>
                    )}
                {this.renderLabel()}
                {!this.props.isReadOnly && this.props.showChange && <InputStatusStraight left={`-${FIELD_CONTENT_PADDING}px`} status={Status.Warning}/>}
            </CheckboxContainer>
        );
    }
}