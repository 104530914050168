import React, { useCallback, useContext, useRef, useState } from "react";
import { Omit, useTranslation } from "react-i18next";
import { getEditableWindowDefinition as getDefinitions, MinorAssetAction } from "./MinorAssetDef";
import { ActionType, ISmartFastEntriesActionEvent } from "@components/smart/smartFastEntryList";
import { PageViewMode } from "../../../enums";
import { FormStorage } from "../../../views/formView/FormStorage";
import Dialog from "../../../components/dialog/Dialog";
import i18next from "i18next";
import DialogPage from "../../DialogPage";
import PairingMinorAssetTableView from "./PairingMinorAssetTableView";
import BindingContext from "../../../odata/BindingContext";
import { MinorAssetFormPage } from "./MinorAssetFormPage";
import { ConfirmationDialogContext } from "@components/dialog/ConfirmationDialogProvider";
import { IMinorAssetItemEntity } from "@odata/GeneratedEntityTypes";
import { hasMinorAssetBound, MINOR_ASSET_FORM_TAB } from "./MinorAsset.utils";

export interface WithMinorAssetPairing {
    onLineItemsAction?: (args: ISmartFastEntriesActionEvent, storage: FormStorage) => Promise<boolean>;
}

interface WithMinorAssetPairingProps {
    onLineItemsAction?: (args: ISmartFastEntriesActionEvent, storage: FormStorage) => Promise<boolean>;
}

type TWithMinorAssetPairing<P> = Omit<P, keyof WithMinorAssetPairing> & WithMinorAssetPairingProps;

export const withMinorAssetPairing = <P extends WithMinorAssetPairing>(Component: React.ComponentType<P>): React.ComponentType<TWithMinorAssetPairing<P>> => {

    return React.forwardRef((props: TWithMinorAssetPairing<P>, ref) => {
        const { onLineItemsAction } = props;

        const { t, ready: tReady } = useTranslation(getDefinitions.translationFiles);
        const confirmationDialog = useContext(ConfirmationDialogContext);

        const documentStorage = useRef<FormStorage>(null);
        const actionArgs = useRef<ISmartFastEntriesActionEvent>(null);

        const [dialogType, setDialogType] = useState<boolean | MinorAssetAction>(false);
        const [activeRowsCount, setActiveRowsCount] = useState<number>(0);

        const handleLineItemsAction = useCallback(async (args: ISmartFastEntriesActionEvent, docStorage: FormStorage) => {
            let shouldInterrupt;

            if (args.actionType === ActionType.Remove) {
                const ids = args.affectedItems.map(item => item.Id).filter(Boolean);
                // todo: go through the data loaded into the table below the form in tabs if we would have access to them from here
                const hasMinorAsset = !ids.length ? false : await hasMinorAssetBound(docStorage.oData, ids);

                if (hasMinorAsset) {
                    // we are trying to remove line item with minor asset relation - shows confirmation dialog
                    const content = (<>
                        {t("Document:Confirmations.ConfirmDeleteMinorAsset")}<br/><br/>
                        {t("Common:Confirmations.ConfirmContinue")}
                    </>);
                    const shouldContinue = await confirmationDialog.open({
                        content
                    });

                    shouldInterrupt = !shouldContinue;
                } else {
                    // no minor asset is connected to the line item, could be deleted in normal way
                    shouldInterrupt = false;
                }

            } else if (args.actionType === ActionType.Custom &&
                [MinorAssetAction.Create, MinorAssetAction.Pair].includes(args.customActionType as MinorAssetAction)) {

                // load translation files
                await i18next.loadNamespaces(getDefinitions.translationFiles);

                documentStorage.current = docStorage;
                actionArgs.current = args;
                // shows pairing/creation dialog
                setDialogType(args.customActionType as MinorAssetAction);
                shouldInterrupt = true;
            } else if (onLineItemsAction) {
                // to enable chaining of multiple HOC wrappers, we need to propagate onLineItemsAction
                shouldInterrupt = await onLineItemsAction?.(args, docStorage);
            } else {
                shouldInterrupt = false;
            }

            return shouldInterrupt;
        }, [confirmationDialog, onLineItemsAction, tReady]);


        const handleClose = useCallback(() => {
            setActiveRowsCount(0);
            setDialogType(false);
        }, []);

        const handleCreate = useCallback((bindingContext: BindingContext, assetItems: IMinorAssetItemEntity[]) => {
            setActiveRowsCount(0);
            documentStorage.current.updateTabsVisibility([MINOR_ASSET_FORM_TAB]);
        }, []);

        const showConfirmDialog = useCallback(async () => {
            await confirmationDialog.open({
                content: i18next.t("MinorAsset:PairingTableView.AssetNotConfirmed"),
                confirmText: i18next.t("MinorAsset:PairingTableView.DoNotComplete"),
                onConfirm: handleClose
            });
        }, [confirmationDialog, handleClose]);

        const items = actionArgs.current?.items;
        return (
            <>
                <Component {...props as P}
                           onLineItemsAction={handleLineItemsAction}
                           ref={ref}/>

                {!!dialogType &&
                    <Dialog isEditableWindow={true}
                            onConfirm={null}
                            onClose={activeRowsCount ? showConfirmDialog : handleClose}>

                        {dialogType === MinorAssetAction.Create &&
                                <MinorAssetFormPage rootStorage={documentStorage.current}
                                                    onClose={handleClose}
                                                    onCreateItem={handleCreate}
                                                    items={items}
                                />
                        }

                        {dialogType === MinorAssetAction.Pair &&
                            <DialogPage
                                pageViewMode={PageViewMode.FormReadOnly}
                                rootStorage={documentStorage.current}
                                tableView={PairingMinorAssetTableView}
                                tableViewProps={{
                                    onClose: handleClose,
                                    onActiveRowActionCountChange: setActiveRowsCount,
                                    onCreateItem: handleCreate,
                                    items
                                }}
                                getDef={getDefinitions}
                            />}
                    </Dialog>
                }
            </>
        );
    }) as any;
};