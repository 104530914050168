import React, { useEffect, useState } from "react";
import { Redirect, useHistory, withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { BasicLoginTextBlock, LoginSmallBottomClickable, LoginSmallBottomText, LoginTitle } from "./LoginPage.styles";
import { AnimationType, LoginTranslationNamespaces } from "./Login.utils";
import { selectIsSessionLoaded, selectSessionData } from "./state/sessionSlice";
import { ButtonSize } from "@components/button/Button.utils";
import TestIds from "../testIds";
import RegistrationForm from "./components/RegisterForm";
import { ROUTE_HOME, ROUTE_LOGIN, ROUTE_LOGIN_REGISTER, ROUTE_LOGIN_TENANT } from "../routes";
import { useTranslation } from "react-i18next";
import { Button } from "../components/button";
import {
    InvitationStatus,
    loadInvitation,
    registerInvitation,
    selectInvitationData,
    selectInvitationStatus,
    selectIsInvitationLoaded,
    setCode,
    setInvitationData
} from "./state/invitationSlice";
import { useLoginDispatch, useLoginSelector } from "./state/hooks";
import { setAnimationType } from "./state/loginSlice";
import Redirects from "./components/Redirects";
import { withBusyIndicator, WithBusyIndicator } from "@components/busyIndicator/withBusyIndicator";
import LoginButtonGroup from "./components/LoginButtonGroup";
import { logout } from "../contexts/authContext/Auth.utils";

interface IInvitationParams {
    code: string;
}

interface IProps extends RouteComponentProps<IInvitationParams>, WithBusyIndicator {
}

const Invitation: React.FC<IProps> = ({ match, setBusy }) => {
    const { t } = useTranslation([...LoginTranslationNamespaces]);
    const history = useHistory();

    const dispatch = useLoginDispatch();
    // session is loaded on LoginPage level
    const session = useLoginSelector(selectSessionData);
    const invitationInfo = useLoginSelector(selectInvitationData);
    const status = useLoginSelector(selectInvitationStatus);

    const isInvitationLoaded = useLoginSelector(selectIsInvitationLoaded);
    const isSessionLoaded = useLoginSelector(selectIsSessionLoaded);

    const { code } = match.params;
    const [isConnected, setIsConnected] = useState<boolean>(false);

    useEffect(() => {
        dispatch(loadInvitation(code));
        dispatch(setAnimationType(AnimationType.StaticBiker));
    }, []);

    const loaded = isInvitationLoaded && isSessionLoaded;

    useEffect(() => {
        if (loaded) {
            setBusy(false);
        }
    }, [loaded, setBusy]);

    const { Email: invitationEmail, TenantName: company } = invitationInfo ?? {};
    const hasSession = !!session;

    const handleLogout = () => logout();
    const handleConnectAccount = async () => {
        const success = await dispatch(registerInvitation(code));

        if (success) {
            setIsConnected(true);
        }
    };
    const handleContinue = () => {
        // reset invitation, let redirects do the job
        dispatch(setInvitationData(null));
        dispatch(setCode(null));
        setIsConnected(false);
        history.push(ROUTE_LOGIN_TENANT);
    };

    const renderedRedirects = (
        <Redirects skipActiveSessionCheck skipNoSessionCheck/>
    );

    switch (true) {
        case !loaded:
            return null;

        case status === InvitationStatus.Accepted:
            return (<Redirect to={ROUTE_LOGIN_TENANT}/>);

        case status !== InvitationStatus.Idle:
            // const key = status === InvitationStatus.Expired ? "AlreadyAccess" : "WrongInvitation";
            // error occured - render error to the user
            return (<>
                {renderedRedirects}
                <LoginTitle data-testid={TestIds.Title}>{t("Login:Invitation.InvalidLinkTitle")}</LoginTitle>

                <BasicLoginTextBlock
                    data-testid={TestIds.Text}>{t(`Login:Invitation.InvalidLinkText`)}</BasicLoginTextBlock>

                {hasSession ? (
                    <LoginButtonGroup>
                        <Button size={ButtonSize.Big}
                                link={ROUTE_HOME}>{t("Login:Invitation.Continue")}</Button>
                    </LoginButtonGroup>
                ) : (
                    <LoginButtonGroup additionalText={(<>
                        <LoginSmallBottomText
                            data-testid={TestIds.BottomTextLabel}>{t("Login:Registration.AlreadyRegistered")}</LoginSmallBottomText>
                        <LoginSmallBottomClickable testId={TestIds.BottomTextLink}
                                                   link={`${ROUTE_LOGIN}`}>{t("Login:Registration.LoginHere")}</LoginSmallBottomClickable>
                    </>)}>
                        <Button size={ButtonSize.Big}
                                link={ROUTE_LOGIN_REGISTER}>{t("Login:Registration.Register")}</Button>
                    </LoginButtonGroup>
                )}
            </>);

        case isConnected:
            // render confirmation of connection with current account
            return (<>
                {renderedRedirects}
                <LoginTitle data-testid={TestIds.Title}>
                    {t("Login:Invitation.ConfirmationTitle")}
                </LoginTitle>

                <BasicLoginTextBlock data-testid={TestIds.Text}>{t("Login:Invitation.ConfirmationConnectDescription", {
                    invitationEmail: hasSession ? session.LoginEmail : invitationEmail,
                    company
                })}</BasicLoginTextBlock>

                <LoginButtonGroup>
                    <Button size={ButtonSize.Big}
                            onClick={handleContinue}>{t("Login:Invitation.Continue")}</Button>
                </LoginButtonGroup>
            </>);

        case hasSession:
            // if existing account has same email as the one from invitation, there is no option
            // to create a new account with the email contained in the invitation
            const canCreateNewAccount = session.LoginEmail !== invitationEmail;
            // Ask about connect invitation with existing account
            return (<>
                {renderedRedirects}
                <LoginTitle data-testid={TestIds.Title}>{t("Login:Invitation.Title")}</LoginTitle>

                <div data-testid={TestIds.Text}>
                    <BasicLoginTextBlock>{t("Login:Invitation.Description", {
                        invitationEmail,
                        company
                    })}</BasicLoginTextBlock>
                    <BasicLoginTextBlock
                        as="span">{t("Login:Invitation.ConnectDescription", { loginEmail: session.LoginEmail })}</BasicLoginTextBlock>
                </div>

                <LoginButtonGroup additionalText={canCreateNewAccount && (<>
                    <LoginSmallBottomText
                        data-testId={TestIds.BottomTextLabel}>{t("Login:Invitation.ConnectAltText")}</LoginSmallBottomText>
                    <LoginSmallBottomClickable testId={TestIds.BottomTextLink} onClick={handleLogout}>
                        {t("Login:Invitation.ConnectAltLink")}
                    </LoginSmallBottomClickable>
                </>)}>
                    <Button size={ButtonSize.Big}
                            onClick={handleConnectAccount}>{t("Login:Invitation.Connect")}</Button>
                </LoginButtonGroup>
            </>);

        default:
            return (<>
                {renderedRedirects}
                <LoginTitle data-testid={TestIds.Title}>{t("Login:Invitation.Title")}</LoginTitle>

                <div data-testid={TestIds.Text}>
                    <BasicLoginTextBlock>{t("Login:Invitation.Description", {
                        invitationEmail,
                        company
                    })}</BasicLoginTextBlock>
                    <BasicLoginTextBlock>{t("Login:Invitation.ActivateDescription")}</BasicLoginTextBlock>
                </div>

                <RegistrationForm>
                    <LoginSmallBottomText
                        data-testId={TestIds.BottomTextLabel}>{t("Login:Invitation.ActivateAltText")}</LoginSmallBottomText>
                    <LoginSmallBottomClickable testId={TestIds.BottomTextLink} link={ROUTE_LOGIN}>
                        {t("Login:Invitation.ActivateAltLink")}
                    </LoginSmallBottomClickable>
                </RegistrationForm>
            </>);
    }
};

export default withRouter(withBusyIndicator({ isDelayed: true, defaultBusyState: true })(Invitation));
