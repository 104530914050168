import { getAccountsParamDef, IReportTableDefinition, TReportConfigListItemOverride } from "../Report.utils";
import i18next from "i18next";
import { ConfigListItemBoundType, FieldType, Sort } from "../../../enums";
import {
    assetIntentFormatter,
    CommonReportProps,
    documentNumberOursOverrideFormatterWithPaymentDocs,
    getDocumentTypeFilterDef
} from "../CommonDefs";
import { IReportHierarchy, IReportVariantDef } from "@components/smart/smartTable";
import BindingContext from "../../../odata/BindingContext";
import { ReportId } from "../ReportIds";
import {
    composedDateRangeOnBeforeLoadCallback,
    getComposedDateRangeFilterDef
} from "../customFilterComponents/ComposedDateRange";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { ReportStorage } from "../ReportStorage";
import { TRecordType } from "../../../global.types";
import { getSimpleBoolSelectItems } from "../../../components/smart/FieldInfo";
import { SaldoProps } from "../saldo/SaldoDef";

export const AccountAnalysisProps = {
    accounts: BindingContext.localContext("Accounts"),
    drilldown: BindingContext.localContext("DrillDown"),
    sumAmounts: BindingContext.localContext("SumAmounts"),
    closingAccounts: BindingContext.localContext("ClosingAccounts")
};

export enum ClosingAccounts {
    Show = "Show",
    DoNotShow = "DoNotShow"
}

export const AccountAnalysisConfiglistSettings: TRecordType<TReportConfigListItemOverride> = {
    JournalEntry_Amount: {
        isRequired: true,
        areItemsReadOnly: true,
        boundTo: ConfigListItemBoundType.Column
    }
};

export enum AccountAnalysisVariant {
    Evala = "-69", // default variant
    FYCBankDrilldown = "fycbank",    // FE only variant - used just in drilldown
}

export const getDefinition = (): IReportTableDefinition => {
    const tableId = ReportId.AccountAnalysis;
    const title = i18next.t("Reporting:AccountAnalysis.Title");
    const path = "AccountAnalysis";
    const initialSortBy = [
        { id: "Account_Number", sort: Sort.Asc },
        { id: "JournalEntry_DateAccountingTransaction", sort: Sort.Desc },
        { id: "Document_NumberOurs", sort: Sort.Asc }
    ];

    const filterBarDef: IFilterGroupDef[] = [
        {
            ...getDefaultFilterGroupDef(FilterBarGroup.Parameters),
            defaultFilters: [
                CommonReportProps.dateRange,
                AccountAnalysisProps.accounts,
                AccountAnalysisProps.drilldown,
                AccountAnalysisProps.sumAmounts,
                AccountAnalysisProps.closingAccounts
            ],
            filterDefinition: {
                [CommonReportProps.dateRange]: getComposedDateRangeFilterDef(tableId),
                [AccountAnalysisProps.accounts]: getAccountsParamDef([], true),
                // hidden parameter only used for drilldown from BalanceSheet
                // not supposed to be seen in filter bar at all
                [AccountAnalysisProps.drilldown]: {
                    // needs to be true for table storage to load info
                    // set to false onAfterLoad
                    isVisible: true,
                    defaultValue: "None"
                },
                [SaldoProps.sumAmounts]: {
                    type: FieldType.ComboBox,
                    defaultValue: false,
                    label: i18next.t("Reporting:AccountAnalysis.SumAmounts"),
                    fieldSettings: {
                        items: getSimpleBoolSelectItems()
                    }
                },
                [AccountAnalysisProps.closingAccounts]: {
                    type: FieldType.ComboBox,
                    label: i18next.t("Reporting:AccountAnalysis.ClosingAccountsParam"),
                    defaultValue: "DoNotShow",
                    fieldSettings: {
                        items: [
                            {
                                id: ClosingAccounts.Show,
                                label: i18next.t("Reporting:AccountAnalysis.ShowClosingAccounts")
                            },
                            {
                                id: ClosingAccounts.DoNotShow,
                                label: i18next.t("Reporting:AccountAnalysis.DoNotShowClosingAccounts")
                            }
                        ]
                    }
                }
            }
        },
        {
            ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
            allowCustomFilters: false,
            defaultFilters: [],
            filterDefinition: {
                [BindingContext.localContext("DocumentType_Name")]: getDocumentTypeFilterDef("JournalEntry_DocumentTypeCode")
            }
        }
    ];
    const onBeforeLoad = async (storage: ReportStorage) => {
        await composedDateRangeOnBeforeLoadCallback(storage, true);
    };
    const parameters: string[] = [
        CommonReportProps.dateRange, AccountAnalysisProps.accounts,
        AccountAnalysisProps.sumAmounts,
        AccountAnalysisProps.drilldown, AccountAnalysisProps.closingAccounts
    ];
    const defaultReportHierarchy: IReportHierarchy = {
        "Aggregate": false,
        "Groups": [],
        "Columns": [
            {
                "ColumnAlias": "Account_Number"
            },
            {
                "ColumnAlias": "PairAccount_Number"
            },
            {
                "ColumnAlias": "JournalEntry_DateAccountingTransaction"
            },
            {
                "ColumnAlias": "FiscalPeriod_Name"
            },
            {
                "ColumnAlias": "Document_NumberOurs"
            },
            {
                "ColumnAlias": "DocumentType_Name"
            },
            {
                "ColumnAlias": "JournalEntry_Description"
            },
            {
                "ColumnAlias": "JournalEntry_Debit"
            },
            {
                "ColumnAlias": "JournalEntry_Credit"
            }
        ],
        "Aggregations": []
    };

    const defaultReportVariants: TRecordType<IReportVariantDef> = {
        [AccountAnalysisVariant.Evala]: {
            ReportHierarchy: defaultReportHierarchy
        },
        [AccountAnalysisVariant.FYCBankDrilldown]: {
            ReportHierarchy: {
                "Aggregate": false,
                "Groups": [],
                "Columns": [
                    { "ColumnAlias": "Account_Number" },
                    { "ColumnAlias": "PairAccount_Number" },
                    { "ColumnAlias": "JournalEntry_DateAccountingTransaction" },
                    { "ColumnAlias": "FiscalPeriod_Name" },
                    { "ColumnAlias": "Document_NumberOurs" },
                    { "ColumnAlias": "DocumentType_Name" },
                    { "ColumnAlias": "JournalEntry_Description" },
                    { "ColumnAlias": "JournalEntry_Amount" },
                    { "ColumnAlias": "JournalEntry_TransactionCurrencyCode" },
                    { "ColumnAlias": "Document_ExchangeRatePerUnit" },
                    { "ColumnAlias": "JournalEntry_TransactionAmount" }
                ],
                "Aggregations": []
            }
        }
    };

    const columnOverrides = {
        Document_NumberOurs: {
            formatter: documentNumberOursOverrideFormatterWithPaymentDocs
        },
        Asset: {
            formatter: assetIntentFormatter
        }
    };

    return {
        title, path, id: tableId, initialSortBy, filterBarDef,
        onBeforeLoad, columnOverrides, parameters, defaultReportHierarchy, defaultReportVariants,
        configListItemsOverride: AccountAnalysisConfiglistSettings
    };
};