import NumberType from "../../../types/Number";
import i18next from "i18next";
import { debounce, isEqual } from "lodash";
import { INPUT_DEBOUNCE_TIME } from "../../../constants";
import Checkbox, { ICheckboxChange } from "../../../components/inputs/checkbox/Checkbox";
import LocalSettings from "../../../utils/LocalSettings";
import Field from "../../../components/inputs/field/Field";
import WriteLineGroup from "../../../components/inputs/writeLine/WriteLineGroup";
import React from "react";
import { BasicInputSizes, FieldType, LabelStatus, ValidatorType } from "../../../enums";
import { IFieldDefFn, IGetValueArgs } from "@components/smart/FieldInfo";
import { TableStorage } from "../../../model/TableStorage";
import { AgingProps, AgingReportType, IGetAgingDefinition } from "../aging/AgingBase";
import { ReportId } from "../ReportIds";
import { capitalize } from "@utils/string";
import BindingContext from "../../../odata/BindingContext";
import { IReportFilterChangeEvent } from "../ReportView";
import { TValue } from "../../../global.types";
import { TestContext } from "yup";
import { IReportStorageDefaultCustomData, ReportStorage } from "../ReportStorage";

export const afterDueDateDefaultRange = ["30", "30", "30"];
const beforeDueDateDefaultRange = ["7", "7", "7"];

interface IAgingIntervalCustomData extends IReportStorageDefaultCustomData {
    sameRange?: boolean;
    disabledIntervals?: boolean[];
}

const getIntervalErrorMessage = (value: string) => {
    const number = NumberType.parse(value);
    let message;

    if (isNaN(number)) {
        message = i18next.t("Common:Validation.MustBeNumber");
    } else if (number <= 0) {
        message = i18next.t("Common:Validation.SmallNumber", { min: 1 });
    }

    return message ? { message } : null;
};

const getCorrectIntervals = (storage: ReportStorage, intervals: string[], sameRange: boolean) => {
    const disabledIntervals: boolean[] = [];
    let correctIntervals = intervals;
    const copyInterval = intervals[0];

    if (sameRange) {
        if (!getIntervalErrorMessage(copyInterval)) {
            correctIntervals = new Array(intervals.length).fill(intervals[0]);
        }

        for (let i = 0; i < intervals.length; i++) {
            disabledIntervals.push(i !== 0);
        }
    }

    return {
        intervals: correctIntervals, disabledIntervals
    };
};

const setAgingIntervalsSettings = (value: string[], storage: ReportStorage<IAgingIntervalCustomData>) => {
    const sameRange = storage.getCustomData().sameRange;
    const { intervals: correctIntervals, disabledIntervals } = getCorrectIntervals(storage, value, sameRange);

    storage.setCustomData({ disabledIntervals });
    storage.settings = {
        ...storage.settings,
        [AgingProps.agingIntervals]: correctIntervals
    };

    // call storage.handleFilterChange to properly store agingIntervals in LocalSettings
    storage.handleFilterChange({
        bindingContext: storage.data.bindingContext.navigate(AgingProps.agingIntervals),
        parsedValue: correctIntervals,
        value: correctIntervals
    });
    storage.refresh();
};

// debounce callback cannot change on every rerender to work!
// has to be defined outside renderIntervals
const setAgingIntervalsSettingsDebounced = debounce((value: string[], storage: ReportStorage) => {
    setAgingIntervalsSettings(value, storage);
}, INPUT_DEBOUNCE_TIME);

const handleSameRangeChange = (args: ICheckboxChange, storage: ReportStorage<IAgingIntervalCustomData>, tableId: string) => {
    const checked = args.value;
    const intervals = storage.getValueByPath(AgingProps.agingIntervals);

    const { intervals: correctIntervals, disabledIntervals } = getCorrectIntervals(storage, intervals, checked);

    storage.setValueByPath(AgingProps.agingIntervals, correctIntervals);
    storage.setCustomData({
        sameRange: checked,
        disabledIntervals
    });
    LocalSettings.set(tableId, {
        customData: {
            ...LocalSettings.get(tableId).customData,
            sameRange: checked
        }
    });

    if (intervals && !isEqual(intervals, correctIntervals)) {
        storage.settings = {
            ...storage.settings,
            [AgingProps.agingIntervals]: correctIntervals
        };
    }

    // call storage.handleFilterChange to properly store agingIntervals in LocalSettings
    storage.handleFilterChange({
        bindingContext: storage.data.bindingContext.navigate(AgingProps.agingIntervals),
        parsedValue: correctIntervals,
        value: correctIntervals
    });
    storage.refresh();
};

const handleIntervalsChange = (value: string[], lengthChange: boolean, storage: ReportStorage) => {
    storage.setValueByPath(AgingProps.agingIntervals, value);
    storage.refreshFields();


    if (lengthChange) {
        setAgingIntervalsSettings(value, storage);
    } else {
        setAgingIntervalsSettingsDebounced(value, storage);
    }
};

const getRenderIntervals = (reportId: ReportId) => {
    return (renderArgs: IFieldDefFn) => {
        const tableStorage = renderArgs.storage as ReportStorage<IAgingIntervalCustomData>;
        const sameRange = tableStorage.getCustomData().sameRange ?? false;
        const intervals = tableStorage.getValueByPath(AgingProps.agingIntervals);
        const disabledIntervals = tableStorage.getCustomData().disabledIntervals;
        const errors = intervals.map((interval: string) => getIntervalErrorMessage(interval));

        return (
            <Field name={AgingProps.agingIntervals}
                   label={renderArgs.props.info.label}
                   width={renderArgs.props.width}
                   isLight>
                <div style={{ marginLeft: "12px", marginRight: "20px", display: "flex" }}>
                    <Checkbox label={i18next.t("Reporting:Aging.SameRange")}
                              isLight
                              checked={sameRange}
                              onChange={(args: ICheckboxChange) => {
                                  handleSameRangeChange(args, tableStorage, reportId);
                              }}/>
                    <div style={{ marginLeft: "22px" }}>
                        <WriteLineGroup
                            isLight
                            width={"30px"}
                            onChange={(value: string[], lengthChange: boolean) => {
                                handleIntervalsChange(value, lengthChange, tableStorage);
                            }}
                            value={intervals}
                            errors={errors}
                            defaultValue={"30"}
                            disabledValues={disabledIntervals}/>
                    </div>
                </div>
            </Field>
        );
    };
};

export const getAgingIntervalFilterDef = (getDefArgs: IGetAgingDefinition) => {
    return {
        id: AgingProps.agingIntervals,
        type: FieldType.Custom,
        label: i18next.t("Reporting:Aging.AgingIntervals"),
        defaultValue: (args: IGetValueArgs) => {
            const savedFilters = LocalSettings.get(getDefArgs.tableId)?.filters;
            let reportType;

            if (savedFilters?.[getDefArgs.reportTypeParam]) {
                reportType = savedFilters[getDefArgs.reportTypeParam];
            } else {
                const filterGroup = (args.storage as TableStorage).data.definition.filterBarDef[0];
                const filterId = filterGroup.defaultFilters.find((filter: string) => filter === getDefArgs.reportTypeParam);

                reportType = filterGroup.filterDefinition[filterId]?.defaultValue;
            }

            return reportType.toString().endsWith(AgingReportType.BeforeDueDate) ? beforeDueDateDefaultRange : afterDueDateDefaultRange;
        },
        render: getRenderIntervals(getDefArgs.tableId),
        validator: {
            type: ValidatorType.Custom,
            settings: {
                customValidator: (value: TValue, args: IGetValueArgs, testContext: TestContext) => {
                    return !(value as string[]).some(val => {
                        return getIntervalErrorMessage(val);
                    });
                },
                message: "doesn't matter, this message is not used"
            }
        }
    };
};

export const getAgingIntervalUnitFilterDef = () => {
    return {
        id: AgingProps.agingIntervalUnit,
        type: FieldType.ComboBox,
        width: BasicInputSizes.S,
        defaultValue: "Day",
        label: i18next.t("Reporting:Aging.IntervalUnit"),
        labelStatus: LabelStatus.Hidden,
        fieldSettings: {
            groupWithPrevious: true,
            items: [
                {
                    label: capitalize(i18next.t("Reporting:Aging.Days2")),
                    id: "Day"
                },
                {
                    label: capitalize(i18next.t("Reporting:Aging.Weeks2")),
                    id: "Week"
                },
                {
                    label: capitalize(i18next.t("Reporting:Aging.Months2")),
                    id: "Month"
                }
            ]
        }
    };
};

export const agingIntervalHandleFilterChange = (args: IReportFilterChangeEvent, storage: ReportStorage) => {
    const path = args.filterChange.bindingContext.toString();
    const cleanPath = BindingContext.cleanLocalContext(path);

    if (cleanPath.endsWith("ReportType")) {
        const reportType = storage.getValue(args.filterChange.bindingContext);
        args.settings[AgingProps.agingIntervals] = reportType.endsWith(AgingReportType.BeforeDueDate) ? beforeDueDateDefaultRange : afterDueDateDefaultRange;
        args.settings[AgingProps.agingIntervalUnit] = "Day";

        // call storage.handleFilterChange to properly store value in LocalSettings and storage
        storage.handleFilterChange({
            bindingContext: storage.data.bindingContext.navigate(AgingProps.agingIntervals),
            parsedValue: args.settings[AgingProps.agingIntervals],
            value: args.settings[AgingProps.agingIntervals]
        });
        storage.handleFilterChange({
            bindingContext: storage.data.bindingContext.navigate(AgingProps.agingIntervalUnit),
            parsedValue: args.settings[AgingProps.agingIntervalUnit],
            value: args.settings[AgingProps.agingIntervalUnit]
        });
    }
};

export const agingIntervalOnAfterLoadCallback = (storage: ReportStorage<IAgingIntervalCustomData>) => {
    const intervals = storage.getValueByPath(AgingProps.agingIntervals) as string[];
    const sameRange = intervals.reduce((allSame, interval) => {
        return allSame && interval === intervals[0];
    }, true);
    const { intervals: correctIntervals, disabledIntervals } = getCorrectIntervals(storage, intervals, sameRange);

    storage.setCustomData({
        sameRange, disabledIntervals
    });
    LocalSettings.set(storage.tableId, {
        customData: {
            ...LocalSettings.get(storage.tableId).customData,
            sameRange
        }
    });
};
