import React, { FocusEvent } from "react";
import autosize from "autosize";

import {
    FakeTabbingButton,
    IconDelimiter,
    IconWrapper,
    InputHoverContainer,
    InputStatus,
    LinkWrapper,
    StyledContent,
    StyledHtmlInput,
    StyledInput,
    StyledTextArea,
    StyledValueUnit
} from "./Input.styles";
import TestIds from "../../../testIds";
import { composeRefHandlers, handleRefHandlers } from "@utils/general";
import { Status, TextAlign } from "../../../enums";
import { IAuditTrailData } from "../../../model/Model";
import { getTabIndex } from "../../auditTrail/Utils";
import { renderReadOnlyText } from "../utils";
import { WithErrorAndTooltip, withErrorAndTooltip, WithErrorAndTooltipProps } from "../formError/WithErrorAndTooltip";
import { IFormatterFns, withFormatter } from "./WithFormatter";
import { IValidationError } from "../../../model/Validator.types";
import { debounce } from "lodash";
import { IDayInterval } from "../date/utils";
import { WithDomManipulator, withDomManipulator } from "../../../contexts/domManipulator/withDomManipulator";
import BusyIndicator from "../../busyIndicator";

import { BusyIndicatorSize } from "../../busyIndicator/BusyIndicator.utils";
import { KeyName } from "../../../keyName";

export interface IInputOnChangeEvent<Type = string | number | Date | IDayInterval> {
    value: Type;
    origEvent?: React.ChangeEvent<HTMLInputElement>;
    /** Distinguish between liveChange (user is writing) and change that should be considered as trigger
     * - steppers in NumericInput, selection in date popups */
    triggerAdditionalTasks?: boolean;
}

export interface IInputOnBlurEvent {
    origEvent: FocusEvent<HTMLInputElement>;
    wasChanged: boolean;
}

export interface IInputRowsChangeEvent {
    numRows: number;
    newHeight: string;
}

/** Shared props for every component that lets user write or select some kind of value.
 * Each of those components has to be able to work with these props.*/
export interface IValueInputComponentProps {
    /** Component should render as always, but in disabled state (lowered opacity, no pointer events) */
    isDisabled?: boolean;
    /** Component should render as text only, without input visuals */
    isReadOnly?: boolean;
    isLight?: boolean;
    showChange?: boolean;
}

/** Shared props for every component that internaly renders Input */
export interface ISharedInputProps extends IValueInputComponentProps, IFormatterFns<any> {
    width?: string;
    height?: string;
    error?: IValidationError;
    /** Type of starting border-radius */
    isSharpLeft?: boolean;
    /** Type of ending border-radius */
    isSharpRight?: boolean;
    name?: string;
    id?: string;
    textAlign?: TextAlign;
    // text put into input and writelines as default(no value) background text
    placeholder?: string;
    // html attribute autocomplete, defaults to "off"
    autocomplete?: string;
    // text put into the end of the input and writeline, with grey color and always visible - typically to show unit
    unit?: string;
    // like placeholder, but doesn't disappear when value is used
    description?: string;
    // disables just the input value
    // TODO remove if we won't need this isDisabled is enough
    isSynchronized?: boolean;
    isMultiLine?: boolean;
    maxRows?: number;
    isLoading?: boolean;
    debouncedWait?: number;
    /** Indicates that icon has different action then the input click*/
    iconHasDifferentAction?: boolean;
    /** Only used for multiline input.
     * Called with current height of the input, when number of rows is changed, because of the length of the value. */
    onRowsChange?: (args: IInputRowsChangeEvent) => void;

    auditTrailData?: IAuditTrailData;
}

export const SHARED_INPUT_PROPS = [
    "width", "height", "error", "isDisabled", "isReadOnly", "isLight", "id", "isLoading",
    "isSharpLeft", "isSharpRight", "isSynchronized", "name", "textAlign", "tooltip", "isRequired", "showChange",
    "placeholder", "isMultiLine", "maxRows", "onRowsChange", "auditTrailData", "debouncedWait", "iconHasDifferentAction"
];

export const getSharedInputProps = (props: ISharedInputProps) => {
    const sharedProps: any = {};

    for (const sharedProp of SHARED_INPUT_PROPS) {
        sharedProps[sharedProp] = props[sharedProp as keyof ISharedInputProps];
    }

    return sharedProps;
};

export interface IInputProps<Type = string> extends ISharedInputProps, WithErrorAndTooltip {
    onIconClick?: (e: React.MouseEvent) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onChange?: (args: IInputOnChangeEvent<Type>) => void;
    onBlur?: (args: IInputOnBlurEvent) => void;
    onWheel?: (event: React.WheelEvent<HTMLElement>) => void;
    onClick?: (e: React.MouseEvent) => void;
    icon?: React.ReactElement;
    iconTitle?: string;
    /** Content added to the end of the input. Used f.e. in line items (add checkbox) */
    content?: React.ReactElement;
    /** Content rendered before the actual input */
    contentBefore?: React.ReactElement;
    link?: React.ReactElement;
    passRef?: React.Ref<HTMLInputElement>;
    value?: Type;
    type?: string;
    inputProps?: React.AllHTMLAttributes<HTMLInputElement>;
    outerRef?: React.RefObject<HTMLDivElement>;
    outerProps?: any;
    className?: string;
    isActive?: boolean;
    cursor?: string;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    selectOnFocus?: boolean;
    debouncedWait?: number;
}

type IInputPropsExtended = IInputProps & WithDomManipulator;

interface IIconProps {
    onClick: (e: React.MouseEvent) => void;
    hasAction: boolean;
    isActive: boolean;
    isSharpRight: boolean;
    icon: React.ReactElement;
    renderWrapperOnly: boolean;
}

const Icon = (props: IIconProps) => {
    return (
        <IconWrapper
            data-testid={TestIds.DropdownIcon}
            onClick={props.onClick}
            isActive={props.isActive}
            isSharpRight={props.isSharpRight}>
            {!props.renderWrapperOnly && (<>
                {props.hasAction && <IconDelimiter/>}
                {props.icon}
            </>)}
        </IconWrapper>
    );
};

interface IDomProps {
    numRows: number;
    newHeight: number;
}

class Input extends React.Component<IInputPropsExtended> {
    _inputRef = React.createRef<HTMLInputElement>();
    _wrapperRef = React.createRef<HTMLElement>();
    _wasChangedSinceBlur = false;

    static defaultProps = {
        iconHasDifferentAction: false
    };

    componentDidMount(): void {
        /** WARNING! changing multiLine during component lifecycle isn't supported */
        if (!this.props.isReadOnly && this.props.isMultiLine) {
            autosize(this._inputRef.current);
            this._inputRef.current.addEventListener("autosize:resized", this.handleAutosizeResize);

            if (this.props.value) {
                // force height event after first render, the input can already be higher than one row
                this.handleRowsChange(true);
            }
        }
    }

    componentWillUnmount(): void {
        if (this.props.isMultiLine) {
            this._inputRef?.current?.removeEventListener("autosize:resized", this.handleAutosizeResize);
        }
    }

    componentDidUpdate(prevProps: IInputPropsExtended): void {
        if (this.props.isMultiLine && prevProps.width !== this.props.width) {
            // autosize isn't triggered when value input is changed programatically
            // => Select calls autosize.update manually on item selected
            // prevProps.value !== this.props.value here would solve this (maybe not efficient?)
            autosize.update(this._inputRef.current);
        }
        if (this.props.value !== prevProps.value && this._inputRef.current === document.activeElement) {
            this._wasChangedSinceBlur = true;
        }
    }

    handleAutosizeResize = (): void => {
        this.handleRowsChange();
    };

    handleRowsChange = (firstTime = false): void => {
        this.props.domManipulatorOrchestrator.registerCallback<IDomProps>(() => {
                if (!this._inputRef.current) {
                    return null;
                }

                const normalLineHeight = 1.2;
                const inputStyle = getComputedStyle(this._inputRef.current);
                const lineHeight = Math.round(parseInt(inputStyle.fontSize) * normalLineHeight);
                const inputHeight = this._inputRef.current.clientHeight;
                const numRows = Math.round(inputHeight / lineHeight);
                return {
                    numRows: numRows,
                    newHeight: inputHeight + lineHeight
                };
            }, (data) => {
                if (!firstTime || data?.numRows > 1) {
                    setTimeout(() => {
                        this.props.onRowsChange?.({
                            numRows: data.numRows,
                            newHeight: `${data.newHeight}px`
                        });
                    }, 0);
                }
            },
            [this._inputRef]
        );
    };

    handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        this.props.onKeyDown?.(e);
    };

    handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        this.props.onKeyPress?.(e);
    };

    handleFocus = (e: React.FocusEvent<HTMLInputElement>): void => {
        if (this.props.selectOnFocus) {
            this._inputRef.current?.setSelectionRange(0, this.props.value?.length ?? 0);
        }
        this.props.onFocus?.(e);
    };

    handleBlur = (e: FocusEvent<HTMLInputElement>): void => {
        this.props.onBlur?.({
            origEvent: e,
            wasChanged: this._wasChangedSinceBlur
        });

        this.debouncedOnChange?.flush();

        this._wasChangedSinceBlur = false;
    };

    handleOnIconClick = (event: React.MouseEvent): void => {
        this.props.onIconClick?.(event);
    };

    handleInputRef = (ref: HTMLInputElement): void => {
        handleRefHandlers(ref, this._inputRef, this.props.passRef);
    };

    debouncedOnChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        this.props?.onChange({
            value: e.target.value,
            origEvent: e,
            triggerAdditionalTasks: true
        });
    }, this.props.debouncedWait);

    handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.props?.onChange({
            value: e.target.value,
            origEvent: e
        });

        if (this.props.debouncedWait) {
            this.debouncedOnChange(e);
        }
    };

    handleInputClick = (event: React.MouseEvent): void => {
        if (this.props.isSynchronized) {
            return;
        }

        this._inputRef.current.focus();
        this.props.onClick && this.props.onClick(event);
    };

    handleMouseDown = (event: any): void => {
        // this prevents firing blur event on input when user still clicks inside wrapper (which is from user perspective
        // still input)
        if (this._wrapperRef.current.contains(event.target) && this._inputRef.current !== event.target && document.activeElement === this._inputRef.current) {
            event.preventDefault();
        }
    };

    getMaxRows = (): number => {
        return this.props.maxRows || 1;
    };

    handleFakeInputKeyDown = (e: React.KeyboardEvent): void => {
        if (e.key === KeyName.Enter) {
            this.props.onIconClick?.(e as any);
        }
    };

    renderIcon = (isOnTopLevel: boolean): React.ReactElement => {
        // if icon has different action, we render it on top level, so it does not share hover border with
        // input hover container. However we need to render the icon active background always in hover container,
        // so it doesn't cover the border. So in this case, we render the container twice, once with background and
        // without icon and one transparent with icon (onTopLevel)

        const iconHasDifferentAction = this.props.iconHasDifferentAction;
        if ((this.props.icon || this.props.isLoading)) {
            return (
                <>
                    {this.props.isLoading && <BusyIndicator size={BusyIndicatorSize.XS} isDelayed/>}
                    {iconHasDifferentAction && isOnTopLevel && <FakeTabbingButton onKeyDown={this.handleFakeInputKeyDown}/>}
                    <Icon
                        icon={this.props.icon}
                        onClick={this.handleOnIconClick}
                        hasAction={!!this.props.onIconClick}
                        isActive={!isOnTopLevel && this.props.isActive}
                        renderWrapperOnly={!isOnTopLevel}
                        isSharpRight={this.props.isSharpRight}
                    />
                </>
            );
        }

        return null;
    };

    renderInput = (): React.ReactElement => {
        const StyledInputComponent: any = this.props.isMultiLine ? StyledTextArea : StyledHtmlInput;
        const showError = this.props.hasError;
        const showErrorText = this.props.hasErrorText;

        const styleProps = {
            isSharpLeft: this.props.isSharpLeft,
            isSharpRight: this.props.isSharpRight,
            isSynchronized: this.props.isSynchronized,
            isDisabled: this.props.isDisabled && !this.props.auditTrailData?.type
        };

        // turn of browser complete for every input
        const autocomplete = this.props.autocomplete ?? "off";

        const { unit } = this.props;

        const { width, ...inputProps } = { ...this.props.inputProps };

        let input = <>
            {this.props.link &&
                <LinkWrapper
                    hasIcon={!!this.props.icon}
                    auditTrailType={this.props.auditTrailData?.type}>
                    {this.props.link}
                </LinkWrapper>
            }
            <StyledInput
                _showErrorText={showErrorText}
                className={this.props.className}
                auditTrailType={this.props.auditTrailData?.type}
                data-testid={TestIds.Input}
                onMouseDown={this.handleMouseDown}
                onClick={this.handleInputClick}
                ref={composeRefHandlers(this._wrapperRef, this.props.outerRef)}
                isMultiLine={this.props.isMultiLine}
                _width={this.props.width}
                _cursor={this.props.cursor}
                _height={this.props.height}
                _hasIcon={!!this.props.icon}
                {...styleProps}>
                <InputHoverContainer
                    _hasIcon={!!this.props.icon}
                    _hasUnit={!!unit}
                    textAlign={this.props.textAlign}
                    isActive={this.props.isActive}
                    isSharpLeft={this.props.isSharpLeft}
                    isSharpRight={this.props.isSharpRight}
                    isSynchronized={!this.props.isDisabled && this.props.isSynchronized}>
                    {this.props.contentBefore}
                    <StyledInputComponent
                        auditTrailType={this.props.auditTrailData?.type}
                        tabIndex={styleProps.isDisabled ? -1 : getTabIndex(this.props)}
                        ref={composeRefHandlers(this._inputRef, this.props.passRef)}
                        _cursor={this.props.cursor}
                        hasIcon={!!this.props.icon}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        onWheel={this.props.onWheel}
                        showError={showError}
                        showErrorText={showErrorText}
                        value={this.props.link ? "" : this.props.value}
                        onChange={this.handleOnChange}
                        type={this.props.type || "search" /* type search is needed so autocomplete="off" works in chrome */}
                        // disable autofill for Dashlane password manage
                        // it would be nice to find something universal, but such attribute doesn't seem to exist
                        data-form-type={"other"}
                        onKeyDown={this.handleKeyDown}
                        onKeyPress={this.handleKeyPress}
                        id={this.props.id}
                        data-name={this.props.name}
                        textAlign={this.props.textAlign}
                        placeholder={this.props.isDisabled ? "" : this.props.placeholder}
                        autoComplete={autocomplete}
                        {...inputProps} // any other prop passed down to input element
                        _width={this.props.inputProps?.width}
                        maxRows={this.getMaxRows()}
                        disabled={styleProps.isDisabled}
                        {...styleProps} />
                    {this.renderIcon(false)}
                    {unit && <StyledValueUnit hasErrorText={this.props.hasErrorText}
                                              data-testid={TestIds.Description}>&nbsp;{unit}</StyledValueUnit>}
                    {this.props.content && <StyledContent>{this.props.content}</StyledContent>}
                    {/* Error and tooltip from HOC */}
                    {this.props.errorAndTooltip}
                </InputHoverContainer>
                {this.renderIcon(true)}
            </StyledInput>
            {!this.props.isReadOnly && this.props.showChange &&
                <InputStatus isSharpLeft={this.props.isSharpLeft} status={Status.Warning}
                             data-testid={TestIds.InputStatus}/>}
        </>;

        return input;
    };

    renderReadOnlyText = (): React.ReactElement => {
        return renderReadOnlyText(this);
    };

    render(): React.ReactElement {
        return this.props.isReadOnly && !this.props.auditTrailData?.type ? this.renderReadOnlyText() : this.renderInput();
    }
}

const InputWithErrorAndTooltip = withDomManipulator(withErrorAndTooltip(Input));

// Input with string formatter - note: we need to export it separately so we don't stack more withFormatter HOC
export { Input as InputComponentType, InputWithErrorAndTooltip };

export default withFormatter<IInputProps & WithErrorAndTooltipProps, string>(InputWithErrorAndTooltip);
