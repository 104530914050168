import { BasicInputSizes, CacheStrategy, CurrencyUnit, FieldType, ValidatorType } from "../../enums";
import BindingContext from "../../odata/BindingContext";
import i18next from "i18next";
import { IFieldInfoProperties, IGetValueArgs } from "@components/smart/FieldInfo";
import { REST_API_URL } from "../../constants";
import { TValue } from "../../global.types";
import { formatDateToDateString } from "@components/inputs/date/utils";
import { ISelectGroup, ISelectItem, SelectGroups } from "@components/inputs/select/BasicSelect";
import { getRouteByDocumentType } from "@odata/EntityTypes";
import { IFormatOptions } from "@odata/OData.utils";
import { getTableIntentLink } from "@components/drillDown/DrillDown.utils";
import { ICellValueObject, TCellValue } from "@components/table";
import { IFilterDef } from "@components/smart/smartFilterBar/SmartFilterBar.types";
import * as yup from "yup";
import { ValidationMessage } from "../../model/Validator.types";
import {
    ClearedStatusCode,
    DocumentTypeCode,
    PostedStatusCode,
    VatStatementStatusCode
} from "@odata/GeneratedEnums";
import { IChangedFilter } from "../../views/table/TableView.utils";
import { ROUTE_FIXED_ASSET } from "../../routes";
import { DEFAULT_RANGE } from "./customFilterComponents/ComposedDateRange";
import { cartesian } from "@utils/general";
import { EntityTypeName } from "@odata/GeneratedEntityTypes";
import {
    getEnumDisplayValue,
    getEnumNameSpaceName,
    getEnumSelectItems,
    getEnumValues
} from "@odata/GeneratedEnums.utils";
import { flatten } from "lodash";
import {
    IComplexFilter,
    isComplexFilterArr
} from "@components/conditionalFilterDialog/ConditionalFilterDialog.utils";
import { IAppContext } from "../../contexts/appContext/AppContext.types";
import { isAccountAssignmentCompany, isVatRegisteredCompany } from "@utils/CompanyUtils";
import { replaceTaxDocumentTypes } from "./Report.utils";
import { getUtcDate } from "../../types/Date";

export enum ReportDisplayType {
    Full = "Full",
    Short = "Short",
    None = "None"
}

export enum ReportPeriods {
    None = "None",
    Months = "Months",
    Periods = "Periods"
}

export enum ReportSectionTypeCode {
    Income = "I",
    Expense = "E",
    Sum = "S"
}

export const CommonReportProps = {
    dateRange: BindingContext.localContext("DateRange"),
    fiscalYear: BindingContext.localContext("FiscalYearId"),
    byDate: BindingContext.localContext("ByDate"),
    comparison: BindingContext.localContext("Comparison"),
    showDelta: BindingContext.localContext("ShowDelta"),
    splitIntoPeriods: BindingContext.localContext("SplitIntoPeriods"),
    units: BindingContext.localContext("Units")
};

export const byDateValidationSchema = yup.date().typeError(ValidationMessage.NotADate)
    .nullable().required(ValidationMessage.Required);

export const getByDateDef = (): IFilterDef => {
    return {
        id: CommonReportProps.byDate,
        type: FieldType.Date,
        label: i18next.t("Reporting:Parameters.ByDate"),
        defaultValue: () => (getUtcDate()),
        validator: {
            type: ValidatorType.Custom,
            settings: {
                customSchema: () => byDateValidationSchema
            }
        }
    };
};

export const getDateRangeDef = (): IFilterDef => {
    return {
        id: CommonReportProps.dateRange,
        type: FieldType.ComboBox,
        label: i18next.t("Reporting:Parameters.DateRange"),
        width: BasicInputSizes.L,
        defaultValue: DEFAULT_RANGE,
        cacheStrategy: CacheStrategy.Company,
        fieldSettings: {
            // to prevent warning
            displayName: CommonReportProps.dateRange,
            endpoint: `${REST_API_URL}/DateRange`,
            items: null,
            preloadItems: true
        },
        formatter: (val: TValue): string => {
            return i18next.t(`Enums:DateRange.${val}`);
        },
        filter: {
            buildFilter: (filter: IChangedFilter) => {
                const items = filter.info.fieldSettings?.items;
                if (items) {
                    const item = items.find(_item => _item.id === filter.value);
                    if (item && item.additionalData) {
                        const dateStart = formatDateToDateString(getUtcDate(item.additionalData?.dateRange?.DateStart));
                        const dateEnd = formatDateToDateString(getUtcDate(item.additionalData?.dateRange?.DateEnd));

                        return `DateAccountingTransaction ge ${dateStart} and DateAccountingTransaction le ${dateEnd}`;
                    }
                }
                return "";
            }
        }
    };
};

export enum FiscalYearComparison {
    None = "None",
    Last = "PrevFYear",
    YearBeforeLast = "FYearBPrev",
    SameDayLastYear = "PrevFYearByDate",
    SameDayYearBeforeLast = "FYearBPrevByDate"
}

export const WITH_DELTA_SUFFIX = "_DELTA";

export const getComparisonDef = (withDelta = false): IFilterDef => {
    let items = [
        {
            label: i18next.t("Reporting:Columns.LastFiscalYearRelative"),
            id: FiscalYearComparison.Last
        },
        {
            label: i18next.t("Reporting:Columns.LastFiscalYearRelativeDelta"),
            id: FiscalYearComparison.Last + WITH_DELTA_SUFFIX,
            additionalData: {
                showDelta: true
            }
        },
        {
            label: i18next.t("Reporting:Columns.FiscalYearBLastRelative"),
            id: FiscalYearComparison.YearBeforeLast
        },
        {
            label: i18next.t("Reporting:Columns.FiscalYearBLastRelativeDelta"),
            id: FiscalYearComparison.YearBeforeLast + WITH_DELTA_SUFFIX,
            additionalData: {
                showDelta: true
            }
        },
        {
            label: i18next.t("Reporting:Columns.SameDayLastYearRelative"),
            id: FiscalYearComparison.SameDayLastYear
        },
        {
            label: i18next.t("Reporting:Columns.SameDayLastYearRelativeDelta"),
            id: FiscalYearComparison.SameDayLastYear + WITH_DELTA_SUFFIX,
            additionalData: {
                showDelta: true
            }
        },
        {
            label: i18next.t("Reporting:Columns.SameDayYearBLastRelative"),
            id: FiscalYearComparison.SameDayYearBeforeLast
        },
        {
            label: i18next.t("Reporting:Columns.SameDayYearBLastRelativeDelta"),
            id: FiscalYearComparison.SameDayYearBeforeLast + WITH_DELTA_SUFFIX,
            additionalData: {
                showDelta: true
            }
        }
    ];

    if (!withDelta) {
        items = items.filter(i => !i.additionalData?.showDelta);
    }

    const defaultValue = withDelta ? FiscalYearComparison.Last + WITH_DELTA_SUFFIX : FiscalYearComparison.Last;

    return {
        id: CommonReportProps.comparison,
        type: FieldType.ComboBox,
        width: BasicInputSizes.L,
        defaultValue,
        label: i18next.t("Reporting:BalanceSheet.Comparison"),
        fieldSettings: {
            additionalItems: [
                {
                    label: i18next.t("Reporting:BalanceSheet.WithoutComparison"),
                    id: FiscalYearComparison.None,
                    groupId: SelectGroups.Default
                }
            ],
            items
        },
        filter: {
            transformFilterValue: (value: string) => {
                return value?.replace(WITH_DELTA_SUFFIX, "");
            }
        }
    };
};

export const getSplitIntoPeriodsDef = (): IFilterDef => {
    return {
        id: CommonReportProps.splitIntoPeriods,
        type: FieldType.ComboBox,
        width: BasicInputSizes.M,
        defaultValue: ReportPeriods.None,
        label: i18next.t("Reporting:BalanceSheet.PeriodsSplit"),
        fieldSettings: {
            additionalItems: [
                {
                    label: i18next.t("Reporting:BalanceSheet.None"),
                    id: ReportPeriods.None,
                    groupId: SelectGroups.Default
                }
            ],
            items: [
                {
                    label: i18next.t("Reporting:BalanceSheet.Months"),
                    id: ReportPeriods.Months
                },
                {
                    label: i18next.t("Reporting:BalanceSheet.Periods"),
                    id: ReportPeriods.Periods
                }
            ]
        }
    };
};

export const getUnitsDef = (): IFilterDef => {
    return {
        id: CommonReportProps.units,
        label: i18next.t("Reporting:BalanceSheet.Values"),
        type: FieldType.ComboBox,
        defaultValue: CurrencyUnit.Units,
        fieldSettings: {
            frontendOnly: true,
            items: [
                {
                    label: i18next.t("Reporting:BalanceSheet.Units"),
                    id: CurrencyUnit.Units
                },
                {
                    label: i18next.t("Reporting:BalanceSheet.Thousands"),
                    id: CurrencyUnit.Thousands
                },
                {
                    label: i18next.t("Reporting:BalanceSheet.Millions"),
                    id: CurrencyUnit.Millions
                }
            ]
        }
    };
};

export const getDocumentTypeFilterDef = (filterName?: string): IFilterDef => {
    return {
        id: BindingContext.localContext("DocumentType_Name"),
        type: FieldType.MultiSelect,
        defaultValue: [],
        filterName: filterName ?? "Document_DocumentTypeCode",
        fieldSettings: {
            // entitySet: EntitySetName.DocumentTypes,
            // displayName: "Name",
            isEnum: true,
            // ProformaInvoice and DDOPP use same document type,
            // and we want to differentiate between them in reports
            // => BE adds custom new document types TaxDocumentReceived and TaxDocumentIssued that are not part of the DocumentType entity set
            // ==> we need to add them into initialItems for the ConditionalDialog to show them
            // AND add correct translation for them in itemsForRender
            initialItems: [
                ...getEnumSelectItems(EntityTypeName.DocumentType),
                {
                    id: "TaxDocumentReceived",
                    label: i18next.t("Reporting:DDOPP.TaxDocumentReceived")
                },
                {
                    id: "TaxDocumentIssued",
                    label: i18next.t("Reporting:DDOPP.TaxDocumentIssued")
                }
            ],
            itemsForRender: (items: ISelectItem[], args: IGetValueArgs) => {
                return items.map(item => {
                    if (["TaxDocumentReceived", "TaxDocumentIssued"].includes(item.id as string)) {
                        return {
                            ...item,
                            label: i18next.t(`Reporting:DDOPP.${item.id}`)
                        };
                    }

                    return item;
                });
            }
        }
    };
};

export const StatusEntities = [EntityTypeName.PostedStatus, EntityTypeName.ClearedStatus, EntityTypeName.VatStatementStatus];
export const StatusCodeEnumTranslations = [
    getEnumNameSpaceName(EntityTypeName.PostedStatus),
    getEnumNameSpaceName(EntityTypeName.ClearedStatus),
    getEnumNameSpaceName(EntityTypeName.VatStatementStatus)
];
const statusFilterValuePrefixSeparator = ":";
const statusFilterValueSeparator = ",";
export const DocumentStatusLocalPath = BindingContext.localContext("DocumentStatus");

/** Filter out entity types not related to the current company */
const getFilteredStatusEntities = (context: IAppContext): EntityTypeName[] => {
    return StatusEntities.filter(t => {
        return (isAccountAssignmentCompany(context) || t !== EntityTypeName.PostedStatus)
            && (isVatRegisteredCompany(context) || t !== EntityTypeName.VatStatementStatus);
    });
};

export function getStatusGroups(): ISelectGroup[] {
    return StatusEntities.map(entityType => ({
        id: entityType,
        title: i18next.t(`Document:Status.${entityType}`),
        hideDivider: true
    }));
}

function getStatusFilterValuePrefix(entityType: EntityTypeName): string {
    return entityType.toString().replace(/[^A-Z]+/g, "");
}

export function getStatusFilterId(entityType: EntityTypeName, code: string): string {
    return `${getStatusFilterValuePrefix(entityType)}${statusFilterValuePrefixSeparator}${code}`;
}

export function splitStatusFilterId(id: string): { entityType: EntityTypeName, code: string } {
    const [prefix, code] = id.split(statusFilterValuePrefixSeparator);
    const entityType = StatusEntities.find(e => getStatusFilterValuePrefix(e) === prefix);
    return { entityType, code };
}

const createDocumentStatusSelectItem = (entityType: EntityTypeName, key: string): ISelectItem => ({
    id: getStatusFilterId(entityType, key),
    label: getEnumDisplayValue(entityType, key),
    groupId: entityType
});

export function combineDocumentStatuses(ps: PostedStatusCode[], cs: ClearedStatusCode[], vs: VatStatementStatusCode[]): string[] {
    return cartesian(ps, cs, vs).map(item => item.join(statusFilterValueSeparator));
}

export function getAllDocumentStatusItems(statusEntities: EntityTypeName[]): ISelectItem[] {
    return flatten(statusEntities.map(entityType => getEnumValues(entityType).map(key => createDocumentStatusSelectItem(entityType, key))));
}

type TDocumentStatusMap = Partial<Record<EntityTypeName, string[]>>;

export const getDocumentStatusFilterDef = (context: IAppContext): IFilterDef => {
    return {
        id: DocumentStatusLocalPath,
        type: FieldType.MultiSelect,
        defaultValue: [],
        filterName: "DocumentStatus",
        filter: {
            transformFilterValue: (value) => {
                const savedValues = (value ?? []) as (string | IComplexFilter<string[]>)[];

                const _transformSingleValue = (v: string, map: TDocumentStatusMap) => {
                    const { entityType, code } = splitStatusFilterId(v);
                    if (!map[entityType]) {
                        map[entityType] = [];
                    }
                    map[entityType].push(code);
                };

                const _transformMapToValues = (map: TDocumentStatusMap) =>
                    combineDocumentStatuses(...(StatusEntities.map(entityType => map[entityType] ?? getEnumValues(entityType)) as [PostedStatusCode[], ClearedStatusCode[], VatStatementStatusCode[]]));

                if (isComplexFilterArr(savedValues)) {
                    return savedValues.map((item) => {
                        const map: TDocumentStatusMap = {};
                        const complexFilter = item as IComplexFilter<string[]>;
                        if (!complexFilter.value?.length) {
                            return null;
                        }
                        complexFilter.value.forEach(singleValue => _transformSingleValue(singleValue, map));
                        return {
                            ...complexFilter,
                            value: _transformMapToValues(map)
                        };
                    }).filter(i => !!i);
                }

                const filteredStatusEntities = getFilteredStatusEntities(context);
                const map: TDocumentStatusMap = {};
                savedValues.forEach(v => _transformSingleValue(v as string, map));

                // remove saved filter that are hidden for current company
                for (const type of Object.keys(map)) {
                    if (!filteredStatusEntities.includes(type as EntityTypeName)) {
                        delete map[type as EntityTypeName];
                    }
                }

                return _transformMapToValues(map);
            }
        },
        fieldSettings: {
            isEnum: true,
            groups: getStatusGroups(),
            initialItems: getAllDocumentStatusItems(getFilteredStatusEntities(context)),
            itemsForRender(items: ISelectItem[], { info }: IGetValueArgs): ISelectItem[] {
                const copiedItems = info.fieldSettings.initialItems.map(i => ({ ...i, isDisabled: true }));

                items.forEach(item => {
                    const keys = item.id.toString().split(statusFilterValueSeparator);
                    StatusEntities.forEach((entityType, idx) => {
                        const copiedItem = copiedItems.find(item => item.id === getStatusFilterId(entityType, keys[idx]) && item.groupId === entityType);

                        if (copiedItem) {
                            copiedItem.isDisabled = false;
                        }
                    });
                });

                return copiedItems.filter(item => !item.isDisabled);
            }
        }
    };
};

interface IGetNumberOursOverride {
    documentTypeProperty: string;
    documentIdProperty: string;
}

export const getDocumentNumberOursOverride = (options: IGetNumberOursOverride): IFieldInfoProperties => {
    return {
        formatter: (val: TValue, args: IFormatOptions): TCellValue => {
            if (!val) {
                return null;
            }

            const documentType: DocumentTypeCode = args.entity[options.documentTypeProperty];
            const documentId = args.entity[options.documentIdProperty];

            const route = getRouteByDocumentType(replaceTaxDocumentTypes(documentType));
            const number = val as string;

            if (route) {
                const url = `${route}/${documentId}`;
                return getTableIntentLink(number, {
                    route: url,
                    context: args.storage.context,
                    storage: args.storage
                });
            }
            return number;
        }
    };
};

export const documentNumberOursOverrideFormatterWithPaymentDocs = (val: TValue, args: IFormatOptions) => {
    const isPaymentDocument = !!args.entity.PaymentDocument_Id;
    const docTypeProp = "JournalEntry_DocumentTypeCode";
    const docIdProp = isPaymentDocument ? "PaymentDocument_Id" : "Document_Id";

    return getDocumentNumberOursOverride({
        documentTypeProperty: docTypeProp,
        documentIdProperty: docIdProp
    }).formatter(val, args);
};

export const assetIntentFormatter = (val: TValue, args: IFormatOptions): ICellValueObject => {
    if (!val) {
        return null;
    }

    const assetId = args.entity["Asset_Id"];
    const url = `${ROUTE_FIXED_ASSET}/${assetId}`;

    return getTableIntentLink(val as string, {
        route: url,
        context: args.storage.context,
        storage: args.storage
    });
};