import styled from "styled-components/macro";
import Checkbox from "../../inputs/checkbox";
import { T_TITLE_2 } from "../../../global.style";
import { StyledSelect } from "../../inputs/select/Select.styles";

export const BulkChangeFieldWrapper = styled.div`
    display: flex;
    justify-content: flex-start;

    ${StyledSelect} + ${StyledSelect} {
        margin-top: 12px;
    }
`;

export const CheckboxStyled = styled(Checkbox)<{
    _hasLabel: boolean;
}>`
    margin-top: ${props => props._hasLabel ? "19px" : "0"};
    height: 34px;
    margin-right: 12px;
`;

export const Header = styled.div`
    ${T_TITLE_2};
    margin-bottom: 30px;
`;

export const FormFooterStyled = styled.div `
    display: flex;
`;
