import React from "react";
import { IconButton } from "../button";
import { getIcon, UploadIcon } from "../icon";
import HiddenFileInput from "../fileUploader/HiddenFileInput";
import { WithTranslation, withTranslation } from "react-i18next";
import ReactDOM from "react-dom";

interface IProps {
    onFileChange: (files: File[]) => void;
    title?: string;
    iconName?: string;
    hotspotId: string;
    accept?: string;
    multiple?: boolean;
    isDisabled?: boolean;
    isDecorative?: boolean;
    isTransparent?: boolean;
    renderCustomButton?: (onClick: () => void, isDisabled: boolean) => void;
}

// provides functionality for opening of the html file input via custom button
class FileUploadButton extends React.PureComponent<IProps & WithTranslation> {

    _fileInput = React.createRef<HTMLInputElement>();

    handleUploadButtonClick = () => {
        this._fileInput.current.click();
    };

    handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onFileChange?.(Array.from(event.target.files));
    };

    render() {
        const Icon = this.props.iconName ? getIcon(this.props.iconName) : UploadIcon;
        return (
            <>
                {!this.props.renderCustomButton &&
                    <IconButton title={this.props.title ?? this.props.t("Components:FileUploader.ChooseFile")}
                                hotspotId={this.props.hotspotId}
                                onClick={this.handleUploadButtonClick}
                                isDisabled={this.props.isDisabled}
                                isDecorative={this.props.isDecorative}
                                isTransparent={this.props.isTransparent}>
                        <Icon/>
                    </IconButton>
                }
                {this.props.renderCustomButton?.(this.handleUploadButtonClick, this.props.isDisabled)}
                {/*we need file input to programmatically trigger the "open file" window*/}
                {ReactDOM.createPortal(
                    <HiddenFileInput passRef={this._fileInput}
                                     accept={this.props.accept}
                                     multiple={this.props.multiple}
                                     onChange={this.handleFileInputChange}/>
                    , document.body)}
            </>
        );
    }
}

export default withTranslation(["Components"])(FileUploadButton);