import {
    ClearedStatusCode,
    DocumentTypeCode,
    PostedStatusCode,
    VatStatementFrequencyCode,
    VatStatementStatusCode
} from "@odata/GeneratedEnums";
import {
    CompanyEntity,
    CompanyVatReducedCoefficientEntity,
    DocumentEntity,
    EntitySetName,
    EntityTypeName,
    ICompanyEntity,
    ICompanyVatReducedCoefficientEntity,
    IDocumentEntity,
    IElectronicSubmissionEntity
} from "@odata/GeneratedEntityTypes";
import { IFormatOptions } from "@odata/OData.utils";
import { LogicOperator, ReportTableRowType, Sort } from "../../enums";
import { TFormTable } from "@components/smart/smartFormGroup/SmartFormGroup";
import i18next from "i18next";
import { TRecordValue, TValue } from "../../global.types";
import { getTableIntentLink } from "@components/drillDown/DrillDown.utils";
import { getRouteByDocumentType } from "@odata/EntityTypes";
import { IAppContext } from "../../contexts/appContext/AppContext.types";
import {
    fetchReportTableData,
    IFetchReportTableDataArgs
} from "@components/smart/smartTable/SmartReportTable.utils";
import { DOCUMENT_JOURNAL_PATH } from "../reports/documentJournal/DocumentJournalDef";
import { IReportData, ReportColumnType } from "../reports/Report.utils";
import { buildFilterTree, TBuildReportNodeFilter } from "../reports/ReportFilter.utils";
import BindingContext from "../../odata/BindingContext";
import {
    Condition,
    ConditionType,
    PredefinedFilter
} from "@components/conditionalFilterDialog/ConditionalFilterDialog.utils";
import { isDefined } from "@utils/general";
import { EMPTY_VALUE } from "../../constants";
import { getDocumentStatusFilterDef, getStatusFilterId } from "../reports/CommonDefs";
import { startsWith } from "@utils/string";
import { Dayjs } from "dayjs";
import DateType, { DATE_MIN, DateFormat, getUtcDayjs } from "../../types/Date";
import { EMPTY_DASH } from "@components/readOnlyList/ReadOnlyList";
import { isSameMonth } from "@components/inputs/date/utils";
import { VatStatementPeriod } from "../companies/Company.utils";
import { OData } from "@odata/OData";
import { isAccountAssignmentCompanyValue, isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import { IGetValueArgs } from "@components/smart/FieldInfo";
import { ICellValueObject } from "@components/table";
import { isSubmissionLocked } from "./ElectronicSubmission.utils";

export enum VatSubmissionType {
    VatControlStatement = "VatControlSt",
    VatStatement = "VatSt",
    VatVIESStatement = "VatViesSt",
}

export interface IVatSubmissionCountArgs {
    submission?: IElectronicSubmissionEntity;
    from?: Date;
    to?: Date;
    drillDown?: boolean;
}

export const ControlStatementNotApplicable = "Na";

const DocumentJournalRootContext = new BindingContext({
    isLocal: true,
    path: DOCUMENT_JOURNAL_PATH
});

function getDocumentJournalPropByType(type: VatSubmissionType): string {
    switch (type) {
        case VatSubmissionType.VatStatement:
            return "VatStatementSection";
        case VatSubmissionType.VatControlStatement:
            return "VatControlStatementSection";
        case VatSubmissionType.VatVIESStatement:
            return "VatVies";
    }
}

interface IGetDocumentJournalFilterDef {
    id: string;
    value: TValue;
    type: ReportColumnType;
}

export function getDocumentJournalDateElectronicSubmissionFilter(args: IVatSubmissionCountArgs): IGetDocumentJournalFilterDef {
    const _isLocked = isSubmissionLocked(args.submission);
    const from = args.submission?.DatePeriodStart ?? args.from;
    const to = args.submission?.DatePeriodEnd ?? args.to;

    return {
        id: BindingContext.localContext(`Document_DateElectronicSubmissionVatStatement${args.drillDown ? "_MONTH" : ""}`),
        value: [{
            type: ConditionType.Included,
            filter: PredefinedFilter.Value,
            condition: _isLocked ? Condition.Between : Condition.IsBeforeOrEqualsTo,
            value: _isLocked ? { from, to } : to
        }],
        type: ReportColumnType.Date
    };
}

export function getDocumentJournalIsLockedFilter(args: IVatSubmissionCountArgs): IGetDocumentJournalFilterDef {
    return {
        id: BindingContext.localContext("DocumentStatus"),
        value: [{
            type: ConditionType.Included,
            filter: PredefinedFilter.Value,
            condition: Condition.Equals,
            value: [getStatusFilterId(EntityTypeName.VatStatementStatus, isSubmissionLocked(args.submission) ? VatStatementStatusCode.Filed : VatStatementStatusCode.NotFiled)]
        }],
        type: ReportColumnType.String
    };
}

export function getDocumentJournalStatusFilter(statusType: EntityTypeName.VatStatementStatus | EntityTypeName.ClearedStatus | EntityTypeName.PostedStatus, values: (ClearedStatusCode | VatStatementStatusCode | PostedStatusCode)[]): IGetDocumentJournalFilterDef {
    return {
        id: BindingContext.localContext("DocumentStatus"),
        value: [{
            type: ConditionType.Included,
            filter: PredefinedFilter.Value,
            condition: Condition.Equals,
            value: values.map(code => getStatusFilterId(statusType, code))
        }],
        type: ReportColumnType.String
    };
}

function getDocumentJournalSubmissionTypeFilter(type: VatSubmissionType): IGetDocumentJournalFilterDef {
    const filterDef = {
        id: BindingContext.localContext(getDocumentJournalPropByType(type)),
        value: [{
            type: ConditionType.Excluded,
            filter: PredefinedFilter.Value,
            condition: Condition.Equals,
            value: EMPTY_VALUE
        }],
        type: ReportColumnType.String
    };

    if (type === VatSubmissionType.VatControlStatement) {
        filterDef.value.push({
            type: ConditionType.Excluded,
            filter: PredefinedFilter.Value,
            condition: Condition.BeginsWith,
            value: ControlStatementNotApplicable
        });
    }

    return filterDef;
}

function getDocumentJournalReportNodeFilter(args: IVatSubmissionCountArgs, fn: ((args: IVatSubmissionCountArgs) => IGetDocumentJournalFilterDef)): TBuildReportNodeFilter {
    const filter = fn(args);
    const ColumnAlias = BindingContext.cleanLocalContext(filter.id);
    return {
        id: filter.id,
        bindingContext: DocumentJournalRootContext.navigate(ColumnAlias),
        value: filter.value,
        columnDef: {
            ColumnAlias,
            Type: filter.type
        }
    };
}

export type TVatSubmissionDocumentsData = Partial<Record<VatSubmissionType, number>>;

export async function getVatSubmissionDocumentsData(args: IVatSubmissionCountArgs, context: IAppContext): Promise<TVatSubmissionDocumentsData> {
    const periodFilter = getDocumentJournalReportNodeFilter(args, getDocumentJournalDateElectronicSubmissionFilter);
    const isLockedFilter = getDocumentJournalReportNodeFilter(args, getDocumentJournalIsLockedFilter);

    // document status has transformation in definition -> we need to call it before calling buildFilterTree
    const def = getDocumentStatusFilterDef(context);
    isLockedFilter.value = def.filter?.transformFilterValue(isLockedFilter.value);

    const statementTypes = [
        VatSubmissionType.VatStatement,
        VatSubmissionType.VatControlStatement,
        VatSubmissionType.VatVIESStatement,
    ];

    const fetchArgs: IFetchReportTableDataArgs = {
        path: DOCUMENT_JOURNAL_PATH,
        settings: {
            DateRange: {
                DateStart: null,
                DateEnd: null
            },
            Filter: buildFilterTree({
                filters: [periodFilter, isLockedFilter],
                operator: LogicOperator.And
            })
        },
        reportHierarchy: {
            Aggregate: false,
            Columns: [
                { ColumnAlias: "Document_NumberOurs" },
                { ColumnAlias: "Document_DateElectronicSubmissionVatStatement" },
                { ColumnAlias: "DocumentStatus" },
                ...(statementTypes.map(type => ({ ColumnAlias: getDocumentJournalPropByType(type) })))
            ],
            Groups: [],
            Aggregations: []
        }
    };

    const response = await fetchReportTableData(fetchArgs);

    const _shouldCountProp = (data: TRecordValue, type: VatSubmissionType): boolean => {
        const value = data[getDocumentJournalPropByType(type)] as string;
        if (type === VatSubmissionType.VatControlStatement) {
            return isDefined(value) && !startsWith(value, ControlStatementNotApplicable);
        }
        return isDefined(value);
    };

    const retVal: TVatSubmissionDocumentsData = {};
    const _countRow = (data: TRecordValue, type: VatSubmissionType) => {
        if (_shouldCountProp(data, type)) {
            retVal[type] = (retVal[type] ?? 0) + 1;
        }
    };

    if (response) {
        const data = (await response.json()) as IReportData;
        (data.Rows ?? []).forEach((row) => {
            if (row.Type === ReportTableRowType.Value) {
                statementTypes
                    .forEach(type => _countRow(row.Value, type));
            }
        });
    }

    return retVal;
}

export async function getVatSubmissionClearingRatio(oData: OData, context: IAppContext, year: number): Promise<ICompanyVatReducedCoefficientEntity> {
    const companyId = context.getCompanyId();
    const query = oData.getEntitySetWrapper(EntitySetName.Companies)
        .query(companyId)
        .select(CompanyEntity.UseVatReducedDeduction, CompanyEntity.Id)
        .expand(CompanyEntity.VatReducedCoefficients, q =>
            q.filter(`${CompanyVatReducedCoefficientEntity.Year} eq ${year}`).select(CompanyVatReducedCoefficientEntity.VatReducedCoefficient, CompanyVatReducedCoefficientEntity.Id));

    const result = await query.fetchData<ICompanyEntity>();
    const company = result.value;

    if (company.UseVatReducedDeduction) {
        return company.VatReducedCoefficients?.[0] ?? {
            Year: year,
            VatReducedCoefficient: 0
        };
    }
    return null;
}

export async function changeVatSubmissionClearingRatio(oData: OData, context: IAppContext, coeff: ICompanyVatReducedCoefficientEntity): Promise<void> {
    const companyId = context.getCompanyId();

    await oData.getEntitySetWrapper(EntitySetName.Companies).update(companyId, {
        [`${CompanyEntity.VatReducedCoefficients}@odata.delta`]: [coeff]
    });
}

export const getLiabilityDocumentTableDef = (context: IAppContext): TFormTable => {
    return {
        id: `LiabilityDocumentTable`,
        entitySet: EntitySetName.Documents,
        initialSortBy: [
            { id: "DateAccountingTransaction", sort: Sort.Asc }
        ],
        columns: [
            {
                id: DocumentEntity.DateAccountingTransaction,
                label: i18next.t("ElectronicSubmission:Tax.Date"),
                isVisible: isAccountAssignmentCompanyValue,
            },
            {
                id: DocumentEntity.DateCbaDocument,
                label: i18next.t("ElectronicSubmission:Tax.Date"),
                isVisible: (args: IGetValueArgs) => isCashBasisAccountingCompany(args.context),
            },
            {

                id: DocumentEntity.DocumentType,
                fieldSettings: {
                    displayName: "Name"
                },
                label: i18next.t("ElectronicSubmission:Tax.DocumentType")
            },
            {
                id: DocumentEntity.NumberOurs,
                label: i18next.t("ElectronicSubmission:Tax.Document"),
                formatter: (val: TValue, args?: IFormatOptions<IDocumentEntity, IDocumentEntity>) => {
                    const { item } = args;
                    const { Id, NumberOurs } = item ?? {};

                    return Id ? getTableIntentLink(NumberOurs, {
                        route: `${getRouteByDocumentType(item.DocumentTypeCode as DocumentTypeCode)}/${Id}`,
                        context
                    }) : null;
                },
            },
            {
                id: DocumentEntity.Amount,
                label: i18next.t("ElectronicSubmission:Tax.Amount")
            }
        ]
    };
};

function isVatStatementPeriod(value: any): value is VatStatementPeriod {
    return value?.from && value?.to;
}

export function getVatSubmissionFrequencyFromPeriod(period: IElectronicSubmissionEntity | VatStatementPeriod): VatStatementFrequencyCode {
    let from: Date, to: Date;
    if (isVatStatementPeriod(period)) {
        from = period.from?.toDate();
        to = period.to?.toDate();
    } else {
        from = period.DatePeriodStart;
        to = period.DatePeriodEnd;
    }
    return isSameMonth(from, to) ? VatStatementFrequencyCode.Monthly : VatStatementFrequencyCode.Quarterly;
}

export function getVatSubmissionPeriodSortValue(date: Date | Dayjs, frequency: VatStatementFrequencyCode): TValue {
    if (!frequency || !date) {
        return DATE_MIN;
    }
    return getUtcDayjs(date).startOf(frequency === VatStatementFrequencyCode.Monthly ? "month" : "quarter").toDate();
}

export function getVatSubmissionPeriodTableCell(date: Date | Dayjs, frequency: VatStatementFrequencyCode, numeric?: boolean): ICellValueObject {
    if (!frequency || !date) {
        return {value: EMPTY_DASH, tooltip: EMPTY_DASH};
    }
    let value: string;
    const periodDate = getUtcDayjs(date);
    switch (frequency) {
        case VatStatementFrequencyCode.Quarterly:
            const quarter = periodDate.quarter();
            const year = periodDate.get("year");
            value = numeric ? `${periodDate.get("year")}/Q${periodDate.quarter()}`
                : i18next.t("ElectronicSubmission:VatSubmission.Quarter", { count: quarter, year, ordinal: true });
            break;
        case VatStatementFrequencyCode.Monthly:
            value = numeric ? periodDate.format("M/YY") : DateType.format(periodDate, DateFormat.monthAndYear);
            break;
    }
    return {value, tooltip: value};
}

export function getVatSubmissionPeriodName(date: Date | Dayjs, frequency: VatStatementFrequencyCode, numeric?: boolean): string {
    return getVatSubmissionPeriodTableCell(date, frequency, numeric).value as string;
}