import styled from "styled-components/macro";
import { StyledField } from "../inputs/field/Field.styles";
import TestIds from "../../testIds";
import { ButtonGroup } from "../button";
import Checkbox from "../inputs/checkbox";
import FieldsWrapper from "../inputs/field/FieldsWrapper";

export const REORDER_ICON_MARGIN = 5;

interface IHasLabel {
    fieldHasLabel?: boolean;
}

export const StyledFastEntry = styled.div`
    position: relative;
    display: flex;
`;

export const LineWrapper = styled(FieldsWrapper)<IHasLabel & {
    _hideContent?: boolean;
}>`
    position: relative;
    
    ${props => props._hideContent && `
    & > div:not([data-testId=${TestIds.ComparisonBorder}]):not([data-testId=${TestIds.AuditTrailLineOrder}]) {
      opacity: 0;
    }
    `}
`;

export const LineWrapperComparisonBorder = styled.div`
    position: absolute;
    top: -10px;
    left: -10px;
    width: calc(100% + 21px);
    height: calc(100% + 8px);
    border: 3px dashed ${props => props.theme.C_AUDIT_TRAIL_DIFF};
    border-radius: ${props => props.theme.borderLargeRadius};
    z-index: 180;
`;

export const LineOrder = styled.div <{
    _isChangedOrder: boolean;
}>`
    color: ${props => props._isChangedOrder ? props.theme.C_AUDIT_TRAIL_DIFF : "#dadada"};
    font-size: 16px;
    font-weight: bold;
    top: -2px;
    position: absolute;
    left: -24px;
`;

export const LastFieldWrapper = styled.div`
    display: flex;
    align-items: flex-end;

    ${StyledField} {
        margin-right: 2px;
    }
`;

export const IconWrapper = styled.div<IHasLabel>`
    position: relative;
    flex-shrink: 0;
    cursor: pointer;
    top: 5px;
    margin-bottom: 5px;
    margin-right: ${REORDER_ICON_MARGIN}px;
    height: fit-content;

    ${props => props.fieldHasLabel && `
        top: 23px;
        margin-bottom: 23px
    `}
`;

export const RightIconButtonGroup = styled(ButtonGroup)<IHasLabel>`
    display: flex;
    position: relative;
    margin-top: ${props => props.fieldHasLabel ? "27px" : "0"};
    margin-left: 2px;
    top: -7px;
`;

export const CheckboxStyledFastEntry = styled(Checkbox)<{
    fieldHasLabel: boolean;
    isDisabled: boolean;
}>`
    align-self: flex-start;
    margin-right: 12px;
    margin-left: 8px;
    top: ${props => props.fieldHasLabel ? "26px" : "10px"};
    opacity: ${props => props.isDisabled ? 0 : 1};
`;