import {
    adjustPxScale,
    DPI,
    fitRectangle,
    mmToPx,
    PrintOrientation,
    PrintType,
    ptToMm,
    ptToPx,
    pxToMm,
    STANDARD_DPI
} from "./Print.utils";
import { range } from "lodash";
import { FONTS } from "../../global.style";
import { logger } from "../log";
import { clamp } from "../general";
import memoizeOne from "../memoizeOne";
import jsPDF from "jspdf";
import evalaLogoSignatureSvg from "../../static/images/evalaLogoSignature.svg";

// all numbers are in PX
// conversion is driven by the DPI constant
export interface PdfPrintOptions {
    width: number;
    height: number;
    orientation: PrintOrientation;
    printType: PrintType;

    scale: number;

    header?: string;
    footer?: string;
    printPageNumbers?: boolean;

    printCropMarks?: boolean;
    cropMarksMargin?: number;
    cropMarksThickness?: number;

    marginTop?: number;
    marginBottom?: number;
    marginLeft?: number;
    marginRight?: number;

    textListAppendix?: ITextListGroup[];
}

export interface ITextListGroup {
    label: string;
    subGroups: ITextListSubGroup[];
}

export interface ITextListSubGroup {
    label?: string;
    values: string[];
}

const HEADER_FOOTER_FONT_SIZE = ptToPx(9);
// 1.2 is normal line height
const LINE_HEIGHT = HEADER_FOOTER_FONT_SIZE * 1.2;
const HEADER_FOOTER_VERTICAL_MARGIN = adjustPxScale(8);
const HEADER_FOOTER_SMALL_MARGIN = adjustPxScale(6);
const HEADER_FOOTER_BIG_MARGIN = adjustPxScale(12);
const HEADER_FOOTER_FONT = `${HEADER_FOOTER_FONT_SIZE}px ${FONTS.join(" , ")}`;
const CROP_MARK_LENGTH = mmToPx(3);
const CANVAS_UPSCALE = 2;

export default class PdfPrinting {
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/canvas
    static MAX_CANVAS_SIZE = 16384;

    private canvas: HTMLCanvasElement;
    private canvasCtx: CanvasRenderingContext2D;

    private options: PdfPrintOptions;

    // in case the width/height are too big for canvas to handle
    // output paperSize can differ (be bigger) from the canvas size
    private paperWidth: number;
    private paperHeight: number;

    constructor(options: PdfPrintOptions) {
        this.updateOptions(options);
    }

    public init = async (element: HTMLElement) => {
        // render the original canvas in bigger scale, to improve the quality of the pdf render
        let scale = CANVAS_UPSCALE;
        const scaledWidth = element.scrollWidth * scale;
        const scaledHeight = element.scrollHeight * scale;

        // limit the size of the 'element' to the max canvas dimension
        const [width, height] = this.limitRectangleToCanvasMax(scaledWidth, scaledHeight);

        scale = width !== scaledWidth ? width / element.scrollWidth : scale;

        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        canvas.width = width;
        canvas.height = height;
        context.scale(scale, scale);
        context.font = HEADER_FOOTER_FONT;

        const { default: html2canvas } = await import("html2canvas");

        this.canvas = await html2canvas(element, {
            backgroundColor: "#fff",
            logging: false,
            canvas,
            scale: 1
        });

        this.canvasCtx = this.canvas.getContext("2d");

        return this.canvas;
    };

    /** Set options via this method everytime there's any change in them.
     * This way, other methods can be called without those options. */
    public updateOptions = (options: Partial<PdfPrintOptions>) => {
        this.options = {
            ...this.options,
            ...options
        };

        // store page dimensions in px for convenience
        if (options.width || options.height) {
            this.paperWidth = options.width ?? this.options.width;
            this.paperHeight = options.height ?? this.options.height;

            // we can't render bigger canvas than MAX_CANVAS_SIZE
            const [pageWidthInPx, pageHeightInPx] = this.limitRectangleToCanvasMax(this.paperWidth, this.paperHeight);

            this.options.width = pageWidthInPx;
            this.options.height = pageHeightInPx;
        }

        this.options.marginTop = this.options.marginTop ?? 0;
        this.options.marginBottom = this.options.marginBottom ?? 0;
        this.options.marginLeft = this.options.marginLeft ?? 0;
        this.options.marginRight = this.options.marginRight ?? 0;
    };

    /** Limits dimension to MAX_CANVAS_SIZE */
    limitRectangleToCanvasMax = (width: number, height: number) => {
        let resultWidth = width;
        let resultHeight = height;

        if (width > PdfPrinting.MAX_CANVAS_SIZE || height > PdfPrinting.MAX_CANVAS_SIZE) {
            [resultWidth, resultHeight] = fitRectangle(width, height, PdfPrinting.MAX_CANVAS_SIZE, PdfPrinting.MAX_CANVAS_SIZE);
            logger.warn(`PdfPrinting: provided dimensions would exceed maximum limit on canvas (${PdfPrinting.MAX_CANVAS_SIZE}) and has been reduced to ${resultWidth}, ${resultHeight} `);
        }

        return [resultWidth, resultHeight];
    };

    /** Width in px of the paper space used for content */
    public getContentWidth = () => {
        return this.options.width - this.getAllHorizontalMargins();
    };

    /** Height in px of the paper space used for content */
    public getContentHeight = () => {
        return this.options.height - this.getAllVerticalMargins();
    };

    /** Content width recalculated with default screen dpi (96).
     * This way we can upscale the images but cut only the correct part from the source. */
    public getSourceContentWidth = () => {
        return this.getContentWidth() * STANDARD_DPI / DPI / this.options.scale * CANVAS_UPSCALE;
    };

    public getSourceContentHeight = () => {
        return this.getContentHeight() * STANDARD_DPI / DPI / this.options.scale * CANVAS_UPSCALE;
    };

    /** Actual width of the content on page "N" */
    public getSourceContentWidthForPage = (page: number) => {
        return this.getColumnForPage(page) + 1 < this.getColumnCount() ? this.options.width :
            ((this.canvas.width - ((this.getColumnCount() - 1) * (this.getContentWidth() / DPI * STANDARD_DPI) / this.options.scale)) * this.options.scale / STANDARD_DPI * DPI) * CANVAS_UPSCALE;
    };

    /** Actual height of the content on page "N" */
    public getSourceContentHeightForPage = (page: number) => {
        return this.getRowForPage(page) + 1 < this.getRowCount() ? this.options.height :
            ((this.canvas.height - ((this.getRowCount() - 1) * (this.getContentHeight() / DPI * STANDARD_DPI) / this.options.scale)) * this.options.scale / STANDARD_DPI * DPI) * CANVAS_UPSCALE;
    };

    public getColumnCount = () => {
        if (!this.canvas) {
            return 0;
        }

        return Math.ceil(this.canvas.width / this.getSourceContentWidth());
    };

    public getRowCount = () => {
        if (!this.canvas) {
            return 0;
        }

        return Math.ceil(this.canvas.height / this.getSourceContentHeight());
    };

    public getColumnForPage = (page: number) => {
        return (page - 1) % this.getColumnCount();
    };

    public getRowForPage = (page: number) => {
        return Math.floor((page - 1) / this.getColumnCount());
    };

    public getMaxPageCount = () => {
        if (this.options.printType === PrintType.SinglePage) {
            return 1;
        }

        if (!this.canvas) {
            return 0;
        }

        const maxCount = this.getColumnCount() * this.getRowCount();

        if (maxCount <= 0) {
            return 0;
        }

        return isFinite(maxCount) ? maxCount : 1;
    };

    getAllVerticalMargins = () => {
        return this.options.marginTop + this.options.marginBottom + this.getHeaderHeight() + this.getFooterHeight();
    };

    getAllHorizontalMargins = () => {
        return this.options.marginLeft + this.options.marginRight;
    };

    /** Canvas doesn't support multi line text. Manually split the lines based on available width. */
    splitTextToLines = memoizeOne((text: string, maxWidth: number): string[] => {
        if (maxWidth <= 0) {
            return [];
        }

        const lines: string[] = [];
        let remainingText = text;
        let endOfLine = 0;
        let maxFound = false;
        let minFound = false;

        // calling 'measureText' lots of times is slow - we want to avoid that
        // find length of the first line by going through the characters one by one
        // then expect all the other lines to have similar length
        while (remainingText) {
            const currentLine = remainingText.slice(0, endOfLine);
            const measurements = this.canvasCtx.measureText(currentLine);
            const lineWidth = measurements.width;
            const endCurrentLine = () => {
                lines.push(remainingText.slice(0, endOfLine));
                remainingText = remainingText.slice(endOfLine);

                endOfLine = Math.min(remainingText.length, endOfLine);
                maxFound = false;
                minFound = false;
            };

            if (lineWidth > maxWidth) {
                endOfLine -= 1;
                maxFound = true;

                if (minFound) {
                    if (endOfLine === 0) {
                        // maxWidth isn't big enough to fit even one character
                        break;
                    }
                    endCurrentLine();
                }
            } else if (currentLine.length === remainingText.length) {
                endCurrentLine();
            } else {
                if (maxFound) {
                    endCurrentLine();
                } else {
                    endOfLine += 1;
                    minFound = true;
                }
            }
        }


        return lines;
    });

    measureTextHeight = (text: string, maxWidth: number) => {
        return this.splitTextToLines(text, maxWidth).length * LINE_HEIGHT;
    };

    renderText = (canvasCtx: CanvasRenderingContext2D, text: string, startX: number, startY: number, maxWidth: number) => {
        const lines = this.splitTextToLines(text, maxWidth);
        let y = startY;

        for (const line of lines) {
            canvasCtx.fillText(line, startX, y);
            y += LINE_HEIGHT;
        }
    };

    getMaxHeaderWidth = () => {
        return this.getContentWidth() - HEADER_FOOTER_SMALL_MARGIN - HEADER_FOOTER_BIG_MARGIN;
    };

    getMemoizedHeaderHeight = memoizeOne(() => {
        if (!this.options.header) {
            return 0;
        }

        return this.measureTextHeight(this.options.header, this.getMaxHeaderWidth()) + 2 * HEADER_FOOTER_VERTICAL_MARGIN;
    }, () => [this.options.header]);

    getHeaderHeight = () => {
        return this.getMemoizedHeaderHeight();
    };

    getMaxFooterWidth = () => {
        let width = this.getContentWidth() - HEADER_FOOTER_SMALL_MARGIN - HEADER_FOOTER_BIG_MARGIN;

        if (this.options.printPageNumbers) {
            width -= this.getPageNumberWidth();
        }

        return width;
    };

    getFooterTextHeight = () => {
        return this.measureTextHeight(this.options.footer, this.getMaxFooterWidth());
    };

    getMemoizedFooterHeight = memoizeOne(() => {
        if (!this.options.footer && !this.options.printPageNumbers) {
            return 0;
        }

        let height;

        if (this.options.footer) {
            height = this.getFooterTextHeight();
        } else if (this.options.printPageNumbers) {
            height = LINE_HEIGHT;
        }

        return height + 2 * HEADER_FOOTER_VERTICAL_MARGIN;
    }, () => [this.options.footer, this.options.printPageNumbers]);

    getFooterHeight = () => {
        return this.getMemoizedFooterHeight();
    };

    getPageNumberWidth = () => {
        if (!this.options.printPageNumbers) {
            return 0;
        }

        // use fixed number to prevent infinite loop
        // getMaxPageCount depends on rowCount that depends on footer height that depends on page number width and so on...
        return this.canvasCtx.measureText("99").width + 2 * HEADER_FOOTER_SMALL_MARGIN;
    };

    renderHeader = (canvasCtx: CanvasRenderingContext2D) => {
        if (!this.options.header) {
            return;
        }

        canvasCtx.textBaseline = "top";
        canvasCtx.textAlign = "start";

        this.renderText(
            canvasCtx, this.options.header,
            this.options.marginLeft + HEADER_FOOTER_VERTICAL_MARGIN,
            this.options.marginTop + HEADER_FOOTER_VERTICAL_MARGIN,
            this.getMaxHeaderWidth()
        );
    };

    renderFooter = (canvasCtx: CanvasRenderingContext2D) => {
        if (!this.options.footer) {
            return;
        }

        canvasCtx.textBaseline = "top";
        canvasCtx.textAlign = "start";

        this.renderText(
            canvasCtx, this.options.footer,
            this.options.marginLeft + HEADER_FOOTER_VERTICAL_MARGIN,
            this.options.height - this.options.marginBottom - HEADER_FOOTER_VERTICAL_MARGIN - this.getFooterTextHeight(),
            this.getMaxFooterWidth()
        );
    };

    renderPageNumbers = (canvasCtx: CanvasRenderingContext2D, page: number) => {
        if (!this.options.printPageNumbers || this.options.printType === PrintType.SinglePage) {
            return;
        }

        canvasCtx.textBaseline = "bottom";
        canvasCtx.textAlign = "end";
        canvasCtx.fillText(page.toString(), this.options.width - this.options.marginRight - HEADER_FOOTER_VERTICAL_MARGIN, this.options.height - this.options.marginBottom - HEADER_FOOTER_VERTICAL_MARGIN);
    };

    /** Returns canvas that contains image for the page "page"
     * accepts custom canvas element the is gonna get repaint over */
    public getCanvasOfPage = (page: number, canvas?: HTMLCanvasElement) => {
        const pageCanvas = canvas ?? document.createElement("canvas");
        const pageCanvasContext = pageCanvas.getContext("2d");

        pageCanvas.width = this.options.width;
        pageCanvas.height = this.options.height;

        // set white background
        pageCanvasContext.fillStyle = "white";
        pageCanvasContext.fillRect(0, 0, pageCanvas.width, pageCanvas.height);

        pageCanvasContext.fillStyle = "#4b4a4b";
        pageCanvasContext.font = HEADER_FOOTER_FONT;

        this.renderHeader(pageCanvasContext);
        this.renderFooter(pageCanvasContext);
        this.renderPageNumbers(pageCanvasContext, page);

        const contentWidth = this.getContentWidth();
        const contentHeight = this.getContentHeight();

        let sourceX, sourceY, sourceWidth, sourceHeight, destinationWidth, destinationHeight;
        const destinationX = this.options.marginLeft;
        const destinationY = this.options.marginTop + this.getHeaderHeight();

        if (this.options.printType === PrintType.SinglePage) {
            // fit the whole canvas to the result dimensions
            const [fittedCanvasWidth, fittedCanvasHeight] = fitRectangle(this.canvas.width, this.canvas.height, contentWidth, contentHeight);

            sourceX = 0;
            sourceY = 0;
            sourceWidth = this.canvas.width;
            sourceHeight = this.canvas.height;
            destinationWidth = fittedCanvasWidth;
            destinationHeight = fittedCanvasHeight;
        } else {
            if (!page) {
                throw new Error("You need to pass the 'page' argument in MultiplePages PrintType");
            }

            const sourceContentWidth = this.getSourceContentWidth();
            const sourceContentHeight = this.getSourceContentHeight();
            const column = this.getColumnForPage(page);
            const row = this.getRowForPage(page);


            sourceX = column * sourceContentWidth;
            sourceY = row * sourceContentHeight;
            sourceWidth = sourceContentWidth;
            sourceHeight = sourceContentHeight;
            destinationWidth = contentWidth;
            destinationHeight = contentHeight;
        }

        pageCanvasContext.drawImage(this.canvas,
            sourceX, sourceY,
            sourceWidth, sourceHeight,
            destinationX, destinationY,
            destinationWidth,
            destinationHeight
        );

        return pageCanvas;
    };

    /** Width of the page gets bigger if crop marks are used */
    getResultPageWidth = (width: number) => {
        // todo this can cause the canvas to get over the MAX_CANVAS_SIZE as well
        if (this.options.printCropMarks) {
            width += 2 * CROP_MARK_LENGTH + 2 * this.options.cropMarksMargin;
        }

        return width;
    };

    /** Height of the page gets bigger if crop marks are used */
    getResultPageHeight = (height: number) => {
        if (this.options.printCropMarks) {
            height += 2 * CROP_MARK_LENGTH + 2 * this.options.cropMarksMargin;
        }

        return height;
    };

    getPageContentSize = (page: number) => {
        let pageContentWidth, pageContentHeight;

        if (this.options.printType === PrintType.SinglePage) {
            [pageContentWidth, pageContentHeight] = fitRectangle(this.canvas.width, this.canvas.height, this.getContentWidth(), this.getContentHeight());
        } else {
            pageContentWidth = this.getSourceContentWidthForPage(page);
            pageContentHeight = this.getSourceContentHeightForPage(page);
        }

        pageContentWidth += this.getAllHorizontalMargins();
        pageContentHeight += this.getAllVerticalMargins();

        pageContentWidth = clamp(pageContentWidth, 0, this.options.width);
        pageContentHeight = clamp(pageContentHeight, 0, this.options.height);

        return [pageContentWidth, pageContentHeight];
    };

    renderCropMarks = (pageCanvas: HTMLCanvasElement, page: number) => {
        if (!this.options.printCropMarks) {
            return;
        }

        const pageContentWidth = this.options.width;
        const pageContentHeight = this.options.height;
        let resultPageWidth = this.getResultPageWidth(this.options.width);
        let resultPageHeight = this.getResultPageHeight(this.options.height);

        [resultPageWidth, resultPageHeight] = this.limitRectangleToCanvasMax(resultPageWidth, resultPageHeight);

        // we need to use new temporary canvas, to change dimensions of the original canvas
        // because width/height change causes the whole canvas to clear
        const tmpCanvas = document.createElement("canvas");
        const tmpCanvasContext = tmpCanvas.getContext("2d");

        tmpCanvas.width = resultPageWidth;
        tmpCanvas.height = resultPageHeight;

        tmpCanvasContext.fillStyle = "white";
        tmpCanvasContext.fillRect(0, 0, tmpCanvas.width, tmpCanvas.height);

        const edgeMargin = CROP_MARK_LENGTH + this.options.cropMarksMargin;
        const cropMarksThickness = this.options.cropMarksThickness ?? 2;

        // copy original canvas
        tmpCanvasContext.drawImage(pageCanvas, edgeMargin, edgeMargin);

        tmpCanvasContext.strokeStyle = "#000";
        tmpCanvasContext.lineWidth = cropMarksThickness;

        // top left |
        tmpCanvasContext.beginPath();
        tmpCanvasContext.moveTo(edgeMargin, 0);
        tmpCanvasContext.lineTo(edgeMargin, CROP_MARK_LENGTH);
        tmpCanvasContext.stroke();

        // top left —
        tmpCanvasContext.beginPath();
        tmpCanvasContext.moveTo(0, edgeMargin);
        tmpCanvasContext.lineTo(CROP_MARK_LENGTH, edgeMargin);
        tmpCanvasContext.stroke();

        // top right |
        tmpCanvasContext.beginPath();
        tmpCanvasContext.moveTo(pageContentWidth + edgeMargin, 0);
        tmpCanvasContext.lineTo(pageContentWidth + edgeMargin, CROP_MARK_LENGTH);
        tmpCanvasContext.stroke();

        // top right —
        tmpCanvasContext.beginPath();
        tmpCanvasContext.moveTo(edgeMargin + pageContentWidth + edgeMargin, edgeMargin);
        tmpCanvasContext.lineTo(edgeMargin + pageContentWidth + this.options.cropMarksMargin, edgeMargin);
        tmpCanvasContext.stroke();

        // bottom left |
        tmpCanvasContext.beginPath();
        tmpCanvasContext.moveTo(edgeMargin, edgeMargin + pageContentHeight + edgeMargin);
        tmpCanvasContext.lineTo(edgeMargin, edgeMargin + pageContentHeight + this.options.cropMarksMargin);
        tmpCanvasContext.stroke();

        // bottom left —
        tmpCanvasContext.beginPath();
        tmpCanvasContext.moveTo(0, edgeMargin + pageContentHeight);
        tmpCanvasContext.lineTo(CROP_MARK_LENGTH, edgeMargin + pageContentHeight);
        tmpCanvasContext.stroke();

        // bottom right |
        tmpCanvasContext.beginPath();
        tmpCanvasContext.moveTo(pageContentWidth + edgeMargin, edgeMargin + pageContentHeight + edgeMargin);
        tmpCanvasContext.lineTo(pageContentWidth + edgeMargin, edgeMargin + pageContentHeight + this.options.cropMarksMargin);
        tmpCanvasContext.stroke();

        // bottom right —
        tmpCanvasContext.beginPath();
        tmpCanvasContext.moveTo(edgeMargin + pageContentWidth + edgeMargin, edgeMargin + pageContentHeight);
        tmpCanvasContext.lineTo(edgeMargin + pageContentWidth + this.options.cropMarksMargin, edgeMargin + pageContentHeight);
        tmpCanvasContext.stroke();

        pageCanvas.width = tmpCanvas.width;
        pageCanvas.height = tmpCanvas.height;

        const canvasCtx = pageCanvas.getContext("2d");

        canvasCtx.drawImage(tmpCanvas, 0, 0);
    };

    public static renderTextList = async (pdf: jsPDF, textListGroups: ITextListGroup[]): Promise<[number, number]> => {
        // font files are big, only load them on demand
        if (!pdf.getFontList()["Lato"]) {
            const { font: latoRegular } = await import("./jsPdfFonts/Lato-Regular");
            const { font: latoBold } = await import("./jsPdfFonts/Lato-Bold");

            pdf.addFileToVFS("Lato-Normal.ttf", latoRegular);
            pdf.addFont("Lato-Normal.ttf", "Lato", "normal");
            pdf.addFileToVFS("Lato-Bold.ttf", latoBold);
            pdf.addFont("Lato-Bold.ttf", "Lato", "bold");

        }

        const PAGE_WIDTH = pdf.internal.pageSize.getWidth();
        const PAGE_HEIGHT = pdf.internal.pageSize.getHeight();

        // values in mm
        const EDGE_MARGIN = 10;
        const GROUP_MARGIN = 13;
        const BIG_TEXT_MARGIN = 8;
        const SMALL_TEXT_MARGIN = 1;
        const COLUMN_WIDTH = 56;
        // values in pt
        const BIG_TEXT_SIZE = 12;
        const SMALL_TEXT_SIZE = 10;
        const LINE_HEIGHT = 1.2;

        let cursorY = EDGE_MARGIN;
        let cursorX = EDGE_MARGIN;

        pdf.setTextColor("#4b4a4b");

        const fnRenderText = (text: string, textSize: number, nextLine?: string, nextLineTextSize: number = SMALL_TEXT_SIZE) => {
            pdf.setFontSize(textSize);

            const textHeight = ptToMm(textSize) * LINE_HEIGHT;
            const lines = pdf.splitTextToSize(text, COLUMN_WIDTH);
            let linesHeight = textHeight * lines.length;

            // we want to ensure that headers are not last line on the page (orphans)
            if (nextLine) {
                const nextLineTextHeight = ptToMm(nextLineTextSize) * LINE_HEIGHT;
                const nextLines = pdf.splitTextToSize(nextLine, COLUMN_WIDTH);
                linesHeight += nextLineTextHeight * nextLines.length + SMALL_TEXT_MARGIN;
            }

            if (cursorY + linesHeight > PAGE_HEIGHT - EDGE_MARGIN) {
                cursorY = EDGE_MARGIN;
                cursorX += COLUMN_WIDTH + EDGE_MARGIN;

                if (cursorX + COLUMN_WIDTH > PAGE_WIDTH - EDGE_MARGIN) {
                    cursorX = EDGE_MARGIN;
                    pdf.addPage();
                }
            }

            pdf.text(lines, cursorX, cursorY);

            cursorY += textHeight * lines.length;
        };


        for (let i = 0; i < textListGroups.length; i++) {
            const group = textListGroups[i];

            pdf.setFont("Lato", "bold");
            fnRenderText(group.label, BIG_TEXT_SIZE, group.subGroups[0].label ?? group.subGroups[0].values[0]);
            cursorY += BIG_TEXT_MARGIN;

            for (let j = 0; j < group.subGroups.length; j++) {
                const subgroup = group.subGroups[j];

                if (subgroup.label) {
                    pdf.setFont("Lato", "bold");
                    fnRenderText(subgroup.label, SMALL_TEXT_SIZE, subgroup.values[0]);
                    cursorY += SMALL_TEXT_MARGIN;
                }

                pdf.setFont("Lato", "normal");

                for (const value of subgroup.values) {
                    fnRenderText(value, SMALL_TEXT_SIZE);
                    cursorY += SMALL_TEXT_MARGIN;
                }

                if (j < group.subGroups.length - 1) {
                    cursorY += BIG_TEXT_MARGIN;
                }
            }

            if (i < textListGroups.length - 1) {
                cursorY += GROUP_MARGIN;
            }
        }

        return [cursorX, cursorY];
    };

    public static renderEvalaLogo = async (pdf: jsPDF, positionY: number): Promise<void> => {
        const PAGE_WIDTH = pdf.internal.pageSize.getWidth();
        const PAGE_HEIGHT = pdf.internal.pageSize.getHeight();
        // in mm
        const EDGE_MARGIN = 10;
        const logoWidthMM = 22;
        const spacer = pxToMm(33);

        pdf.setFont("Lato", "bold");
        pdf.setFontSize(8);
        pdf.setTextColor("#a7a6a7");

        const text = "Vyexportovala";
        const textWidth = pdf.getTextWidth(text);
        const cursorX = PAGE_WIDTH - EDGE_MARGIN - logoWidthMM;
        const cursorY = PAGE_HEIGHT - EDGE_MARGIN;

        pdf.text(text, cursorX - textWidth - spacer, cursorY);

        const promise = new Promise(async (resolve) => {
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            const img = new Image();

            img.src = evalaLogoSignatureSvg;
            img.onload = function() {
                const aspectRatio = img.width / img.height;
                const logoWidthMM = 22;
                const logoHeightMM = logoWidthMM / aspectRatio;

                canvas.width = mmToPx(logoWidthMM);
                canvas.height = mmToPx(logoWidthMM);
                context.drawImage(img, 0, 0, canvas.width, canvas.height);

                const pngDataUrl = canvas.toDataURL("image/png");

                pdf.addImage(pngDataUrl, "image/png", cursorX, cursorY - logoHeightMM, logoWidthMM, logoHeightMM);
                resolve(null);
            };
        });

        await promise;

        return;
    };

    generatePdf = async (pages?: number[]) => {
        const pdfWidth = pxToMm(this.getResultPageWidth(this.paperWidth));
        const pdfHeight = pxToMm(this.getResultPageHeight(this.paperHeight));

        const { default: jsPDF } = await import("jspdf");
        const pdf = new jsPDF({
            orientation: this.options.orientation === PrintOrientation.Landscape ? "landscape" : "portrait",
            format: [pdfWidth, pdfHeight],
            units: "mm"
        });

        if (!pages) {
            const maxPageCount = this.getMaxPageCount();

            if (maxPageCount <= 0) {
                throw new Error("Wrong settings, size of the page is too small to render any content");
            }

            pages = range(maxPageCount).map(page => page + 1);
        }


        for (let i = 0; i < pages.length; i++) {
            const page = pages[i];
            const pageCanvas = this.getCanvasOfPage(page);

            if (this.options.printCropMarks) {
                this.renderCropMarks(pageCanvas, page);
            }

            if (i > 0) {
                pdf.addPage();
            }

            pdf.addImage(pageCanvas.toDataURL("image/jpeg", 1), "JPEG", 0, 0, pdfWidth, pdfHeight);
        }

        if (this.options.textListAppendix) {
            pdf.addPage();
            const [cursorX, cursorY] = await PdfPrinting.renderTextList(pdf, this.options.textListAppendix);
            await PdfPrinting.renderEvalaLogo(pdf, cursorY);
        }

        return pdf;
    };

    public savePdf = async (fileName: string, pages?: number[]) => {
        const pdf = await this.generatePdf(pages);

        return pdf.save(fileName, { returnPromise: true });
    };

    public getPdf = async (fileName: string, pages?: number[]) => {
        const pdf = await this.generatePdf(pages);

        return pdf.output(fileName);

    };
}