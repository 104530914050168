import React from "react";
import { getDefinitions } from "./AccountAssignmentDef";
import { withTranslation } from "react-i18next";
import TableView from "../../views/table/TableView";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import Page, { IProps } from "../Page";
import { withRouter } from "react-router-dom";
import { withOData } from "@odata/withOData";

class AccountAssignment extends Page<IProps> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;

    static defaultProps = {
        getDef: getDefinitions,
        tableView: TableView
    };
}

export default withRouter(withOData(withTranslation(["AccountAssignment", "Common", "Components", "Error"])(AccountAssignment)));