import { IEntity } from "@odata/BindingContext";

export enum AuditTrailChangeType {
    Entity = "Entity",
    Attachment = "Attachment"
}

export enum AuditAttachmentOperation {
    New = "New",
    Update = "Update",
    Delete = "Delete"
}

export interface IAuditEntity {
    AuthorId: number;
    AuthorName: string;
    ChangedOn: string;
    Type: AuditTrailChangeType;
    Operation: AuditAttachmentOperation;
    EntityId: number;
    CurrentValue: string;
    PreviousValue: string;
}

export interface IAuditEntityAtTime {
    AuthorId: number;
    AuthorName: string;
    ChangedOn: string;
    Entity: IEntity;
}

export enum AuditTrailTableColumn {
    ChangedOnDate = "ChangedOnDate",
    ChangedOnTime = "ChangedOnTime",
    AuthorName = "AuthorName",
    Type = "Type"
}