import React from "react";
import Table, {
    ICellValueObject,
    IColumn,
    IColumnResizeEvent,
    IMetaColumn,
    IRow,
    ITableProps,
    TColumn,
    TId
} from "./Table";
import { IconSize, TableBatch, TableSizes } from "../../enums";
import {
    getAction,
    getLeavesColumns,
    isMetaColumn,
    isRowValueCellValueObject,
    isTableWithoutHeader
} from "./TableUtils";
import { textAlignToPadding } from "./Rows.styles";
import TestIds from "../../testIds";
import { clamp, getValue, handleRefHandlers, isObjectEmpty, quantile } from "@utils/general";
import { TRecordAny } from "../../global.types";
import memoizeOne from "../../utils/memoizeOne";

interface IColumnsExtension {
    [id: string]: {
        width: number;
        afterContentMinWidth?: number;
    };
}

export interface ITableWithAutoSizedColumnsProps extends Omit<ITableProps, "onColumnResize"> {
    onColumnResize?: (columnId: TId, newWidth: number, columnsWidths: Record<string, number>) => void;
    loaded?: boolean;
    busy?: boolean;
    customBusyContent?: React.ReactNode;
    initialColumnWidths?: Record<string, number>;
    /** Should only be used when table is inside fixed width wrapper.
     * After all column widths are computed, remaining empty space is assigned to the first column.
     * Used in Dashboard tables to make it more visually pleasing.*/
    assignRemainingSpaceToFirstColumn?: boolean;
}

interface IAutoSizedColumnsTableState {
    hasChanged: boolean;
    columnsExtensions: IColumnsExtension;
    lastColumnId: string;
}

export default class TableWithAutoSizedColumns extends React.PureComponent<ITableWithAutoSizedColumnsProps, IAutoSizedColumnsTableState> {
    static defaultProps: Partial<ITableWithAutoSizedColumnsProps> = {
        initialColumnWidths: {},
        loaded: true
    };

    state: IAutoSizedColumnsTableState = {
        hasChanged: true,
        columnsExtensions: {},
        lastColumnId: null
    };

    tableRef = React.createRef<HTMLDivElement>();
    canvasCtx: CanvasRenderingContext2D;

    componentDidMount() {
        if (this.props.loaded) {
            this.init();
        }
    }

    componentDidUpdate(prevProps: ITableWithAutoSizedColumnsProps, prevState: IAutoSizedColumnsTableState) {
        const lastColumnId = (this.props.columns[this.props.columns.length - 1] as IColumn)?.id;
        const isLastColumnDifferent = lastColumnId !== this.state.lastColumnId;

        if ((!prevProps.loaded && this.props.loaded && (isObjectEmpty(this.state.columnsExtensions) || this.props.columns.find(col => !isMetaColumn(col) && !this.state.columnsExtensions[col.id])))
            || (prevProps.rows.length === 0 && this.props.rows.length > 0)
            || (prevProps.rows.length === 0 && !prevProps.addingRow && !!this.props.addingRow)
            // for printing, we need to enlarge columns with icons, to fit their titles instead
            // and the other way around as well
            || (prevProps.isForPrint !== this.props.isForPrint && this.props.loaded)
            || isLastColumnDifferent
        ) {
            this.reInit();
        }

        if (isLastColumnDifferent) {
            this.setState({
                lastColumnId
            });

        }
    }

    init = (): void => {
        if (this.props.rows.length > 0 && this.getColumnItems(0)?.length <= (isTableWithoutHeader(this.props.columns) ? 0 : 1)) {
            // when Table is virtualized, it is possible that the rows are not yet rendered in the first cycle
            // => skip to the next one so that the rows are rendered
            setTimeout(this.init);
            return;
        }
        this.computeColumnsExtensions();
    };

    reset = async (): Promise<void> => {
        return new Promise((resolve) => {
            // don't reset whole columnsExtensions, to prevent columns from jumping,
            // but we still need to know that the extensions are being re-computed
            this.setState({
                hasChanged: true
            }, resolve);
        });
    };

    reInit = async (): Promise<void> => {
        await this.reset();
        this.init();
    };

    getTextWidth = (text: string, font: string): number => {
        if (!this.canvasCtx) {
            const canvas = document.createElement("canvas");
            this.canvasCtx = canvas.getContext("2d");
        }

        this.canvasCtx.font = font;

        return this.canvasCtx.measureText(text).width + 2;
    };

    getColumnWidths = (rows: IRow[], column: string, font: string): number[] => {
        let widths: number[] = [];

        if (rows.length === 0 || !font) {
            return widths;
        }

        for (const row of rows) {
            if (!row) {
                continue;
            }

            if (row.values[column]) {
                const rowValue = getValue(row.values[column]);

                let rowTextValue: string;

                if (typeof rowValue === "string") {
                    rowTextValue = rowValue;
                } else if (typeof (rowValue as ICellValueObject)?.tooltip === "string") {
                    rowTextValue = (rowValue as ICellValueObject).tooltip as string;
                } else if (typeof (rowValue as ICellValueObject)?.value === "string") {
                    rowTextValue = (rowValue as ICellValueObject).value as string;
                } else {
                    // no string value, nothing to compute from,
                    // but there can still be nested rows
                }

                if (rowTextValue) {
                    widths.push(this.getTextWidth(rowTextValue.toString(), font));
                }
            }

            if (row.rows) {
                widths = [...widths, ...this.getColumnWidths(row.rows, column, font)];
            }
        }

        return widths;
    };

    // returns first row that contains value for given column
    getRowWithColumnValue = (rows: IRow[], columnId: string): IRow => {
        for (const row of rows) {
            if (row.values[columnId]) {
                return row;
            }

            if (row.rows) {
                const innerRow: IRow = this.getRowWithColumnValue(row.rows, columnId);

                if (innerRow) {
                    return innerRow;
                }
            }
        }

        return null;
    };

    getLeavesColumns = (): IColumn[] => {
        return getLeavesColumns(this.props.columns as TColumn[]);
    };

    hasColumnContentFit = (valueIsCellObject: boolean): boolean => {
        return this.props.isForPrint && valueIsCellObject;
    };

    getColumnItems = (index: number): NodeListOf<HTMLElement> => {
        return this.tableRef.current?.querySelectorAll(`[data-column-index='${index}']`);
    };

    computeColumnsExtensions = (): void => {
        const columnWidths = this.props.initialColumnWidths;
        const columnsExtensions: IColumnsExtension = {};

        // assume all rows has action or none of them. If we conditionally show it, we need to refactor this to
        // prop most likely to say if we want to add the padding for it to all rows or only to the ones with action
        // same logic is used in Table to render HeaderCell
        const hasAction = this.props.rows?.length && !!getAction(this.props.rows[0], this.props.isForPrint);

        this.getLeavesColumns().forEach((column, i, leavesColumns) => {
            let width: number;
            let afterContentMinWidth: number;
            const rowWithValue = this.getRowWithColumnValue(this.props.rows, column.id);
            const valueIsCellObject = isRowValueCellValueObject(rowWithValue?.values?.[column.id]);
            const isFirst = i === 0;
            const isLast = i === leavesColumns.length - 1;
            const {
                paddingLeft,
                paddingRight
            } = textAlignToPadding(column.textAlign, isFirst, !!this.props.hierarchy, isLast, hasAction, this.props.useStatusHighlight);

            if (!this.hasColumnContentFit(valueIsCellObject) && column.width) {
                width = column.width;
            } else if (!this.hasColumnContentFit(valueIsCellObject) && columnWidths && columnWidths[column.id]
                // last column should ignore stored width and just try to use as much space as it can by default
                // https://solitea-cz.atlassian.net/browse/DEV-27970
                && !isLast) {
                width = columnWidths[column.id];
            } else {
                // this doesn't really make much sense, because of virtualization. we don't render many items at once
                const items = this.getColumnItems(i);

                if (items?.[0]) {
                    let widths: number[];

                    const getItemWidth = (item: HTMLElement) => {
                        let itemWidth = item.scrollWidth;

                        if (item.childElementCount > 0) {
                            // sum of children width can be bigger than parent width, because of overflow: hidden somewhere inside
                            const childrenWidth = Array.from(item.children).reduce((childrenWidthSum, child) => {
                                return childrenWidthSum + getItemWidth(child as HTMLElement);
                            }, 0);

                            if (childrenWidth > itemWidth) {
                                itemWidth = childrenWidth;
                            }
                        }

                        // add width of after content element
                        if (item.nextElementSibling?.getAttribute("data-testid") === TestIds.TableCellAfterContent) {
                            const afterContent = item.nextElementSibling.firstChild as HTMLElement;
                            itemWidth += afterContent.scrollWidth;
                            afterContentMinWidth = Math.max(afterContentMinWidth ?? 0, afterContent.scrollWidth);
                        }

                        return itemWidth;
                    };

                    if ((valueIsCellObject /*and at least one other value than header is rendered*/ && items?.[1]?.scrollWidth > 0) || this.props.disableVirtualization) {
                        // for values formatted into e.g. icon, we cannot compute width from the width of the text
                        widths = Array.from(items).map(item => getItemWidth(item));
                    } else {
                        // compute widths of ALL values by using canvas measureText instead
                        widths = this.getColumnWidths(this.props.rows, column.id, items?.[1] && getComputedStyle(items[1]).font);
                    }

                    // if max width of column content isn't too big, take everything, otherwise use quantile
                    width = Math.max(...widths) + 3;

                    if (width > 300 && !this.hasColumnContentFit(valueIsCellObject)) {
                        width = quantile(widths, 0.8) * 1.05;
                    }

                    width += paddingLeft + paddingRight + 2 /*padding and border */;
                    const header = items[0];
                    const label = column.info ? header.children[0]?.children[0] : header.children[0];
                    const headerCell = header.parentElement.parentElement;
                    const headerCellStyles = getComputedStyle(headerCell);
                    // scrollwidth +1 to fix problem with rounding
                    const headerWidth = (label?.scrollWidth ?? 0) + 1 + ((!this.props.disableSort && !column.disableSort) ? IconSize.asNumber("XS") + 1 : 0) /*sort icon */
                        + (column.info ? IconSize.asNumber("S") + 4 : 0) // info icon + 4px icon margin
                        + paddingLeft + paddingRight
                        + parseFloat(headerCellStyles.borderLeftWidth) + parseFloat(headerCellStyles.borderRightWidth);
                    width = Math.min(Math.max(width, headerWidth, TableSizes.MinColumnWidth), TableSizes.MaxColumnWidth);
                } else {
                    width = TableSizes.MinColumnWidth;
                }
            }

            columnsExtensions[column.id] = {
                width: width,
                afterContentMinWidth
            };
        });

        const headerRef = this.tableRef.current?.children?.[0];

        for (let i = 0; i < this.props.columns.length; i++) {
            const col = this.props.columns[i];

            if (!isMetaColumn(col as TColumn)) {
                continue;
            }

            const leavesCols = getLeavesColumns((col as IMetaColumn).columns as TColumn[]);
            const leavesColsCombinedWidth = leavesCols.reduce((combinedWidth, currentCol) => {
                return combinedWidth + columnsExtensions[currentCol.id].width;
            }, 0);

            // we need subpixel precision for the meta columns to line up with columns properly
            // using getBoundingClientRect instead of scrollWidth
            const metaColWidth = headerRef?.children[i]?.children?.[0]?.getBoundingClientRect().width ?? 0;

            if (metaColWidth > leavesColsCombinedWidth) {
                // enlarge each leave column so that their combined width is same as of the parent meta column
                const addWidth = (metaColWidth - leavesColsCombinedWidth) / leavesCols.length;

                for (const leaveCol of leavesCols) {
                    columnsExtensions[leaveCol.id].width += addWidth;
                }
            }
        }

        if (this.props.assignRemainingSpaceToFirstColumn && this.props.columns?.length > 0) {
            let remainingWidth = this.tableRef.current.clientWidth;

            for (const col of Object.values(columnsExtensions)) {
                remainingWidth -= col.width;
            }

            // assign remaining width to first column
            columnsExtensions[(this.props.columns[0] as IColumn).id].width += remainingWidth;
        }

        this.setState({
            columnsExtensions,
            hasChanged: false
        });
    };

    handleColumnResize = ({ id, width, end }: IColumnResizeEvent) => {
        this.setState((state) => {
            const newWidth = clamp(width, TableSizes.MinColumnWidth, TableSizes.MaxColumnWidth);
            const newColumnsExtensions = {
                ...state.columnsExtensions,
                [id.toString()]: {
                    ...state.columnsExtensions[id.toString()],
                    width: newWidth
                }
            };

            if (end) {
                const widths = Object.entries(newColumnsExtensions).reduce((columnWidths: TRecordAny, [columnId, columnExtension]) => {
                    columnWidths[columnId] = columnExtension.width;

                    return columnWidths;
                }, {});

                this.props.onColumnResize?.(id, newWidth, widths);
            }

            return {
                columnsExtensions: newColumnsExtensions
            };
        });
    };

    prepareColumns = (columns: TColumn[]): TColumn[] => {
        return columns.map((column: TColumn) => {
                if (isMetaColumn(column)) {
                    return {
                        ...column,
                        columns: this.prepareColumns(column.columns)
                    };
                } else {
                    return {
                        ...column,
                        width: this.state.columnsExtensions[column.id]?.width ?? TableSizes.DefaultColumnWidth,
                        // For columns which content should stretch to the size of the widest value, we need to render it differently
                        // first time, when calculating width, and later to stretch them to maximum size
                        stretchContent: this.state.columnsExtensions[column.id]?.width ? column.stretchContent : false,
                        afterContentMinWidth: this.state.columnsExtensions[column.id]?.afterContentMinWidth
                    };
                }
            }
        );
    };

    getColumns = memoizeOne((): TColumn[] => {
        return this.prepareColumns(this.props.columns);
    }, () => [this.props.columns, this.state.columnsExtensions]);

    handleTableRef = (ref: Element): void => {
        handleRefHandlers(ref, this.tableRef, this.props.passRef);
    };

    render() {
        return (
            <Table {...this.props}
                // for first render, enforce more rendered rows, so that we can accurately compute column width
                   minimumRenderedRows={!this.state.hasChanged ? 0 : TableBatch.DefaultBatchSize}
                   columns={this.getColumns()}
                   onColumnResize={this.handleColumnResize}
                   passRef={this.handleTableRef}
            />
        );
    }
}