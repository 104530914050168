export enum FieldType {
    Autocomplete = "autocomplete",
    BusinessPartnerSelect = "businessPartnerSelect",
    Checkbox = "checkbox",
    CheckboxGroup = "checkboxGroup",
    ColorPicker = "colorPicker",
    ComboBox = "comboBox",
    Date = "date",
    DateRange = "dateRange",
    DateTime = "dateTime",
    EditableText = "editabletext",
    HierarchyComboBox = "hierarchycombobox",
    HierarchyMultiComboBox = "hierarchymulticombobox",
    Input = "input",
    LabelSelect = "labelSelect",
    MonthYear = "monthYear",
    MultiSelect = "multiselect",
    NumberInput = "numberinput",
    Password = "password",
    RadioButtonGroup = "radioButtonGroup",
    ResponsiveInput = "responsiveInput",
    // We do not want to use basic select. Use ComboBox (@see DEV-6116)
    // Select = "select",
    Slider = "slider",
    SegmentedButton = "segmentedButton",
    Switch = "switch",
    Text = "text",
    TextArea = "textarea",
    Time = "time",
    TimeRange = "timeRange",
    WriteLine = "writeline",
    Custom = "Custom",
    TokenInput = "TokenInput",
    ValueHelper = "ValueHelper"
}

export enum ToolbarItemType {
    Icon = "icon",
    SmallIcon = "smallIcon",
    IconSelect = "iconSelect",
    SegmentedButton = "segmentedbutton",
    WriteLine = "writeline",
    NumericWriteLine = "numericWriteLine",
    Button = "button",
    TransparentButton = "transparentButton",
    ConfirmationButtons = "confirmationButtons",
    Custom = "custom"
}

export enum TableViewMode {
    Saved = "Saved",
    Draft = "Draft"
}

export enum GroupedField {
    NoWrapStart = "nowrapstart",
    NoWrapEnd = "nowrapend",
    MultiStart = "multistart",
    MultiEnd = "multiend"
}

export enum ValueType {
    String = "String",
    Date = "Date",
    Number = "Number",
    Boolean = "Boolean",
}

export enum ValidatorType {
    String = "String",
    Date = "Date",
    Number = "Number",
    PositiveNumber = "PositiveNumber",
    Integer = "Integer",
    Boolean = "Boolean",

    Email = "Email",
    Phone = "Phone",

    Custom = "Custom"
}

export const P13nType = Object.freeze({
    CustomFilters: "CustomFilters",
    Widths: "widths",
    PrintDialogSettings: "PrintDialogSettings",
    SelectedFolders: "SelectedFolders",
    SelectedBankAccounts: "SelectedBankAccounts",
    HiddenBankAccounts: "HiddenBankAccounts",
    SelectedCashBoxes: "SelectedCashBoxes",
    HiddenCashBoxes: "HiddenCashBoxes",

    ParsedCsvFile: "ParsedCsvFile",
    SavedDashboards: "SavedDashboards"
});

export enum TableSizes {
    DefaultColumnWidth = 150,
    MinColumnWidth = 95,
    MaxColumnWidth = 600,
    RowHeight = 38,
    RowSpacing = 4,
    GroupMargin = 26,
    ShadowPadding = 15
}

export enum TableBatch {
    InfiniteLoaderMinBatchSize = 100,
    DefaultBatchSize = 100
}

export enum TableAddingRowType {
    New = "New",
    Copy = "Copy",
    Custom = "Custom"
}

export enum EditableTextSizes {
    S = "180px",
    M = "300px",
    L = "600px"
}

export enum BasicInputSizes {
    XS = "80px",
    S = "120px",
    M = "180px",
    L = "240px",
    XL = "300px",
    XXL = "360px"
}

export enum FastEntryInputSizes {
    XS = "80px",
    S = "120px",
    M = "180px",
    L = "370px"
}

export enum FieldVisibility {
    All = "All",
    EditOnly = "EditOnly",
    CreateOnly = "CreateOnly",
    // Field Visibility for TableView
    ExportOnly = "ExportOnly"
}

export const IconSize = Object.freeze({
    XS: "17px",
    S: "21px",
    M: "26px",
    L: "34px",
    XL: "42px",
    asNumber(size: "XS" | "S" | "M" | "L" | "XL"): number {
        return parseInt(this[size].slice(0, -2));
    }
});

export enum AvatarSize {
    S = "23px",
    SM = "30px",
    almostM = "38px",
    M = "44px",
    L = "66px",
    XL = "88px"
}

export enum RowType {
    Value = "value",
    Aggregation = "aggregation",
    /** row.content will be rendered across whole row, instead of row.values */
    Merged = "merged",
    Group = "group",
    New = "New"
}

export enum ReportTableRowType {
    Group = "Group",
    GroupValue = "GroupValue",
    MergedGroup = "MergedGroup",
    Value = "Value",
    Total = "Total",
    GrandTotal = "GrandTotal"
}

export enum Sort {
    Asc = "Asc",
    Desc = "Desc"
}

export enum Status {
    Success = "Success",
    Warning = "Warning",
    Error = "Error"
}

export enum RadioButtonGroupLayout {
    Row = "row",
    Column = "column"
}

export enum ValidationErrorType {
    // error returned from backend
    Backend = "Backend",
    // persistent error tied to the field, not removed when validateAll is called
    // has to be cleared manually
    Field = "Field",
    // form errors could be produced by custom validators, but they are displayed at the top of the form
    Form = "Form",
    // error from yup returned from wrong data type (e.g. string instead of number)
    TypeError = "typeError"
}

export enum ValidationErrorDetailType {
    NoRecord = "norecord"
}

export enum TextAlign {
    Left = "left",
    Right = "right",
    Center = "center"
}

export enum PaneStatus {
    Normal,
    Expanded,
    Collapsed
}

export enum GroupStatus {
    Unknown = "Unknown",
    Expanded = "Expanded",
    Collapsed = "Collapsed"
}

export enum PageViewMode {
    FormReadOnly = "readonly",
    Default = "default"
}

export const DnDType = Object.freeze({
    ListItem: "ListItem"
});

export const TestTable = Object.freeze({
    Height: 100,
    VisibleRows: 6
});

export enum DatePickerView {
    Days = "Days",
    Months = "Months",
    Years = "Years"
}

export enum CustomClasses {
    SummarySpace = "SummarySpace",
    FilterBarGroupIcon = "FilterBarGroupIcon"
}

export enum MouseButton {
    MainButton,
    MiddleButton,
    SecondaryButton,
    BackButton,
    ForwardButton
}

export enum GroupListDropType {
    Item = "Item",
    Group = "Group"
}

export enum ConfigListItemBoundType {
    Group = "Group",
    Column = "Column"
}

export enum ODataFilterFunction {
    Startswith = "startswith",
    Contains = "contains"
}

export enum MimeType {
    PDF = "application/pdf",
    DOC = "application/msword",
    DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    CSV = "text/csv",
    XLS = "application/vnd.ms-excel",
    XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    BMP = "image/bmp",
    GIF = "image/gif",
    ICO = "image/vnd.microsoft.icon",
    JPG = "image/jpeg",
    PNG = "image/png",
    SVG = "image/svg+xml",
    TIFF = "image/tiff",
    WEBP = "image/webp",
    XML = "application/xml",
    TEXT_XML = "text/xml",
    TEXT = "text/plain"
}

export enum LabelStatus {
    Visible = "visible",
    Hidden = "hidden",
    Removed = "removed"
}

export enum FormMode {
    Default = "Default",
    AuditTrail = "AuditTrail",
}

export enum RowAction {
    Remove = "Remove",
    Lock = "Lock",
    Check = "Check",
    MassEdit = "MassEdit",
    Custom = "Custom"
}

export enum FileAction {
    Info = "Info",
    Rename = "Rename",
    Download = "Download",
    Print = "Print",
    Delete = "Delete"
}

export enum ActionState {
    // action toggled
    Active = "Active",
    // action not toggled
    Inactive = "Inactive",
    // action disabled
    Disabled = "Disabled",
    // action completely removed
    None = "None"

}

export enum Direction {
    Horizontal = "Horizontal",
    Vertical = "Vertical"
}

export enum AvatarStyle {
    Light = "Light",
    Dark = "Dark",
    None = "None"
}

export enum CurrencyUnit {
    Units = "Units",
    Thousands = "Thousands",
    Millions = "Millions"
}

export enum SizeUnit {
    Millimeters = "mm",
    Centimeters = "cm",
    Meters = "m",
    Inches = "in",
    Pixels = "px"
}

export enum ToggleState {
    AllChecked = "AllChecked",
    AllUnchecked = "AllUnchecked",
    Other = "Other",
    Disabled = "Disabled"
}

export enum BorderSize {
    None = "None",
    Thin = "Thin",
    Thick = "Thick"
}

export enum LogicOperator {
    And = "AND",
    Or = "OR",
    // this operator is used in table when we want to create condition but it will be applied ONLY
    // if there is any other condition
    // we can use this for forcing some values (ID in ...) but not to make this condition to exclude other lines
    NonSoloOr = "NonSoloOR"
}

/**
 * Different strategies for caching:
 * None      ... data are not cached at all
 * Route     ... when location.pathname changes, cache is cleared
 * View      ... when first segment of pathname changes, cache is cleared,
 *                e.g. /menu to /invoicesReceived/20 is different, but /invoicesReceived/20 to /invoicesReceived/21 is not
 * Company   ... when currently selected company is changed
 * EndOfTime ... caches are never cleared (until page is reloaded)
 */
export enum CacheStrategy {
    None = "None",
    Route = "Route",
    View = "View",
    Company = "Company",
    EndOfTime = "EndOfTime",
}


export enum QueryParam {
    Filter = "filter",
    Variant = "variant",
    CustomDateRange = "customDateRange",
    RedirectUri = "redirectUri",
    Tab = "tab",
    ViewMode = "viewMode",
    Invitation = "invitation",
    DocumentType = "docType",
    CardResult = "cardResult",
    Drafts = "drafts",
    DraftId = "draftId",
    Action = "action",
    Type = "type",
    SecondaryBookmarkActive = "secondaryBookmarkActive",
    DefaultTab = "defaultTab",
    ReportDisplayType = "reportDisplayType",
    CompanyId = "CompanyId",
    PreventDraftSave = "preventDraftSave",
    PurchaseEvala = "purchaseEvala",
    bankApiCallbackState = "bankApiCallbackState",
    BankAccountId = "bankAccountId",
    NewLabelSaved = "newLabelSaved",
    PurchaseAfterTrial = "purchaseAfterTrial",
    Language = "lang"
}

export enum BankAccountType {
    Account = "account",
    ABA = "aba",
    IBANOnly = "ibanOnly",
    Service = "service"
}

export enum NavigationSource {
    Root = "root",
    Parent = "parent",
    Itself = "itself"
}

export enum BackendErrorCode {
    ValidationError = "ValidationError",
    NoConnection = "NoConnection",
    GatewayTimeout = "GatewayTimeout",
    PermissionError = "PermissionError",
    PermissionMissing = "PermissionMissing", // todo: check why BE has 2 different permission errors
    NoSession = "NoSession"
}

export enum ContactType {
    Billing = "Billing",
    Technical = "Technical"
}

export enum SubscriptionModule {
    Api = "API",
    Integr = "Integr",
    Storage = "Storage",
    Rossum = "Rossum"
}

export enum HTTPStatusCode {
    OK = 200,
    Created = 201,
    NoContent = 204,
    MovedPermanently = 301,
    Found = 302,
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    Conflict = 409,
    Gone = 410,
    UnprocessableEntity = 422,
    GatewayTimeout = 504
}