import React, { memo } from "react";
import { IDashboardTileComponentProps } from "../../dashboard";
import { useTranslation } from "react-i18next";
import { withOData, WithOData } from "@odata/withOData";
import DashboardTileHeader from "../../dashboard/DashboardTileHeader";
import {
    GroupedInfoTileBackground,
    GroupedInfoTileContentWrapper,
    GroupInfoTileHeader
} from "../infoTile/InfoTile.styles";
import InfoTile from "../infoTile";
import { getJournalEntriesLastMonthDef, getPostedDocumentsLastMonthDef } from "@pages/home/Home.utils";

interface IProps extends IDashboardTileComponentProps, WithOData {
}

const AgendaPerformanceOverview: React.FC<IProps> = ({ inEditMode }) => {
    const { t } = useTranslation("Home");
    const postedDocumentsLastMonth = getPostedDocumentsLastMonthDef();
    const journalEntriesLastMonthDef = getJournalEntriesLastMonthDef();
    return (
            <GroupedInfoTileBackground
                    inEditMode={inEditMode}
                    overflowed>
                <GroupInfoTileHeader>
                    <DashboardTileHeader>{t("Home:AgendaPerformanceOverview.Title")}</DashboardTileHeader>
                </GroupInfoTileHeader>

                <GroupedInfoTileContentWrapper cols={1} rows={2}>
                    <InfoTile inEditMode={inEditMode} info={postedDocumentsLastMonth} isGrouped/>
                    <InfoTile inEditMode={inEditMode} info={journalEntriesLastMonthDef} isGrouped/>
                </GroupedInfoTileContentWrapper>
            </GroupedInfoTileBackground>
    );
};

export default memo(withOData(AgendaPerformanceOverview));
