import React, { useRef } from "react";
import { logger } from "@utils/log";
import styled, { css, DefaultTheme } from "styled-components/macro";
import { setLightness } from "polished";
import TestIds from "../../testIds";
import { PropsWithTheme, themes } from "../../theme";
import { getSemanticBgColor, getSemanticElColor } from "../../global.style";
import { Status } from "../../enums";
import { handleRefHandlers } from "@utils/general";

import { ReactComponent as AccountingAdjustmentSvg } from "../../icons/accountingAdjustment.svg";
import { ReactComponent as AddChildSvg } from "../../icons/addchild.svg";
import { ReactComponent as AddParentSvg } from "../../icons/addparent.svg";
import { ReactComponent as AddFilterSvg } from "../../icons/addFilter.svg";
import { ReactComponent as AddMessageSvg } from "../../icons/addMessage.svg";
import { ReactComponent as AddMinorAssetSvg } from "../../icons/addMinorAsset.svg";
import { ReactComponent as AddSvg } from "../../icons/add.svg";
import { ReactComponent as AddToFilledSvg } from "../../icons/addToFilled.svg";
import { ReactComponent as AddToMinorAssetSvg } from "../../icons/addToMinorAsset.svg";
import { ReactComponent as AddToSvg } from "../../icons/addTo.svg";
import { ReactComponent as AgendaSvg } from "../../icons/agenda.svg";
import { ReactComponent as AppsSvg } from "../../icons/apps.svg";
import { ReactComponent as ArchiveSvg } from "../../icons/archive.svg";
import { ReactComponent as ArchiveFilledSvg } from "../../icons/archiveFilled.svg";
import { ReactComponent as ArrowSvg } from "../../icons/arrow.svg";
import { ReactComponent as AssetDisposalSvg } from "../../icons/assetDisposal.svg";
import { ReactComponent as AttachmentSvg } from "../../icons/attachment.svg";
import { ReactComponent as AttachmentIndicatorSvg } from "../../icons/statusIndicators/attachmentIndicator.svg";
import { ReactComponent as AuditTrailSvg } from "../../icons/audittrail.svg";
import { ReactComponent as AutomaticPairingSettingsSvg } from "../../icons/automaticpairingsettings.svg";
import { ReactComponent as AutomaticPairingSvg } from "../../icons/automaticpairing.svg";
import { ReactComponent as BankApiSvg } from "../../icons/bankApi.svg";
import { ReactComponent as BinSvg } from "../../icons/bin.svg";
import { ReactComponent as BriefcaseSvg } from "../../icons/briefcase.svg";
import { ReactComponent as BulkEditSvg } from "../../icons/bulkedit.svg";
import { ReactComponent as CaretSvg } from "../../icons/caret.svg";
import { ReactComponent as CashBoxSvg } from "../../icons/cashbox.svg";
import { ReactComponent as ChainedSvg } from "../../icons/chained.svg";
import { ReactComponent as ChangePriceSvg } from "../../icons/changePrice.svg";
import { ReactComponent as CheckSvg } from "../../icons/check.svg";
import { ReactComponent as CheckDoubleSvg } from "../../icons/checkDouble.svg";
import { ReactComponent as CheckboxGroupSvg } from "../../icons/checkboxGroup.svg";
import { ReactComponent as CheckboxSvg } from "../../icons/checkbox.svg";
import { ReactComponent as RadioButtonGroupSvg } from "../../icons/radioButtonGroup.svg";
import { ReactComponent as CleanFilterSvg } from "../../icons/cleanFilter.svg";
import { ReactComponent as CloseSvg } from "../../icons/close.svg";
import { ReactComponent as CollapseSvg } from "../../icons/collapse.svg";
import { ReactComponent as CollapseAllSvg } from "../../icons/collapseAll.svg";
import { ReactComponent as ContainsTwoFormsSvg } from "../../icons/containsTwoForms.svg";
import { ReactComponent as CopyLinkSvg } from "../../icons/copyLink.svg";
import { ReactComponent as CopySvg } from "../../icons/copy.svg";
import { ReactComponent as CopySimpleSvg } from "../../icons/copySimple.svg";
import { ReactComponent as CorrectionSvg } from "../../icons/correction.svg";
import { ReactComponent as CostsSvg } from "../../icons/costs.svg";
import { ReactComponent as CursorSvg } from "../../icons/cursor.svg";
import { ReactComponent as CustomerPortalSvg } from "../../icons/customerPortal.svg";
import { ReactComponent as DateSvg } from "../../icons/date.svg";
import { ReactComponent as DateTimeSvg } from "../../icons/datetime.svg";
import { ReactComponent as DepreciationSvg } from "../../icons/depreciation.svg";
import { ReactComponent as DiscardSvg } from "../../icons/discard.svg";
import { ReactComponent as DocListSvg } from "../../icons/docList.svg";
import { ReactComponent as DocMenuSvg } from "../../icons/docmenu.svg";
import { ReactComponent as DocTemplateSvg } from "../../icons/documents/template.svg";
import { ReactComponent as DocumentDraftSvg } from "../../icons/documentDraft.svg";
import { ReactComponent as DocumentFilledSvg } from "../../icons/documentFilled.svg";
import { ReactComponent as DocumentSavedSvg } from "../../icons/documentSaved.svg";
import { ReactComponent as DocumentSvg } from "../../icons/document.svg";
import { ReactComponent as DoubleCaretSvg } from "../../icons/forward.svg";
import { ReactComponent as DownSvg } from "../../icons/down.svg";
import { ReactComponent as DownloadSvg } from "../../icons/download.svg";
import { ReactComponent as EditSvg } from "../../icons/edit.svg";
import { ReactComponent as EditSimpleSvg } from "../../icons/editSimple.svg";
import { ReactComponent as EmailSvg } from "../../icons/email.svg";
import { ReactComponent as EmojiSvg } from "../../icons/emoji.svg";
import { ReactComponent as EnterSvg } from "../../icons/enter.svg";
import { ReactComponent as EvalaSvg } from "../../icons/logos/evala.keepStyle.svg";
import { ReactComponent as EvalaPlainSvg } from "../../icons/logos/evala.plain.svg";
import { ReactComponent as ExcelSvg } from "../../icons/documents/excel.svg";
import { ReactComponent as ExitSimpleSvg } from "../../icons/exitSimple.svg";
import { ReactComponent as ExpandAllSvg } from "../../icons/expandAll.svg";
import { ReactComponent as ExpandSvg } from "../../icons/expand.svg";
import { ReactComponent as ExportCsvSvg } from "../../icons/exportCsv.svg";
import { ReactComponent as ExportExcelSvg } from "../../icons/exportExcel.svg";
import { ReactComponent as ExportPdfSvg } from "../../icons/exportPdf.svg";
import { ReactComponent as ExportReportFullSvg } from "../../icons/exportReportFull.svg";
import { ReactComponent as ExportReportShortSvg } from "../../icons/exportReportShort.svg";
import { ReactComponent as ExportSvg } from "../../icons/export.svg";
import { ReactComponent as FacebookSvg } from "../../icons/logos/facebook.svg";
import { ReactComponent as FieldValidationNegativeSvg } from "../../icons/fieldValidation/fieldValidationNegative.svg";
import { ReactComponent as FieldValidationPositiveSvg } from "../../icons/fieldValidation/fieldValidationPositive.svg";
import { ReactComponent as FieldValidationWarningSvg } from "../../icons/fieldValidation/fieldValidationWarning.svg";
import { ReactComponent as FileSimpleSvg } from "../../icons/fileSimple.svg";
import { ReactComponent as FillFormSvg } from "../../icons/fillForm.svg";
import { ReactComponent as FilterSvg } from "../../icons/filter.svg";
import { ReactComponent as FitToPageSvg } from "../../icons/fitToPage.svg";
import { ReactComponent as FitToWidthSvg } from "../../icons/fitToWidth.svg";
import { ReactComponent as FloppyDiskSvg } from "../../icons/floppyDisk.svg";
import { ReactComponent as FolderSvg } from "../../icons/folder.svg";
import { ReactComponent as FolderAddSvg } from "../../icons/folderAdd.svg";
import { ReactComponent as FolderOtherSvg } from "../../icons/folderOther.svg";
import { ReactComponent as FormSvg } from "../../icons/form.svg";
import { ReactComponent as GoogleSvg } from "../../icons/logos/google.svg";
import { ReactComponent as GrowthNegativeSvg } from "../../icons/microChart/growthNegative.svg";
import { ReactComponent as GrowthPositiveSvg } from "../../icons/microChart/growthPositive.svg";
import { ReactComponent as GrowthZeroSvg } from "../../icons/microChart/growthZero.svg";
import { ReactComponent as HandSvg } from "../../icons/hand.svg";
import { ReactComponent as HomeSvg } from "../../icons/home.svg";
import { ReactComponent as HotSpotsSvg } from "../../icons/hotspots.svg";
import { ReactComponent as IDokladSvg } from "../../icons/logos/idoklad.keepStyle.svg";
import { ReactComponent as ImageSvg } from "../../icons/image.svg";
import { ReactComponent as IncomingSvg } from "../../icons/incoming.svg";
import { ReactComponent as IncreaseOfPriceSvg } from "../../icons/increaseOfPrice.svg";
import { ReactComponent as InfoSvg } from "../../icons/info.svg";
import { ReactComponent as InitialBalancesSvg } from "../../icons/initialBalances.keepStyle.svg";
import { ReactComponent as InvoicesIssuedBigSvg } from "../../icons/invoiceIssuedBig.svg";
import { ReactComponent as InvoicesReceivedBigSvg } from "../../icons/invoiceReceivedBig.svg";
import { ReactComponent as IsdocSvg } from "../../icons/documents/isdoc.svg";
import { ReactComponent as LabelSvg } from "../../icons/label.svg";
import { ReactComponent as LastUpdatedSvg } from "../../icons/lastUpdated.svg";
import { ReactComponent as LastUpdatedSimpleSvg } from "../../icons/lastUpdatedSimple.svg";
import { ReactComponent as LikeSvg } from "../../icons/like.svg";
import { ReactComponent as LikeFilledSvg } from "../../icons/likeFilled.svg";
import { ReactComponent as DislikeSvg } from "../../icons/dislike.svg";
import { ReactComponent as DislikeFilledSvg } from "../../icons/dislikeFilled.svg";
import { ReactComponent as PaidDocumentSvg } from "../../icons/paidDocument.svg";
import { ReactComponent as UnpaidDocumentSvg } from "../../icons/unpaidDocument.svg";
import { ReactComponent as ListSvg } from "../../icons/list.svg";
import { ReactComponent as LockClosedSvg } from "../../icons/lockclosed.svg";
import { ReactComponent as LockFilledSvg } from "../../icons/lockFilled.svg";
import { ReactComponent as LockOpenedSvg } from "../../icons/lockopened.svg";
import { ReactComponent as LockSvg } from "../../icons/lock.svg";
import { ReactComponent as LogoutSvg } from "../../icons/logout.svg";
import { ReactComponent as MenuSvg } from "../../icons/menu.svg";
import { ReactComponent as MoreOptionsSvg } from "../../icons/moreoptions.svg";
import { ReactComponent as MoreSvg } from "../../icons/more.svg";
import { ReactComponent as NoteFilledSvg } from "../../icons/noteFilled.svg";
import { ReactComponent as NoteSvg } from "../../icons/note.svg";
import { ReactComponent as NotificationSvg } from "../../icons/notification.svg";
import { ReactComponent as OldImportClockSvg } from "../../icons/oldImportClock.svg";
import { ReactComponent as OtherSvg } from "../../icons/documents/other.svg";
import { ReactComponent as OutgoingSvg } from "../../icons/outgoing.svg";
import { ReactComponent as PairSvg } from "../../icons/pair.svg";
import { ReactComponent as PairingAccruedItemsSvg } from "../../icons/pairingAccruedItems.svg";
import { ReactComponent as PairingDepositSvg } from "../../icons/pairingDeposit.svg";
import { ReactComponent as PairingExpensesSvg } from "../../icons/pairingExpenses.svg";
import { ReactComponent as PairingGeneralSvg } from "../../icons/pairingGeneral.svg";
import { ReactComponent as ParametersSvg } from "../../icons/parameters.svg";
import { ReactComponent as PaymentRequestSvg } from "../../icons/paymentRequest.svg";
import { ReactComponent as PdfSvg } from "../../icons/documents/pdf.svg";
import { ReactComponent as PictureSvg } from "../../icons/documents/picture.svg";
import { ReactComponent as PlaySvg } from "../../icons/play.svg";
import { ReactComponent as PlusSvg } from "../../icons/plus.svg";
import { ReactComponent as PrintSvg } from "../../icons/print.svg";
import { ReactComponent as QuerySvg } from "../../icons/query.svg";
import { ReactComponent as ReductionOfPriceSvg } from "../../icons/reductionOfPrice.svg";
import { ReactComponent as RefreshSvg } from "../../icons/refresh.svg";
import { ReactComponent as RefreshSimpleSvg } from "../../icons/refreshSimple.svg";
import { ReactComponent as ReorderRowsSmallSvg } from "../../icons/reorderrowssmall.svg";
import { ReactComponent as ReorderRowsSvg } from "../../icons/reorderrows.svg";
import { ReactComponent as ResizeSvg } from "../../icons/resize.svg";
import { ReactComponent as RubberSvg } from "../../icons/rubber.svg";
import { ReactComponent as SafeSvg } from "../../icons/safe.svg";
import { ReactComponent as SearchSvg } from "../../icons/search.svg";
import { ReactComponent as SearchSmallSvg } from "../../icons/searchSmall.svg";
import { ReactComponent as SendDunningLetterSvg } from "../../icons/sendDunningLetter.svg";
import { ReactComponent as SendInvoiceSvg } from "../../icons/sendInvoice.svg";
import { ReactComponent as SendSvg } from "../../icons/send.svg";
import { ReactComponent as SendMessageSvg } from "../../icons/sendMessage.svg";
import { ReactComponent as SettingsSvg } from "../../icons/setting.svg";
import { ReactComponent as SettingsSimpleSvg } from "../../icons/settingsSimple.svg";
import { ReactComponent as SortDownSvg } from "../../icons/sortDown.svg";
import { ReactComponent as SortSvg } from "../../icons/sort.svg";
import { ReactComponent as SortingSvg } from "../../icons/sorting.svg";
import { ReactComponent as GraphSvg } from "../../icons/graph.svg";
import { ReactComponent as DemoSvg } from "../../icons/demo.svg";
import { ReactComponent as PinSvg } from "../../icons/pin.svg";
import { ReactComponent as CalculatorSvg } from "../../icons/calculator.svg";
import { ReactComponent as PostedStatusNotPostedSvg } from "../../icons/statusIndicators/ps_notPosted.svg";
import { ReactComponent as PostedStatusPostedSvg } from "../../icons/statusIndicators/ps_posted.svg";
import { ReactComponent as PostedStatusNotApplicableSvg } from "../../icons/statusIndicators/ps_notApplicable.svg";
import { ReactComponent as ClearedStatusClearedSvg } from "../../icons/statusIndicators/cs_cleared.svg";
import {
    ReactComponent as ClearedStatusPartiallyClearedSvg
} from "../../icons/statusIndicators/cs_partiallyCleared.svg";
import { ReactComponent as ClearedStatusNotClearedSvg } from "../../icons/statusIndicators/cs_notCleared.svg";
import {
    ReactComponent as VatStatementStatusNotApplicableSvg
} from "../../icons/statusIndicators/vat_notApplicable.svg";
import { ReactComponent as VatStatementStatusNotPostedSvg } from "../../icons/statusIndicators/vat_notPosted.svg";
import { ReactComponent as VatStatementStatusPostedSvg } from "../../icons/statusIndicators/vat_posted.svg";
import { ReactComponent as StatusDoubleFormSvg } from "../../icons/statusIndicators/doubleForm.svg";
import { ReactComponent as StatusLockedSvg } from "../../icons/statusIndicators/locked.svg";
import { ReactComponent as StatusNoSvg } from "../../icons/statusIndicators/no.svg";
import { ReactComponent as StatusPartlyLockedSvg } from "../../icons/statusIndicators/partlyLocked.svg";
import { ReactComponent as StatusUnlockedSvg } from "../../icons/statusIndicators/unlocked.svg";
import { ReactComponent as StatusYesSvg } from "../../icons/statusIndicators/yes.svg";
import { ReactComponent as StatusWarnSvg } from "../../icons/statusIndicators/warn.svg";
import { ReactComponent as StatusTicketDoneSvg } from "../../icons/statusIndicators/ticket_done.svg";
import { ReactComponent as StatusTicketInProgressSvg } from "../../icons/statusIndicators/ticket_inProgress.svg";
import { ReactComponent as StatusIsdocSvg } from "../../icons/statusIndicators/isdoc.svg";
import { ReactComponent as StatusRossumSvg } from "../../icons/statusIndicators/rossum.svg";
import { ReactComponent as StatusNoDataReadSvg } from "../../icons/statusIndicators/noDataRead.svg";
import { ReactComponent as SupportSvg } from "../../icons/support.svg";
import { ReactComponent as SwitchSvg } from "../../icons/switch.svg";
import { ReactComponent as SynchronizationSvg } from "../../icons/synchronization.svg";
import { ReactComponent as TableCaretSvg } from "../../icons/tablecaret.svg";
import { ReactComponent as TaxInvoiceSvg } from "../../icons/taxInvoice.svg";
import { ReactComponent as TechnicalImprovementSvg } from "../../icons/technicalImprovement.svg";
import { ReactComponent as TemplateSvg } from "../../icons/template.svg";
import { ReactComponent as TimeSvg } from "../../icons/time.svg";
import { ReactComponent as TimeDependentSvg } from "../../icons/timeDependent.svg";
import { ReactComponent as TimeDependentFilledSvg } from "../../icons/timeDependentFilled.svg";
import { ReactComponent as TxtSvg } from "../../icons/documents/text.svg";
import { ReactComponent as UnavailableSvg } from "../../icons/unavailable.svg";
import { ReactComponent as UnchainedSvg } from "../../icons/unchained.svg";
import { ReactComponent as UnrelatedItemsSvg } from "../../icons/unrelatedItems.svg";
import { ReactComponent as UpSvg } from "../../icons/up.svg";
import { ReactComponent as UploadSvg } from "../../icons/upload.svg";
import { ReactComponent as UploadSimpleSvg } from "../../icons/uploadSimple.svg";
import { ReactComponent as UserSvg } from "../../icons/user.svg";
import { ReactComponent as UseIsDocSvg } from "../../icons/use_isdoc.svg";
import { ReactComponent as UseRossumSvg } from "../../icons/use_rossum.svg";
import { ReactComponent as ValueHelpFilledSvg } from "../../icons/valuehelpfilled.svg";
import { ReactComponent as ValueHelpSvg } from "../../icons/valuehelp.svg";
import { ReactComponent as VisibleFilledSvg } from "../../icons/visibleFilled.svg";
import { ReactComponent as VisibleSvg } from "../../icons/visible.svg";
import { ReactComponent as WebInvoiceSvg } from "../../icons/webInvoice.svg";
import { ReactComponent as WordSvg } from "../../icons/documents/word.svg";
import { ReactComponent as ZoomInSvg } from "../../icons/zoomin.svg";
import { ReactComponent as ZoomOutSvg } from "../../icons/zoomout.svg";
import { ReactComponent as ZoomSmallSvg } from "../../icons/zoomsmall.svg";
import { ReactComponent as InvoicesReceivedPlainSvg } from "../../icons/documentTypes/invoicesReceived.svg";
import { ReactComponent as InvoicesIssuedPlainSvg } from "../../icons/documentTypes/invoicesIssued.svg";
import { ReactComponent as OtherLiabilitiesPlainSvg } from "../../icons/documentTypes/otherLiabilities.svg";
import { ReactComponent as OtherReceivablesPlainSvg } from "../../icons/documentTypes/otherReceivables.svg";
import {
    ReactComponent as CorrectiveInvoicesReceivedPlainSvg
} from "../../icons/documentTypes/correctiveInvoicesReceived.svg";
import {
    ReactComponent as CorrectiveInvoicesIssuedPlainSvg
} from "../../icons/documentTypes/correctiveInvoicesIssuedPlain.svg";

import { ReactComponent as ProformaInvoicesIssuedPlainSvg } from "../../icons/documentTypes/proformaInvoicesIssued.svg";
import {
    ReactComponent as ProformaInvoicesIssuedTaxPlainSvg
} from "../../icons/documentTypes/proformaInvoicesIssuedTax.svg";
import {
    ReactComponent as ProformaInvoicesReceivedPlainSvg
} from "../../icons/documentTypes/proformaInvoicesReceived.svg";
import {
    ReactComponent as ProformaInvoicesReceivedTaxPlainSvg
} from "../../icons/documentTypes/proformaInvoicesReceivedTax.svg";
import { ReactComponent as TaxDocumentSvg } from "../../icons/documentTypes/taxDocument.svg";
import { ReactComponent as InternalDocumentCancelSvg } from "../../icons/documentTypes/internalDocumentCancel.svg";
import { ReactComponent as InternalDocumentPlainSvg } from "../../icons/documentTypes/internalDocuments.svg";
import { ReactComponent as FolderFPSvg } from "../../icons/folderLabels/FP.svg";
import { ReactComponent as FolderFVSvg } from "../../icons/folderLabels/FV.svg";
import { ReactComponent as FolderDotsSvg } from "../../icons/folderLabels/dots.svg";
import { ReactComponent as FolderPDSvg } from "../../icons/folderLabels/PD.svg";
import { ReactComponent as FolderPPDSvg } from "../../icons/folderLabels/PPD.svg";
import { ReactComponent as MassAccountingSvg } from "../../icons/massaccounting.svg";
import { ReactComponent as FolderVBUSvg } from "../../icons/folderLabels/VBU.svg";
import { ReactComponent as FolderVPDSvg } from "../../icons/folderLabels/VPD.svg";
import { ReactComponent as FolderZDSvg } from "../../icons/folderLabels/ZD.svg";
import { ReactComponent as FolderZFPSvg } from "../../icons/folderLabels/ZFP.svg";
import { ReactComponent as FolderZFVSvg } from "../../icons/folderLabels/ZFV.svg";
import { ReactComponent as FolderPDDOPPSvg } from "../../icons/folderLabels/PDDOPP.svg";
import { ReactComponent as FolderVDDOPPSvg } from "../../icons/folderLabels/VDDOPP.svg";
import { ReactComponent as InboxFolderSvg } from "../../icons/folderLabels/_folder.svg";
import { ReactComponent as WorkDateSvg } from "../../icons/workDate.svg";
import { ReactComponent as OneDocPairSvg } from "../../icons/onedocpair.svg";
import { ReactComponent as OutgoingPaymentSvg } from "../../icons/outgoingpayment.svg";
import { ReactComponent as IncomingPaymentSvg } from "../../icons/incomingpayment.svg";
import { ReactComponent as HelpSvg } from "../../icons/help.svg";


// object page icons
import { ReactComponent as RossumSvg } from "../../icons/objectPage/rossum.svg";
import { ReactComponent as IntegrationSvg } from "../../icons/objectPage/integration.svg";
import { ReactComponent as CashBoxListSvg } from "../../icons/objectPage/cashbox.svg";
import { ReactComponent as DataBoxSvg } from "../../icons/objectPage/dataBox.svg";
import { ReactComponent as ApiSvg } from "../../icons/objectPage/api.svg";
import { ReactComponent as VatStatementSvg } from "../../icons/objectPage/VatStatement.svg";
import { ReactComponent as VatControlStatementSvg } from "../../icons/objectPage/VatControlStatement.svg";
import { ReactComponent as StorageSvg } from "../../icons/objectPage/storage.svg";
import { ReactComponent as SubscriptionSvg } from "../../icons/objectPage/subscription.svg";
import { ReactComponent as VatViesStatementSvg } from "../../icons/objectPage/VatViesStatement.svg";
import { ReactComponent as PohodaSvg } from "../../icons/objectPage/pohoda.svg";
import { ReactComponent as MoneyS3Svg } from "../../icons/objectPage/MoneyS3.svg";
import { ReactComponent as IsdocImportSvg } from "../../icons/objectPage/isdocImport.svg";
import { ReactComponent as ExportDocumentsSvg } from "../../icons/objectPage/exportDocuments.svg";
import { ReactComponent as ExportBankTransactionsSvg } from "../../icons/objectPage/exportBankTransactions.svg";
import { ReactComponent as ExportCashReceiptsSvg } from "../../icons/objectPage/exportCashReceipts.svg";

// login icons
import { ReactComponent as DocSimpleSvg } from "../../icons/docsimple.svg";
import { ReactComponent as ConfirmSvg } from "../../icons/confirm.svg";
import { ReactComponent as TabletSvg } from "../../icons/login/tablet.keepStyle.svg";
import { ReactComponent as PcSvg } from "../../icons/login/pc.keepStyle.svg";
import { ReactComponent as MobileSvg } from "../../icons/login/mobile.keepStyle.svg";

// form customization icons
import { ReactComponent as DependentFieldsSvg } from "../../icons/formCustomization/dependentFields.keepStyle.svg";
import { ReactComponent as LockedGroupSvg } from "../../icons/formCustomization/locked.keepStyle.svg";

// alert icons
import { ReactComponent as ErrorSvg } from "../../icons/alert/error.svg";
import { ReactComponent as WarningSvg } from "../../icons/alert/warning.svg";
import { ReactComponent as SuccessSvg } from "../../icons/alert/success.svg";

// dashboard icons
import { ReactComponent as WorkDateFilledSvg } from "../../icons/workDateFilled.svg";
import { ReactComponent as AccountingJournalSvg } from "../../icons/dashboard/accountingJournal.svg";
import { ReactComponent as CustomerSupportSvg } from "../../icons/dashboard/customerSupport.svg";
import { ReactComponent as BalanceSheetSvg } from "../../icons/dashboard/balanceSheet.svg";
import { ReactComponent as CustomerPortalInvoicingSvg } from "../../icons/dashboard/customerPortalInvoicing.svg";
import { ReactComponent as ForPaymentSvg } from "../../icons/dashboard/forPayment.svg";
import { ReactComponent as CustomerPortalPaymentSvg } from "../../icons/dashboard/customerPortalPayment.svg";
import { ReactComponent as DocumentJournalSvg } from "../../icons/dashboard/documentJournal.svg";
import { ReactComponent as PaymentJournalSvg } from "../../icons/dashboard/paymentJournal.svg";
import { ReactComponent as DemoTenantSvg } from "../../icons/dashboard/demoTenant.svg";

import { ReactComponent as GeneralLedgerSvg } from "../../icons/dashboard/generalLedger.svg";
import { ReactComponent as InboxSvg } from "../../icons/dashboard/inbox.svg";
import { ReactComponent as IncomeStatementSvg } from "../../icons/dashboard/incomeStatement.svg";
import { ReactComponent as SendToAccountantSvg } from "../../icons/dashboard/sendToAccountant.svg";
import { ReactComponent as TicketsSvg } from "../../icons/dashboard/tickets.svg";
import { ReactComponent as CbaEntryLedgerSvg } from "../../icons/dashboard/cbaEntryLedger.svg";


export interface IProps {
    width?: string;
    height?: string;
    className?: string;
    style?: React.CSSProperties;
    /** Changes default look from dark to light colors */
    isLight?: boolean;
    isLightHover?: boolean;
    /** Force trigger hover state color */
    forceHover?: boolean;
    /** Always use colors from default theme */
    ignoreTheme?: boolean;
    /** By default, Icon inherits cursor from parent. Custom cursor can be passed via this prop. */
    cursor?: string;
    /** Disable icon's default color change on hover */
    preventHover?: boolean;
    title?: string;
    /** Override config colors with custom color. Can be either name of color from theme, or css color definition. */
    color?: keyof DefaultTheme;
    status?: Status;
    passRef?: React.Ref<SVGSVGElement>;
}

export const IconNames: string[] = [];

const getHoverColor = (props: IProps & PropsWithTheme, darkColor: keyof DefaultTheme, lightColor: keyof DefaultTheme) => {
    const theme = getTheme(props);
    let color;

    if (!props.isLight) {
        if (props.isLightHover) {
            color = setLightness(0.7, theme[darkColor]);
        } else {
            color = theme[darkColor];
        }
    } else {
        color = theme[lightColor];
    }

    return color;
};

const getTheme = (props: IProps & PropsWithTheme) => {
    return props.ignoreTheme && (props.theme === themes.light || props.theme === themes.dark) ? themes.light : props.theme;
};

const getColor = (props: IProps & PropsWithTheme, defaultColorName: string) => {
    const theme = getTheme(props);

    if (props.color) {
        return theme[props.color] ?? props.color;
    }

    // @ts-ignore
    return theme[`C_ICON_${defaultColorName}_${!props.isLight ? "dark" : "light"}`];
};

export const hoverColors = (props: IProps & PropsWithTheme) => {
    return css`
        .IC_4L_main, .IC_4L_filled {
            stroke: ${getHoverColor(props, "C_ICON_primary_dark", "C_ICON_primary_light_hover")};
        }

        .IC_4L_filled, .IC_4L_filled_no_stroke, .IC_4L_dark_dot {
            fill: ${getHoverColor(props, "C_ICON_primary_dark", "C_ICON_primary_light_hover")};
        }

        .IC_4L_sub {
            stroke: ${getHoverColor(props, "C_ICON_secondary_dark", "C_ICON_secondary_light")};
        }

        .IC_4L_dot {
            fill: ${getHoverColor(props, "C_ICON_tertiary_dark", "C_ICON_tertiary_light")};
        }

        .DI_4L_main {
            stroke: ${getHoverColor(props, "C_ICON_document", "C_ICON_document")};
        }
    `;
};

/**
 *
 * @param SvgIcon
 * @param iconName has to be the same as the variable name of the Icon for the list of icons in storybook to work properly
 *
 * !!Only use toIcon on svg files with basic icons inside "src/icons" folder that use the IC_ styles!!
 * "import { ReactComponent as PictureSvg }" makes the svg part of the bundle making it larger
 * => it doesn't make sense to use it on every svg in the system. Check svg.stories.tsx if you need more help.
 *
 */
const toIcon = (SvgIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & {
    title?: string;
}>, iconName: string) => {
    IconNames.push(iconName);

    const Icon = ({ width = "100%", height = "100%", className, style, title, passRef }: IProps) => {
        const iconRef = useRef<SVGElement>();

        const handleRef = (ref: SVGElement) => {
            handleRefHandlers(ref, iconRef, passRef);
        };

        return (
                <SvgIcon style={style} className={className}
                         data-testid={TestIds.Icon}
                         data-icon={iconName}
                         title={title} ref={handleRef}
                         width={width} height={height}/>
        );
    };

    // suppress weird error that started showing after ts version update
    // @ts-ignore
    const StyledIcon = styled(Icon)`
        display: block; // svg is inline item by default, causing to be higher than content because of line-height
        flex: 0 0 auto;

        // if icon used in button, automatically apply hover colors on button hover
        // in the same way, automatically disable hover colors for icon itself, if the button is disabled 

        button:hover:disabled & .IC_4L_main, .IC_4L_main {
            stroke: ${props => getColor(props, "primary")};
        }

        button:hover:disabled & .IC_4L_sub, .IC_4L_sub {
            stroke: ${props => getColor(props, "secondary")};
        }

        button:hover:disabled & .IC_4L_dark_dot, .IC_4L_dark_dot {
            fill: ${props => getColor(props, "primary")};
        }

        button:hover:disabled & .IC_4L_dot, .IC_4L_dot {
            fill: ${props => getColor(props, "tertiary")};
        }

        button:hover:disabled & .IC_4L_filled, .IC_4L_filled,
        button:hover:disabled & .IC_4L_filled_no_stroke, .IC_4L_filled_no_stroke {
            fill: ${props => getColor(props, "primary")};
            stroke: ${props => getColor(props, "primary")};
        }

        ${props => !props.preventHover && css`
            &:hover {
                cursor: ${props.cursor ? props.cursor : "inherit"};
            }

            button:hover:not(:disabled):not(:active) &, &:hover:not(:active) {
                ${hoverColors(props)};
            }

            *:hover:not(:active) > & .DI_4L_main {
                stroke: ${getHoverColor(props, "C_ICON_document", "C_ICON_document")};
            }

            // active color for lightHover icons
            ${props.isLightHover && !props.isLight && css`
                button:active:not(:disabled) &, &:active, *:active > & {
                    .IC_4L_main, .IC_4L_filled, .DI_4L_main {
                        stroke: ${props => props.theme.C_ACT_der}
                    }
                }
            `}

        `};

        .IC_4L_main {
            fill: none;
            stroke-width: 3;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 10;
        }

        .lighter {
            stroke-width: 2.5;
        }

        .IC_4L_sub {
            fill: none;
            stroke-width: 3;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 10;
        }

        .IC_4L_filled {
            stroke-width: 3;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 10;
        }

        .IC_4L_filled_no_stroke {
            stroke-width: 0;
        }

        .IC_4L_evalalogo {
            fill: ${props => props.theme.C_EVALA_LOGO};
            stroke: none;
        }

        .IC_4D_main {
            stroke: ${props => props.theme.C_ICON_primary_light};
            fill: none;
            stroke-width: 3;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 10;
        }

        // semantic colors

        .SI_4L_stroke {
            stroke: ${props => props.theme.C_ICON_STATUS_INDICATOR_stroke};
            fill: none;
            stroke-width: 2;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 10;
        }

        .SI_4L_fill_green {
            fill: ${props => props.theme.C_SEM_el_good};
        }

        .SI_4L_fill_green_compl {
            fill: ${props => getSemanticBgColor(props.theme, Status.Success, true)};
        }

        .SI_4L_fill_gray, .SI_4L_fill_grey {
            fill: #a6a5a6;
        }

        .SI_4L_fill_orange {
            fill: ${props => props.theme.C_SEM_el_warning};
        }

        .SI_4L_fill_orange_compl {
            fill: ${props => getSemanticBgColor(props.theme, Status.Warning, true)};
        }

        .SI_4L_fill_red {
            fill: ${props => props.theme.C_SEM_el_bad};
        }

        .SI_4L_fill_red_compl {
            fill: ${props => getSemanticBgColor(props.theme, Status.Error, true)};
        }

        .SI_4L_stroke_green {
            stroke: ${props => props.theme.C_SEM_el_good};
            fill: none;
        }

        .SI_4L_stroke_orange {
            stroke: ${props => props.theme.C_SEM_el_warning};
            fill: none;
        }

        .SI_4L_stroke_red {
            stroke: ${props => props.theme.C_SEM_el_bad};
            fill: none;
        }

        // semantic colors

        .GI_stroke {
            stroke: ${props => getSemanticElColor(props.theme, props.status)};
            fill: none;
            stroke-width: 4;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 10;
        }

        // document icons color

        .DI_4L_main {
            stroke: ${props => props.theme.C_ICON_document};
            fill: none;
            stroke-width: 2.5;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 10;
        }

        // object page icon colors

        .OI_4L_main {
            stroke: ${props => props.theme.C_ICON_OBJECT_PAGE_main};
            fill: none;
            stroke-width: 2.5;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 10;
        }

        .B_4L_main {
            fill: ${props => props.theme.C_ICON_OBJECT_PAGE_main};
        }

        ${props => props.forceHover && hoverColors(props)};
    `;

    return StyledIcon;
};

export const AccountingAdjustmentIcon = toIcon(AccountingAdjustmentSvg, "AccountingAdjustment");
export const AddChildIcon = toIcon(AddChildSvg, "AddChild");
export const AddIcon = toIcon(AddSvg, "Add");
export const AddMessageIcon = toIcon(AddMessageSvg, "AddMessage");
export const AddMinorAssetIcon = toIcon(AddMinorAssetSvg, "AddMinorAsset");
export const AddToMinorAssetIcon = toIcon(AddToMinorAssetSvg, "AddToMinorAsset");
export const AddParentIcon = toIcon(AddParentSvg, "AddParent");
export const AddFilterIcon = toIcon(AddFilterSvg, "AddFilter");
export const AddToFilledIcon = toIcon(AddToFilledSvg, "AddToFilled");
export const AddToIcon = toIcon(AddToSvg, "AddTo");
export const AppsIcon = toIcon(AppsSvg, "Apps");
export const ApiIcon = toIcon(ApiSvg, "Api");
export const ArchiveIcon = toIcon(ArchiveSvg, "Archive");
export const ArchiveFilledIcon = toIcon(ArchiveFilledSvg, "ArchiveFilled");
export const ArrowIcon = toIcon(ArrowSvg, "Arrow");
export const AssetDisposalIcon = toIcon(AssetDisposalSvg, "AssetDisposal");
export const AttachmentIcon = toIcon(AttachmentSvg, "Attachment");
export const AttachmentIndicatorIcon = toIcon(AttachmentIndicatorSvg, "AttachmentIndicator");
export const AutomaticPairingIcon = toIcon(AutomaticPairingSvg, "AutomaticPairing");
export const AutomaticPairingSettingsIcon = toIcon(AutomaticPairingSettingsSvg, "AutomaticPairingSettings");
export const BankApiIcon = toIcon(BankApiSvg, "BankApi");
export const BinIcon = toIcon(BinSvg, "Bin");
export const BriefcaseIcon = toIcon(BriefcaseSvg, "Briefcase");
export const BulkEditIcon = toIcon(BulkEditSvg, "BulkEdit");
export const CaretIcon = toIcon(CaretSvg, "Caret");
export const ChainedIcon = toIcon(ChainedSvg, "Chained");
export const ChangePriceIcon = toIcon(ChangePriceSvg, "ChangePrice");
export const CheckIcon = toIcon(CheckSvg, "Check");
export const CheckDoubleIcon = toIcon(CheckDoubleSvg, "CheckDouble");
export const RadioButtonGroupIcon = toIcon(RadioButtonGroupSvg, "RadioButtonGroup");
export const CheckboxGroupIcon = toIcon(CheckboxGroupSvg, "CheckboxGroup");
export const CheckboxIcon = toIcon(CheckboxSvg, "Checkbox");
export const CleanFilterIcon = toIcon(CleanFilterSvg, "CleanFilter");
export const CloseIcon = toIcon(CloseSvg, "Close");
export const CollapseIcon = toIcon(CollapseSvg, "Collapse");
export const CollapseAllIcon = toIcon(CollapseAllSvg, "CollapseAll");
export const CopyIcon = toIcon(CopySvg, "Copy");
export const CopySimpleIcon = toIcon(CopySimpleSvg, "CopySimple");
export const CorrectionIcon = toIcon(CorrectionSvg, "Correction");
export const CopyLinkIcon = toIcon(CopyLinkSvg, "CopyLink");
export const CostsIcon = toIcon(CostsSvg, "Costs");
export const CursorIcon = toIcon(CursorSvg, "Cursor");
export const CustomerPortalIcon = toIcon(CustomerPortalSvg, "CustomerPortal");
export const DataBoxIcon = toIcon(DataBoxSvg, "DataBox");
export const DateIcon = toIcon(DateSvg, "Date");
export const DateTimeIcon = toIcon(DateTimeSvg, "DateTime");
export const DepreciationIcon = toIcon(DepreciationSvg, "Depreciation");
export const DiscardIcon = toIcon(DiscardSvg, "Discard");
export const EvalaPlainIcon = toIcon(EvalaPlainSvg, "EvalaPlain");
export const IncreaseOfPriceIcon = toIcon(IncreaseOfPriceSvg, "IncreaseOfPrice");
export const ReductionOfPriceIcon = toIcon(ReductionOfPriceSvg, "ReductionOfPrice");
export const TechnicalImprovementIcon = toIcon(TechnicalImprovementSvg, "TechnicalImprovement");
export const DocListIcon = toIcon(DocListSvg, "DocList");
export const DocMenuIcon = toIcon(DocMenuSvg, "DocMenu");
export const DocumentDraftIcon = toIcon(DocumentDraftSvg, "DocumentDraft");
export const DocumentFilledIcon = toIcon(DocumentFilledSvg, "DocumentFilled");
export const DocumentSavedIcon = toIcon(DocumentSavedSvg, "DocumentSaved");
export const DocumentIcon = toIcon(DocumentSvg, "Document");
export const DoubleCaretIcon = toIcon(DoubleCaretSvg, "DoubleCaret");
export const DownIcon = toIcon(DownSvg, "Down");
export const DownloadIcon = toIcon(DownloadSvg, "Download");
export const EditIcon = toIcon(EditSvg, "Edit");
export const EditSimpleIcon = toIcon(EditSimpleSvg, "EditSimple");
export const EmailIcon = toIcon(EmailSvg, "Email");
export const EmojiIcon = toIcon(EmojiSvg, "Emoji");
export const EnterIcon = toIcon(EnterSvg, "Enter");
export const ExitIcon = toIcon(ExitSimpleSvg, "Exit");
export const ExpandAllIcon = toIcon(ExpandAllSvg, "ExpandAll");
export const ExpandIcon = toIcon(ExpandSvg, "Expand");
export const ExportExcelIcon = toIcon(ExportExcelSvg, "ExportExcel");
export const ExportIcon = toIcon(ExportSvg, "Export");
export const ExportPdfIcon = toIcon(ExportPdfSvg, "ExportPdf");
export const ExportReportFullIcon = toIcon(ExportReportFullSvg, "ExportReportFull");
export const ExportReportShortIcon = toIcon(ExportReportShortSvg, "ExportReportShort");
export const ExportSvgIcon = toIcon(ExportCsvSvg, "ExportSvg");
export const FileSimpleIcon = toIcon(FileSimpleSvg, "FileSimple");
export const FillFormIcon = toIcon(FillFormSvg, "FillForm");
export const FilterIcon = toIcon(FilterSvg, "Filter");
export const FitToPageIcon = toIcon(FitToPageSvg, "FitToPage");
export const FitToWidthIcon = toIcon(FitToWidthSvg, "FitToWidth");
export const FloppyDiskIcon = toIcon(FloppyDiskSvg, "FloppyDisk");
export const FolderIcon = toIcon(FolderSvg, "Folder");
export const FolderAddIcon = toIcon(FolderAddSvg, "FolderAdd");
export const FolderOtherIcon = toIcon(FolderOtherSvg, "FolderOther");
export const FormIcon = toIcon(FormSvg, "Form");
export const GrowthNegativeIcon = toIcon(GrowthNegativeSvg, "GrowthNegative");
export const GrowthPositiveIcon = toIcon(GrowthPositiveSvg, "GrowthPositive");
export const GrowthZeroIcon = toIcon(GrowthZeroSvg, "GrowthZero");
export const HandIcon = toIcon(HandSvg, "Hand");
export const HomeIcon = toIcon(HomeSvg, "Home");
export const HotSpotsIcon = toIcon(HotSpotsSvg, "HotSpots");
export const IncomingIcon = toIcon(IncomingSvg, "Incoming");
export const InfoIcon = toIcon(InfoSvg, "Info");
export const InitialBalancesIcon = toIcon(InitialBalancesSvg, "InitialBalances");
export const IntegrationIcon = toIcon(IntegrationSvg, "Integration");
export const InvoicesIssuedBigIcon = toIcon(InvoicesIssuedBigSvg, "InvoicesIssuedBig");
export const InvoicesReceivedBigIcon = toIcon(InvoicesReceivedBigSvg, "InvoicesReceivedBig");

export const EvalaAppIcon = toIcon(EvalaSvg, "EvalaApp");
export const IDokladAppIcon = toIcon(IDokladSvg, "IDokladApp");

export const LabelIcon = toIcon(LabelSvg, "Label");
export const LastUpdatedIcon = toIcon(LastUpdatedSvg, "LastUpdated");
export const LastUpdatedSimpleIcon = toIcon(LastUpdatedSimpleSvg, "LastUpdatedSimple");
export const LikeIcon = toIcon(LikeSvg, "Like");
export const LikeFilledIcon = toIcon(LikeFilledSvg, "LikeFilled");
export const DislikeIcon = toIcon(DislikeSvg, "Dislike");
export const DislikeFilledIcon = toIcon(DislikeFilledSvg, "DislikeFilled");
export const PaidDocumentIcon = toIcon(PaidDocumentSvg, "PaidDocument");
export const UnpaidDocumentIcon = toIcon(UnpaidDocumentSvg, "UnpaidDocument");
export const ListIcon = toIcon(ListSvg, "List");
export const LockFilledIcon = toIcon(LockFilledSvg, "LockFilled");
export const LockIcon = toIcon(LockSvg, "Lock");
export const LogoutIcon = toIcon(LogoutSvg, "Logout");
export const MenuIcon = toIcon(MenuSvg, "Menu");
export const MoreIcon = toIcon(MoreSvg, "More");
export const MoreOptionsIcon = toIcon(MoreOptionsSvg, "MoreOptions");
export const NoteFilledIcon = toIcon(NoteFilledSvg, "NoteFilled");
export const NoteIcon = toIcon(NoteSvg, "Note");
export const NotificationIcon = toIcon(NotificationSvg, "Notification");
export const OldImportClockIcon = toIcon(OldImportClockSvg, "OldImportClock");
export const OutgoingIcon = toIcon(OutgoingSvg, "Outgoing");
export const PairIcon = toIcon(PairSvg, "Pair");
export const PairingAccruedItemsIcon = toIcon(PairingAccruedItemsSvg, "PairingAccruedItems");
export const PairingDepositIcon = toIcon(PairingDepositSvg, "PairingDeposit");
export const PairingExpensesIcon = toIcon(PairingExpensesSvg, "PairingExpenses");
export const PairingGeneralIcon = toIcon(PairingGeneralSvg, "PairingGeneral");
export const ParametersIcon = toIcon(ParametersSvg, "Parameters");
export const PaymentRequestIcon = toIcon(PaymentRequestSvg, "PaymentRequest");
export const PlayIcon = toIcon(PlaySvg, "Play");
export const PlusIcon = toIcon(PlusSvg, "Plus");
export const PrintIcon = toIcon(PrintSvg, "Print");
export const QueryIcon = toIcon(QuerySvg, "Query");
export const RefreshIcon = toIcon(RefreshSvg, "Refresh");
export const RefreshSimpleIcon = toIcon(RefreshSimpleSvg, "RefreshSimple");
export const ReorderIcon = toIcon(ReorderRowsSvg, "Reorder");
export const ReorderSmallIcon = toIcon(ReorderRowsSmallSvg, "ReorderSmall");
export const ResizeIcon = toIcon(ResizeSvg, "Resize");
export const RubberIcon = toIcon(RubberSvg, "Rubber");
export const RossumIcon = toIcon(RossumSvg, "Rossum");
export const SafeFilterIcon = toIcon(SafeSvg, "SafeFilter");
export const SearchIcon = toIcon(SearchSvg, "Search");
export const SearchSmallIcon = toIcon(SearchSmallSvg, "SearchSmall");
export const SendDunningLetterIcon = toIcon(SendDunningLetterSvg, "SendDunningLetter");
export const SendIcon = toIcon(SendSvg, "Send");
export const SendInvoiceIcon = toIcon(SendInvoiceSvg, "SendInvoice");
export const SendMessageIcon = toIcon(SendMessageSvg, "SendMessage");
export const SettingsIcon = toIcon(SettingsSvg, "Settings");
export const SettingsSimpleIcon = toIcon(SettingsSimpleSvg, "SettingsSimple");
export const SortDownIcon = toIcon(SortDownSvg, "SortDown");
export const SortIcon = toIcon(SortSvg, "Sort");
export const SortingIcon = toIcon(SortingSvg, "Sorting");
export const GraphIcon = toIcon(GraphSvg, "Graph");
export const DemoIcon = toIcon(DemoSvg, "Demo");
export const PinIcon = toIcon(PinSvg, "Pin");
export const CalculatorIcon = toIcon(CalculatorSvg, "Calculator");
export const SupportIcon = toIcon(SupportSvg, "Support");
export const PostedStatusNotPostedIcon = toIcon(PostedStatusNotPostedSvg, "PostedStatusNotPosted");
export const PostedStatusPostedIcon = toIcon(PostedStatusPostedSvg, "PostedStatusPosted");
export const PostedStatusNotApplicableIcon = toIcon(PostedStatusNotApplicableSvg, "PostedStatusNotApplicable");
export const ClearedStatusClearedIcon = toIcon(ClearedStatusClearedSvg, "ClearedStatusCleared");
export const ClearedStatusPartiallyClearedIcon = toIcon(ClearedStatusPartiallyClearedSvg, "ClearedStatusPartiallyCleared");
export const ClearedStatusNotClearedIcon = toIcon(ClearedStatusNotClearedSvg, "ClearedStatusNotCleared");
export const VatStatementStatusNotApplicableIcon = toIcon(VatStatementStatusNotApplicableSvg, "VatStatementStatusNotApplicable");
export const VatStatementStatusNotPostedIcon = toIcon(VatStatementStatusNotPostedSvg, "VatStatementStatusNotPosted");
export const VatStatementStatusPostedIcon = toIcon(VatStatementStatusPostedSvg, "VatStatementStatusPosted");
export const StatusDoubleFormIcon = toIcon(StatusDoubleFormSvg, "StatusDoubleForm");
export const StatusLockedIcon = toIcon(StatusLockedSvg, "StatusLocked");
export const StatusPartlyLockedIcon = toIcon(StatusPartlyLockedSvg, "StatusPartlyLocked");
export const StatusUnlockedIcon = toIcon(StatusUnlockedSvg, "StatusUnlocked");
export const StatusNoIcon = toIcon(StatusNoSvg, "StatusNo");
export const StatusYesIcon = toIcon(StatusYesSvg, "StatusYes");
export const StatusWarnIcon = toIcon(StatusWarnSvg, "StatusWarn");
export const TicketStatusInProgressIcon = toIcon(StatusTicketInProgressSvg, "TicketStatusInProgress");
export const StatusIsdocIcon = toIcon(StatusIsdocSvg, "StatusIsdoc");
export const StatusRossumIcon = toIcon(StatusRossumSvg, "StatusRossum");
export const StatusNoDataReadIcon = toIcon(StatusNoDataReadSvg, "StatusNoDataRead");
export const TicketStatusDoneIcon = toIcon(StatusTicketDoneSvg, "TicketStatusDone");
export const StorageIcon = toIcon(StorageSvg, "Storage");
export const SubscriptionIcon = toIcon(SubscriptionSvg, "Subscription");
export const SwitchIcon = toIcon(SwitchSvg, "Switch");
export const SynchronizationIcon = toIcon(SynchronizationSvg, "Synchronization");
export const TableCaretIcon = toIcon(TableCaretSvg, "TableCaret");
export const TaxInvoiceIcon = toIcon(TaxInvoiceSvg, "TaxInvoice");
export const TemplateIcon = toIcon(TemplateSvg, "Template");
export const TimeIcon = toIcon(TimeSvg, "Time");
export const TimeDependentIcon = toIcon(TimeDependentSvg, "TimeDependent");
export const TimeDependentFilledIcon = toIcon(TimeDependentFilledSvg, "TimeDependentFilled");
export const UnavailableIcon = toIcon(UnavailableSvg, "Unavailable");
export const UnchainedIcon = toIcon(UnchainedSvg, "Unchained");
export const UnrelatedItemsIcon = toIcon(UnrelatedItemsSvg, "UnrelatedItems");
export const UpIcon = toIcon(UpSvg, "Up");
export const UploadIcon = toIcon(UploadSvg, "Upload");
export const UploadSimpleIcon = toIcon(UploadSimpleSvg, "UploadSimple");
export const UserIcon = toIcon(UserSvg, "User");
export const UseIsDocIcon = toIcon(UseIsDocSvg, "UseIsDoc");
export const UseRossumIcon = toIcon(UseRossumSvg, "UseRossum");
export const ValueHelpFilledIcon = toIcon(ValueHelpFilledSvg, "ValueHelpFilled");
export const ValueHelpIcon = toIcon(ValueHelpSvg, "ValueHelp");
export const WebInvoiceIcon = toIcon(WebInvoiceSvg, "WebInvoice");
export const ZoomInIcon = toIcon(ZoomInSvg, "ZoomIn");
export const ZoomOutIcon = toIcon(ZoomOutSvg, "ZoomOut");
export const ZoomSmallIcon = toIcon(ZoomSmallSvg, "ZoomSmall");

export const FacebookIcon = toIcon(FacebookSvg, "Facebook");
export const FieldValidationNegativeIcon = toIcon(FieldValidationNegativeSvg, "FieldValidationNegative");
export const FieldValidationPositiveIcon = toIcon(FieldValidationPositiveSvg, "FieldValidationPositive");
export const FieldValidationWarningIcon = toIcon(FieldValidationWarningSvg, "FieldValidationWarning");
export const GoogleIcon = toIcon(GoogleSvg, "Google");

export const LockOpenedIcon = toIcon(LockOpenedSvg, "LockOpened");
export const LockClosedIcon = toIcon(LockClosedSvg, "LockClosed");
export const VisibleIcon = toIcon(VisibleSvg, "Visible");
export const VisibleFilledIcon = toIcon(VisibleFilledSvg, "VisibleFilled");
export const AgendaIcon = toIcon(AgendaSvg, "Agenda");

export const ContainsTwoFormsIcon = toIcon(ContainsTwoFormsSvg, "ContainsTwoForms");
export const ImageIcon = toIcon(ImageSvg, "Image");

export const ErrorIcon = toIcon(ErrorSvg, "Error");
export const SuccessIcon = toIcon(SuccessSvg, "Success");
export const WarningIcon = toIcon(WarningSvg, "Warning");

export const DocTemplateIcon = toIcon(DocTemplateSvg, "DocTemplate");
export const ExcelIcon = toIcon(ExcelSvg, "Excel");
export const IsdocIcon = toIcon(IsdocSvg, "Isdoc");
export const OtherDocIcon = toIcon(OtherSvg, "OtherDoc");
export const PdfIcon = toIcon(PdfSvg, "Pdf");
export const PictureIcon = toIcon(PictureSvg, "Picture");
export const TxtIcon = toIcon(TxtSvg, "Txt");
export const WordIcon = toIcon(WordSvg, "Word");
export const CashBoxIcon = toIcon(CashBoxSvg, "CashBox");
export const CashBoxListIcon = toIcon(CashBoxListSvg, "CashBoxList");

export const AuditTrailIcon = toIcon(AuditTrailSvg, "AuditTrail");

export const FolderFPIcon = toIcon(FolderFPSvg, "FolderFP");
export const FolderFVIcon = toIcon(FolderFVSvg, "FolderFV");
export const FolderDotsIcon = toIcon(FolderDotsSvg, "FolderDots");
export const FolderPDIcon = toIcon(FolderPDSvg, "FolderPD");
export const FolderPPDIcon = toIcon(FolderPPDSvg, "FolderPPD");
export const FolderVBUIcon = toIcon(FolderVBUSvg, "FolderVBU");
export const FolderVPDIcon = toIcon(FolderVPDSvg, "FolderVPD");
export const FolderZDIcon = toIcon(FolderZDSvg, "FolderZD");
export const FolderZFPIcon = toIcon(FolderZFPSvg, "FolderZFP");
export const FolderZFVIcon = toIcon(FolderZFVSvg, "FolderZFV");
export const FolderPDDOPPIcon = toIcon(FolderPDDOPPSvg, "FolderPDDOPP");
export const FolderVDDOPPIcon = toIcon(FolderVDDOPPSvg, "FolderVDDOPP");
export const InboxFolderIcon = toIcon(InboxFolderSvg, "InboxFolder");

export const InvoicesReceivedPlainIcon = toIcon(InvoicesReceivedPlainSvg, "InvoicesReceivedPlain");
export const InvoicesIssuedPlainIcon = toIcon(InvoicesIssuedPlainSvg, "InvoicesIssuedPlain");
export const OtherLiabilitiesPlainIcon = toIcon(OtherLiabilitiesPlainSvg, "OtherLiabilitiesPlain");
export const OtherReceivablesPlainIcon = toIcon(OtherReceivablesPlainSvg, "OtherReceivablesPlain");
export const CorrectiveInvoicesReceivedPlainIcon = toIcon(CorrectiveInvoicesReceivedPlainSvg, "CorrectiveInvoicesReceivedPlain");
export const CorrectiveInvoicesIssuedPlainIcon = toIcon(CorrectiveInvoicesIssuedPlainSvg, "CorrectiveInvoicesIssuedPlain");
export const ProformaInvoicesIssuedPlainIcon = toIcon(ProformaInvoicesIssuedPlainSvg, "ProformaInvoicesIssuedPlain");
export const ProformaInvoicesIssuedTaxPlainIcon = toIcon(ProformaInvoicesIssuedTaxPlainSvg, "ProformaInvoicesIssuedTaxPlain");
export const ProformaInvoicesReceivedPlainIcon = toIcon(ProformaInvoicesReceivedPlainSvg, "ProformaInvoicesReceivedPlain");
export const ProformaInvoicesReceivedTaxPlainIcon = toIcon(ProformaInvoicesReceivedTaxPlainSvg, "ProformaInvoicesReceivedTaxPlain");
export const TaxDocumentIcon = toIcon(TaxDocumentSvg, "TaxDocument");
export const InternalDocumentsPlainIcon = toIcon(InternalDocumentPlainSvg, "InternalDocumentsPlain");
export const InternalDocumentCancelIcon = toIcon(InternalDocumentCancelSvg, "InternalDocumentCancel");

export const VatStatementIcon = toIcon(VatStatementSvg, "VatStatement");
export const VatControlStatementIcon = toIcon(VatControlStatementSvg, "VatControlStatement");
export const PohodaIcon = toIcon(PohodaSvg, "Pohoda");
export const MoneyS3Icon = toIcon(MoneyS3Svg, "MoneyS3");
export const IsdocImportIcon = toIcon(IsdocImportSvg, "IsdocImport");
export const ExportDocumentsIcon = toIcon(ExportDocumentsSvg, "ExportDocuments");
export const ExportBankTransactionsIcon = toIcon(ExportBankTransactionsSvg, "ExportBankTransactions");
export const ExportCashReceiptsIcon = toIcon(ExportCashReceiptsSvg, "ExportCashReceipts");
export const VatViesStatementIcon = toIcon(VatViesStatementSvg, "VatViesStatement");
export const SimpleDocumentIcon = toIcon(DocSimpleSvg, "SimpleDocument");
export const ConfirmIcon = toIcon(ConfirmSvg, "Confirm");

// Form customization icons
export const LockedGroupIcon = toIcon(LockedGroupSvg, "LockedGroup");
export const DependentFieldsIcon = toIcon(DependentFieldsSvg, "DependentFields");

export const PcIcon = toIcon(PcSvg, "Pc");
export const TabletIcon = toIcon(TabletSvg, "Tablet");
export const MobileIcon = toIcon(MobileSvg, "Mobile");

export const WorkDateFilledIcon = toIcon(WorkDateFilledSvg, "WorkDateFilled");

export const WorkDateIcon = toIcon(WorkDateSvg, "WorkDate");

export const AccountingJournalIcon = toIcon(AccountingJournalSvg, "AccountingJournal");
export const CustomerSupportIcon = toIcon(CustomerSupportSvg, "CustomerSupport");
export const BalanceSheetIcon = toIcon(BalanceSheetSvg, "BalanceSheet");
export const CustomerPortalInvoicingIcon = toIcon(CustomerPortalInvoicingSvg, "CustomerPortalInvoicing");
export const ForPaymentIcon = toIcon(ForPaymentSvg, "ForPayment");
export const CustomerPortalPaymentIcon = toIcon(CustomerPortalPaymentSvg, "CustomerPortalPayment");
export const DocumentJournalIcon = toIcon(DocumentJournalSvg, "DocumentJournal");
export const PaymentJournalIcon = toIcon(PaymentJournalSvg, "PaymentJournal");
export const DemoTenantIcon = toIcon(DemoTenantSvg, "DemoTenant");
export const GeneralLedgerIcon = toIcon(GeneralLedgerSvg, "GeneralLedger");
export const InboxIcon = toIcon(InboxSvg, "Inbox");
export const IncomeStatementIcon = toIcon(IncomeStatementSvg, "IncomeStatement");
export const SendToAccountantIcon = toIcon(SendToAccountantSvg, "SendToAccountant");
export const TicketsIcon = toIcon(TicketsSvg, "Tickets");
export const OneDocPairIcon = toIcon(OneDocPairSvg, "OneDocPair");
export const OutgoingPaymentIcon = toIcon(OutgoingPaymentSvg, "OutgoingPayment");
export const IncomingPaymentIcon = toIcon(IncomingPaymentSvg, "IncomingPayment");

export const MassAccountingIcon = toIcon(MassAccountingSvg, "MassAccounting");

export const CbaEntryLedgerIcon = toIcon(CbaEntryLedgerSvg, "CbaEntryLedger");
export const HelpIcon = toIcon(HelpSvg, "Help");

export const getIcon = (name: string): React.ComponentType<IProps> => {
    try {
        if (name) {
            return eval(`${name}Icon`);
        }
    } catch (e) {
        (logger as any).error(`Icons.tsx: Wrong icon name used! (${name})`);
    }

    return null;
};
