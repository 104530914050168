import React from "react";
import Input, { IInputProps } from "./Input";
import { IconSize } from "../../../enums";
import { WithPasswordInput, withPasswordInput } from "./withPasswordInput";

interface IProps extends IInputProps {

}

class PasswordInput extends React.PureComponent<IProps & WithPasswordInput> {

    render = () => {
        const Icon = this.props.password.icon;
        return (
            <Input {...this.props}
                   passRef={this.props.password.inputRef}
                   onIconClick={this.props.password.onIconClick}
                   type={this.props.password.type}
                   icon={<Icon width={IconSize.M}/>}
                   iconTitle={this.props.password.iconTitle}/>
        );
    };
}

export default withPasswordInput(PasswordInput);