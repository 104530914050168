import BindingContext, { createPath } from "../../odata/BindingContext";
import {
    BasicInputSizes,
    FastEntryInputSizes,
    FieldType,
    LabelStatus,
    NavigationSource,
    TextAlign,
    ValidationErrorType,
    ValidatorType
} from "../../enums";
import { TRecordAny, TValue } from "../../global.types";
import { IFormatOptions } from "@odata/OData.utils";
import {
    BankTransactionTypeCode,
    DocumentTypeCode,
    PaymentDocumentItemTypeCode,
    PaymentStatusCode,
    SelectionCode
} from "@odata/GeneratedEnums";
import i18next, { TFunction } from "i18next";
import { createNewGain, getExpenseTitle } from "@utils/PairTableUtils";
import Currency from "../../types/Currency";
import CurrencyType, { formatCurrency } from "../../types/Currency";
import { AccountDefFormatter, getLinks } from "@components/smart/GeneralFieldDefinition";
import { IFieldDefFn, IGetValueArgs } from "@components/smart/FieldInfo";
import { ISelectItem } from "@components/inputs/select/BasicSelect";
import {
    accountsAreClosedValidator,
    getAccAssignmentFilterWithTypeAndDate,
    getAccountAssignmentItemDef,
    getChoAIdBasedOnDate,
    getExpenseGains,
    handleItemAccAssignmentChange,
    ItemCreateCustomAssignmentBc
} from "../accountAssignment/AccountAssignment.utils";
import { FormStorage } from "../../views/formView/FormStorage";
import {
    BankTransactionEntity,
    CashReceiptEntity,
    CbaCategoryEntity,
    DocumentCbaCategoryEntity,
    DocumentEntity,
    DocumentItemCbaCategoryEntity,
    DocumentItemEntity,
    EntitySetName,
    EntityTypeName,
    IAccountEntity,
    IBankTransactionEntity,
    ICashReceiptEntity,
    IPaymentDocumentItemEntity,
    PaymentDocumentEntity,
    PaymentDocumentItemEntity
} from "@odata/GeneratedEntityTypes";
import {
    calculateExchangeRate,
    formatNumberCurrency,
    getTransactionTypeFromEntity,
    hasAdditionalExRate,
    hasExchangeGain,
    IBankCustomData
} from "./bankTransactions/BankTransactions.utils";
import { ISmartFieldBlur, ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { getNestedValue, getNewItemsMaxId, setNestedValue } from "@odata/Data.utils";
import {
    BANK_ACCOUNT_BALANCE_SHEET_ACCOUNT_PREFIX,
    CASH_BOXES_BALANCE_SHEET_ACCOUNT_PREFIX,
    REST_API_URL,
    UPDATE_CLEARING_SPLIT_ITEMS
} from "../../constants";
import { formatDateToDateString } from "@components/inputs/date/utils";
import customFetch, { getDefaultPostParams } from "../../utils/customFetch";
import { isDefined, isNotDefined, roundToDecimalPlaces } from "@utils/general";
import { TCellValue } from "@components/table";
import NumberType, { currencyScaleFormatter, currencyScaleParser, isNumber } from "../../types/Number";
import { DOCUMENT_DATE_CHANGE_DEBOUNCE } from "../documents/DocumentDef";
import { TFieldsDefinition } from "../PageUtils";
import { fetchAndSetItemsByInfo } from "@components/smart/smartSelect/SmartSelectAPI";
import { getDocumentTypeCodeFromEntityType } from "@odata/EntityTypes";
import { TableStorage } from "../../model/TableStorage";
import { IFieldInfo } from "@odata/FieldInfo.utils";
import { getCompanyCurrency } from "@utils/CompanyUtils";
import { IAppContext } from "../../contexts/appContext/AppContext.types";
import { readOnlyCurrencyItemFormatter } from "../documents/Document.utils";
import { getUtcDate } from "../../types/Date";
import { parseError } from "../../odata/ODataParser";

export const PAYMENT_GROUP = "payment_group";
export const EXCHANGE_GAIN_GROUP = "exchange_group";

export const PairAdditionalData = [{
    id: "Items/Amount"
}, {
    id: "Items/TransactionAmount"
}, {
    id: "Items/LinkedDocument/NumberOurs"
}, {
    id: "Items/LinkedDocument/DocumentTypeCode"
}, {
    id: "Items/LinkedDocument/TransactionAmount"
}, {
    id: "Items/ClearedAmount"
}, {
    id: "Items/LinkedDocument/TransactionAmountDue"
}, {
    id: "Items/LinkedDocument/DateAccountingTransaction"
}, {
    id: "Items/AccountAssignmentSelection/AccountAssignment"
}, {
    id: `Items/${ItemCreateCustomAssignmentBc}`
}, {
    id: `Items/${BindingContext.localContext("AccountAmount")}`
}, {
    id: `Items/${BindingContext.localContext("ExchangeGainTitle")}`
}, {
    id: `Items/${BindingContext.localContext("GainAmount")}`
}, {
    id: "Items/Order"
}, {
    id: "Items/ExchangeRate"
}, {
    id: "Items/LinkedDocument/ExchangeRatePerUnit"
}, {
    id: "Items/LinkedDocument/TransactionCurrencyCode"
}, {
    id: "Items/Order"
}, {
    id: "Items/PaymentDocumentItemTypeCode"
}, {
    id: `${BindingContext.localContext("ItemsHeader")}`
}, {
    id: "Items/SplitAccountAssignments/TransactionAmount"
}, {
    id: "Items/SplitAccountAssignments/Description"
}, {
    id: "Items/SplitAccountAssignments/CreditAccount"
}, {
    id: "Items/SplitAccountAssignments/DebitAccount"
}, {
    id: createPath(PaymentDocumentEntity.Items, PaymentDocumentItemEntity.LinkedDocument, DocumentEntity.CbaCategory, DocumentCbaCategoryEntity.IsAssetAcquisition)
}, {
    id: createPath(PaymentDocumentEntity.Items, PaymentDocumentItemEntity.LinkedDocument, DocumentEntity.CbaCategory, DocumentCbaCategoryEntity.TaxImpactCode)
}, {
    id: createPath(PaymentDocumentEntity.Items, PaymentDocumentItemEntity.LinkedDocument, DocumentEntity.CbaCategory, DocumentCbaCategoryEntity.TaxPercentage)
}, {
    id: createPath(PaymentDocumentEntity.Items, PaymentDocumentItemEntity.LinkedDocument, DocumentEntity.CbaCategory, DocumentCbaCategoryEntity.Category, CbaCategoryEntity.Name)
}, {
    id: createPath(PaymentDocumentEntity.Items, PaymentDocumentItemEntity.LinkedDocument, DocumentEntity.DocumentItems, DocumentItemEntity.CbaCategory, DocumentItemCbaCategoryEntity.IsAssetAcquisition)
}, {
    id: createPath(PaymentDocumentEntity.Items, PaymentDocumentItemEntity.LinkedDocument, DocumentEntity.DocumentItems, DocumentItemEntity.CbaCategory, DocumentItemCbaCategoryEntity.TaxImpactCode)
}, {
    id: createPath(PaymentDocumentEntity.Items, PaymentDocumentItemEntity.LinkedDocument, DocumentEntity.DocumentItems, DocumentItemEntity.CbaCategory, DocumentItemCbaCategoryEntity.TaxPercentage)
}, {
    id: createPath(PaymentDocumentEntity.Items, PaymentDocumentItemEntity.LinkedDocument, DocumentEntity.DocumentItems, DocumentItemEntity.CbaCategory, DocumentItemCbaCategoryEntity.Category, CbaCategoryEntity.Name)
}];

export const getBankTransItemDefaultDateAccountingTransaction = (storage: FormStorage): Date => {
    const name = BankTransactionEntity.DateBankTransaction;
    const dateBankTransaction = storage.getValueByPath(name);
    return dateBankTransaction ?? getUtcDate();
};

export const getCashReceiptItemDefaultDateAccountingTransaction = (storage: FormStorage): Date => {
    const name = CashReceiptEntity.DateIssued;
    const dateBankTransaction = storage.getValueByPath(name);

    return dateBankTransaction ?? getUtcDate();
};

export const getBankItemDefaultDateAccountingTransaction = (storage: FormStorage): Date => {
    const type = getDocumentTypeCodeFromEntityType(storage.data.bindingContext.getRootParent().getEntityType().getName() as EntityTypeName);

    return type === DocumentTypeCode.BankTransaction ? getBankTransItemDefaultDateAccountingTransaction(storage) : getCashReceiptItemDefaultDateAccountingTransaction(storage);
};

const getTransactionAmountUnit = (args: IGetValueArgs) => {
    const currency = args.storage.data.entity?.TransactionCurrency?.Code ?? args.storage.data.entity?.TransactionCurrencyCode;
    return currency ? CurrencyType.getCurrencyUnit(currency) : getCompanyCurrency(args.storage.context);
};

export const isPairAmountReadOnly = (storage: FormStorage, bc: BindingContext): boolean => {
    const row = storage.getValue(bc.getParent());
    const hasDoc = row?.LinkedDocument?.Id;
    return !!hasDoc;
};

export const getPairDef = (type: DocumentTypeCode, hasAccountAssignment: boolean): TFieldsDefinition => {
    const accDefs = getAccountAssignmentItemDef("Items", type, false, hasAccountAssignment);

    const _getBankAccAssignment = (propName: string) => {
        const isDebit = propName === "DebitAccount";
        const filterStart = isDebit ? "5" : "6";
        const disabledStat = isDebit ? PaymentDocumentItemTypeCode.ExchangeGain : PaymentDocumentItemTypeCode.ExchangeLoss;
        const filterStat = isDebit ? PaymentDocumentItemTypeCode.ExchangeLoss : PaymentDocumentItemTypeCode.ExchangeGain;
        const defaultDef = accDefs[`Items/AccountAssignmentSelection/AccountAssignment/${propName}`];
        return {
            ...defaultDef,
            isRequired: (args: IGetValueArgs) => {
                // for dialog you have to show required and value is stored in temporal data
                // or in save when it is stored in entity
                const row = args.storage.getValue(args.bindingContext.getParent().getParent().getParent());
                if (Array.isArray(row)) {
                    // called from SmartFastEntryList -> returns full Items not only one item due different binding context
                    // always return true
                    return true;
                }
                const code = row?.AccountAssignmentSelection?.Selection?.Code;
                const temporalData = args.storage.getTemporalData(args.bindingContext.getParent())?.value;

                return code === SelectionCode.Own || temporalData === SelectionCode.Own;
            },
            formatter: (val: TValue, args?: IFormatOptions) => {
                const row = args.storage.getValue(args.bindingContext.getParent().getParent().getParent());
                if (!isPaymentOrOther(row?.PaymentDocumentItemTypeCode)) {
                    const entity = args.item || args.entity;
                    return entity?.Number;
                }

                return AccountDefFormatter(val, args);
            },
            isReadOnly: (args: IGetValueArgs) => {
                const row = args.storage.getValue(args.bindingContext.getParent().getParent().getParent());
                return row?.PaymentDocumentItemTypeCode === disabledStat;
            },
            width: (args: IGetValueArgs) => {
                const row = args.storage.getValue(args.bindingContext.getParent().getParent().getParent());
                // check whether there is TypeCode prop too, don't use '?' can be called from different scopes
                if (row?.PaymentDocumentItemTypeCode && isPaymentOrOther(row.PaymentDocumentItemTypeCode)) {
                    return BasicInputSizes.L;
                }

                return BasicInputSizes.S;
            },
            fieldSettings: {
                ...defaultDef.fieldSettings,
                entitySet: (args: IGetValueArgs) => {
                    const isSplit = args.bindingContext.getFullPath().includes(PaymentDocumentItemEntity.SplitAccountAssignments);
                    let bc = args.bindingContext.getParent().getParent();
                    // split uses same fields but are stored in different path
                    if (!isSplit) {
                        bc = bc.getParent();
                    }

                    const date = args.storage.getValue(bc)?.DateAccountingTransaction
                        ?? getBankItemDefaultDateAccountingTransaction(args.storage as FormStorage);
                    const choaId = getChoAIdBasedOnDate(args.storage as FormStorage, date, false);

                    return `ChartsOfAccounts(${choaId})/Accounts`;
                },
                localDependentFields: [{
                    from: { id: "Number" },
                    to: { id: "Number" },
                    navigateFrom: NavigationSource.Itself
                }, {
                    from: { id: "Name" },
                    to: { id: "Name" },
                    navigateFrom: NavigationSource.Itself
                }],
                itemsForRender: (items: ISelectItem[], args: IGetValueArgs) => {
                    const row = args.storage.getValue((args.info as TRecordAny).bindingContext.getParent().getParent().getParent());
                    if (row?.PaymentDocumentItemTypeCode === filterStat) {
                        return items?.filter((item: ISelectItem) => {
                            return item.additionalData?.Number?.startsWith(filterStart);
                        });
                    }

                    return items;
                }
            }
        };
    };

    let def: TFieldsDefinition = {
        "Items/TransactionAmount": {
            width: BasicInputSizes.S,
            label: i18next.t("Common:General.Amount"),
            fieldSettings: {
                unit: getTransactionAmountUnit
            },
            formatter: readOnlyCurrencyItemFormatter,
            isReadOnly: (args: IGetValueArgs) => {
                return isPairAmountReadOnly(args.storage as FormStorage, args.bindingContext);
            },
            parser: (value: TValue, args: IFormatOptions) => {
                return currencyScaleParser(value as string, args.storage.data.entity.TransactionCurrency);
            },
            validator: {
                type: ValidatorType.Custom,
                settings: {
                    customValidator: (value, args) => {
                        const code = args.storage.getValue(args.bindingContext.getParent())?.PaymentDocumentItemTypeCode;
                        const isPayment = isPaymentOrOther(code);
                        return isPayment ? isNumber(value) && value > 0 : true;
                    },
                    message: i18next.t("Comnon:Validation.MustBePositiveNumber")
                }
            },
            affectedFields: [{
                navigateFromParent: true,
                id: "Amount"
            }]
        },
        "Items/Amount": {
            width: BasicInputSizes.S,
            type: FieldType.Input,
            formatter: (val: TValue, args: IGetValueArgs) => {
                return formatCurrency(val, getCompanyCurrency(args.context));
            },
            customizationData: {
                useForCustomization: false
            },
            isReadOnly: true,
            label: i18next.t("Banks:Transactions.Accounted")
        },
        "Items/LinkedDocument/NumberOurs": {
            label: i18next.t("Document:FormTab.PairedDocument"),
            isVisible: (args: IGetValueArgs) => {
                // visible if at least one row has value
                // we cannot have column only for some rows, it would break virtualization
                // (because rows can wrap and every row has to have same height)
                const entity = args.storage.data.entity as IBankTransactionEntity;

                return entity?.Items?.some(item => item.LinkedDocument?.NumberOurs);
            },
            formatter: (val: TValue, args?: IFormatOptions) => {
                const row = args.storage.getValue(args.bindingContext.getParent());
                const code = getLinks(args, [{
                    LinkedDocument: row
                }]);

                return code.fragment as TCellValue;
            },
            isRequired: false,
            isReadOnly: true
        }
    };

    if (hasAccountAssignment) {
        def = {
            ...def,
            ...accDefs,
            "Items/SplitAccountAssignments/TransactionAmount": {
                useForValidation: true,
                fieldSettings: {
                    unit: getTransactionAmountUnit
                },
                validator: {
                    type: ValidatorType.PositiveNumber
                },
                formatter: (value: TValue, args: IFormatOptions) => currencyScaleFormatter(value as number, args.entity.TransactionCurrency),
                parser: (value: TValue, args: IFormatOptions) => {
                    const maximumFractionDigits = args.entity.TransactionCurrency?.MinorUnit ?? 2;
                    return NumberType.parse(value as string, true, {
                        maximumFractionDigits
                    });
                },
                isRequired: true,
                customizationData: {
                    useForCustomization: false
                }
            },
            "Items/SplitAccountAssignments/Description": {
                useForValidation: true,
                isRequired: true,
                customizationData: {
                    useForCustomization: false
                }
            },
            "Items/SplitAccountAssignments/CreditAccount": {
                ..._getBankAccAssignment("CreditAccount"),
                isRequired: true
            },
            "Items/SplitAccountAssignments/DebitAccount": {
                ..._getBankAccAssignment("DebitAccount"),
                isRequired: true
            },

            "Items/DateAccountingTransaction": {
                isRequired: true,
                label: i18next.t("Banks:Transactions.DateTransactionLabel"),
                fieldSettings: {
                    debouncedWait: DOCUMENT_DATE_CHANGE_DEBOUNCE
                },
                isReadOnly: (args: IGetValueArgs) => {
                    const row = args.storage.getValue(args.bindingContext.getParent());
                    // we can use the fact that for one group this field is always readonly
                    // and for the other it is always not read only
                    // so if the groupID is provided use it, otherwise we need to grab it from row
                    if (isDefined(args.fastEntryListId)) {
                        return args.fastEntryListId === EXCHANGE_GAIN_GROUP;
                    }

                    return !isPaymentOrOther(row?.PaymentDocumentItemTypeCode);
                },
                defaultValue: (args: IGetValueArgs) => {
                    return getBankItemDefaultDateAccountingTransaction(args.storage as FormStorage);
                }
            },
            [`Items/${BindingContext.localContext("ExchangeGainTitle")}`]: {
                type: FieldType.Custom,
                width: FastEntryInputSizes.S,
                labelStatus: LabelStatus.Hidden,
                label: null,
                // smart fast entry list will call render only if there is a formatter (I am FAR from being that brave to change that, hence I add it here :)
                formatter: (val: TValue) => {
                    return val as string;
                },
                render: (args: IFieldDefFn) => {
                    const row = args.storage.getValue(args.props.info.bindingContext.getParent());
                    const type = row?.PaymentDocumentItemTypeCode;
                    const isLoss = type === PaymentDocumentItemTypeCode.ExchangeLoss;
                    return getExpenseTitle(isLoss, { width: args.props.width, withMargin: true });
                },
                customizationData: {
                    useForCustomization: false
                }
            },
            [`Items/${BindingContext.localContext("GainAmount")}`]: {
                isReadOnly: true,
                textAlign: TextAlign.Right,
                label: i18next.t("Banks:Pairing.Accounted"),
                formatter: (val: TValue, args?: IFormatOptions) => {
                    const row = args.storage.getValue(args.bindingContext.getParent());
                    const amount = row?.Amount;
                    return Currency.format(Math.abs(amount), { currency: getCompanyCurrency(args.context) });
                },
                customizationData: {
                    useForCustomization: false
                }
            },
            "Items/AccountAssignmentSelection/AccountAssignment": {
                ...accDefs["Items/AccountAssignmentSelection/AccountAssignment"],
                defaultValue: "",
                label: i18next.t("Banks:Transactions.AccountAssignmentFormLabel"),
                validator: {
                    type: ValidatorType.Custom,
                    settings: {
                        customValidator: [{ validator: accountsAreClosedValidator }]
                    }
                },
                filter: {
                    select: (args: IGetValueArgs) => {
                        // TODO: rename in IGetValueArgs to IFieldInfo
                        const bc = (args.info as IFieldInfo).bindingContext;
                        const date = args.storage.getValue(bc.getParent().getParent())?.DateAccountingTransaction ?? getBankItemDefaultDateAccountingTransaction(args.storage as FormStorage);
                        // this can be empty because the same definition is used in both BankTransactions and CashReceipts
                        const bankTransactionType: BankTransactionTypeCode = (args.storage.data.entity as IBankTransactionEntity)[BankTransactionEntity.BankTransactionType]?.Code as BankTransactionTypeCode;
                        let filter = getAccAssignmentFilterWithTypeAndDate(type, args, date);
                        const accountPrefix = bc.getRootParent().getPath(true) === EntitySetName.BankTransactions ? BANK_ACCOUNT_BALANCE_SHEET_ACCOUNT_PREFIX : CASH_BOXES_BALANCE_SHEET_ACCOUNT_PREFIX;

                        if (bankTransactionType) {
                            filter += " AND (";

                            if (bankTransactionType === BankTransactionTypeCode.IncomingPayment) {
                                filter += `startswith(DebitAccount/Number,'${accountPrefix}')`;
                            } else if (bankTransactionType === BankTransactionTypeCode.OutgoingPayment) {
                                filter += `startswith(CreditAccount/Number,'${accountPrefix}')`;
                            }

                            filter += ")";
                        }

                        return filter;
                    }
                },
                fieldSettings: {
                    ...accDefs["Items/AccountAssignmentSelection/AccountAssignment"].fieldSettings,

                    onBeforeRequest: async (args) => {
                        // transformFetchedItems is dependent on accounts
                        // we need to fetch them here, so that they are already stored in info before transformFetchedItems is called
                        // this shouldn't add any unnecessary requests, because fetchItems is memoized
                        const relevantAccountSide = getRelevantAccountSide(args.storage as FormStorage);
                        const accountsBc = args.bindingContext.navigate(relevantAccountSide);

                        // if it is linked document add "Split" decision
                        const row = args.storage.getValue(args.bindingContext.getParent().getParent());
                        if (row?.LinkedDocument?.Id) {
                            const hasSplit = !!args.info.fieldSettings.additionalItems?.find(item => item.id === SelectionCode.Split);
                            if (!hasSplit) {
                                args.info.fieldSettings.additionalItems.splice(1, 0, {
                                    id: SelectionCode.Split,
                                    label: i18next.t("Document:AccountAssignment.Split"),
                                    groupId: "Default"
                                });
                            }
                        }

                        // use fetchAndSetItemsByInfo instead of fetchSelectItem
                        // so that we can retrieve it in transformFetchedItems without using some custom data
                        await fetchAndSetItemsByInfo(args.storage as FormStorage, args.storage.getInfo(accountsBc));
                    },
                    transformFetchedItems: (items: ISelectItem[], args: IGetValueArgs): ISelectItem[] => {
                        const relevantAccountSide = getRelevantAccountSide(args.storage as FormStorage);
                        const origBc = (args.info as IFieldInfo).bindingContext;
                        const accountsBc = origBc.navigate(relevantAccountSide);
                        const accounts = args.storage.getInfo(accountsBc)?.fieldSettings?.items ?? [] as ISelectItem[];
                        const entity = args.storage.data.entity;
                        const {
                            accountPrefix,
                            accountSuffix
                        } = getFixedAccountAssignmentAccountNumberForPaymentEntity(args.storage.data.bindingContext, entity);

                        // the correct, analytical account number
                        // different for BankTransactions and CashReceipts
                        const correctAccountNumber = `${accountPrefix}${accountSuffix}`;
                        const correctAccountItem = accounts.find(accountItem => (accountItem.additionalData as IAccountEntity).Number === correctAccountNumber);

                        if (!correctAccountItem) {
                            // analytical account doesn't exist
                            return items;
                        }

                        return items
                            // remove items that already use analytical account, but different one
                            .filter(item => {
                                const account = item.additionalData[relevantAccountSide] as IAccountEntity;

                                if (!account.Number.startsWith(accountPrefix)) {
                                    return true;
                                }

                                return account.Number === accountPrefix || account.Number === correctAccountNumber;
                            })
                            .map((item) => {
                                const account = item.additionalData[relevantAccountSide] as IAccountEntity;

                                if (account.Number.startsWith(accountPrefix) && account.Number !== correctAccountNumber) {
                                    const correctLabel = item.label.replace(accountPrefix, correctAccountNumber);

                                    return {
                                        ...item,
                                        label: correctLabel,
                                        tabularData: [
                                            correctLabel,
                                            ...item.tabularData.slice(1)
                                        ],
                                        additionalData: {
                                            ...item.additionalData,
                                            [relevantAccountSide]: correctAccountItem.additionalData,
                                            ShortName: correctLabel
                                        }
                                    };
                                } else {
                                    return item;
                                }
                            });
                    }
                }
            },
            "Items/AccountAssignmentSelection/AccountAssignment/CreditAccount": _getBankAccAssignment("CreditAccount"),
            "Items/AccountAssignmentSelection/AccountAssignment/DebitAccount": _getBankAccAssignment("DebitAccount")
        };
    }

    return def;
};

export const getRelevantAccountSide = (storage: FormStorage): "CreditAccount" | "DebitAccount" => {
    const rootEntitySet = storage.data.bindingContext.getRootParent().getPath(true);

    if (rootEntitySet === EntitySetName.CashReceiptsReceived) {
        return "DebitAccount";
    } else if (rootEntitySet === EntitySetName.CashReceiptsIssued) {
        return "CreditAccount";
    }

    // else EntitySetName.BankTransactions
    const bankTransactionType: BankTransactionTypeCode = storage.data.entity[BankTransactionEntity.BankTransactionType]?.Code;

    if (bankTransactionType === BankTransactionTypeCode.IncomingPayment) {
        return "DebitAccount";
    } else {
        return "CreditAccount";
    }
};


/** In BankTransactions and CashReceipts, if user selected custom account assignments,
 * we want to always change it to the one that corresponds with selected BankAccount/CashBox.
 * This function will return the correct account number;
 * @see https://solitea-cz.atlassian.net/browse/DEV-17687*/
export const getFixedAccountAssignmentAccountNumberForPaymentEntity = (bindingContext: BindingContext, entity: IBankTransactionEntity | ICashReceiptEntity): {
    accountPrefix: string,
    accountSuffix: string
} => {
    const accountPrefix = bindingContext.getRootParent().getPath(true) === EntitySetName.BankTransactions ? BANK_ACCOUNT_BALANCE_SHEET_ACCOUNT_PREFIX.toString() : CASH_BOXES_BALANCE_SHEET_ACCOUNT_PREFIX.toString();
    const accountSuffix = (entity as IBankTransactionEntity).BankStatement?.BankAccount?.BalanceSheetAccountNumberSuffix ?? (entity as ICashReceiptEntity).CashBox?.BalanceSheetAccountNumberSuffix;

    return { accountPrefix, accountSuffix };
};

const getTransactionCurrency = (storage: FormStorage) => {
    return storage.data.entity?.TransactionCurrency?.Code ?? storage.data.entity?.TransactionCurrencyCode;
};

export const refreshDocumentsExRate = async (storage: FormStorage<any, IBankCustomData>, items?: IPaymentDocumentItemEntity[]): Promise<Map<number, number>> => {
    items = items ?? storage.data.entity.Items?.filter((item: IPaymentDocumentItemEntity) => {
        return item.PaymentDocumentItemTypeCode === PaymentDocumentItemTypeCode.Payment && isDefined(item.LinkedDocument?.Id);
    });
    const exRates: Map<number, number> = storage.getCustomData().documentsExchangeRates ?? new Map<number, number>();
    const itemsWithoutRate: IPaymentDocumentItemEntity[] = items?.filter((item: IPaymentDocumentItemEntity) => !exRates?.has(item.Id));
    if (itemsWithoutRate?.length > 0) {
        const rates = await getCorrectExchangeRates(itemsWithoutRate.map(item => ({
            id: item.LinkedDocument?.Id,
            date: item.DateAccountingTransaction
        })), storage.context);

        const newRates = new Map([...exRates, ...rates]);
        storage.setCustomData({ documentsExchangeRates: newRates });

        return newRates;
    }

    return exRates;
};

export const correctGainAccountAssignment = (storage: FormStorage, item: IPaymentDocumentItemEntity): void => {
    const linkedDocId = item?.LinkedDocument?.Id;
    if (linkedDocId) {
        const gain = storage.data.entity.Items?.find((_item: IPaymentDocumentItemEntity) => (_item.PaymentDocumentItemTypeCode === PaymentDocumentItemTypeCode.ExchangeGain || _item.PaymentDocumentItemTypeCode === PaymentDocumentItemTypeCode.ExchangeLoss)
            && _item.LinkedDocument?.Id === linkedDocId);

        if (gain) {
            const propName = gain.PaymentDocumentItemTypeCode === PaymentDocumentItemTypeCode.ExchangeGain ? "DebitAccount" : "CreditAccount";
            const isReceived = (storage.data.entity.TransactionAmount || 0) > 0;
            const sourcePropName = isReceived ? "CreditAccount" : "DebitAccount";

            const path = `AccountAssignmentSelection/AccountAssignment/${propName}`;
            const sourcePath = `AccountAssignmentSelection/AccountAssignment/${sourcePropName}`;

            const val = getNestedValue(sourcePath, item);
            setNestedValue(val, path, gain);

            const id = gain.Id ?? `${BindingContext.NEW_ENTITY_ID_PROP}=${gain[BindingContext.NEW_ENTITY_ID_PROP]}`;
            const _path = `Items(${id})/AccountAssignmentSelection/AccountAssignment/${propName}`;
            const bc = storage.data.bindingContext.navigate(_path);
            storage.setValue(bc, val);

            // we need to invalidate whole fast entry as some lines could be hidden (when clicking "no accounting" f.e.)
            storage.addActiveField(storage.data.bindingContext.navigate("Items"));
        }
    }
};

export const calculateExchangeGainFromItem = async (storage: FormStorage, item: IPaymentDocumentItemEntity, amount: number = item?.TransactionAmount) => {
    if (item.PaymentDocumentItemTypeCode === PaymentDocumentItemTypeCode.Payment) {
        const linkedId = item?.LinkedDocument?.Id;
        if (linkedId) {
            const doc = item.LinkedDocument;
            const docCurrency = doc.TransactionCurrencyCode;
            const tranCurrency = getTransactionCurrency(storage);

            const rates = await refreshDocumentsExRate(storage, [item]);
            const docExchangeRate = rates.get(linkedId) ?? 1;
            const docType = doc.DocumentTypeCode as DocumentTypeCode;

            const hasExchangeRateDiff = hasExchangeGain(docCurrency, docType, storage.context);
            if (hasExchangeRateDiff) {
                const tranExRate = storage.data.entity.ExchangeRatePerUnit;
                const linkExRate = item.ExchangeRate;

                return calculateExchangeRate({
                    docCurrency,
                    tranCurrency,
                    docExchangeRate,
                    docType,
                    tranExRate,
                    currentAmount: amount,
                    ratedAmount: item.ClearedAmount,
                    currentExRate: linkExRate,
                    type: getTransactionTypeFromEntity(storage),
                    context: storage.context
                });
            }
        }
    }

    return null;
};

export const updateSplitFromBe = async (storage: FormStorage, item: IPaymentDocumentItemEntity) => {
    const entity: IBankTransactionEntity = storage.data.entity;
    const docCurrency = item.LinkedDocument?.TransactionCurrencyCode;
    const docType = item.LinkedDocument?.DocumentTypeCode;

    if (hasExchangeGain(docCurrency, docType as DocumentTypeCode, storage.context)) {
        const splitsRequests: TRecordAny[] = [];
        const choAId = getChoAIdBasedOnDate(storage, item.DateAccountingTransaction);

        for (let splitItem of item.SplitAccountAssignments) {
            splitsRequests.push({
                Amount: splitItem.Amount,
                TransactionAmount: splitItem.TransactionAmount,
                ChartOfAccountsId: choAId,
                DebitAccountNumber: splitItem.DebitAccount?.Number,
                CreditAccountNumber: splitItem.CreditAccount?.Number
            });
        }

        const body = {
            Payment: {
                PaymentDate: entity.DateBankTransaction,
                AccountNumber: entity.BankAccount?.AccountNumber,
                CurrencyCode: entity.CurrencyCode,
                TransactionCurrencyCode: entity.TransactionCurrencyCode,
                ExchangeRatePerUnit: entity.ExchangeRatePerUnit,
                IsDebit: entity.BankTransactionTypeCode === BankTransactionTypeCode.OutgoingPayment,
                IsCredit: entity.BankTransactionTypeCode === BankTransactionTypeCode.IncomingPayment
            },
            PaymentItems: [
                {
                    LinkedDocumentId: item.LinkedDocument?.Id,
                    Amount: item.Amount,
                    TransactionAmount: item.TransactionAmount,
                    ClearedAmount: item.ClearedAmount ?? item.TransactionAmount,
                    DateAccountingTransaction: item.DateAccountingTransaction,
                    AccountAssignmentSelection: {
                        SelectionCode: SelectionCode.Split
                    },
                    SplitAccountAssignments: splitsRequests
                }
            ]
        };

        const url = `${UPDATE_CLEARING_SPLIT_ITEMS}?CompanyId=${storage.context.getCompany().Id}`;

        const response = await fetch(url, {
            ...getDefaultPostParams(),
            body: JSON.stringify(body)
        });

        const results = await response.json();

        if (!response.ok) {
            return parseError(results);
        }

        const gains: IPaymentDocumentItemEntity[] = [];
        const gainsResults = results?.[0]?.ExchangeRateDifferences;

        const items = storage.data.entity.Items.filter((_item: IPaymentDocumentItemEntity) => _item.LinkedDocument?.Id !== item.LinkedDocument.Id
            || _item.PaymentDocumentItemTypeCode === PaymentDocumentItemTypeCode.Payment);

        let maxId = getNewItemsMaxId(items) + 1;

        for (let gain of gainsResults || []) {
            const accAss = gain.AccountAssignmentSelection.AccountAssignment;
            gains.push({
                Amount: gain.Amount,
                LinkedDocument: { ...item.LinkedDocument },
                DateAccountingTransaction: item.DateAccountingTransaction,
                AccountAssignmentSelection: {
                    AccountAssignment: {
                        Id: SelectionCode.Own as any as number,
                        ChartOfAccounts: {
                            Id: accAss.ChartOfAccounts.Id
                        },
                        CreditAccount: {
                            Id: accAss.CreditAccount.Id,
                            Name: accAss.CreditAccount.Name,
                            Number: accAss.CreditAccount.Number
                        },
                        DebitAccount: {
                            Id: accAss.DebitAccount.Id,
                            Name: accAss.DebitAccount.Name,
                            Number: accAss.DebitAccount.Number
                        },
                        Name: accAss.Name,
                        ShortName: accAss.ShortName
                    },
                    Selection: {
                        Code: SelectionCode.Own
                    }
                },
                [BindingContext.NEW_ENTITY_ID_PROP]: maxId++,
                PaymentDocumentItemTypeCode: gain.PaymentDocumentItemTypeCode,
                TransactionAmount: gain.TransactionAmount
            });
        }

        storage.data.entity.Items = items.concat(gains);
    }
    return undefined;
};

export const updateSplitGains = async (storage: FormStorage, item: IPaymentDocumentItemEntity): Promise<void> => {
    const items = storage.data.entity.Items.filter((_item: IPaymentDocumentItemEntity) => _item.LinkedDocument?.Id !== item.LinkedDocument.Id
        || _item.PaymentDocumentItemTypeCode === PaymentDocumentItemTypeCode.Payment);

    storage.data.entity.Items = items.concat(await getSplitGains(storage, item, getNewItemsMaxId(items) + 1));
};

export const getSplitGains = async (storage: FormStorage, item: IPaymentDocumentItemEntity, maxId: number) => {
    const exRateDiff = await calculateExchangeGainFromItem(storage, item);
    const items: IPaymentDocumentItemEntity[] = [];

    if (exRateDiff) {
        const rowAmount = item?.TransactionAmount;

        for (let i = 0; i < (item.SplitAccountAssignments || []).length; i++) {
            const split = item.SplitAccountAssignments[i];
            const rate = split.TransactionAmount / rowAmount;
            const amount = roundToDecimalPlaces(2, exRateDiff * rate);

            const gain = await createNewGain({
                item,
                amount,
                storage,
                newMax: maxId + i,
                splitItem: split,
                date: item.DateAccountingTransaction
            });

            items.push(gain);
        }
    }

    return items;
};

export const getCorrectTranAmount = (storage: FormStorage, docId: number, docAmount: number, docCurrency: string): number => {
    // status 'Needs Approval' indicates document is not yet posted by the transaction value so skip this routine
    // can be called for internal document too, but it is undefined then and we can skip it
    const needsApproval = storage.data.entity.PaymentStatusCode === PaymentStatusCode.NeedsApproval;
    if (needsApproval) {
        return docAmount;
    }

    const entity: IBankTransactionEntity = storage.data.origEntity;
    const paired = entity.Items?.find(item => item.LinkedDocument?.Id === docId &&
        // internal document does not have documentItemTypeCode so we check for undefined
        (isNotDefined(item.PaymentDocumentItemTypeCode) || item.PaymentDocumentItemTypeCode === PaymentDocumentItemTypeCode.Payment));

    if (paired) {
        // Cleared amount -- ALWAYS in document currency
        // this should clear cases with different NON CZK currencies (EUR-$)
        if (paired.ClearedAmount) {
            return docAmount + paired.ClearedAmount;
        }

        return docAmount + (docCurrency === getCompanyCurrency(storage.context) ? paired.Amount : paired.TransactionAmount);
    }

    return docAmount;
};

export const handleItemAmountBlur = async (storage: FormStorage, args: ISmartFieldBlur): Promise<void> => {
    const path = args.bindingContext.getPath();

    if (path === "Amount" || path === "TransactionAmount" || path === "ExchangeRatePerUnit") {
        storage.addActiveField(storage.data.bindingContext.navigate("Items"));
    }
};

interface ICorrectExchangeRateData {
    id: number;
    date: Date;
    currency?: string;
}

export const getCorrectExchangeRate = async (data: ICorrectExchangeRateData, context: IAppContext): Promise<number> => {
    const rates = await getCorrectExchangeRates([data], context);
    if (rates) {
        return rates.get(data.id) ?? 1;
    }

    return 1;
};

export const getCorrectExchangeRates = async (data: ICorrectExchangeRateData[], context: IAppContext): Promise<Map<number, number>> => {
    const formattedData = data?.filter(item => {
        return item.currency !== getCompanyCurrency(context);
    }).map(item => {
        return {
            DocumentId: item.id,
            DateTransaction: formatDateToDateString(item.date)
        };
    });

    const rates = new Map<number, number>();
    if (formattedData?.length > 0) {
        const response = await customFetch(`${REST_API_URL}/ExchangeRate/RatesForDocuments`, {
            ...getDefaultPostParams(),
            body: JSON.stringify({ DocumentDatesPair: formattedData })
        });

        const results = await response.json();
        for (const res of results?.DocumentRatesPair || []) {
            rates.set(res.DocumentId, res.ExchangeRatePerUnit);
        }
    }

    return rates;
};

export const itemHasAssignment = (item: IPaymentDocumentItemEntity, items: IPaymentDocumentItemEntity[]): boolean => {
    const docId = item.LinkedDocument?.Id;
    const payment = items.find(_i => _i.PaymentDocumentItemTypeCode === PaymentDocumentItemTypeCode.Payment && _i.LinkedDocument?.Id === docId);
    return !(payment?.AccountAssignmentSelection?.Selection?.Code === SelectionCode.None);
};

export const addExpenseGainIfMissing = async (storage: FormStorage, item: IPaymentDocumentItemEntity): Promise<void> => {
    const gains = getExpenseGains(storage, item?.LinkedDocument?.Id);
    if (!gains?.length) {
        const amount = await calculateExchangeGainFromItem(storage, item);
        if (amount) {
            const gain = await createNewGain({
                item,
                amount,
                storage,
                newMax: getNewItemsMaxId(storage.data.entity.Items) + 1,
                date: item.DateAccountingTransaction
            });

            storage.data.entity.Items.push(gain);
            storage.refresh();
        }
    }
};

export const deleteAdditionalGains = async (storage: FormStorage, item: IPaymentDocumentItemEntity, oldType: TValue) => {
    if (oldType === SelectionCode.Split) {
        const linkedDocId = item?.LinkedDocument?.Id;

        let foundPayment = false;

        // a bit clunky, what we want is to make sure there is only one Linked Document loss(gain) row
        storage.data.entity.Items = storage.data.entity.Items?.filter((_item: IPaymentDocumentItemEntity) => {
            const isLinkedPayemt = (_item.PaymentDocumentItemTypeCode === PaymentDocumentItemTypeCode.ExchangeGain
                || _item.PaymentDocumentItemTypeCode === PaymentDocumentItemTypeCode.ExchangeLoss) && _item.LinkedDocument?.Id === linkedDocId;

            let result = true;
            if (isLinkedPayemt) {
                result = !foundPayment;
                foundPayment = true;
            }

            return result;
        });

        // recalculate
        const gain = getExpenseGains(storage, item?.LinkedDocument?.Id)[0];
        if (!gain) {
            await addExpenseGainIfMissing(storage, item);
        } else {
            const amount = await calculateExchangeGainFromItem(storage, item);
            const currency = storage.data.entity?.TransactionCurrency?.Code;

            gain.Amount = amount;
            gain.TransactionAmount = currency === getCompanyCurrency(storage.context) ? amount : 0;
        }
    }
};

export const handleBankAssignmentChange = async (args: ISmartFieldChange, storage: FormStorage): Promise<void> => {
    if (args.bindingContext?.getPath() === "AccountAssignment") {
        const { bindingContext } = args;
        const itemBc = bindingContext.getParent().getParent();
        const item = storage.getValue(itemBc);

        const oldType = item?.AccountAssignmentSelection?.Selection?.Code;

        handleItemAccAssignmentChange({
            storage,
            e: args
        });

        const items = storage.data.entity.Items;

        await deleteAdditionalGains(storage, item, oldType);
        correctGainAccountAssignment(storage, item);

        // clear all gains and losses assigned to changed pair
        if (args.value === SelectionCode.None) {
            storage.data.entity.Items = items.filter((_item: IPaymentDocumentItemEntity) => {
                if (isPaymentOrOther(_item.PaymentDocumentItemTypeCode)) {
                    return true;
                }

                return item.LinkedDocument?.Id !== _item.LinkedDocument?.Id;
            });

            storage.refresh();
        }

        if (args.value !== SelectionCode.None) {
            addExpenseGainIfMissing(storage, item);
        }
    }
};

export const isPaymentOrOther = (type: string): boolean => {
    return type === PaymentDocumentItemTypeCode.Other || type === PaymentDocumentItemTypeCode.Payment;
};

interface IPairDataForRow {
    originalTransactionAmount: number;
    amounts: TRecordAny;
    docAmount: number;
    docExchangeRate: number;
    docCurrency: string;
    tranExRate: number;
    tranCurrency: string;
    defaultExchangeRate: number;
    tranType: BankTransactionTypeCode;
    docType: DocumentTypeCode;
}

export const getPairDataForRow = (args: IPairDataForRow, context: IAppContext) => {

    // Transaction Amount - already paid amount
    const transAmount = args.originalTransactionAmount - args.amounts.transactionAmount;

    let amount, transactionAmount;
    // we convert different currencies to CZK (or system) currency and then we compare them
    const docAmountCZK = args.docAmount * args.docExchangeRate;
    const transAmountCZK = transAmount * args.tranExRate;

    if (args.docCurrency !== args.tranCurrency) {
        let exchangedDocAmount = formatNumberCurrency(docAmountCZK / args.tranExRate);
        const reverted = formatNumberCurrency(exchangedDocAmount * args.tranExRate);

        if (reverted > docAmountCZK) {
            // with trunc we make sure truncated amount is always lower then the doc amount
            // f.e. 1$ ->25.888CZK should not make 25.89 as in revert it would make 1.01$
            // we are fine with 25.88 and a bit in exchange rate gain(loss)

            exchangedDocAmount = Math.trunc(docAmountCZK / args.tranExRate * 100) / 100;
        }


        if (docAmountCZK < transAmountCZK) {
            amount = docAmountCZK;
            transactionAmount = exchangedDocAmount;
        } else {
            amount = transAmountCZK;
            transactionAmount = transAmount;
        }
    } else {
        const value = Math.min(transAmount, args.docAmount);
        amount = formatNumberCurrency(value * args.tranExRate);
        transactionAmount = value;
    }

    const _hasExchangeGain = hasExchangeGain(args.docCurrency, args.docType, context);
    const _hasAdditionalExRate = hasAdditionalExRate(args.docCurrency, args.tranCurrency, context);

    let exchangeGain = null;
    let ratedAmount;
    if (_hasAdditionalExRate && args.defaultExchangeRate) {
        ratedAmount = formatNumberCurrency(transactionAmount * args.tranExRate / args.defaultExchangeRate);
        if (docAmountCZK < transAmountCZK) {
            ratedAmount = formatNumberCurrency(args.docAmount);
            amount = formatNumberCurrency(ratedAmount * args.defaultExchangeRate);
            transactionAmount = formatNumberCurrency(amount / (args.tranExRate ?? 1));
        }

        exchangeGain = calculateExchangeRate({
            docCurrency: args.docCurrency,
            tranCurrency: args.tranCurrency,
            tranExRate: args.tranExRate,
            docExchangeRate: args.docExchangeRate,
            ratedAmount,
            docType: args.docType,
            currentExRate: args.defaultExchangeRate,
            currentAmount: transactionAmount,
            type: args.tranType,
            context
        });
    } else if (_hasExchangeGain) {
        exchangeGain = calculateExchangeRate({
            docCurrency: args.docCurrency,
            tranCurrency: args.tranCurrency,
            tranExRate: args.tranExRate,
            docType: args.docType,
            docExchangeRate: args.docExchangeRate,
            currentAmount: transactionAmount,
            type: args.tranType,
            context
        });
    }

    return {
        amount,
        transactionAmount,
        exchangeGain,
        ratedAmount
    };
};

export enum ValidateMaxType {
    Amount = "amount",
    Gain = "gain"
}

export interface IValidateMaxArgs {
    type: ValidateMaxType;
    amount: number;
    docAmount: number;
    tranExRate: number;
    docExRate: number;
    docCurrency: string;
    tranCurrency: string;
    tranAmount: number;

    clearingByID?: boolean;
    t?: TFunction;
}

export const createConditionForPairTable = (storage: TableStorage<unknown, IBankCustomData>, appendOriginalDataValues = true) => {
    const pairedDocuments = storage.getCustomData().pairedDocuments;
    const ids: string[] = Object.keys(pairedDocuments || {});
    return ids?.length > 0 ? `Id in (${ids})` : "";

    // till it is really clear let this be here..... this takes into default filter paired items from original entity too
    // const formStorage = storage.getCustomData().rootStorage;
    // const origEntity: TRecordAny = formStorage.data.origEntity;
    //
    // let idsOrig = appendOriginalDataValues ? (origEntity.Items as IPaymentDocumentItemEntity[])?.filter(item => item.LinkedDocument?.Id)
    //     .map((item: IPaymentDocumentItemEntity) => item.LinkedDocument?.Id) || [] : [];
    //
    // const pairedDocuments = storage.getCustomData().pairedDocuments;
    // const ids: string[] = Object.keys(pairedDocuments || {});
    //
    // const allIds = [...ids, ...idsOrig];
    // return allIds?.length > 0 ? `Id in (${allIds})` : "";
};

export const validateMaxData = (args: IValidateMaxArgs, context: IAppContext) => {
    if (args.type === ValidateMaxType.Amount) {
        return validateMaxAmount(args, context);
    }

    if (args.type === ValidateMaxType.Gain) {
        // for gain we only validate document amount as transaction is validated for "amount"
        return validateMaxDocAmount(args, context);
    }

    return null;
};

const validateMaxAmount = (args: IValidateMaxArgs, context: IAppContext) => {
    if (args.clearingByID) {
        return validateMaxDocAmount(args, context);
    }

    // if there is additional exchange rate row
    // we validate only against transaction
    const hasAddExRate = hasAdditionalExRate(args.docCurrency, args.tranCurrency, context);
    if (hasAddExRate) {
        return validateMaxTranAmount(args);
    }

    // otherwise we validate both document transaction
    return validateMaxDocAmount(args, context) || validateMaxTranAmount(args);
};

const validateMaxDocAmount = (args: IValidateMaxArgs, context: IAppContext) => {
    // can be called for either amount or gain validation (amount vs rated amount)
    // so this condition covers both cases
    const withoutExRate = hasAdditionalExRate(args.docCurrency, args.tranCurrency, context) || args.docCurrency === args.tranCurrency;
    const amount = roundToDecimalPlaces(2, args.amount * (withoutExRate ? 1 : args.tranExRate));
    const docAmount = roundToDecimalPlaces(2, args.docAmount * (withoutExRate ? 1 : args.docExRate));

    if (Math.abs(amount) > Math.abs(docAmount)) {
        return {
            message: args.t?.("Banks:Pairing.AmountToHigh"),
            errorType: ValidationErrorType.Field
        };
    }

    return null;
};

const validateMaxTranAmount = (args: IValidateMaxArgs) => {
    const amount = args.amount;
    const transactionAmount = Math.abs(args.tranAmount);
    if (amount > transactionAmount) {
        return {
            message: args.t?.("Banks:Pairing.AmountToHighTran"),
            errorType: ValidationErrorType.Field
        };
    }

    return null;
};


