import React from "react";
import { AppContext, ContextEvents, IAppContext } from "../../contexts/appContext/AppContext.types";

import {
    CloseButton,
    HomeEditButtonFooter,
    HomeSmartHeaderStyled,
    InnerWrapper,
    StyledHome,
    StyledHomeBackground,
    UserResearchWrapper
} from "./Home.style";
import DashboardBackground from "../../components/navigation/DashboardBackground";
import { CenteredTextPage } from "../../global.style";
import { WithTranslation, withTranslation } from "react-i18next";
import { IHeaderIcon } from "@components/header/Header";
import { getDashboardDefinition as getAgendaDef } from "./AgendaDashboardDef";
import { getDashboardDefinition as getTenantDef } from "./TenantDashboardDef";
import { getDashboardDefinition as getCustomerDef } from "./CustomerDashboardDef";
import { VIEW_PADDING_VALUE } from "../../views/View.styles";
import { TilePadding } from "@components/dashboard/Dashboard.styles";
import { ScrollBar } from "../../components/scrollBar";
import HomeDashboard from "./HomeDashboard";
import { Button } from "../../components/button";
import { ExtendedShellContent } from "../../views/main/Shell.types";
import { DashboardManagerFactory, IDashboardManager } from "./DashboardManager";
import { forEachKey } from "@utils/general";
import { getVisibleDashboards, isInCustomerPortal } from "./Home.utils";
import { SvgImage } from "@components/svgImage/SvgImage";
import userResearchSvgPath from "../../svg/misc/userResearch.svg";
import { HOTSPOT_VIEW_ATTR } from "@components/hotspots/Hotspots.utils";
import { CustomerCompanySelector } from "../../components/navigation/CompanySelector";
import CloseCustomerPortalButton from "./CloseCustomerPortalButton";
import TestIds from "../../testIds";
import { getEnumNameSpaceName } from "@odata/GeneratedEnums.utils";
import { EntityTypeName } from "@odata/GeneratedEntityTypes";
import { TRecordType } from "../../global.types";
import { IDashboardDefinition } from "@components/dashboard";
import { withTheme } from "styled-components/macro";
import { PropsWithTheme } from "../../theme";
import { setChartTheme } from "@components/charts/Charts.types";

export const HomeDashboards: TRecordType<() => IDashboardDefinition> = {
    Agenda: getAgendaDef,
    Customer: getCustomerDef,
    Tenant: getTenantDef
};

interface IProps extends WithTranslation, PropsWithTheme {
}

interface IState {
    isLoading?: boolean;
    managers?: IDashboardManager[];
    inEditMode?: boolean;
}

class Home extends React.PureComponent<IProps, IState> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;

    _isMounted = false;
    // _scrollBarInstanceRef = React.createRef<SimpleBar>();
    state: IState = {
        isLoading: true,
        managers: []
    };

    constructor(props: IProps, context: IAppContext) {
        super(props);

        context.eventEmitter.on(ContextEvents.ExtendedShellHidden, this.handleShellClosed);

        // todo: move to chart provider or something like that
        setChartTheme(props.theme);
    }

    componentDidMount() {
        this._isMounted = true;

        if (this.props.tReady) {
            this.loadDashboards();
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
        if (!prevProps.tReady && this.props.tReady) {
            this.loadDashboards();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.context.eventEmitter.off(ContextEvents.ExtendedShellHidden, this.handleSettingsDone);
    }

    get headerIcons(): IHeaderIcon[] {
        return [{
            id: "Settings",
            onClick: this.handleSettingsIconClick,
            label: this.props.t("Home:Settings"),
            iconName: "Settings"
        }];
    }

    async loadDashboards() {
        const promises: Promise<IDashboardManager>[] = [];
        forEachKey(HomeDashboards, (id) => {
            promises.push(DashboardManagerFactory(id, HomeDashboards[id], this.context));
        });

        const managers = await Promise.all(promises);

        if (this._isMounted) {
            this.setState({
                isLoading: false,
                managers
            });
        }
    }

    handleSettingsIconClick = () => {
        if (!this.state.inEditMode) {
            getVisibleDashboards(this.state.managers, this.context)
                .forEach(manager => manager.setOriginalConfig());

            this.context.openExtendedShell(ExtendedShellContent.DashboardSettings);
            this.setState({ inEditMode: true });
        }
    };

    handleShellClosed = () => {
        // todo: possibly also save settings? Or dashboard config is applied immediately?
        this.setState({ inEditMode: false });
    };

    handleSettingsCancel = () => {
        getVisibleDashboards(this.state.managers, this.context)
            .forEach(manager => manager.restoreOriginalConfig());

        this.context.openExtendedShell(null);
        this.setState({inEditMode: false});
    };


    handleSettingsDone = () => {
        getVisibleDashboards(this.state.managers, this.context)
            .forEach(manager => manager.sync());

        this.context.openExtendedShell(null);
        this.handleShellClosed();
    };

    renderCustomerPortalHeader(): React.ReactNode {
        return (<>
            <HomeSmartHeaderStyled customHeaderContent={<CustomerCompanySelector/>}
                                   hotspotId={"DASHBOARD_HEADER"}
                                   icons={this.headerIcons}
                                   shouldHideVariant
                                   hideBreadcrumbs/>
            <CloseCustomerPortalButton/>
        </>);
    }

    renderHeader(): React.ReactNode {
        return (
            <HomeSmartHeaderStyled title={this.props.t("Home:Title")}
                                   hotspotId={"DASHBOARD_HEADER"}
                                   icons={this.headerIcons}
                                   shouldHideVariant
                                   hideBreadcrumbs/>
        );
    }

    renderDashboardPage(): React.ReactNode {
        const visibleDashboards = getVisibleDashboards(this.state.managers, this.context);
        const _isInCustomerPortal = isInCustomerPortal();

        return (
            <StyledHomeBackground {...{ [HOTSPOT_VIEW_ATTR]: "homeView" }} data-testid={TestIds.View}>
                {_isInCustomerPortal ? this.renderCustomerPortalHeader() : this.renderHeader()}
                <StyledHome $inEditMode={this.state.inEditMode}>
                    <ScrollBar primary style={{
                        padding: `${TilePadding}px ${VIEW_PADDING_VALUE}px ${TilePadding}px`
                    }}>
                        {visibleDashboards.map(manager => (
                            <HomeDashboard key={manager.name}
                                           manager={manager}
                                           showHeader={visibleDashboards.length > 1}
                                           inEditMode={this.state.inEditMode}/>
                        ))}
                    </ScrollBar>
                </StyledHome>
                {this.state.inEditMode && (
                    <HomeEditButtonFooter>
                        <CloseButton onClick={this.handleSettingsCancel}
                                isLight ignoreTheme>
                            {this.props.t("Common:General.Cancel")}
                        </CloseButton>

                        <Button onClick={this.handleSettingsDone}
                                isLight ignoreTheme>
                            {this.props.t("Home:Done")}
                        </Button>
                    </HomeEditButtonFooter>
                )}
                {/* isOverContent means that it's positioned over StyledHomeBackground, but the actual
                  content - StyledHome - is positioned even higher */}
                <DashboardBackground isOverContent/>
            </StyledHomeBackground>
        );
    }

    renderUserResearchPage = () => {
        return (
            <CenteredTextPage>
                <InnerWrapper>
                    <h1>Evala vás vítá na uživatelském testování!</h1>
                    <h3>Velmi si váží vašeho času a zpětné vazby, aby se mohla zlepšovat a za nějaký čas Vám dobře
                        sloužit.</h3>
                    <UserResearchWrapper>
                        <SvgImage src={userResearchSvgPath}/>
                    </UserResearchWrapper>
                </InnerWrapper>
                <DashboardBackground isTransparent/>
            </CenteredTextPage>
        );
    };

    render() {
        if (!this.props.tReady) {
            return null;
        }
        return this.renderDashboardPage();
    }
}

export default withTheme(withTranslation([
    "Home", "Common",
    getEnumNameSpaceName(EntityTypeName.Accounting),
    getEnumNameSpaceName(EntityTypeName.ElectronicSubmissionType)]
)(Home));