import { TRecordAny, TRecordType, TRecordValue } from "../global.types";
import { IReportColumnDef } from "@components/smart/smartTable";
import { IFormGroupDef } from "@components/smart/smartFormGroup/SmartFormGroup";
import { ITableVariant } from "@components/variantSelector/VariantOdata";

export const LOCAL_SETTINGS_KEY = "local_settings";

export interface ICustomizationVariant {
    id: string;
    title: string;
    groups: IFormGroupDef[];
}

export interface ISplitPageSettings {
    isCollapsed?: boolean;
    width?: number;
}

export interface ILocalSettings {
    filters?: TRecordValue;
    selectedTab?: string;
    reportColumns?: IReportColumnDef[];
    customData?: TRecordAny;
    formVariant?: IFormGroupDef[];
    tableVariant?: ITableVariant;
    splitPageSettings?: ISplitPageSettings;
    openedRowsIds?: Record<string, boolean>;
    isFirstRenderAfterLogin?: boolean;
}

interface ILocalSettingsObject<T> {
    get: (id: string) => Partial<T>;
    set: <K extends keyof T>(id: string, settings: (Pick<T, K> | T | null), forceSync?: boolean) => void;
    remove: (id: string, path: keyof T) => void;
    clear: () => void;
    sync: () => void;
    STORAGE_KEY: string;
}

export class LocalSettingsManager {
    static settings: ILocalSettingsObject<unknown>[] = [];

    static clear(): void {
        LocalSettingsManager.settings.forEach(settings => settings.clear());
    }

    static register(settings: ILocalSettingsObject<unknown>): void {
        LocalSettingsManager.settings.push(settings);
    }
}

export function LocalSettingsFactory<Type>(KEY: string): ILocalSettingsObject<Type> {

    let settingsCache: TRecordType<Type>;

    const _getSettings = (): TRecordType<Type> => {
        if (settingsCache) {
            return settingsCache;
        }

        const settingsString = localStorage.getItem(KEY);

        if (settingsString) {
            try {
                settingsCache = JSON.parse(settingsString);
            } catch (e) {
                settingsCache = {};
            }
        } else {
            settingsCache = {};
        }

        return settingsCache;
    };

    const get = (id: string): Partial<Type> => {
        return _getSettings()[id] ?? {};
    };

    const remove = (id: string, path: keyof Type) => {
        const allSettings = _getSettings();
        delete allSettings[id]?.[path];
        sync();
    };

    const set = <K extends keyof Type>(id: string, settings: (Pick<Type, K> | Type | null), forceSync?: boolean) => {
        const allSettings = _getSettings();

        allSettings[id] = {
            ...allSettings[id],
            ...settings
        };

        if (forceSync) {
            sync();
        }

        // todo remove when resolved problem with cypress clear storage
        sync();
    };

    /** Removes all stored settings from localStorage */
    const clear = () => {
        settingsCache = null;
        localStorage.removeItem(KEY);
    };

    /** Update localstorage with current settings */
    const sync = () => {
        if (settingsCache) {
            localStorage.setItem(KEY, JSON.stringify(settingsCache));
        }
    };

    /** Automatically sync settings into localStorage before page change/close */
    window.addEventListener("beforeunload", (e: BeforeUnloadEvent) => {
        // causes problem with cypress
        // cypress clears localStorage before each test
        // BUT the beforeEach call is called BEFORE 'beforeunload' event meaning the storage is cleared then again synced
        // sync();
    });

    // If there is a change outside of this window, clear the cache - e.g. setSystemToday in tests, but might also have
    // impact in real app used in different tabs
    window.addEventListener("storage", ({ key }) => {
        if (key === KEY) {
            settingsCache = null;
        }
    });

    const settings = { get, set, remove, clear, sync, STORAGE_KEY: KEY };
    LocalSettingsManager.register(settings);
    return settings;
}

const LocalSettings = LocalSettingsFactory<ILocalSettings>(LOCAL_SETTINGS_KEY);

export default LocalSettings;

export const DEVEL_SETTINGS_KEY = "devel";
export const DevelLocalSettings = LocalSettingsFactory<{
    timeTravelDate?: string;
}>(DEVEL_SETTINGS_KEY);