import styled, { css } from "styled-components/macro";
import { fadeInAnimation, LayoutStackOrder } from "../../global.style";

export const TooltipHorizontalPadding = 10;
export const TooltipPaddingFromPage = 19;

export const StyledTooltip = styled.div<{
    noBackground?: boolean
}>`
    ${fadeInAnimation};
    font-size: 10px;
    width: fit-content;
    min-width: min-content;
    max-width: 400px;
    z-index: ${LayoutStackOrder.Tooltips};
    white-space: pre-wrap;
    ${props => !props.noBackground && css`
        background-color: ${props.theme.C_BG_tooltip};
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.24);
        border-radius: 3px;
        padding: 3px ${TooltipHorizontalPadding}px;
    `};
`;