import { withTranslation, WithTranslation } from "react-i18next";
import { withTheme } from "styled-components/macro";
import React from "react";
import { KeyName } from "../../keyName";
import { getCompanyLogoUrl } from "@utils/CompanyUtils";
import {
    AvatarButton,
    Body,
    BoldLabel,
    CompanyActionWrapper,
    CompanyTileAvatar,
    Content,
    DeleteRequestedText,
    Footer,
    Header,
    LegalNumber,
    StyledCompanyTile
} from "./CompanyDashboard.styles";
import TestIds from "../../testIds";
import { AvatarSize, IconSize } from "../../enums";
import { CustomerPortalIcon, IProps as IIconProps } from "../../components/icon";
import { PropsWithTheme } from "../../theme";
import { CompanyStateCode } from "@odata/GeneratedEnums";
import { ICompany } from "@odata/EntityTypes";
import { ROUTE_SWITCH_TO_CUSTOMER_PORTAL } from "../../routes";
import { IconButton } from "@components/button";


export interface ICompanyTileProps {
    company: ICompany;
    // color: keyof DefaultTheme;
    isSelected: boolean;
    isDisabled?: boolean;
    isDeleteRequested?: boolean;
    onClick: (id: number, ref: React.RefObject<HTMLElement>) => void;
    onEdit: (id: number) => void;

    // action handling
    /** "Active" action that can be clicked on. */
    actionIcon: React.ComponentType<IIconProps>;
    /** black icon that represents current state the company tile is in (archived). Cannot be used together with actionIcon. */
    statusIcon: React.ComponentType<IIconProps>;
    /** Used for both actionIcon and statusIcon - only one of them can be used at one time. */
    iconTitle?: string;

    onActionClick: (id: number, event: React.MouseEvent) => void;
}

class CompanyTile extends React.PureComponent<ICompanyTileProps & WithTranslation & PropsWithTheme> {
    private _ref = React.createRef<HTMLInputElement>();
    handleEdit = (e: React.MouseEvent) => {
        e.stopPropagation();
        this.props.onEdit(this.props.company.Id);
    };

    handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.target === e.currentTarget && // don't steal clicks from inner button
            (e.key === KeyName.Enter || e.key === KeyName.Space)) {
            e.preventDefault();
            this.props.onClick?.(this.props.company.Id, this._ref);
        }
    };

    handleSwitchToCustomerPortal = (event: React.MouseEvent) => {
        // we switch to customer portal using router navigation - prevent duplicate
        // handling of the click on the whole CompanyTile
        event.stopPropagation();
    };

    handleActionClick = (event: React.MouseEvent) => {
        this.props.onActionClick?.(this.props.company.Id, event);
    };

    renderIcon = (): React.ReactElement => {
        const Icon = this.props.actionIcon ?? this.props.statusIcon;

        if (!Icon) {
            return null;
        }

        const title = this.props.iconTitle;
        const icClickable = !!this.props.actionIcon;
        let content = <Icon width={IconSize.S}
                            color={icClickable ? null : "C_BTN_hover_light"}
                            title={icClickable ? null : title}
                            preventHover={!icClickable}/>;

        if (icClickable) {
            content = (
                <IconButton onClick={this.handleActionClick}
                            title={title}
                            isDecorative>
                    {content}
                </IconButton>
            );
        }

        content = (
            <CompanyActionWrapper>
                {content}
            </CompanyActionWrapper>
        );

        return content;
    };

    render() {
        const { company } = this.props;
        const { Id } = company;
        const logoUrl = getCompanyLogoUrl(company);
        const footerText = `${company.Accounting?.Name ?? ""}`;
        const isInitialized = company.StateCode !== CompanyStateCode.New;
        const isArchived = company.StateCode === CompanyStateCode.Archived;
        const isDeleteRequested = this.props.isDeleteRequested;

        const isGray = !isInitialized || isDeleteRequested || isArchived;

        const showCustomerPortalButton = isInitialized && company.CanAccessCustomerPortal;
        const isTileClickable = !this.props.actionIcon && !this.props.isDisabled;

        return (
            <StyledCompanyTile
                ref={this._ref}
                tabIndex={0}
                onKeyDown={this.handleKeyDown}
                onClick={this.props.onClick.bind(this, Id, this._ref)}
                _isSelected={this.props.isSelected}
                _color={this.props.company.ColorCode}
                _isGray={isGray}
                _isWithoutPointer={!isTileClickable}
                data-companyid={Id}
                data-testid={TestIds.CompanyTile}>
                {this.renderIcon()}
                <Content
                    hasAction={!!this.props.actionIcon}
                    isDisabled={this.props.isDisabled}
                    data-testid={TestIds.CompanyTileContent}
                >
                    <Header data-testid={TestIds.CompanyTileHeader}>
                        <CompanyTileAvatar src={logoUrl}
                                           size={AvatarSize.XL}
                                           _isWithoutHover={this.props.isSelected || !isTileClickable}/>
                        {/*{!isDeleteRequested &&*/}
                        {/*    <AvatarButton*/}
                        {/*        title={this.props.t("Common:General.Edit")} onClick={this.handleEdit}>*/}
                        {/*        <AgendaIcon width={IconSize.M} isLight/>*/}
                        {/*    </AvatarButton>*/}
                        {/*}*/}
                        {showCustomerPortalButton && (
                            <AvatarButton link={`${ROUTE_SWITCH_TO_CUSTOMER_PORTAL}/${Id}`}
                                          onClick={this.handleSwitchToCustomerPortal}
                                          title={this.props.t("Common:General.SwitchToCustomerPortal")}>
                                <CustomerPortalIcon width={IconSize.M} isLight/>
                            </AvatarButton>
                        )}
                    </Header>
                    <Body data-testid={TestIds.CompanyTileBody}>
                        <BoldLabel title={company.Name}
                                   _color={this.props.company.ColorCode}
                                   _isGray={isGray}>
                            {company.Name}
                        </BoldLabel>
                        {isInitialized && !isDeleteRequested &&
                            <LegalNumber title={company.LegalNumber} onClick={e => e.stopPropagation()}>
                                {company.LegalNumber}
                            </LegalNumber>
                        }
                        {(!isInitialized || isArchived) &&
                            <BoldLabel title={this.props.t(`Common:General.${isArchived ? "Archived" : "Inactive"}`)}
                                       _color={this.props.company.ColorCode}
                                       _isGray={isGray}
                                       style={{
                                           marginTop: "12px"
                                       }}>
                                {this.props.t(`Common:General.${isArchived ? "Archived" : "Inactive"}`)}
                            </BoldLabel>
                        }
                    </Body>
                    {!isGray &&
                        <Footer data-testid={TestIds.CompanyTileFooter} title={footerText}>
                            {footerText}
                        </Footer>
                    }
                    {isDeleteRequested &&
                        <DeleteRequestedText _color={this.props.company.ColorCode}>
                            {this.props.t("Companies:CompanyTile.WillBeDeletedAfterMailConfirm")}
                        </DeleteRequestedText>
                    }
                </Content>
            </StyledCompanyTile>
        );
    }
}

export default withTranslation(["Common", "Components"])(withTheme(CompanyTile));