import React, { memo, useContext, useEffect, useMemo, useState } from "react";
import { DashboardTileType, IDashboardTileComponentProps, IInfoTileInfo } from "../../dashboard";
import { useTranslation } from "react-i18next";
import { withOData, WithOData } from "@odata/withOData";
import DashboardTileHeader from "../../dashboard/DashboardTileHeader";
import { getIncomeExpenseOverviewData, IIncomeExpenseOverviewData } from "./IncomeExpenseOverview.utils";
import { AppContext } from "../../../contexts/appContext/AppContext.types";
import {
    GroupedInfoTileBackground,
    GroupedInfoTileContentWrapper,
    GroupInfoTileHeader
} from "../infoTile/InfoTile.styles";
import { Status } from "../../../enums";
import { ROUTE_CBA_ENTRY_LEDGER } from "../../../routes";
import { CbaEntryLedgerVariants } from "@pages/reports/cbaEntryLedger/CbaEntryLedgerDef";
import { getDrillDownNavParams } from "../../drillDown/DrillDown.utils";
import InfoTile from "../infoTile/InfoTile";

interface IProps extends IDashboardTileComponentProps, WithOData {
}

const IncomeExpenseOverview: React.FC<IProps> = ({ inEditMode }) => {
    const { t } = useTranslation("Home");
    const context = useContext(AppContext);


    const [loadedData, setData] = useState<IIncomeExpenseOverviewData>(null);
    const [isLoading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        let mounted = true;

        try {
            getIncomeExpenseOverviewData(context)
                    .then(data => mounted && setData(data))
                    .finally(() => mounted && setLoading(false));
        } catch (e) {
            if (mounted) {
                setData(null);
            }
        }

        return () => {
            mounted = false;
        };
    }, [context]);

    const incomeInfo: IInfoTileInfo = useMemo(() => ({
        type: DashboardTileType.Info,
        size: { w: 2, h: 1 },
        title: t("Home:IncomeExpenseOverview.Income"),
        infoData: [{
            value: loadedData?.income?.tax,
            label: t("Home:IncomeExpenseOverview.Tax"),
            severity: Status.Success,
            unit: "Kč"
        }, {
            value: loadedData?.income?.nontax,
            label: t("Home:IncomeExpenseOverview.NonTax"),
            unit: "Kč"
        }],
    }), [t, loadedData]);

    const expenseInfo: IInfoTileInfo = useMemo(() => ({
        type: DashboardTileType.Info,
        size: { w: 2, h: 1 },
        title: t("Home:IncomeExpenseOverview.Expense"),
        infoData: [{
            value: loadedData?.expense?.tax,
            label: t("Home:IncomeExpenseOverview.Tax"),
            unit: "Kč"
        }, {
            value: loadedData?.expense?.nontax,
            label: t("Home:IncomeExpenseOverview.NonTax"),
            unit: "Kč"
        }],
    }), [t, loadedData]);

    return (
            <GroupedInfoTileBackground
                    inEditMode={inEditMode}
                    overflowed
                    to={getDrillDownNavParams({
                        route: ROUTE_CBA_ENTRY_LEDGER,
                        context,
                        customQueryString: `variant=${CbaEntryLedgerVariants.CZK}`
                    })}
                    isLoading={isLoading}>
                <GroupInfoTileHeader>
                    <DashboardTileHeader><b>{t("Home:IncomeExpenseOverview.Heading")}</b></DashboardTileHeader>
                </GroupInfoTileHeader>

                {!isLoading && (
                        <GroupedInfoTileContentWrapper cols={1} rows={2}>
                            <InfoTile inEditMode={inEditMode} info={incomeInfo} isGrouped/>
                            <InfoTile inEditMode={inEditMode} info={expenseInfo} isGrouped/>
                        </GroupedInfoTileContentWrapper>
                )}
            </GroupedInfoTileBackground>
    );
};

export default memo(withOData(IncomeExpenseOverview));