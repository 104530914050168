import i18next, { TFunction } from "i18next";
import {
    IReportColumnDef,
    IReportColumnGroupDef,
    IReportHierarchy,
    IReportRowDef,
    IReportRowFormatterArgs,
    IReportVariantDef,
    IReportWarning,
    TMetaColumnFormatter,
    TReportColumnFormatter,
    TReportRowFormatter
} from "@components/smart/smartTable";
import {
    BasicInputSizes,
    CacheStrategy,
    CurrencyUnit,
    FieldType,
    ReportTableRowType,
    ValidatorType,
    ValueType
} from "../../enums";
import { IFieldInfoProperties, IGetValueArgs } from "@components/smart/FieldInfo";
import { IColumn, IRowValues, ISort, TColumn } from "@components/table";
import { IGroupListItemDef } from "@components/configurationList";
import { Optional, TRecordAny, TRecordType, TRecordValue } from "../../global.types";
import { capitalize } from "lodash";
import BindingContext, { IEntity } from "../../odata/BindingContext";
import { getWeekNumber, getYearQuarter, isDefined } from "@utils/general";
import { getLeavesColumns, isMetaColumn } from "@components/table/TableUtils";
import ExcelExport from "../../utils/ExcelExport";
import { IDefinition } from "../PageUtils";
import DateType, { DateFormat, getUtcDate } from "../../types/Date";
import { IFilterGroupDef, TFilterDef } from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { memoizedWithCacheStrategy } from "@utils/CacheCleaner";
import { IAppContext } from "../../contexts/appContext/AppContext.types";
import { REST_API_URL } from "../../constants";
import customFetch from "../../utils/customFetch";
import { ReportStorage } from "./ReportStorage";
import { CurrencyCode, DocumentTypeCode } from "@odata/GeneratedEnums";
import { IReportFilterNode } from "./ReportView";
import { ITableVariant, IVariantColumn } from "@components/variantSelector/VariantOdata";
import { ISplitPageTableDef } from "../../views/table/TableView.utils";
import { IDayInterval, isValidDateInterval } from "@components/inputs/date/utils";
import { getActiveChartOfAccountsId } from "../fiscalYear/FiscalYear.utils";
import { AccountEntity, EntitySetName, EntityTypeName } from "@odata/GeneratedEntityTypes";
import { isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import { getColumnOverride } from "@components/smart/smartTable/SmartReportTable.utils";
import { CommonReportProps } from "./CommonDefs";
import { getComposedDateRangeFilterDef } from "./customFilterComponents/ComposedDateRange";
import { SwitchType } from "@components/inputs/switch/Switch";
import { ReportId } from "./ReportIds";
import { getEnumNameSpaceName } from "@odata/GeneratedEnums.utils";

export const reportGroups: (keyof IReportHierarchy)[] = ["Groups", "Columns", "Aggregations"];

export interface IGetReportDefVal {
    settings: IEntity;
    storage: ReportStorage;
}

export type TGetReportDefValFn<P> = (args: IGetReportDefVal) => P;
export type TReportDefVal<P> = P | TGetReportDefValFn<P>;
export type TReportColumnOverrides = TRecordType<IFieldInfoProperties> | ((columnId: string) => IFieldInfoProperties);

export interface IDrillDownColumnDef {
    id: string;
    filterGroup: string;
    getParsedFilters: (rowValues: IRowValues, settings: TRecordAny) => TRecordValue;
}

export interface IReportTableDefinition extends ISplitPageTableDef {
    id: string;
    title: string;
    path: string;
    filterBarDef: IFilterGroupDef[];
    initialSortBy?: ISort[];
    disableAggregateButton?: boolean;
    /** Initial report hierarchy in format that is send to backend */
    defaultReportHierarchy: IReportHierarchy;
    /** If multiple system default variants are used */
    defaultReportVariants?: Record<string, IReportVariantDef>;
    /** ReportView creates internally config list items definition from the current state of report hierarchy.
     * With this props, item settings can be overridden (e.g. we need one of the items to be disabled) */
    configListItemsOverride?: TGetReportConfigListItemOverride;
    parameters?: string[];
    showDrilldown?: boolean;
    // called during ReportView initialization, can be used for example to set filter values that are dependent on additional requests
    onBeforeLoad?: (storage: ReportStorage) => Promise<void>;
    onAfterTableLoad?: (storage: ReportStorage) => void;
    onGroupToggle?: (storage: ReportStorage) => void;
    currencyUnit?: TReportDefVal<CurrencyUnit>;
    rowFormatter?: TReportRowFormatter;
    metaColumnFormatter?: TMetaColumnFormatter;
    columnFormatter?: TReportColumnFormatter;
    /** When we need to define field info (e.g. formatter, text align) for one of the columns */
    columnOverrides?: TReportColumnOverrides;
    /** Can be used to alter the data returned from backend. Called before rows are created .*/
    rowsDataFactory?: (rowsData: IReportRowDef[], args: IReportRowFormatterArgs) => IReportRowDef[];
    /** If true, filters are built from columns returned from backend response.
     * Otherwise, filters are built from current ReportHierarchy settings.
     * This is needed for reports like BalanceSheet, where backend returns MORE columns
     * than we actually request in ReportHierarchy settings.*/
    updateFiltersFromResponse?: boolean;
    /** Override for GeneralLedger which in some situations is nested even when only one report hierarchy group is selected. */
    canExpandCollapseRows?: (storage: ReportStorage) => boolean;
}

export interface IReportDefinition extends Omit<IDefinition, "table"> {
    table: IReportTableDefinition;
}

export interface IReportData {
    Columns: IReportColumnDef[];
    Rows: IReportRowDef[];
    ColumnGroups?: IReportColumnGroupDef[];
    Warnings?: IReportWarning[];
    MetaValueKeys?: string[];
}

export interface IReportErrorData {
    Message: string;
}

export interface IReportSettings {
    // default settings
    Filter?: IReportFilterNode;
    Sort?: ISort[];
    ReportHierarchy?: IReportHierarchy;

    // any other parameters used in different reports
    [key: string]: any;
}

// if group specified, settings will only be applied when item is in that group
export type TReportConfigListItemOverride = Optional<Omit<IGroupListItemDef & {
    group?: string;
    hideFromAvailable?: boolean;
}, "id">, "value">;

export type TGetReportConfigListItemOverride =
    TRecordType<TReportConfigListItemOverride>
    | ((item: IGroupListItemDef) => TReportConfigListItemOverride);

export enum ReportColumnType {
    Date = "Date",
    DateTimeOffset = "DateTimeOffset",
    Currency = "Currency",
    Delta = "Delta",
    Integer = "Integer",
    Number = "Number",
    String = "String",
    Boolean = "Bool",
    // number, but we don't want to use numeric agg functions on it
    StringyInteger = "StringyInteger",
    Label = "Label",
}

export enum ReportNodeOperator {
    Equal = "eq",
    StartsWith = "startswith",
    EndsWith = "endswith",
    Substring = "substring",

    GreaterThan = "gt",
    GreaterOrEqual = "ge",
    LessThan = "lt",
    LessOrEqual = "le",
}

export enum ReportConfigGroup {
    Groups = "Groups",
    Columns = "Columns",
    Aggregations = "Aggregations",
    AvailableColumns = "AvailableColumns"
}

export enum ReportFilterNodeColumnType {
    Logic = "Logic",
    Not = "Not",

    Decimal = "Decimal",
    String = "String",
    DateTimeOffset = "DateTimeOffset",
    Date = "Date",
    Integer = "Integer",
    Boolean = "Bool",

    MultiColumnDecimal = "MultiColumnDecimal",
    MultiColumnString = "MultiColumnString",
    MultiColumnDateTimeOffset = "MultiColumnDateTimeOffset",
    MultiColumnDate = "MultiColumnDate",
    MultiColumnInteger = "MultiColumnInteger",
    MultiColumnBool = "MultiColumnBool"
}

export type TAggregationFunction = NumberAggregationFunction | TimeAggregationFunction;

export enum NumberAggregationFunction {
    Sum = "SUM",
    Count = "COUNT",
    Avg = "AVG",
    Min = "MIN",
    Max = "MAX"
}

export enum TimeAggregationFunction {
    Day = "DAY",
    Month = "MONTH",
    Year = "YEAR",
    Week = "WEEK",
    Quarter = "QUARTER"
}

export enum PrintReportType {
    BalanceSheet = "BS",
    IncomeStatementByNature = "IS_N",
    IncomeStatementByFunction = "IS_F"
}

export const commonReportTranslations = [
    "Reporting", "Common", "Enums", getEnumNameSpaceName(EntityTypeName.DocumentType)
];

export const labelColumnNamePrefix = "LabelWithPath_Name_";
export const labelChildrenSuffix = "_Children";

export const getAggFuncLabel = (fnName: TimeAggregationFunction | NumberAggregationFunction) => {
    return i18next.t(`Reporting:AggrFunc.${capitalize(fnName)}`);
};

export const getNumberAggFuncItems = () => {
    return [
        { id: NumberAggregationFunction.Sum, label: getAggFuncLabel(NumberAggregationFunction.Sum) },
        { id: NumberAggregationFunction.Avg, label: getAggFuncLabel(NumberAggregationFunction.Avg) },
        { id: NumberAggregationFunction.Min, label: getAggFuncLabel(NumberAggregationFunction.Min) },
        { id: NumberAggregationFunction.Max, label: getAggFuncLabel(NumberAggregationFunction.Max) },
        { id: NumberAggregationFunction.Count, label: getAggFuncLabel(NumberAggregationFunction.Count) }
    ];
};

export const getNumberAggFuncItem = (funcId: NumberAggregationFunction) => {
    return getNumberAggFuncItems().find(item => item.id === funcId);
};

export const getTimeAggFuncItems = () => {
    // items are ordered by specificity, day is first as the most specific
    return [
        { id: TimeAggregationFunction.Day, label: getAggFuncLabel(TimeAggregationFunction.Day) },
        { id: TimeAggregationFunction.Week, label: getAggFuncLabel(TimeAggregationFunction.Week) },
        { id: TimeAggregationFunction.Month, label: getAggFuncLabel(TimeAggregationFunction.Month) },
        { id: TimeAggregationFunction.Quarter, label: getAggFuncLabel(TimeAggregationFunction.Quarter) },
        { id: TimeAggregationFunction.Year, label: getAggFuncLabel(TimeAggregationFunction.Year) }
    ];
};

export const getTimeAggFuncItem = (funcId: TimeAggregationFunction) => {
    return getTimeAggFuncItems().find(item => item.id === funcId);
};

// e.g. [JournalEntry_DateAccountingTransaction_MONTH, JournalEntry_DateAccountingTransaction_DAY] sorts as
// [JournalEntry_DateAccountingTransaction_DAY, JournalEntry_DateAccountingTransaction_MONTH] because day is more specific
export const compareColumnIdsByTimeAggFuncSpecificity = (columnId1: string, columnId2: string) => {
    const col1 = getColumnFromColumnAlias(columnId1);
    const col2 = getColumnFromColumnAlias(columnId2);
    const aggFn1Index = getTimeAggFuncItems().findIndex(item => item.id === col1.AggregationFunction);
    const aggFn2Index = getTimeAggFuncItems().findIndex(item => item.id === col2.AggregationFunction);

    return aggFn1Index - aggFn2Index;
};

export const isAggregationFunction = (text: string) => {
    return Object.values(NumberAggregationFunction).includes(text as NumberAggregationFunction) || Object.values(TimeAggregationFunction).includes(text as TimeAggregationFunction);
};

export const formatDateByTimeAggFn = (date: Date, type: TimeAggregationFunction) => {
    let formattedValue;

    switch (type.toUpperCase()) {
        case TimeAggregationFunction.Year:
            formattedValue = DateType.format(date, DateFormat.year);
            break;
        case TimeAggregationFunction.Month:
            formattedValue = DateType.format(date, DateFormat.monthAndYear);
            break;
        case TimeAggregationFunction.Quarter:
            formattedValue = `${getYearQuarter(date)}. ${getAggFuncLabel(TimeAggregationFunction.Quarter).toLowerCase()}`;
            break;
        case TimeAggregationFunction.Week:
            formattedValue = `${getWeekNumber(date)}. ${getAggFuncLabel(TimeAggregationFunction.Week).toLowerCase()}`;
            break;
        case TimeAggregationFunction.Day:
        default:
            formattedValue = DateType.format(date, DateType.defaultDateFormat);
            break;
    }

    return formattedValue;
};

export const NumberAggFuncTypes = [ReportColumnType.Currency, ReportColumnType.Integer, ReportColumnType.Number];

// columnAlias can include aggregation function 'JournalEntry_Amount_SUM'
// we need to find its clean definition {ColumnAlias: JournalEntry_Amount, AggregationFunction: SUM}
export const getColumnFromColumnAlias = (columnAlias: string): IReportColumnDef => {
    const splittedColumnAlias = columnAlias.split("_");
    const aggregationFunction = splittedColumnAlias[splittedColumnAlias.length - 1];

    if (aggregationFunction && !isAggregationFunction(aggregationFunction)) {
        // e.g. LabelWithPath_Name_1
        return {
            ColumnAlias: columnAlias
        };
    }

    const cleanColumnAlias = [...splittedColumnAlias].splice(0, splittedColumnAlias.length - 1).join("_");

    const column: IReportColumnDef = {
        ColumnAlias: cleanColumnAlias
    };

    if (aggregationFunction) {
        column.AggregationFunction = aggregationFunction as TAggregationFunction;
    }

    return column;
};

export const findColumnInHierarchy = (reportHierarchy: IReportHierarchy, columnAlias: string) => {
    if (!reportHierarchy) {
        return null;
    }
    const cleanColumn = getColumnFromColumnAlias(columnAlias);
    const _isSortColumn = (col: IReportColumnDef) => {
        return col.ColumnAlias === cleanColumn.ColumnAlias && col.AggregationFunction?.toLowerCase() === cleanColumn.AggregationFunction?.toLowerCase();
    };

    return reportHierarchy.Groups?.find(_isSortColumn)
        || reportHierarchy.Columns?.find(_isSortColumn)
        || reportHierarchy.Aggregations?.find(_isSortColumn);
};

export const getMatchingColumn = (columns: IReportColumnDef[], columnAlias: string): IReportColumnDef => {
    if (!columns || columns.length === 0) {
        return null;
    }

    // startsWith used because columns can have aggregation function as appendix
    const matchingColumns = columns?.filter((supCol: IReportColumnDef) => columnAlias.startsWith(supCol.ColumnAlias));

    if (matchingColumns.length === 0) {
        return null;
    }

    // if multiple columns startswith the same string, try to find exact match
    const column = matchingColumns?.find(col => col.ColumnAlias === columnAlias);

    if (column) {
        return column;
    }

    // this is kinda coinflip if there are still multiple columns, hopefully there is just one
    return matchingColumns[0];
};


export const getFieldTypeFromColumnType = (column: IReportColumnDef): FieldType => {
    switch (column.Type) {
        case ReportColumnType.Date:
        case ReportColumnType.DateTimeOffset:
            return FieldType.Date;
        case ReportColumnType.Integer:
        case ReportColumnType.Number:
        case ReportColumnType.Currency:
        case ReportColumnType.Delta:
            return FieldType.NumberInput;
        case ReportColumnType.Boolean:
            return FieldType.MultiSelect;
        case ReportColumnType.String:
        default:
            return FieldType.Input;
    }
};

export const getValueTypeFromColumnType = (column: IReportColumnDef): ValueType => {
    switch (column.Type) {
        case ReportColumnType.Date:
        case ReportColumnType.DateTimeOffset:
            return ValueType.Date;
        case ReportColumnType.Integer:
        case ReportColumnType.Number:
        case ReportColumnType.Currency:
        case ReportColumnType.Delta:
            return ValueType.Number;
        case ReportColumnType.Boolean:
            return ValueType.Boolean;
        case ReportColumnType.String:
        default:
            return ValueType.String;
    }
};

export const getValidatorTypeFromColumnType = (column: IReportColumnDef): ValidatorType => {
    switch (column.Type) {
        case ReportColumnType.Date:
            return ValidatorType.Date;
        case ReportColumnType.Integer:
        case ReportColumnType.Number:
        case ReportColumnType.Currency:
            return ValidatorType.Number;
        case ReportColumnType.Boolean:
            return ValidatorType.Boolean;
        case ReportColumnType.String:
        default:
            return ValidatorType.String;
    }
};

export const getCurrencyColumn = (column: IReportColumnDef) => {
    return column.DependentColumnsAliases?.find(col => col.includes("CurrencyCode"));
};

interface IExportCellValue {
    id: string,
    label: string,
    value: string
}

interface IFormatArgs {
    entity?: TRecordAny;
}

type TExportFormatterFn = (args: IFormatArgs) => IExportCellValue[];

interface IColumnExtended extends IColumn {
    type?: ReportColumnType;
    currency?: string;
    exportFormatter?: TExportFormatterFn;
}

export const prepareReportDataForExport = (data: IReportData, translate: TFunction, tableColumns?: TColumn[], def?: IReportTableDefinition) => {
    let columns: IColumnExtended[] = (data?.Columns ?? []).map((column: IReportColumnDef) => {
        return {
            id: column.ColumnAlias,
            label: column.Label,
            type: column.Type,
            currency: getCurrencyColumn(column),
            exportFormatter: getColumnOverride(def?.columnOverrides, column.ColumnAlias)?.exportFormatter
        };
    });
    const rows = unrollRows((data?.Rows ?? []));

    // add meta column labels, retrieve from tableColumns because labels are correctly formatted there
    let metaColRow: IEntity;
    if (tableColumns) {
        for (const tableColumn of tableColumns) {
            if (isMetaColumn(tableColumn)) {
                const leaveCols = getLeavesColumns([tableColumn]);

                if (!metaColRow) {
                    metaColRow = {
                        [ExcelExport.META_COL_ROW_ID]: true
                    };
                }

                for (const leaveCol of leaveCols) {
                    metaColRow[leaveCol.id] = tableColumn.label;
                }
            }
        }
    }

    // sometimes, BE doesn't send currency for the row => use CZK
    // will be changed in the future, when the currency will work completely different
    const currencyColumns: string[] = [];
    // add currency as standalone columns
    for (let i = 0; i < columns.length; i++) {
        const column = columns[i];

        if (column.currency) {
            currencyColumns.push(column.currency);
            const currencyColumn = {
                id: column.currency,
                label: `${translate("Reporting:Columns.JournalEntry_CurrencyCode")} (${column.label})`
            };

            i += 1;
            columns.splice(i, 0, currencyColumn);
        }
    }

    const _exportedColumnId = (column: { id: string }, exportedCol: { id: string }): string =>
        `${column.id}_${exportedCol.id}`;

    const cleanRows = rows.map((row) => {
        const values: TRecordAny = {};

        for (const column of columns) {
            const value = row.Value[column.id];

            if (column.exportFormatter) {
                const exportedCells = column.exportFormatter({ entity: row.Value });
                for (const cell of exportedCells) {
                    values[_exportedColumnId(column, cell)] = cell.value;
                }
            } else if (value && column.type === ReportColumnType.Date) {
                values[column.id] = getUtcDate(value as string);
            } else {
                values[column.id] = value;

                if (!value && currencyColumns.includes(column.id)) {
                    values[column.id] = CurrencyCode.CzechKoruna;
                }
            }
        }

        return values;
    });

    if (metaColRow) {
        cleanRows.unshift(metaColRow);
    }

    // columns with export formatter may multiply itself to more columns -> duplicate also preparedColumns
    columns = columns.reduce((prepared, column) => {
        if (column.exportFormatter) {
            column.exportFormatter({})
                .forEach(exportedCol => prepared.push({
                    ...column,
                    id: _exportedColumnId(column, exportedCol),
                    label: exportedCol.label
                }));
        } else {
            prepared.push(column);
        }
        return prepared;
    }, []);

    return { rows: cleanRows, columns };
};

export const unrollRows = (rows: IReportRowDef[], allRows: IReportRowDef[] = []) => {
    for (const row of rows) {
        // only export value rows
        if ([ReportTableRowType.Value, ReportTableRowType.GroupValue, ReportTableRowType.MergedGroup].includes(row.Type)) {
            allRows.push(row);
        }

        if (row.Rows) {
            unrollRows(row.Rows, allRows);
        }
    }

    return allRows;
};

export const getColumnFilterId = (column: IReportColumnDef, aggFnIncluded?: boolean): string => {
    return BindingContext.localContext(getColumnFullAlias(column, aggFnIncluded));
};

/** Returns ColumnAlias together with AggregationFunction if present */
export const getColumnFullAlias = (column: IReportColumnDef, aggFnIncluded?: boolean): string => {
    if (!column) {
        return null;
    }

    return `${column.ColumnAlias}${!aggFnIncluded && column.AggregationFunction ? `_${column.AggregationFunction}` : ""}`;
};

export const getColumnLabel = (column: IReportColumnDef): string => {
    return `${column.Label}${column.AggregationFunction ? ` (${getAggFuncLabel(column.AggregationFunction).toLowerCase()})` : ""}`;
};

export const getReportRowId = (path: number[]) => {
    // return `${level}-(${parentIndex})-${index}`;
    return path.join(":");
};

export const getReportRowNewParentIndex = (parentIndex: string, index: number) => {
    return `${parentIndex}:${index}`;
};

export const getFilterNameWithoutEntitySetPrefix = (filterName: string) => {
    return filterName.split("/").slice(-1)[0];
};

/** Removes local context sign from settings keys*/
export const cleanSettings = (settings: IEntity) => {
    return Object.entries(settings).reduce((filteredSettings: TRecordAny, [settingKey, settingValue]) => {
        const cleanKey = BindingContext.cleanLocalContext(settingKey);

        if (isDefined(settingValue)) {
            filteredSettings[cleanKey] = settingValue;
        }

        return filteredSettings;
    }, {});
};

export const getReportDefinition = (getReportTableDef: (context: IAppContext) => IReportTableDefinition, context: IAppContext): IReportDefinition => {
    const reportTableDefinition = getReportTableDef(context);

    return {
        entitySet: BindingContext.localContext(reportTableDefinition.path),
        table: reportTableDefinition
    };
};

const convertVariantColumnToReportColumn = (variantColumn: IVariantColumn): IReportColumnDef => {
    return {
        ColumnAlias: variantColumn.id,
        AggregationFunction: variantColumn.aggregationFunction as TAggregationFunction
    };
};

export const convertVariantToReportHierarchy = (variant: ITableVariant): IReportHierarchy => {
    const columns = variant.columns;
    // columns have groupId specified when aggregate is used
    // todo will break when variant with aggregate and zero columns is stored
    // aggregate would have to be stored as new parameter to BE for full functionality
    const aggregate = !!columns?.[0]?.group;

    return {
        Aggregate: aggregate,
        Groups: columns.filter(column => column.group === ReportConfigGroup.Groups).map(column => convertVariantColumnToReportColumn(column)),
        Columns: columns.filter(column => !aggregate || column.group === ReportConfigGroup.Columns).map(column => convertVariantColumnToReportColumn(column)),
        Aggregations: columns.filter(column => column.group === ReportConfigGroup.Aggregations).map(column => convertVariantColumnToReportColumn(column))
    };
};

export interface IDateRangeParam {
    DateStart: Date,
    DateEnd: Date
}

export interface IReportDateRange {
    Key: string;
    Value: {
        DateStart: string;
        DateEnd: string;
    };
}

const fetchCompanyDateRangesMemoized = memoizedWithCacheStrategy<IAppContext, IReportDateRange[]>(
    async (context: IAppContext) => {
        const endPoint = isCashBasisAccountingCompany(context) ? "CalendarDateRange" : "DateRange";
        const res = await customFetch(`${REST_API_URL}/${endPoint}`);

        return await res.json();
    },
    (context: IAppContext) => {
        return `dateRanges_${context.getCompany().Id}`;
    }
);

export const fetchCompanyDateRanges = async (context: IAppContext): Promise<IReportDateRange[]> => {
    return fetchCompanyDateRangesMemoized(context, CacheStrategy.Company);
};

export interface IDayIntervalWithTimeAggFn extends IDayInterval {
    type: TimeAggregationFunction;
}

export const isValidDateIntervalWithTimeAggFn = (value: any): value is IDayIntervalWithTimeAggFn => {
    return isValidDateInterval(value) && !!(value as any).type && !!getTimeAggFuncItem((value as any).type);
};

export const getAccountsParamDef = (defaultValues: string[] = [], withChildrenAccounts?: boolean, onlyAccountsForSaldo?: boolean): TFilterDef => {
    let filter = withChildrenAccounts ? "" : "Parent eq null";

    if (onlyAccountsForSaldo) {
        if (filter) {
            filter += " AND ";
        }

        filter += `startswith(${AccountEntity.Number}, '3')`;
    }

    return {
        filter: {
            select: filter
        },
        type: FieldType.MultiSelect,
        label: i18next.t("Reporting:Parameters.Accounts"),
        columns: [
            { id: "Number", label: i18next.t("Reporting:Parameters.AccountsNumber") },
            { id: "Name", label: i18next.t("Reporting:Parameters.AccountsName") }
        ],
        fieldSettings: {
            entitySet: (args: IGetValueArgs) => {
                if (withChildrenAccounts) {
                    return EntitySetName.FlattenAccounts;
                } else {
                    return `ChartsOfAccounts(${getActiveChartOfAccountsId(args.context)})/Accounts`;
                }
            },
            // we need to use Number as both default and output value
            // => changing the id value of the items to Number from Id
            idName: "Number",
            displayName: "Number",
            preloadItems: true
        },
        defaultValue: defaultValues,
        width: BasicInputSizes.L
    };
};

/** Creates set of label filters for drilldown from given entity */
export const getLabelDrilldownFilters = (entity: IEntity): TRecordAny => {
    const filters: TRecordAny = {};

    for (const key of Object.keys(entity).sort()) {
        const value = entity[key];
        const childrenKey = `${key}${labelChildrenSuffix}`;

        if (key.startsWith(labelColumnNamePrefix) && !key.endsWith(labelChildrenSuffix)) {
            if (entity[childrenKey]) {
                filters[BindingContext.localContext(key)] = [entity[key], ...entity[childrenKey]];
            } else {
                filters[BindingContext.localContext(key)] = [value];
            }
        }
    }

    return filters;
};

export enum CbaIncomeExpenseType {
    All = "All",
    Income = "Income",
    Expense = "Expense"
}

export const CbaIncomeExpenseProps = {
    type: BindingContext.localContext("Type"),
    includeUnrelatedToBusiness: BindingContext.localContext("IncludeUnrelatedToBusiness")
};

export const cbaReportCommonParams = [CommonReportProps.dateRange, CbaIncomeExpenseProps.type, CbaIncomeExpenseProps.includeUnrelatedToBusiness];

export const getCbaReportParamsDef = (tableId: ReportId): Record<string, TFilterDef> => {
    return {
        [CommonReportProps.dateRange]: {
            ...getComposedDateRangeFilterDef(tableId),
            defaultValue: "ThisYear"
        },
        [CbaIncomeExpenseProps.type]: {
            type: FieldType.ComboBox,
            label: i18next.t("Reporting:CbaIncomeExpense.Direction"),
            fieldSettings: {
                items: [{
                    id: CbaIncomeExpenseType.All,
                    label: i18next.t("Reporting:CbaIncomeExpense.All")
                }, {
                    id: CbaIncomeExpenseType.Income,
                    label: i18next.t("Reporting:CbaIncomeExpense.Income")
                }, {
                    id: CbaIncomeExpenseType.Expense,
                    label: i18next.t("Reporting:CbaIncomeExpense.Expense")
                }]
            },
            defaultValue: CbaIncomeExpenseType.All
        },
        [CbaIncomeExpenseProps.includeUnrelatedToBusiness]: {
            type: FieldType.Switch,
            fieldSettings: {
                type: SwitchType.YesNo
            },
            label: i18next.t("Reporting:CbaIncomeExpense.UnrelatedToBusiness"),
            defaultValue: false
        }
    };
};

/**
 * Replace fake document type for DDOPP documents in Reports with real document type of the entity
 * @param docType
 */
export function replaceTaxDocumentTypes(docType: DocumentTypeCode | "TaxDocumentReceived" | "TaxDocumentIssued"): DocumentTypeCode {
    if (docType === "TaxDocumentReceived") {
        return DocumentTypeCode.ProformaInvoiceReceived;
    } else if (docType === "TaxDocumentIssued") {
        return DocumentTypeCode.ProformaInvoiceIssued;
    }
    return docType;
}