import React from "react";
import { getAdditionalLineItemActions, getDefinitions, MinorAssetAction } from "./MinorAssetDef";
import { IFormGroupDef } from "@components/smart/smartFormGroup/SmartFormGroup";
import i18next from "i18next";
import { FormStorage, IFormModel } from "../../../views/formView/FormStorage";
import { cloneDeep } from "lodash";
import { createBindingContext } from "@odata/BindingContext";
import { getInfoValue, IGetValueArgs } from "@components/smart/FieldInfo";
import { addNewLineItem } from "@components/smart/smartFastEntryList";
import {
    EntitySetName,
    EntityTypeName,
    IMinorAssetEntity,
    IMinorAssetHistoryItemEntity,
    IMinorAssetItemEntity,
    IRegularDocumentItemEntity
} from "@odata/GeneratedEntityTypes";
import { IDefinition } from "../../PageUtils";
import { Sort, ValueType } from "../../../enums";
import { TValue } from "../../../global.types";
import { IFormatOptions, transformToODataString } from "@odata/OData.utils";
import { TCellValue } from "@components/table";
import { getTableIntentLink } from "@components/drillDown/DrillDown.utils";
import { ROUTE_MINOR_ASSET } from "../../../routes";
import { getCompanyCurrency, isVatRegisteredCompany } from "@utils/CompanyUtils";
import { NEW_ITEM_DETAIL } from "../../../constants";
import { AssetItemTypeCode, CompanyPermissionCode, MinorAssetStatusCode } from "@odata/GeneratedEnums";
import { getBoundValue } from "@odata/Data.utils";
import { addLineActionInfoValueCallback } from "../../../views/formView/Form.utils";
import { OData } from "@odata/OData";
import { roundToDecimalPlaces } from "@utils/general";
import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import dayjs from "dayjs";
import { getUtcDate } from "../../../types/Date";
import { DefaultTheme } from "styled-components";
import { ITabData } from "@components/tabs";
import { SECONDARY_FILTERS_ALL } from "../../../views/table/TableView.utils";
import { getEnumDisplayValue } from "@odata/GeneratedEnums.utils";
import { TFormatterFn } from "@components/smart/smartTable/SmartTable.utils";
import { ColoredText } from "../../../global.style";

export const MINOR_ASSET_FORM_TAB = "MinorAsset";

const isItemSelectable = (args: IGetValueArgs) => {
    // new Fast Entries are not selectable by default, so we don't have any other conditions for now
    return true;
};

/**
 * Returns minor asset status color
 * @param StatusCode
 */
export const getStatusColor = (StatusCode: MinorAssetStatusCode): keyof DefaultTheme => {
    let color: keyof DefaultTheme;

    switch (StatusCode) {
        case MinorAssetStatusCode.Active:
            color = "C_SEM_text_good";
            break;
    }

    return color;
};

export const minorAssetStatusFormatter: TFormatterFn = (val: TValue, args?: IFormatOptions): TCellValue => {
    const title = val as string;
    const color = getStatusColor(args.entity.Status?.Code);

    const colorWrapper = <ColoredText color={color}> {val} </ColoredText>;

    return { tooltip: title, value: colorWrapper };
};

export const getMinorAssetTabs = (): ITabData[] => [
    {
        id: SECONDARY_FILTERS_ALL,
        title: i18next.t("MinorAsset:Status.All")
    }, {
        id: MinorAssetStatusCode.Active,
        title: getEnumDisplayValue(EntityTypeName.MinorAssetStatus, MinorAssetStatusCode.Active)
    }, {
        id: MinorAssetStatusCode.WithoutAssets,
        title: getEnumDisplayValue(EntityTypeName.MinorAssetStatus, MinorAssetStatusCode.WithoutAssets)
    }
];

/**
 * Adds minor asset definitions to document definition
 * @param definition
 * @param context
 * @param isReceivedDocument
 */
export function addMinorAssetDef(definition: IDefinition, context: IAppContext, isReceivedDocument = true): void {
    // Add LineItems actions for minor asset
    const itemsGroup = definition.form.groups.find(group => group.id === "Items");

    itemsGroup.lineItems.actions = itemsGroup.lineItems.actions ?? [];
    if (context.getCompanyPermissions().has(CompanyPermissionCode.Asset)) {
        itemsGroup.lineItems.actions.push(...getAdditionalLineItemActions(isReceivedDocument));
    }

    addLineActionInfoValueCallback(itemsGroup, "isItemSelectable", [MinorAssetAction.Create, MinorAssetAction.Pair], isItemSelectable);

    const minorAssetTab: IFormGroupDef = {
        id: MINOR_ASSET_FORM_TAB,
        title: i18next.t("Document:FormTab.MinorAsset"),
        table: {
            id: `minorAsset_${definition.entitySet}`,
            entitySet: EntitySetName.MinorAssets,
            filter: ({ storage }) => (
                `Items/any(param: param/Document/Id eq ${storage.data?.entity?.Id})`
            ),
            initialSortBy: [{
                id: "DateOfLastTransaction",
                sort: Sort.Desc
            }],
            columns: [
                {
                    id: "NumberOurs",
                    formatter: (val: TValue, args: IFormatOptions): TCellValue => {
                        if (!args.entity?.Id) {
                            return args.entity?.NumberOurs;
                        }

                        return getTableIntentLink(val as string, {
                            route: `${ROUTE_MINOR_ASSET}/${args.entity.Id}`,
                            context: args.storage.context,
                            storage: args.storage
                        });
                    }
                },
                { id: "Description" }
            ]
        }
    };
    // Add minor asset tab
    const tabsGroup: IFormGroupDef = definition.form.groups.find(group => group.id === "Tabs");
    tabsGroup.tabs.splice(2, 0, minorAssetTab);
}

export function hasMinorAssetBound(oData: OData, DocumentItemIds: number[]): Promise<boolean> {
    return oData.getEntitySetWrapper(EntitySetName.MinorAssets)
        .query()
            .filter(`Items/any(param: param/DocumentItem/Id in (${transformToODataString(DocumentItemIds, ValueType.Number)}))`)
        .top(1)
        .fetchData<IMinorAssetEntity[]>()
        .then(data => data.value.length > 0, () => false);
}

export async function createMinorAssetStorage(docStorage: FormStorage, args: Partial<IFormModel>, lineItemOnly: boolean, documentItems: Partial<IRegularDocumentItemEntity>[]): Promise<FormStorage> {
    // use clone in form storage - we can edit the form storage definition but still keep original values
    const definition = cloneDeep(getDefinitions(docStorage.context));
    definition.form.groups.forEach(grp => {
        if (grp.id === "Items") {
            grp.title = i18next.t("MinorAsset:Form.Acquisition");
            grp.lineItems.isItemCloneable = false;
            grp.lineItems.isItemRemovable = false;
            grp.lineItems.canAdd = false;
            // simplified field list when creating / pairing minor asset from document form
            grp.lineItems.columns = [{ id: "Description" }, { id: "Quantity" }, { id: "Amount" }, { id: "Note" }];
            if (lineItemOnly) {
                grp.title = null;
                grp.lineItems.canReorder = false;
            }
        } else if (lineItemOnly) {
            grp.isVisible = false;
        }
        if (grp.id === "Movements") {
            grp.isVisible = false;
        }
    });

    const { oData, t, history, match, theme, context, data: { entity } } = docStorage;

    const formStorage = new FormStorage({
        oData, t, history, match, theme, context,
        id: definition.form.id,
        definition: definition.form,
        ...args
    });

    const bindingContext = createBindingContext(getInfoValue(definition, "entitySet"), oData.getMetadata())
        .addKey(NEW_ITEM_DETAIL, true);

    formStorage.init({
        ignoreVariants: true,
        bindingContext,
        onAfterLoad: () => {
            const entityType = docStorage.data.bindingContext.getEntityType();
            // create new items from selected document line items' data
            documentItems.forEach(docItem => {
                const item = addNewLineItem<IMinorAssetItemEntity>(formStorage, "Items");
                item.Document = {
                    _metadata: {
                        "": { type: `#${entityType.getFullName()}` }
                    },
                    Id: entity.Id,
                    DocumentTypeCode: entity.DocumentTypeCode,
                    NumberOurs: entity.NumberOurs
                };
                item.DocumentItem = {
                    _metadata: {
                        "": { type: `#${docStorage.data.bindingContext.navigate("Items").getEntityType().getFullName()}` }
                    },
                    Id: docItem.Id
                };
                item.Quantity = docItem.Quantity;
                // Tax payer uses price without tax, the rest whole price including VAT.
                item.IsVatIncluded = !isVatRegisteredCompany(docStorage.context);
                item.Amount = item.IsVatIncluded ? docItem.Amount ?? docItem.TransactionAmount : docItem.AmountNet ?? docItem.TransactionAmountNet;
                item.CurrencyCode = getCompanyCurrency(context);
                item.DateAcquired = docStorage?.data.entity.DateAccountingTransaction;
                item.Description = docItem.Description;
            });

            // default minor asset name from item
            // formStorage.setValueByPath("Description", documentItems[0].Description);
        }
    });

    return formStorage;
}

/**
 * Corrects minorAssetItems after user changes them -> e.g. generates new movements, recalculates prices, etc...
 * @param items
 * @param storage
 */
export function correctMinorAssetItems(items: IMinorAssetItemEntity[], storage: FormStorage): IMinorAssetItemEntity[] {
    const dataBindingContext = storage.data.bindingContext.navigate("Items");
    const origItems = (storage.data.origEntity as IMinorAssetEntity).Items;
    return items.map(item => {
        const origItem = getBoundValue({
            bindingContext: dataBindingContext.addKey(item),
            data: origItems,
            dataBindingContext
        });
        return correctMinorAssetItemEntity(storage, item, origItem);
    });
}

const defaultItem: IMinorAssetItemEntity = {
    Quantity: 0, Amount: 0
};

export function correctMinorAssetItemEntity(storage: FormStorage, item: IMinorAssetItemEntity, origItem: IMinorAssetItemEntity = defaultItem): IMinorAssetItemEntity {
    const now = dayjs();
    if (!item.DateAcquired) {
        // add default if not present, todo: may be better handled on BE?
        item.DateAcquired = now.toDate();
    }
    if (!item.CurrencyCode) {
        item.CurrencyCode = storage.context.getDefaultCurrency();
    }
    if (item.Amount === origItem.Amount && item.Quantity === origItem.Quantity) {
        // no change -> return original item without recalculation (typically for disposed items)
        return item;
    }
    let origMovements: IMinorAssetItemEntity[] = item.Items ?? [];
    const newAmount = roundToDecimalPlaces(2, item.Quantity ? item.Amount / item.Quantity : origItem.Amount / origItem.Quantity);
    // in case single item is rounded, it will calculate exact amount for the whole asset
    item.Amount = roundToDecimalPlaces(2, newAmount * item.Quantity);

    origMovements = newAmount ? origMovements.map(movement => ({
        ...movement,
        Amount: roundToDecimalPlaces(2, newAmount * movement.Quantity)
    })) : origMovements;

    let newMovement: IMinorAssetHistoryItemEntity = null;
    // correct movements
    if (item.Quantity !== origItem.Quantity) {
        const quantity = item.Quantity - origItem.Quantity;
        newMovement = {
            Amount: roundToDecimalPlaces(2, Math.abs(newAmount * quantity)),
            DateOfTransaction: getUtcDate(),
            IsVatIncluded: item.IsVatIncluded,
            CurrencyCode: item.CurrencyCode,
            ItemTypeCode: quantity < 0 ? AssetItemTypeCode.Disposal : AssetItemTypeCode.Acquisition,
            Quantity: Math.abs(quantity)
        };
    }
    item.Items = [...origMovements, newMovement].filter(item => !!item);
    return item;
}