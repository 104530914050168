import React from "react";
import { NavDashboard } from "../../components/navigation/NavDashboard";
import { filterMenu, getMainMenuDefinition, IMenuDefGroup, OrganizationSettingsMenuDefinition } from "../../menu-def";
import { withOData, WithOData } from "@odata/withOData";
import { BatchRequest } from "@odata/OData";
import { FETCH_ABORT_ERROR } from "../../constants";
import { AppContext, ContextEvents } from "../../contexts/appContext/AppContext.types";
import { WithPermissionContext, withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { NoPermission, NotFound } from "../notFound";

/** Wrapper around NavDashboard that loads "count" property for individual items */
interface IPropsTest extends WithOData, WithPermissionContext {
}

interface IState {
    groupWithItemsWithCounts: IMenuDefGroup;
    group: IMenuDefGroup;
}

class SmartNavDashboard extends React.Component<IPropsTest, IState> {
    static contextType = AppContext;

    batch: BatchRequest;

    state: IState = {
        groupWithItemsWithCounts: null,
        group: null
    };

    componentDidMount() {
        this.context.eventEmitter.on(ContextEvents.CompanyChanged, this.handleCompanyChanged);
        this.initMenuGroup();
    }

    componentDidUpdate(prevProps: IPropsTest) {
        this.initMenuGroup();
    }

    componentWillUnmount() {
        this.context.eventEmitter.off(ContextEvents.CompanyChanged, this.handleCompanyChanged);
        this.batch?.abort();
    }

    handleCompanyChanged = () => {
        this.initMenuGroup(true);
    };

    initMenuGroup = (force?: boolean) => {
        const currentGroup = this.context.getSelectedMenu()?.group?.key;

        if (currentGroup !== this.state.group?.key || force) {
            const group = this.getAllViews()?.find(view => view.key === currentGroup);
            if (group) {
                this.loadCounts(group);
                this.setState({ group });
            }
        }
    };

    loadCounts = async (group: IMenuDefGroup): Promise<void> => {
        const isAnyGetter = group?.items && group?.items.find(item => item.countGetter);

        if (!isAnyGetter) {
            this.setState({
                groupWithItemsWithCounts: null
            });
            return;
        }

        this.batch?.abort();

        this.batch = this.props.oData.batch();

        const countPromises: Record<string, Promise<number>> = {};

        for (const item of group.items) {
            countPromises[item.key] = item.countGetter?.(this.batch);
        }

        if (!this.batch.isEmpty()) {
            // requests are handled in countGetter
            // => only call batch.execute() and then retrieve the counts from countGetter promises
            try {
                await this.batch.execute();
            } catch (e) {
                if (e.name === FETCH_ABORT_ERROR) {
                    return null;
                }
            }

            const groupWithItemsWithCounts = {
                ...this.state.group,
                items: await Promise.all(
                    this.state.group.items.map(async (item) => {
                        return {
                            ...item,
                            count: countPromises[item.key] ? await countPromises[item.key] : null
                        };
                    })
                )
            };

            this.setState({
                groupWithItemsWithCounts
            });
        }
    };

    appendIsDisabled = (group: IMenuDefGroup): IMenuDefGroup => {
        if (!group) {
            return null;
        }

        return {
            ...group,
            items: group.items?.map((item) => {
                const isDisabled = item.isDisabledFn?.(this.context);

                return {
                    ...item,
                    isDisabled: !!isDisabled,
                    tooltip: isDisabled?.reason
                };
            })
        };
    };

    getAllViews = () => {
        const allViews = [...getMainMenuDefinition(this.context), ...OrganizationSettingsMenuDefinition];
        return filterMenu(allViews, this.context, this.props.permissionContext);
    };

    render() {
        const groupWithDisabled = this.appendIsDisabled(this.state.groupWithItemsWithCounts ?? this.state.group);

        if (groupWithDisabled?.items?.length === 0) {
            return <NoPermission/>;
        }

        if (!groupWithDisabled) {
            return <NotFound/>;
        }

        return (
                <NavDashboard group={groupWithDisabled}/>
        );
    }
}

export default withPermissionContext(withOData(SmartNavDashboard));