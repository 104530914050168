import React from "react";
import i18next from "i18next";
import {
    BasicInputSizes,
    CacheStrategy,
    FastEntryInputSizes,
    FieldType,
    FieldVisibility,
    GroupedField,
    IconSize,
    LabelStatus,
    NavigationSource,
    Sort,
    TextAlign,
    ValidatorType
} from "../../enums";
import { getItemBreadCrumbsText, IDefinition } from "../PageUtils";
import { TValue } from "../../global.types";
import BindingContext, { IEntity } from "../../odata/BindingContext";
import { TCellValue } from "@components/table";
import { StatusNoIcon, StatusYesIcon } from "@components/icon";
import { ifAny, IGetValueArgs, TValidatorFnResult } from "@components/smart/FieldInfo";
import { IFormDef } from "../../views/formView/Form";
import { SwitchType } from "@components/inputs/switch/Switch";
import { ISummaryItem } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import ChartOfAccountsFormView from "./ChartOfAccountsFormView";
import { ISelectItem } from "@components/inputs/select/BasicSelect";
import { ColoredText } from "../../global.style";
import { DefaultTheme } from "styled-components";
import { TFormatterFn } from "@components/smart/smartTable/SmartTable.utils";
import { IFormatOptions } from "@odata/OData.utils";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import {
    AccountDefFormatter,
    getAccountDef,
    getCommonFilterDefs,
    getCommonTableColumnsDefs,
    withDisplayName
} from "@components/smart/GeneralFieldDefinition";
import { setDefByEntityType } from "../getDefByEntityType";
import {
    AccountEntity,
    EntitySetName,
    EntityTypeName,
    IAccountEntity,
    IChartOfAccountsEntity,
    IFinancialReportLayoutSectionEntity
} from "@odata/GeneratedEntityTypes";
import {
    AccountCategoryCode,
    FiscalYearStatusCode,
    ReportSectionTypeCode,
    TaxApplicabilityCode
} from "@odata/GeneratedEnums";
import { ISplitPageTableDef } from "../../views/table/TableView.utils";
import { isNotDefined, isObjectEmpty } from "@utils/general";
import Text from "../../components/text";
import {
    BANK_ACCOUNT_BALANCE_SHEET_ACCOUNT_PREFIX,
    CASH_BOXES_BALANCE_SHEET_ACCOUNT_PREFIX,
    DASH_CHARACTER
} from "../../constants";
import { IAppContext } from "../../contexts/appContext/AppContext.types";
import { FormStorage } from "../../views/formView/FormStorage";
import {
    AccountType,
    CATEGORY_SELECT_PATH,
    CategoryItems,
    composeCategory,
    IChartOfAccountsFormCustomData
} from "./ChartOfAccounts.utils";
import { Model } from "../../model/Model";
import { ValidationError } from "yup";
import { getUtcDayjs } from "../../types/Date";

const DISABLED_PARENT_PREFIX = [CASH_BOXES_BALANCE_SHEET_ACCOUNT_PREFIX, BANK_ACCOUNT_BALANCE_SHEET_ACCOUNT_PREFIX];

export const accountCannotBeParent = (account: IAccountEntity): boolean => {
    return !!(!account || account.Parent?.Id);
};

export const typeFormatter = (val: TValue, { entity }: IFormatOptions<IAccountEntity>): TCellValue => {
    const title = val as string;
    const type = (entity.Type?.Code ?? entity.TypeCode) as AccountType;
    let color: keyof DefaultTheme;
    switch (true) {
        case [AccountType.Active, AccountType.Income].includes(type):
            color = "C_SEM_text_good";
            break;
        case [AccountType.Liability, AccountType.Expense].includes(type):
            color = "C_SEM_text_warning";
            break;
    }
    const iconInstance = <ColoredText color={color}> {val} </ColoredText>;

    return { tooltip: title, value: iconInstance };
};

const getBalanceSheetReportSectionItemsForRender = (isLiability?: boolean) => {
    return (items: ISelectItem[]) => {
        return items.filter(item => {
            const section = item.additionalData as IFinancialReportLayoutSectionEntity;
            const noNumberCode = isLiability ? ReportSectionTypeCode.BS_L : ReportSectionTypeCode.BS_A;

            return ((section.ReportSectionType?.Code === noNumberCode && !!section.Number) || (section.ReportSectionType?.Code === ReportSectionTypeCode.BS_S))
                    // only include sections that are part of FinancialReportLayout
                    && (!section.FinancialReportLayout.DateValidTo || getUtcDayjs(section.FinancialReportLayout.DateValidTo).isSameOrBefore(getUtcDayjs(), "day"));
        }).map(item => {
            item.isDisabled = item.children?.length > 0;
            return item;
        });
    };
};


const getIncomeStatementReportSectionItemsForRender = (isByNatureReportSection?: boolean) => {
    return (items: ISelectItem[], args: IGetValueArgs) => {
        const account = args.storage.data.entity as IAccountEntity;
        const type = account?.Type?.Code;
        let code: ReportSectionTypeCode;

        if (type === AccountType.Income) {
            code = isByNatureReportSection ? ReportSectionTypeCode.IS_N_I : ReportSectionTypeCode.IS_F_I;
        } else {
            code = isByNatureReportSection ? ReportSectionTypeCode.IS_N_E : ReportSectionTypeCode.IS_F_E;
        }


        return items.filter(item => {
            const section = item.additionalData as IFinancialReportLayoutSectionEntity;
            return section?.ReportSectionType?.Code === code;
        }).map(item => {
            item.isDisabled = item.children?.length > 0;
            return item;
        });
    };
};

export const genericStatusFormatter = (isPositive: boolean): TCellValue => {
    const title = i18next.t(`Common:General.${isPositive ? "Yes" : "No"}`);

    const props = {
        width: `${IconSize.asNumber("S") * 2}px`,
        height: IconSize.M
    };

    const iconInstance = isPositive ?
            <StatusYesIcon {...props} /> :
            <StatusNoIcon {...props} />;

    return { tooltip: title, value: iconInstance };
};

export const closedFormatter = (val: TValue): TCellValue => {
    return genericStatusFormatter(val as boolean);
};

export const activeFormatter: TFormatterFn = (val: TValue, values: IFormatOptions): TCellValue => {
    const isActive = values.entity.IsActive;

    return genericStatusFormatter(isActive);
};

const isParentInvertible = (args: IGetValueArgs) => {
    const storage = args.storage as FormStorage<IAccountEntity, IChartOfAccountsFormCustomData>;

    return storage.getCustomData().isParentInvertible;
};

const getIncomeStatementLayoutField = () => ({
    type: FieldType.HierarchyComboBox,
    width: BasicInputSizes.XL,
    isVisible: (args: IGetValueArgs) => {
        const type = args.storage.getValueByPath("Type/Code", { useDirectValue: false }) as string;
        return type === AccountType.Expense || type === AccountType.Income;
    },
    additionalProperties: [
        { id: "Name" }
    ],
    columns: [
        {
            id: "Number",
            additionalProperties: [{
                id: "/Name"
            }],
            formatter: AccountDefFormatter
        }
    ],
    // formatter: AccountDefFormatter,
    fieldSettings: {
        preloadItems: true, // TODO: fix setting value from parent, and then remove
        noRecordText: i18next.t("Common:Select.NoRecord"),
        displayName: "Number",
        localDependentFields: [
            { from: { id: "Name" }, to: { id: "Name" }, navigateFrom: NavigationSource.Itself }
        ],
        additionalProperties: [
            { id: "ReportSectionType" },
            { id: "FinancialReportLayout/DateValidTo" }
        ],
        transformFetchedItems: (items: ISelectItem[]): ISelectItem[] => {
            return items.filter(item => {
                // remove root level items that are not used here instead of in itemsForRender,
                // to have correct indent value on items
                const section = item.additionalData as IFinancialReportLayoutSectionEntity;

                return !!section.Number || section.ReportSectionType?.Code === ReportSectionTypeCode.BS_S;
            });
        }
    }
});

const getCategoryItems = (disableIsInvertible: boolean) => {
    return [
        {
            id: CategoryItems.BalanceAssets,
            label: i18next.t("ChartsOfAccounts:Items.Balance"),
            tabularData: [i18next.t("ChartsOfAccounts:Items.Balance"), i18next.t("ChartsOfAccounts:Items.Active"), "—"]
        },
        {
            id: CategoryItems.BalanceLiability,
            label: i18next.t("ChartsOfAccounts:Items.Balance"),
            tabularData: [i18next.t("ChartsOfAccounts:Items.Balance"), i18next.t("ChartsOfAccounts:Items.Liability"), "—"]
        },
        {
            id: CategoryItems.BalanceLiabilityAssets,
            label: i18next.t("ChartsOfAccounts:Items.Balance"),
            tabularData: [i18next.t("ChartsOfAccounts:Items.Balance"), i18next.t("ChartsOfAccounts:Items.ActiveAndLiability"), "—"],
            isDisabled: disableIsInvertible
        },
        {
            id: CategoryItems.IncomeStatementExpense,
            label: i18next.t("ChartsOfAccounts:Items.Statement"),
            tabularData: [i18next.t("ChartsOfAccounts:Items.Statement"), i18next.t("ChartsOfAccounts:Items.Expense"), i18next.t("ChartsOfAccounts:NonTaxable")]
        },
        {
            id: CategoryItems.IncomeStatementExpenseTaxable,
            label: i18next.t("ChartsOfAccounts:Items.Statement"),
            tabularData: [i18next.t("ChartsOfAccounts:Items.Statement"), i18next.t("ChartsOfAccounts:Items.Expense"), i18next.t("ChartsOfAccounts:Taxable")]
        },
        {
            id: CategoryItems.IncomeStatementActive,
            label: i18next.t("ChartsOfAccounts:Items.Statement"),
            tabularData: [i18next.t("ChartsOfAccounts:Items.Statement"), i18next.t("ChartsOfAccounts:Items.Income"), i18next.t("ChartsOfAccounts:NonTaxable")]
        },
        {
            id: CategoryItems.IncomeStatementActiveTaxable,
            label: i18next.t("ChartsOfAccounts:Items.Statement"),
            tabularData: [i18next.t("ChartsOfAccounts:Items.Statement"), i18next.t("ChartsOfAccounts:Items.Income"), i18next.t("ChartsOfAccounts:Taxable")]
        }
    ];
};

const getInvertibleItems = () => {
    return [{
        id: "A",
        label: i18next.t("ChartsOfAccounts:Items.Active")
    }, {
        id: "L",
        label: i18next.t("ChartsOfAccounts:Items.Liability")
    }];
};

export interface ChartOfAccountsGetDef {
    sortDir?: Sort;
    title?: string;
    tableId?: string;
    formId?: string;
    formControl?: any;
    translationFiles?: string[];
}

const isPostedOrMandatory = (args: IGetValueArgs): boolean => {
    const storage = args.storage as FormStorage<IAccountEntity, IChartOfAccountsFormCustomData>;
    const isPosted = storage.getCustomData().isPosted;
    const isMandatory = storage.getCustomData().isMandatory;
    return isPosted || isMandatory;
};

const isMandatory = (args: IGetValueArgs): boolean => {
    const storage = args.storage as FormStorage<IAccountEntity, IChartOfAccountsFormCustomData>;
    return !!storage.getCustomData().isMandatory;
};

const hasChildWithJournalEntry = (args: IGetValueArgs) => {
    const storage = args.storage as FormStorage<IAccountEntity, IChartOfAccountsFormCustomData>;
    return !!storage.getCustomData().journalData?.DescendantsHaveJournalEntries;
};


const hasJournalEntries = (args: IGetValueArgs): boolean => {
    const storage = args.storage as FormStorage<IAccountEntity, IChartOfAccountsFormCustomData>;
    const journalData = storage.getCustomData().journalData;

    // AccountCategoryCode.IncomeStatement shouldn't have continuity through fiscal years => DEV-10763
    return journalData?.HasJournalEntries ||
            (args.storage.data.entity?.Category?.Code !== AccountCategoryCode.IncomeStatement && journalData?.HasJournalEntriesInDifferentFiscalYear);
};

export const getDefinitions = (context?: IAppContext, args?: ChartOfAccountsGetDef): IDefinition => {
    const balanceSheetLayout = AccountEntity.BalanceSheetLayout;
    const incomeStatementLayout = AccountEntity.IncomeStatementLayout;

    const filterBarDef: IFilterGroupDef[] = [{
        ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
        defaultFilters: [
            AccountEntity.Number,
            AccountEntity.Name,
            AccountEntity.Category,
            AccountEntity.Type,
            AccountEntity.IsActive,
            AccountEntity.IsClosed
        ],
        filterDefinition: {
            Number: { description: false },
            Name: { description: false },
            ...withDisplayName(AccountEntity.Category, { description: false }),
            ...withDisplayName(AccountEntity.Type, { description: false }),
            IsActive: { description: false },
            IsClosed: {
                type: FieldType.ValueHelper,
                description: false
            },
            Note: { description: false },
            ...withDisplayName(AccountEntity.Parent, { description: false }),
            ...withDisplayName(AccountEntity.TaxApplicability, { description: false }),
            ...getCommonFilterDefs({ description: false })
        },
        isValueHelp: true
    }];

    const parentDefinition = {
        sort: [{
            id: "FiscalYear/DateStart",
            sort: Sort.Asc
        }],
        columns: [
            { id: "Name" },
            { id: "FiscalYear/Number" },
            { id: "FiscalYear/StatusCode" }
        ],
        entitySet: EntitySetName.ChartsOfAccounts
    };

    const table: ISplitPageTableDef = {
        parentDefinition,
        filterBarDef,
        id: args?.tableId ?? "chartOfAccountTable",
        filter: `${AccountEntity.CategoryCode} ne '${AccountCategoryCode.FinancialStatement}'`,
        columns: [
            // Parent has to be defined here, before Name, to be used in Excel export in correct position
            "Parent",
            "Name",
            "Number",
            "Category",
            "Type",
            "TaxApplicability",
            "Note",
            "IsActive",
            "IsClosed"
        ],
        columnDefinition: {
            Parent: {
                id: "Parent",
                fieldSettings: {
                    displayName: "Name"
                },
                fieldVisibility: FieldVisibility.ExportOnly
            },
            Name: { id: "Name" },
            Number: { id: "Number" },
            Category: {
                id: "Category",
                fieldSettings: {
                    displayName: "Name"
                }
            },
            Type: {
                id: "Type",
                formatter: typeFormatter,
                fieldSettings: {
                    displayName: "Name"
                }
            },
            Note: {
                id: "Note"
            },
            IsActive: {
                id: "IsActive",
                formatter: activeFormatter,
                textAlign: TextAlign.Center
            },
            IsClosed: {
                id: "IsClosed",
                label: i18next.t("ChartsOfAccounts:Closed"),
                formatter: closedFormatter,
                textAlign: TextAlign.Center
            },
            TaxApplicability: {
                id: "TaxApplicability",
                additionalProperties: [{
                    id: "/Accounts/Category"
                }],
                formatter: (val, args): TCellValue => {
                    if (isNotDefined(val)) {
                        return DASH_CHARACTER;
                    }
                    return i18next.t(`ChartsOfAccounts:${val === TaxApplicabilityCode.TaxApplicable ? "Taxable" : "NonTaxable"}`).toString();
                }
            },
            ...getCommonTableColumnsDefs()
        },
        title: args?.title ? i18next.t(args?.title) : i18next.t("ChartsOfAccounts:Title"),
        initialSortBy: [{ id: "Number", sort: Sort.Asc }],
        hierarchy: "Children"
    };

    let accountDef;

    const summary: ISummaryItem[] = [];

    const incomeStatementLayoutField = getIncomeStatementLayoutField();

    const form: IFormDef = {
        id: args?.formId ?? "chartOfAccountForm",
        summary,
        translationFiles: args?.translationFiles ?? getDefinitions.translationFiles,
        formControl: args?.formControl || ChartOfAccountsFormView,
        isDeletable: true,
        getItemBreadCrumbText: (storage: Model) =>
                getItemBreadCrumbsText(storage, i18next.t("ChartsOfAccounts:NewAccount"), storage.data.entity?.Name),
        isReadOnly: (args: IGetValueArgs) => {
            const storage = args.storage as FormStorage<IAccountEntity, IChartOfAccountsFormCustomData>;
            // system entities are readOnly (id < 0)
            const chartsOfAccounts: IChartOfAccountsEntity[] = storage.getCustomData().chartOfAccounts;
            const coaId = storage.data.bindingContext?.getParent()?.getKey();
            const selectedChart = chartsOfAccounts?.find(coa => coa.Id === coaId);
            return selectedChart?.FiscalYear?.StatusCode === FiscalYearStatusCode.Closed;
        },
        additionalProperties: [{
            id: "Category"
        }, {
            id: "Type"
        }, {
            id: "TaxApplicabilityCode"
        }, {
            id: "IsInvertible"
        }, {
            id: "Parent",
            additionalProperties: [{
                id: "IsInvertible"
            }]
        }, {
            id: "Children"
        }],
        fieldDefinition: {
            Parent: {
                ...(accountDef = getAccountDef("/Parent/Number")),
                // content of this select might be changed directly in the form
                formatter: AccountDefFormatter,
                cacheStrategy: CacheStrategy.Route,
                width: BasicInputSizes.XL,
                fieldSettings: {
                    ...accountDef.fieldSettings,
                    noRecordText: i18next.t("Common:Select.NoRecord"),
                    entitySet: (args: IGetValueArgs) => {
                        return args.bindingContext.getParent().removeKey().toString();
                    },
                    transformFetchedItems: (items) => {
                        return items.map(item => {
                            if (accountCannotBeParent(item.additionalData as IAccountEntity)) {
                                item.isDisabled = true;
                            }


                            return item;
                        });
                    }
                },
                filter: {
                    select: (args: IGetValueArgs) => {
                        return args.data.Id ? `Id ne ${args.data.Id}` : "";
                    }
                },
                additionalProperties: [{
                    id: "Name"
                }],
                isDisabled: (args) => {
                    if (args.storage.data.bindingContext.isNew()) {
                        return false;
                    }

                    const entity = args.storage.getEntity<IAccountEntity>();

                    return !!entity.Children?.length || DISABLED_PARENT_PREFIX.includes(parseInt(entity.Parent?.Number));
                },
                isReadOnly: ifAny(hasJournalEntries, isPostedOrMandatory, hasChildWithJournalEntry),
                affectedFields: [
                    {
                        id: "Number",
                        revalidate: true
                    }
                ]
            },
            Number: {
                width: FastEntryInputSizes.S,
                validator: {
                    type: ValidatorType.Number,
                    settings: {
                        customValidator: (value: TValue, args: IGetValueArgs): TValidatorFnResult => {
                            const hasParent = !!(args.storage.data.entity as IAccountEntity).Parent;

                            if (!hasParent && value?.toString().length > 3) {
                                return new ValidationError(i18next.t(`ChartsOfAccounts:Validation.SyntheticAccountNumberLength`), value, args.bindingContext.getPath());
                            }

                            return true;
                        }
                    }
                },
                isReadOnly: ifAny(hasJournalEntries, isPostedOrMandatory, hasChildWithJournalEntry),
                extraFieldContent: ({ storage }) => {
                    const { Parent } = storage.data.entity;
                    const hasParent = !isObjectEmpty(Parent);
                    return hasParent ? (<Text>{Parent.Number}</Text>) : null;
                }
            },
            Name: {
                width: BasicInputSizes.XL,
                isReadOnly: ifAny(hasJournalEntries, isPostedOrMandatory, hasChildWithJournalEntry)
            },
            Note: {
                width: BasicInputSizes.XL
            },
            [CATEGORY_SELECT_PATH]: {
                isReadOnly: ifAny(hasJournalEntries, isPostedOrMandatory, isParentInvertible, hasChildWithJournalEntry),
                isRequired: true,
                label: i18next.t("ChartsOfAccounts:Category"),
                type: FieldType.ComboBox,
                width: BasicInputSizes.XL,
                fieldSettings: {
                    displayName: "Category",
                    shouldDisplayAdditionalColumns: true,
                    itemsFactory: async (args: IGetValueArgs): Promise<ISelectItem[]> => {
                        const storage = args.storage as FormStorage<IAccountEntity, IChartOfAccountsFormCustomData>;
                        return getCategoryItems(storage.getCustomData().journalData?.DescendantsHaveJournalEntries);
                    }
                },
                columns: [{
                    id: "Category",
                    label: i18next.t("ChartsOfAccounts:Category")
                }, {
                    id: "Type",
                    label: i18next.t("ChartsOfAccounts:Type")
                }, {
                    id: "Taxable",
                    label: i18next.t("ChartsOfAccounts:Taxable")
                }],
                affectedFields: [{
                    id: `${balanceSheetLayout}/LiabilitiesReportSection`
                }, {
                    id: `${balanceSheetLayout}/AssetsReportSection`
                }, {
                    id: `${balanceSheetLayout}/IsAssetsCorrection`
                }, {
                    id: BindingContext.localContext("IsInvertible")
                }, {
                    id: `${incomeStatementLayout}/ByNatureReportSection`
                }, {
                    id: `${incomeStatementLayout}/ByFunctionReportSection`
                }],
                comparisonFunction: (entity1: IEntity, entity2: IEntity, bc: BindingContext): boolean => {
                    return composeCategory(entity1) === composeCategory(entity2);
                }
            },
            [BindingContext.localContext("IsInvertible")]: {
                isReadOnly: ifAny(hasJournalEntries, isPostedOrMandatory, isParentInvertible),
                type: FieldType.ComboBox,
                label: i18next.t("ChartsOfAccounts:BalanceLayout.ActiveLiability"),
                fieldSettings: {
                    items: getInvertibleItems()
                },
                isVisible: (args: IGetValueArgs) => {
                    const category = args.storage.getValueByPath(CATEGORY_SELECT_PATH, { useDirectValue: false }) as string;
                    return category === CategoryItems.BalanceLiabilityAssets;
                },
                defaultValue: AccountType.Active
            },
            [`${balanceSheetLayout}/IsAssetsCorrection`]: {
                type: FieldType.Checkbox,
                labelStatus: LabelStatus.Hidden,
                label: i18next.t("ChartsOfAccounts:BalanceLayout.Correction"),
                isVisible: (args: IGetValueArgs) => {
                    const type = args.storage.getValueByPath("Type/Code", { useDirectValue: false }) as string;
                    return type === AccountType.Active && !args.storage.getValueByPath("IsInvertible");
                },
                groupedField: GroupedField.NoWrapEnd,
                isReadOnly: isParentInvertible
            },
            [`${balanceSheetLayout}/LiabilitiesReportSection`]: {
                ...incomeStatementLayoutField,
                label: i18next.t("ChartsOfAccounts:BalanceLayout.Balance"),
                isVisible: (args: IGetValueArgs) => {
                    const type = args.storage.getValueByPath("Type/Code");
                    const isInvertible = args.storage.getValueByPath("IsInvertible");
                    return type === AccountType.Liability || !!isInvertible;
                },
                isReadOnly: false,
                fieldSettings: {
                    ...incomeStatementLayoutField.fieldSettings,
                    itemsForRender: getBalanceSheetReportSectionItemsForRender(true)
                }
            },
            [`${balanceSheetLayout}/AssetsReportSection`]: {
                ...incomeStatementLayoutField,
                label: i18next.t("ChartsOfAccounts:BalanceLayout.Balance"),
                groupedField: GroupedField.NoWrapStart,
                isVisible: (args: IGetValueArgs) => {
                    const type = args.storage.getValueByPath("Type/Code");
                    const isInvertible = args.storage.getValueByPath("IsInvertible");
                    return type === AccountType.Active || !!isInvertible;
                },
                isReadOnly: false,
                fieldSettings: {
                    ...incomeStatementLayoutField.fieldSettings,
                    itemsForRender: getBalanceSheetReportSectionItemsForRender()
                }
            },
            [`${incomeStatementLayout}/ByNatureReportSection`]: {
                ...incomeStatementLayoutField,
                isReadOnly: isParentInvertible,
                fieldSettings: {
                    ...incomeStatementLayoutField.fieldSettings,
                    itemsForRender: getIncomeStatementReportSectionItemsForRender(true)
                }
            },

            [`${incomeStatementLayout}/ByFunctionReportSection`]: {
                ...incomeStatementLayoutField,
                fieldSettings: {
                    ...incomeStatementLayoutField.fieldSettings,
                    itemsForRender: getIncomeStatementReportSectionItemsForRender()
                },
                isReadOnly: isParentInvertible
            },
            IsActive: {
                type: FieldType.Switch,
                fieldSettings: {
                    type: SwitchType.Icons
                },
                isDisabled: isMandatory,
                defaultValue: true
            },
            IsClosed: {
                label: i18next.t("ChartsOfAccounts:Closed"),
                type: FieldType.Switch,
                fieldSettings: {
                    type: SwitchType.Icons
                }
            }
        },
        groups: [{
            id: "mainGroup",
            rows: [
                [{ id: "Parent" }, { id: "Number" }, { id: "Name" }], [{ id: "Note" }],
                [{ id: CATEGORY_SELECT_PATH }, { id: BindingContext.localContext("IsInvertible") }],
                [{ id: `${balanceSheetLayout}/AssetsReportSection` }, { id: `${balanceSheetLayout}/IsAssetsCorrection` }], [{ id: `${balanceSheetLayout}/LiabilitiesReportSection` },
                    { id: `${incomeStatementLayout}/ByNatureReportSection` }, { id: `${incomeStatementLayout}/ByFunctionReportSection` }],

                [{ id: "IsActive" }, { id: "IsClosed" }]
            ]
        }]
    };

    const getEntitySet = (args: IGetValueArgs) => {
        const activeCOAId = args.data?.parentId;

        if (!activeCOAId) {
            return undefined;
        }

        return `${EntitySetName.ChartsOfAccounts}(${activeCOAId})/Accounts`;
    };

    return {
        entitySet: getEntitySet,
        table,
        form
    };
};

getDefinitions.translationFiles = ["ChartsOfAccounts"];
setDefByEntityType(EntityTypeName.Account, getDefinitions);