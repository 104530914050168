import styled, { css } from "styled-components/macro";
import {
    getDisabledStyle,
    getFocusBorderElement,
    LocalStackOrder,
    multiline_ellipsis,
    T_TITLE_2,
    T_TITLE_4_NORMAL
} from "../../global.style";
import { transparentize } from "polished";
import { IconButton } from "../button";
import { PropsWithTheme } from "../../theme";
import { MENU_ICON_SIZE } from "../toolbar";
import { StyledToolbar } from "../toolbar/Toolbar.styles";

export const StyledFileUploader = styled.div<{ isHidden: boolean }>`
    width: 100%;
    flex: 1 1 auto;
    display: ${props => props.isHidden ? "none" : "flex"};
    flex-direction: column;

    position: relative;
    ${getFocusBorderElement({ offsetY: 10, offsetX: 18, keyboardOnly: true })}
    ${StyledToolbar} {
        margin-bottom: 10px;
    }
`;

export const StyledHiddenFileInput = styled.input.attrs({ type: "file" })`
    display: none;
`;

export const FileIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: ${MENU_ICON_SIZE}px;
`;

interface IDropArea {
    isFileOver: boolean;
    hasFiles: boolean;
    disableBorders: boolean;
}

const getBorderColor = (props: IDropArea & PropsWithTheme) => {
    if (props.isFileOver) {
        return transparentize(0.5, props.theme.C_ACT_main);
    }

    return props.hasFiles || props.disableBorders ? "transparent" : props.theme.C_ACT_thick_line;
};

export interface IFileDropArea {
    isFileOver?: boolean;
}

export const fileDropAreaBackground = css<IFileDropArea>`
    background-color: ${props => props.isFileOver ? transparentize(0.9, props.theme.C_ACT_der) : "transparent"};
`;

export const DROPAREA_PADDING = 8;
export const DropAreaWithPadding = css`
    padding: 0 ${DROPAREA_PADDING}px;
    margin: 0 -${DROPAREA_PADDING}px;
`;

export const DropArea = styled.div<IDropArea>`
    position: relative;
    flex: 1 1 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: ${props => props.hasFiles ? "flex-start" : "center"};
    align-content: ${props => props.hasFiles ? "flex-start" : "center"};
    color: ${props => props.isFileOver ? props.theme.C_ACT_main : props.theme.C_ACT_thick_line};
    border: ${props => `2px dashed ${getBorderColor(props)}`};
    border-radius: 4px;

    ${props => props.isFileOver && css`
        &::before {
            content: " ";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            ${fileDropAreaBackground};
            z-index: ${LocalStackOrder.Overlay};
            border-radius: inherit;
        }`
    }
`;

export const Placeholder = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: ${LocalStackOrder.AboveOverlay};
`;

export const Description = styled.div<{ areaHeight: number }>`
    ${T_TITLE_2};
    // 2% of area height according to spec
    margin: ${props => props.areaHeight * 0.02}px 0;
`;

export const CustomDescription = styled.div`
    ${T_TITLE_4_NORMAL};
    line-height: 1.43;
    white-space: pre;
    text-align: center;
`;

export const StyledFile = styled.div`
    width: 143px;
    height: 235px;
    padding: 5px 15px 15px;
    position: relative;
    ${getFocusBorderElement({})};
`;

export const FileInnerWrapper = styled.div<{ isDisabled: boolean; isDisabledLook: boolean; isActionIconVisible: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: visible;
    position: relative;
    cursor: ${props => props.isActionIconVisible ? "default" : "pointer"};

    ${T_TITLE_4_NORMAL};

    ${props => props.isDisabled && getDisabledStyle(props.isDisabled)};
    ${props => props.isDisabledLook && css`opacity: ${props.theme.disabled_opacity}`};
`;

export const FileName = styled.div`
    position: relative;
    top: -3px;
    text-align: center;
    ${multiline_ellipsis("100%", 4)};
    word-break: break-word;
    color: ${props => props.theme.C_TEXT_primary};
`;

export const FileActionButton = styled(IconButton)`
    position: absolute;
    top: 3px;
    left: 3px;
    z-index: 1;
`;