import React, { useCallback } from "react";
import { UploadedFilesStyled } from "./TicketMessage.styles";
import MessageFile from "./MessageFile";

interface IProps {
    files: File[];
    onRemove: (file: File, idx: number) => void;
}

const UploadedFiles: React.FC<IProps> = ({ files, onRemove }) => {

    const handleRemove = useCallback((idx) => {
        const file = files[idx];
        onRemove(file, idx);
    }, [files, onRemove]);

    const handleClick = useCallback((idx) => {
        const file = files[idx];
        window.open(URL.createObjectURL(file));
    }, [files]);

    return files?.length ? (
        <UploadedFilesStyled>
            {files.map((f, idx) => (
                    <MessageFile key={idx} id={idx} name={f.name} onClick={handleClick} onRemove={handleRemove}/>
            ))}
        </UploadedFilesStyled>
    ) : null;
};

export default UploadedFiles;