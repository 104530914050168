import { ColoredIcon, EmailIcon } from "@components/icon";
import LinkWithTextInDialog from "./LinkWithTextInDialog";
import Message from "../../components/chat/Message";
import React from "react";

interface IProps {
    text: string;
}

const EmailBodyMessage: React.FC<IProps> = ({ text }) => {
    return (
        <Message icon={<ColoredIcon><EmailIcon/></ColoredIcon>}>
            <LinkWithTextInDialog text={text}/>
        </Message>
    );
};

export default EmailBodyMessage;