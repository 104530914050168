import { BorderSize, IconSize, MouseButton, RowType, Sort, Status, TableAddingRowType, TextAlign } from "../../enums";
import React, { AriaAttributes, PureComponent } from "react";
import TestIds from "../../testIds";
import { IColumnResizeEvent, IRow, ISort, ISticky, TId } from "./Table";
import { TableCaretIcon } from "../icon";
import {
    ActionColumn,
    AfterContent,
    AfterContentWrapper,
    GroupIconWrapper,
    HeaderCellInner,
    HoverContentWrapper,
    IconWrapper,
    LabelGroup,
    Resizer,
    RowStretcher,
    SortDownIconStyled,
    SortIconStyled,
    StyledBodyCell,
    StyledBodyCellContent,
    StyledHeaderCell,
    StyledStatusHighlight,
    StyledValueRow,
    ValueWrapper
} from "./Rows.styles";
import { IModifierKeys, TRecordAny } from "../../global.types";
import { KeyName } from "../../keyName";
import Tooltip, { ITooltipProps } from "../tooltip/Tooltip";
import { HeaderTooltipIcon } from "./Table.styles";
import { removeWhiteSpace } from "@utils/string";
import { withTranslation, WithTranslation } from "react-i18next";
import { composeRefHandlers, doesElementContainsElement } from "@utils/general";

export interface IRowMouseEvent {
    originalEvent: React.MouseEvent;
    target: Element;
    id: TId;
    groupId?: TId;
    part: number;
    customData: any;
}

export interface IRowFocusEvent {
    originalEvent: React.FocusEvent;
    id: TId;
    groupId?: TId;
    customData: any;
}

export interface IRowProps {
    id: TId;
    /** Id for testing purposes, can be different from id.toString() */
    dataId?: string;
    /** Used for shared hover state between multiple rows */
    groupId?: TId;
    onClick?: (id: TId, props: IRowProps, modifiers?: IModifierKeys) => void;
    onContextMenu?: (id: TId, props: IRowProps, event: React.MouseEvent) => void;
    onDragStart?: (id: TId, props: IRowProps, event: React.DragEvent<HTMLDivElement>) => void;
    onMouseEnter?: (args: IRowMouseEvent) => void;
    onMouseLeave?: (args: IRowMouseEvent) => void;
    onFocus?: (args: IRowFocusEvent) => void;
    selected?: boolean;
    isList?: boolean;
    isLocked?: boolean;
    type?: RowType;
    statusHighlight?: Status;
    statusHighlightTooltip?: ITooltipProps["content"];
    isDivider?: boolean;
    sticky?: ISticky;
    minWidth?: number;
    offset?: number;
    level?: number;
    nextRowIsSelected?: boolean;
    action?: React.ReactElement;
    addingRow?: TableAddingRowType;
    tooltip?: string;
    isDisabled?: boolean;
    isHoverDisabled?: boolean;
    isHighlighted?: boolean;
    style?: React.CSSProperties;
    passRef?: React.Ref<HTMLDivElement>;
    passProps?: TRecordAny;
    hierarchy?: string;
    customBackgroundColor?: string;
    customRowBorderColor?: string;
    /** Can be used to store some custom data, passed in onClick event */
    customData?: any;

    // group properties
    hasRows?: boolean;
    /** Whether any row in current level is a group with toggle caret */
    hasGroups?: boolean;
    onGroupToggle?: (id: TId) => void;
    open?: boolean;
    isBold?: boolean;
    nextRow?: IRow;
}

export class Row extends PureComponent<IRowProps> {
    /* We want to prevent onClick when user just wants to select some text for copy paste */
    selectedTextWhenMouseDown = "";
    _rowRef = React.createRef<HTMLDivElement>();

    handleRowClick = (event: React.MouseEvent | React.KeyboardEvent): boolean => {
        if (event.defaultPrevented
            // ignore clicks on <a> tag (DrilldownLink)
            || (event.target as HTMLElement).tagName === "A"
        ) {
            return false;
        }

        if (this.selectedTextWhenMouseDown === removeWhiteSpace(window.getSelection().toString())) {
            const { shiftKey, altKey, ctrlKey, metaKey } = event;
            this.props.onClick?.(this.props.id, this.props, { shiftKey, altKey, ctrlKey, metaKey });
        }

        this.selectedTextWhenMouseDown = "";

        if (!this.props.action && this.props.hasRows && this.props.onGroupToggle) {
            this.props.onGroupToggle(this.props.id);
        }

        return true;
    };

    handleMouseDown = (event: React.MouseEvent): void => {
        if (event.shiftKey) {
            // when shift key is pressed, we don't want to make selection in table (prevent default), but we
            // do make multi row selection, @see handleRowClick
            event.preventDefault();
        }

        const selection = window.getSelection();

        if (selection && doesElementContainsElement(this._rowRef.current, selection.anchorNode?.parentElement as Element)) {
            this.selectedTextWhenMouseDown = removeWhiteSpace(window.getSelection().toString());
        }
    };

    handleGroupIconClick = (e: React.MouseEvent<HTMLDivElement>): void => {
        this.props.onGroupToggle?.(this.props.id);
        e.stopPropagation(); // prevent rowClick event
    };

    handleMouseEnter = (event: React.MouseEvent): void => {
        this.props.onMouseEnter?.({
            originalEvent: event,
            target: (event.target as HTMLDivElement).parentElement.parentElement,
            id: this.props.id,
            groupId: this.props.groupId,
            part: -1,
            customData: this.props.customData
        });
    };

    handleMouseLeave = (event: React.MouseEvent): void => {
        this.props.onMouseLeave?.({
            originalEvent: event,
            target: (event.target as HTMLDivElement).parentElement.parentElement,
            id: this.props.id,
            groupId: this.props.groupId,
            part: -1,
            customData: this.props.customData
        });
    };

    handleFocus = (event: React.FocusEvent): void => {
        this.props.onFocus?.({
            originalEvent: event,
            id: this.props.id,
            groupId: this.props.groupId,
            customData: this.props.customData
        });
    };

    handleKeyDown = (e: React.KeyboardEvent): void => {
        if (e.key === KeyName.Enter) {
            const handled = this.handleRowClick(e);

            // without the check, enter doesn't work on drilldown link
            if (handled) {
                e.preventDefault();
            }
        } else if (e.key === KeyName.Space) {
            this.props.hasRows && this.props.onGroupToggle?.(this.props.id);
            e.preventDefault();
        }
    };

    handleContextMenu = (event: React.MouseEvent): void => {
        this.props.onContextMenu?.(this.props.id, this.props, event);
    };

    handleDragStart = (event: React.DragEvent<HTMLDivElement>): void => {
        this.props.onDragStart?.(this.props.id, this.props, event);
    };

    // TODO: are 4 different test ids necessary
    dataTestId = (): string => {
        if (this.props.type === RowType.Aggregation) {
            return TestIds.TableAggregationRow;
        } else if (this.props.type === RowType.Group) {
            return TestIds.TableGroupRow;
        } else if (this.props.type === RowType.Merged) {
            return TestIds.TableMergedRow;
        }
        return TestIds.TableRow;
    };

    render() {
        return (
            <Tooltip isHidden={!this.props.tooltip}
                     content={this.props.tooltip}>
                {(ref) =>
                    <StyledValueRow onClick={this.handleRowClick}
                                    onMouseDown={this.handleMouseDown}
                                    onKeyDown={this.handleKeyDown}
                                    onMouseEnter={this.handleMouseEnter}
                                    onMouseLeave={this.handleMouseLeave}
                                    onContextMenu={!this.props.isDisabled ? this.handleContextMenu : null}
                                    onDragStart={this.handleDragStart}
                                    draggable={!this.props.isDisabled && !!this.props.onDragStart}
                                    onFocus={this.handleFocus}
                                    selected={this.props.selected}
                                    isList={this.props.isList}
                                    isDisabled={this.props.isDisabled}
                                    isHoverDisabled={this.props.isHoverDisabled}
                                    isHighlighted={this.props.isHighlighted}
                                    hasAction={!!this.props.action}
                                    isOpen={this.props.open}
                                    hasRows={this.props.hasRows}
                                    _type={this.props.type}
                                    nextRow={this.props.nextRow}
                                    nextRowIsSelected={this.props.nextRowIsSelected}
                                    hasGroups={!!this.props.hierarchy}
                                    isDivider={this.props.isDivider}
                                    sticky={this.props.sticky}
                                    customBackgroundColor={this.props.customBackgroundColor}
                                    customBorderColor={this.props.customRowBorderColor}
                                    ref={composeRefHandlers(ref, this._rowRef)}
                                    key={this.props.id.toString()}
                                    _minWidth={this.props.minWidth}
                                    _offset={this.props.offset}
                                    level={this.props.level}
                                    isBold={this.props.isBold}
                                    data-testid={this.dataTestId()}
                                    data-groupid={this.props.groupId}
                                    data-id={this.props.dataId ?? this.props.id.toString()}
                                    aria-selected={!!this.props.selected}
                                    aria-expanded={this.props.open}
                                    role="row" {...this.props.passProps}>
                        {this.props.statusHighlight &&
                            <Tooltip content={this.props.statusHighlightTooltip}>
                                {(ref) => (
                                    <StyledStatusHighlight ref={ref}
                                                           data-testid={TestIds.State}
                                                           statusHighlight={this.props.statusHighlight}
                                                           selected={this.props.selected}/>
                                )}
                            </Tooltip>
                        }
                        {(this.props.hasGroups || this.props.hierarchy) &&
                            this.props.hasRows &&
                            <GroupIconWrapper isOpen={this.props.open} onClick={this.handleGroupIconClick}>
                                <TableCaretIcon width={IconSize.XS} height={IconSize.XS}/>
                            </GroupIconWrapper>
                        }
                        {this.props.children}
                        <RowStretcher/>
                        {this.props.action &&
                            <ActionColumn data-testid={TestIds.TableRowAction}>
                                {this.props.action}
                            </ActionColumn>
                        }
                    </StyledValueRow>}
            </Tooltip>
        );
    }
}

export interface ICellProps {
    id: TId;
    columnIndex: number;
    sticky?: ISticky;
    isLoading?: boolean;
    isColumnHighlighted?: boolean;
    isRowHighlighted?: boolean;
    border?: BorderSize;
    first?: boolean;
    last?: boolean;
    hasGroups?: boolean;
    tooltip?: string | React.ReactNode;
    onlyShowTooltipWhenChildrenOverflowing?: boolean;
    textAlign?: TextAlign;
    width: number;
    level?: number;
    customBackgroundColor?: string;
    // can be used as "subcolumn" positioned after the cell value
    afterContent?: React.ReactNode;
    hoverContent?: React.ReactNode;
    keepSpaceForStatus?: boolean;
    hasAction?: boolean;
    afterContentMinWidth?: number;
    allowOverflow?: boolean;
    stretchContent?: boolean;
    isHierarchy?: boolean;
    isForPrint?: boolean;
    onColumnResize?: (props: IColumnResizeEvent) => void;
}

export interface IHeaderCellProps extends ICellProps {
    sort?: Sort;
    // if this column isn't sorted, next sort direction on click is selected
    // based on the current sort (on another column which actually has sort)
    currentSort?: Sort;
    info?: string;
    disableSort?: boolean;
    onClick?: (props: ISort) => void;
}

class HeaderCell extends PureComponent<IHeaderCellProps & WithTranslation> {
    onLabelGroupClick = (): void => {
        let sort: Sort;

        if (this.props.sort || !this.props.currentSort) {
            sort = !this.props.sort || this.props.sort === Sort.Desc ? Sort.Asc : Sort.Desc;
        } else {
            sort = this.props.currentSort;
        }

        if (this.props.onClick && !this.props.disableSort) {
            this.props.onClick({
                id: this.props.id,
                sort
            });
        }
    };

    getAriaSort = (): AriaAttributes["aria-sort"] => {
        if (!this.props.sort) {
            return null;
        }
        return this.props.sort === Sort.Asc ? "ascending" : "descending";
    };

    render() {
        // html2canvas doesn't support css rotation = >use two icons instead
        const currentSort = this.props.sort ?? this.props.currentSort;
        const Icon = !currentSort || currentSort === Sort.Asc ? SortIconStyled : SortDownIconStyled;

        return (
            <StyledHeaderCell
                isColumnHighlighted={this.props.isColumnHighlighted}
                isRowHighlighted={this.props.isRowHighlighted}
                border={this.props.border}
                isForPrint={this.props.isForPrint}
            >
                {/*we don't wont to propagate all the props, for example onClick to header cell*/}
                <HeaderCellInner _width={this.props.width}
                                 first={this.props.first}
                                 last={this.props.last}
                                 hasAction={this.props.last && this.props.hasAction}
                                 sticky={this.props.sticky}
                                 textAlign={this.props.textAlign}
                                 aria-sort={this.getAriaSort()}
                                 data-testid={TestIds.TableHeaderCell}
                                 role="columnheader">
                    <LabelGroup textAlign={this.props.textAlign}
                                onClick={this.onLabelGroupClick}
                                disableSort={this.props.disableSort}>
                        <ValueWrapper textAlign={this.props.textAlign}
                                      data-column-index={this.props.columnIndex}
                                      data-testid={TestIds.TableHeaderCellContent}>
                            {this.props.children}
                        </ValueWrapper>
                        {/* !!! IconWrapper has to be right next to ValueHelper because of hover styles */}
                        {
                            <IconWrapper textAlign={this.props.textAlign}
                                         isAscending={this.props.sort === Sort.Asc}
                                         title={this.props.t(`Components:Table.${this.props.sort === Sort.Asc ? "Ascending" : "Descending"}`)}
                                         data-testid={this.props.sort ? TestIds.ColumnSortIconWrapper : null}>
                                <Icon sort={this.props.sort} disableSort={this.props.disableSort}/>
                            </IconWrapper>
                        }
                        {this.props.info &&
                            <HeaderTooltipIcon offsetY={18}>
                                {this.props.info}
                            </HeaderTooltipIcon>
                        }
                    </LabelGroup>
                </HeaderCellInner>
            </StyledHeaderCell>
        );
    }
}

const HeaderCellExtended = withTranslation(["Components"])(HeaderCell);
export { HeaderCellExtended as HeaderCell };

export class BodyCell extends PureComponent<ICellProps> {
    static defaultProps: Partial<ICellProps> = {
        level: 0,
        onlyShowTooltipWhenChildrenOverflowing: true
    };

    cellRef = React.createRef<HTMLDivElement>();
    initOffset: number;

    handleMouseDown = (event: React.MouseEvent): void => {
        if (event.button !== MouseButton.MainButton) {
            return;
        }

        event.preventDefault(); // disable text selection
        event.stopPropagation();
        this.initOffset = this.cellRef.current.getBoundingClientRect().right - event.clientX;

        window.document.addEventListener("mousemove", this.handleMouseMove);
        // use capture to prevent row click after resizing
        window.document.addEventListener("click", this.handleMouseUp, true);
    };

    // prevent click event from propagation to row, to prevent row selection when resizing
    handleMouseClick = (event: React.MouseEvent | MouseEvent): void => {
        event.stopPropagation();
        event.preventDefault();
    };

    handleColumnResize = (event: MouseEvent, end?: boolean): void => {
        let newWidth;

        if (this.props.onColumnResize) {
            const offset = this.cellRef.current.getBoundingClientRect().right - event.clientX;
            newWidth = (event.clientX - this.cellRef.current.getBoundingClientRect().left) + this.initOffset;

            this.props.onColumnResize({
                id: this.props.id,
                columnIndex: this.props.columnIndex,
                oldWidth: this.props.width,
                width: newWidth,
                delta: newWidth - this.props.width,
                stepDelta: this.initOffset - offset,
                level: this.props.level,
                end
            });
        }
    };

    handleMouseMove = (event: MouseEvent): void => {
        this.handleColumnResize(event);
    };

    handleMouseUp = (event: MouseEvent): void => {
        event.stopPropagation();
        event.preventDefault();
        this.handleColumnResize(event, true);

        window.document.removeEventListener("mousemove", this.handleMouseMove);
        window.document.removeEventListener("click", this.handleMouseUp, true);
    };

    renderContent = (): React.ReactElement => {
        // overflowing text is supposed to have tooltip
        return (
            <Tooltip content={this.props.tooltip ?? this.props.children}
                     onlyShowWhenChildrenOverflowing={this.props.onlyShowTooltipWhenChildrenOverflowing}>
                {(ref) => {
                    return (
                        <StyledBodyCellContent data-column-index={this.props.columnIndex}
                                               allowOverflow={this.props.allowOverflow}
                                               stretchContent={this.props.stretchContent}
                                               _width={this.props.width}
                                               isLoading={this.props.isLoading}
                                               data-testid={TestIds.TableCellContent}
                                               ref={ref}>
                            {this.props.isLoading ? null : this.props.children}
                        </StyledBodyCellContent>
                    );
                }}
            </Tooltip>
        );
    };

    renderAfterContent = (): React.ReactElement => {
        return (
            <AfterContentWrapper data-testid={TestIds.TableCellAfterContent}>
                <AfterContent _minWidth={this.props.afterContentMinWidth}>
                    {this.props.afterContent}
                </AfterContent>
            </AfterContentWrapper>
        );
    };

    renderHoverContent = (): React.ReactElement => {
        return (
            <HoverContentWrapper data-testid={TestIds.TableCellHoverContent}
                                 textAlign={this.props.textAlign}>
                {this.props.hoverContent}
            </HoverContentWrapper>
        );
    };

    render() {
        const hasHoverContent = !!this.props.hoverContent;
        return (
            <StyledBodyCell ref={this.cellRef}
                            sticky={this.props.sticky}
                            isForPrint={this.props.isForPrint}
                            isColumnHighlighted={this.props.isColumnHighlighted}
                            isRowHighlighted={this.props.isRowHighlighted}
                            border={this.props.border}
                            _width={this.props.width}
                            first={this.props.first}
                            last={this.props.last}
                            textAlign={this.props.textAlign}
                            hasHoverContent={hasHoverContent}
                            hasAction={this.props.hasAction}
                            keepSpaceForStatus={this.props.keepSpaceForStatus}
                            role="cell"
                            isHierarchy={this.props.isHierarchy}
                            customBackgroundColor={this.props.customBackgroundColor}
                            data-testid={TestIds.TableCell}>
                {this.renderContent()}
                {this.props.afterContent && this.renderAfterContent()}
                {hasHoverContent && this.renderHoverContent()}
                {this.props.onColumnResize &&
                    <Resizer onMouseDown={this.handleMouseDown} onClick={this.handleMouseClick}/>
                }
            </StyledBodyCell>
        );
    }
}