import BindingContext, { IEntity } from "../../../odata/BindingContext";
import { TableStorage } from "../../../model/TableStorage";
import { cloneDeep } from "lodash";
import { TRecordType, TValue } from "../../../global.types";
import { IFieldDef } from "../FieldInfo";
import { getBoundValue } from "@odata/Data.utils";
import { FieldType } from "../../../enums";
import { IFormStorageDefaultCustomData } from "../../../views/formView/FormStorage";
import { TFunction } from "i18next";


export enum BulkChangeAction {
    Add = "Add",
    Replace = "Replace",
    Remove = "Remove",
    RemoveAll = "RemoveAll"
}

export interface IBulkChangeDialogStorageCustomData extends IFormStorageDefaultCustomData {
    isChecked: Record<string, boolean>;
}

// todo could be changed from PerFieldType to differentiate between collections and single entities,
// we'll see after we use more than two different fields in BulkChange
export const BulkChangeActionPerFieldType: Partial<Record<FieldType, BulkChangeAction[]>> = {
    [FieldType.LabelSelect]: [BulkChangeAction.Add, BulkChangeAction.Replace, BulkChangeAction.Remove, BulkChangeAction.RemoveAll],
    [FieldType.EditableText]: [BulkChangeAction.Replace, BulkChangeAction.Remove]
};

export const getBulkChangeActionTranslation = (id: BulkChangeAction, isCollection: boolean, t: TFunction): string => {
    return t(`Common:BulkChange.${id === BulkChangeAction.Remove && !isCollection ? "RemoveExisting" : id}`);
};

export const getDefaultBulkChangeAction = (isCollection: boolean): BulkChangeAction => {
    return isCollection ? BulkChangeAction.Add : BulkChangeAction.Replace;
};

export function replaceCollectionValues(storage: TableStorage, data: IEntity, originalEntity: IEntity, collections: string[], collectionActions: TRecordType<BulkChangeAction>): IEntity {
    const replaced = cloneDeep(data);
    collections.forEach(path => {
        const bindingContext = storage.data.bindingContext.navigate(path);
        const def = { id: path, ...storage.data.definition.massEditableDef[path] };
        replaced[path] = replaceCollectionValue(def, data, originalEntity, collectionActions[path], bindingContext);
    });
    return replaced;
}

export function replaceCollectionValue(def: IFieldDef, data: IEntity, originalEntity: IEntity, action: BulkChangeAction, bindingContext: BindingContext): TValue[] {
    const path = def.id;
    const origCollection = originalEntity[path] ?? [];
    const keyPath = def.fieldSettings?.keyPath;
    const keyPathBc = bindingContext.navigate(keyPath ?? bindingContext.getKeyPropertyName());

    // compares collection items by key property
    const _areItemsSame = (item1: IEntity, item2: IEntity): boolean => {
        const id1 = getBoundValue({ bindingContext: keyPathBc, data: item1, dataBindingContext: bindingContext });
        const id2 = getBoundValue({ bindingContext: keyPathBc, data: item2, dataBindingContext: bindingContext });
        return id1 === id2;
    };

    const _isItemInCollection = (item: IEntity, collection: IEntity[]) => {
        return collection.find(collectionItem => _areItemsSame(collectionItem, item));
    };

    switch (action) {
        case BulkChangeAction.RemoveAll:
            return [];
        case BulkChangeAction.Replace:
            return data[path];
        case BulkChangeAction.Remove:
            // new value is original value without removed items
            return origCollection
                .filter((originalItem: IEntity) => !_isItemInCollection(originalItem, data[path]));
        case BulkChangeAction.Add:
        default:
            // new value is original value with added items, if they are not already contained
            const addedWithoutOriginal = (data[path] ?? []).filter((addedItem: IEntity) => !_isItemInCollection(addedItem, origCollection));
            return [...origCollection, ...addedWithoutOriginal];
    }
}