import styled from "styled-components/macro";
import { getFocusBorderElement, T_PLAIN_big, T_PLAIN_big_hig, T_TITLE_4_NORMAL } from "../../global.style";
import { LockFilledIcon } from "@components/icon";
import { ellipsis } from "polished";
import { StyledFieldsWrapper } from "@components/inputs/field/FieldsWrapper.styles";
import { ScrollBar } from "../../components/scrollBar";
import { StyledButtonGroup } from "@components/button";

export const IconWrapper = styled.div`
    padding: 0 14px;
`;

export const Circle = styled.div`
    border: 1px solid ${props => props.theme.C_TEXT_primary};
    border-radius: 23px;
    height: 22px;
    width: 22px;
    margin: 0 2px;
    background: ${props => props.theme.C_BG_floorplan};
`;

export const CaretWrapper = styled.div`
    position: absolute;
    right: 4px;
    cursor: pointer;
`;

export const TaskRowWrapper = styled.div<{
    isSelected?: boolean
}>`
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    height: 50px;
    overflow: visible;

    & > ${LockFilledIcon} {
        position: absolute;
        top: 15px;
        left: -5px;
    }

    ${IconWrapper} {
        circle {
            fill: ${props => props.theme.C_BG_floorplan};
        }
    }

    ${CaretWrapper} {
        display: ${props => props.isSelected ? "block" : "none"};
    }

    &:hover {
        ${CaretWrapper} {
            display: block;
        }
    }
`;

export const TaskRowStyled = styled.div<{
    isChecked: boolean,
    isSelected?: boolean,
    isClosing?: boolean
}>`
    background: ${props => props.theme.C_BG_row_field};
    width: 100%;
    height: 100%;
    padding: 5px 26px;
    font-weight: bold;
    font-size: 16px;
    ${ellipsis()};
    display: inline-flex;
    align-items: center;
    letter-spacing: 0.14px;
    border-radius: ${props => props.theme.borderRadius};
    position: relative;
    cursor: pointer;
    box-shadow: ${props => props.isSelected ? "0 0 15px 0 rgba(0, 0, 0, 0.15)" : "0 0 5px 0 rgba(0, 0, 0, 0.05)"};

    ${props => props.isChecked && `
        &:after {
            border-top-left-radius: ${props.theme.borderRadius};
            border-bottom-left-radius: ${props.theme.borderRadius};
            content: "";
            width: 6px;
            top: 0;
            left: 0;
            height: 100%;
            position: absolute;
            background-color: ${props.theme.C_SEM_el_good};
        }`}
    ${IconWrapper} {
        position: absolute;
        left: 0;
        top: 14px;

        ${Circle} {
            background: none;
        }

        circle {
            fill: none;
        }
    }

    ${props => props.isClosing && `
        padding-left: 54px;
    `}
    &:hover {
        background-color: ${props => props.theme.C_BG_row_field_hover}; // C_ACT_hover combined with C_BG_row_field
    }

    ${getFocusBorderElement({ offset: 0, keyboardOnly: true })}
`;

export const TableWrapper = styled.div<{
    $marginBottom?: number
}>`
    height: fit-content;
    margin-bottom: ${props => props.$marginBottom ?? 20}px;
    width: 100%;

    & + ${StyledButtonGroup} {
        margin-top: ${props => 60 - (props.$marginBottom ?? 20)}px;
    }
`;

export const TasksWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    padding: 0 14px;
    position: relative;

    &:before {
        position: absolute;
        content: "";
        left: 40px;
        top: 40px;
        width: 0;
        height: calc(100% - 90px);
        border-left: dotted 3px #efe2ea;
    }
`;

export const SmallHeaderStyled = styled.h2`
    font-size: 22px;
    font-weight: bold;

    &:not(:first-of-type) {
        margin-top: 20px;
    }

    margin-bottom: 15px;
`;

export const TextStyled = styled.p`
    ${T_TITLE_4_NORMAL};
    margin: 0 0 20px 0;
`;

export const CellContentStyled = styled.div<{ isBold: boolean }>`
    display: flex;
    align-items: center;
    font-weight: ${props => props.isBold ? "bold" : "normal"};
    justify-content: flex-end;

    & > svg {
        margin-left: 5px;
    }

    [data-icon="Caret"] {
        display: none;
    }
`;

export const InventoryGrid = styled.div<{ $isLoading: boolean }>`
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    row-gap: 12px;
    margin: 20px 0 30px 0;
    width: 100%;
    overflow-x: auto;
    flex-shrink: 0;
    position: relative;
    min-height: ${props => props.$isLoading ? "200px" : 0};

    & > div:nth-child(5n+1) {
        padding-left: 12px !important;
    }

    a {
        margin-top: -1px;
        ${T_PLAIN_big}
    }
`;

export const AdditionalAccountInfoTable = styled.div`
    display: inline-grid; // inline grid to prevent additional line break after the table
    width: 100%;
    grid-template-columns: auto auto;
    color: ${props => props.theme.C_TEXT_primary};
    margin-top: 15px;
    text-align: left;
    row-gap: 5px;

    div:nth-of-type(2n) {
        text-align: right;
    }

    div {
        position: relative;
        padding-right: 5px;
        ${T_PLAIN_big}
    }

    div:nth-of-type(0), div:nth-of-type(1) {
        ${T_PLAIN_big_hig}
    }
`;

export const AccountNamesTableStyled = styled.div`
    margin-top: 10px;
`;

export const AccountNameStyled = styled.div`
    position: relative;
    & > a {
        ${T_PLAIN_big}
    }
`;

export const SwitchWrapperStyled = styled(StyledFieldsWrapper)`
    justify-content: flex-end;
    flex-grow: 0;
    margin-top: 16px;
    padding-bottom: 36px;
`;

export const StyledFieldsWrapperWithMargin = styled(StyledFieldsWrapper)`
    margin-bottom: 20px;
    flex-grow: 0;
`;

export const Gap = styled.span`
    display: inline-block;
    width: 30px;
    height: 1px;
    margin: 0;
`;

export const StyledScrollBar = styled(ScrollBar)`
    left: -15px;
    padding-top: 15px;
`;

export const StyledRadioLabel = styled.div<{
    size: number;
}>`
    display: inline-block;
    text-align: right;
    min-width: ${props => `${props.size - 1}ch`}; // ch is size of digit "0", -1 because of decimal point and space before unit
`;