import React from "react";
import { StyledFieldsWrapper } from "./FieldsWrapper.styles";
import TestIds from "../../../testIds";

interface IProps {
    /** Renders as flex column, instead of row */
    isColumn?: boolean;
    /** Used for smaller gap between rows (used in FastEntry) */
    smallerGap?: boolean;
    isWithoutWrap?: boolean;
    testid?: string;
    passRef?: React.Ref<HTMLDivElement>;
    className?: string;
    style?: React.CSSProperties;
}

/**
 * Everytime multiple Field components are rendered next to each other,
 * they have to be put into FieldsWrapper.
 * This way, margins and row-gap will be correctly applied.
 *
 * */
class FieldsWrapper extends React.PureComponent<IProps> {
    render() {
        return (
            <StyledFieldsWrapper $isColumn={this.props.isColumn}
                                 className={this.props.className}
                                 style={this.props.style}
                                 $smallerGap={this.props.smallerGap}
                                 $isWithoutWrap={this.props.isWithoutWrap}
                                 data-testid={this.props.testid ?? TestIds.FieldsWrapper}
                                 ref={this.props.passRef}>
                {this.props.children}
            </StyledFieldsWrapper>
        );
    }
}

export default FieldsWrapper;
